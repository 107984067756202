import React from 'react';
import { MdOutlineShoppingCart, MdOutlineShoppingBag } from 'react-icons/md';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Feature identification
const featureKey = '@create_order/CART_DELIVERY_TAB_MENU';

interface IProps {
  selectedTab: 'infinite_aisle' | 'own_stock';
  setSelectedTab: (value: 'infinite_aisle' | 'own_stock') => void;
}

const OwnStockTabMenu: React.FC<IProps> = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation(featureKey);
  return (
    <div className="tabMenu">
      <button
        type="button"
        onClick={() => setSelectedTab('own_stock')}
        className={selectedTab === 'infinite_aisle' ? 'disabledButton' : ''}
      >
        <div className="buttonContent">
          <MdOutlineShoppingBag />
          <p>{t('OWN_STOCK', 'Levar Agora')}</p>
        </div>
      </button>
      <button
        type="button"
        onClick={() => setSelectedTab('infinite_aisle')}
        className={selectedTab === 'own_stock' ? 'disabledButton' : ''}
      >
        <div className="buttonContent">
          <MdOutlineShoppingCart />
          <p>{t('INFINITE_AISLE', 'Receber em Casa')}</p>
        </div>
      </button>
    </div>
  );
};

export { OwnStockTabMenu };
