import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .mobileMetadata {
    @media screen and (min-width: 769px) {
      display: none;
    }
  }

  .desktopMetadata {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;
