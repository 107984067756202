import React, { useRef, useState, useEffect } from 'react';

// Components import
import { Modal, IModalRef } from '@components/Modal';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Service import
import { maisVendasApi } from '@services/maisVendasApi';

// Util import
import { getNPSAssessmentDates } from '@util/getNPSAssessmentDates';

import { SatisfactionSurveyModal } from './SatisfactionSurveyModal';
import { FirstStepModal } from './FirstStepModal';
import { SecondStepModal } from './SecondStepModal';
import { ThirdStepModal } from './ThirdStepModal';
import { FarewellModal } from './FarewellModal';

// Style import
import { Container, LoadingContainer } from './styles';

// const featureKey = '@satisfaction_survey/INDEX';

const SatisfactionSurvey: React.FC = () => {
  // Local refs
  const satisfactionSurveyModalRef = useRef<IModalRef>(null);

  // Local states
  const [selectedSentiment, setSelectedSentiment] = useState<
    | 'very_dissatisfied'
    | 'dissatisfied'
    | 'neutral'
    | 'satisfied'
    | 'very_satisfied'
    | 'none'
  >('none');

  const [selectedStep, setSelectedStep] = useState<
    'salutation' | 'first_step' | 'second_step' | 'third_step' | 'farewell'
  >('salutation');
  const [secondStepComment, setSecondStepComment] = useState('');
  const [thirdStepComment, setThirdStepComment] = useState('');
  const [loading, setLoading] = useState(false);

  const RenderSteps = () => {
    switch (selectedStep) {
      case 'salutation':
        return (
          <SatisfactionSurveyModal
            hide={() => satisfactionSurveyModalRef.current?.hide()}
            setSelectedStep={setSelectedStep}
          />
        );
      case 'first_step':
        return (
          <FirstStepModal
            hide={() => satisfactionSurveyModalRef.current?.hide()}
            selectedSentiment={selectedSentiment}
            setSelectedSentiment={setSelectedSentiment}
            setSelectedStep={setSelectedStep}
          />
        );
      case 'second_step':
        return (
          <SecondStepModal
            hide={() => satisfactionSurveyModalRef.current?.hide()}
            selectedSentiment={selectedSentiment}
            setSelectedStep={setSelectedStep}
            secondStepComment={secondStepComment}
            setSecondStepComment={setSecondStepComment}
          />
        );
      case 'third_step':
        return (
          <ThirdStepModal
            hide={() => satisfactionSurveyModalRef.current?.hide()}
            setSelectedStep={setSelectedStep}
            selectedSentiment={selectedSentiment}
            secondStepComment={secondStepComment}
            thirdStepComment={thirdStepComment}
            setThirdStepComment={setThirdStepComment}
            setLoading={setLoading}
          />
        );
      case 'farewell':
        return (
          <FarewellModal
            hide={() => satisfactionSurveyModalRef.current?.hide()}
          />
        );
      default:
        return (
          <SatisfactionSurveyModal
            hide={() => satisfactionSurveyModalRef.current?.hide()}
            setSelectedStep={setSelectedStep}
          />
        );
    }
  };

  // Check if NPS modal will be shown
  useEffect(() => {
    const date = new Date();
    const handleNPSModal = async () => {
      const getLocalNPSAnswerDate = localStorage.getItem('NPSAnswerDate');

      const localAnswerIsValid =
        getLocalNPSAnswerDate && getNPSAssessmentDates(getLocalNPSAnswerDate);

      if (localAnswerIsValid) {
        return;
      }

      const response = await maisVendasApi.get('/soma-store-nps');

      if (!response) {
        satisfactionSurveyModalRef.current?.show();
        return;
      }

      const lastNPSAnswerSent = response?.data?.createdAt;
      localStorage.setItem('NPSAnswerDate', lastNPSAnswerSent);
      const isLastSentNPSAnswerValid = getNPSAssessmentDates(lastNPSAnswerSent);

      if (!isLastSentNPSAnswerValid) {
        satisfactionSurveyModalRef.current?.show();
      }
    };

    if (date.getDate() >= 27 || date.getDate() <= 10) {
      handleNPSModal();
    }
  }, []);

  return (
    <>
      <Container>
        <Modal ref={satisfactionSurveyModalRef} size="small-1">
          {loading ? (
            <LoadingContainer>
              <LoadingIndicator size={2} color="primary" />
            </LoadingContainer>
          ) : (
            <RenderSteps />
          )}
        </Modal>
      </Container>
      <div />
    </>
  );
};

export { SatisfactionSurvey };
