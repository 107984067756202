import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;

  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;
  padding-left: 3rem;

  @media screen and (max-width: 768px) {
    margin-top: 0rem;
  }

  .giftCardProduct {
    display: flex;
    flex-direction: column;
    width: 100%;

    .giftCardProductTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 30%;

      color: ${({ theme }) => theme.font_secondary};
      font-size: 1.5rem;
      font-weight: 600;

      p {
        font-size: 1.25rem;
        margin-left: 0.75rem;
      }

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }

    .giftCardProductContent {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .giftCardComponent {
        width: 50%;
        margin-right: 1rem;

        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      .giftCardInfo {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin-left: 1rem;

        @media screen and (max-width: 768px) {
          width: 100%;
          /* padding-right: 1rem; */
          margin-left: 0rem;
        }

        .unavailableProduct {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          height: 100%;
        }

        .giftCardUserInfo {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          @media screen and (max-width: 768px) {
            gap: 0;
          }

          .giftCardUserInfoTitle {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.75rem;
          }

          .giftCardUserInfoContent {
            display: flex;
            flex-direction: column;

            .giftCardUserInfoEdit {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 1.5rem;

              .editText {
                display: flex;
                flex-direction: column;

                .editTextTitle {
                  font-weight: 300;
                  font-size: 0.875rem;
                  line-height: 1.5rem;
                }

                .editTextContent {
                  font-weight: 500;
                  font-size: 1rem;
                  line-height: 1.5rem;
                }
              }

              .editIcon {
                button {
                  svg {
                    fill: ${({ theme }) => theme.button_primary};
                    width: 1.5rem;
                    height: 1.5rem;
                  }
                }
              }
            }

            .checkEmail {
              display: flex;
              flex-direction: row;
              align-items: center;

              font-size: 0.75rem;
              font-weight: 400;
            }
          }
        }

        .divider {
          width: 100%;
          border-top: 0.063rem solid ${({ theme }) => theme.font_secondary};
          margin-top: 1rem;
          margin-bottom: 1rem;
          border-radius: 0.125rem;
        }

        .giftCardTimeInfo {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .giftCardTimeInfoTitle {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.75rem;
          }

          .giftCardTimeInfoEdit {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .editText {
              display: flex;
              flex-direction: column;

              .editTextTitle {
                font-weight: 300;
                font-size: 0.875rem;
                line-height: 1.5rem;
              }

              .editTextContent {
                font-weight: 500;
                font-size: 1rem;
                line-height: 1.5rem;
              }
            }

            .editIcon {
              button {
                svg {
                  fill: ${({ theme }) => theme.button_primary};
                  width: 1.5rem;
                  height: 1.5rem;
                }
              }
            }
          }

          .giftCardTimeInfoContent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            button {
              width: 45%;
              border: solid 0.188rem ${({ theme }) => theme.button_primary};
              border-radius: 0.5rem;
              padding: 1rem;

              p {
                color: ${({ theme }) => theme.font_secondary};
                font-weight: 700;
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    padding-left: 0;
  }
`;

export const ProductItemDesktop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  background: ${({ theme }) => theme.background_primary};
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  min-height: 11.688rem;
  margin-right: 0.5rem;

  @media screen and (max-width: 768px) {
    margin-right: 0rem;
  }

  .quantityMobileRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 1rem;
    padding: 1rem;

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.font_secondary};
      margin-right: 1rem;
    }

    .quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.background_high};
      border-radius: 0.25rem;

      button {
        min-width: auto;
        width: 1.875rem;
        height: 1.875rem;
      }

      .number {
        color: ${({ theme }) => theme.font_low};
        padding: 0 0.5rem;
      }
    }
  }

  .generalArea {
    display: flex;
    width: 100%;

    .image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      align-self: stretch;
      max-width: 8.625rem;

      img {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        object-fit: cover;
        flex: 1;
        align-self: stretch;
        max-width: 8.625rem;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      width: 100%;
      padding: 1rem;
      overflow: hidden;

      .name {
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme.font_primary};
        width: -webkit-fill-available;
        margin-bottom: 0.25rem;
        P {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .infoRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin-bottom: 0.25rem;

        color: ${({ theme }) => theme.font_secondary};
        font-size: 0.75rem;
        font-weight: 400;

        p:first-child {
          margin-right: 1rem;
        }
      }

      .colorAndSizeRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;

        color: ${({ theme }) => theme.font_secondary};
        font-size: 0.75rem;
        font-weight: 400;

        .sizeText {
          display: flex;
          flex-direction: row;

          font-size: 0.75rem;
          color: ${({ theme }) => theme.font_primary};
          text-decoration: underline;
          margin-right: 0.125rem;
        }
      }

      .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        font-size: 1rem;
        color: ${({ theme }) => theme.font_primary};

        .original_price {
          text-decoration: line-through;
          color: ${({ theme }) => theme.button_secondary};
        }
      }
      .unavailablePill {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        p {
          background-color: ${({ theme }) => theme.background_danger};
          padding: 0.5rem 1.5rem;
          border-radius: 1.25rem;
          font-weight: 500;
        }
      }

      .editDelivery {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        button {
          p {
            font-size: 0.75rem;
            font-weight: 400;
            text-decoration: underline;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }

      .quantityRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        gap: 1rem;

        p {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.font_secondary};
          margin-right: 1rem;
        }

        .quantity {
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${({ theme }) => theme.background_high};
          border-radius: 0.25rem;

          button {
            min-width: auto;
            width: 1.2rem;
            height: 1.2rem;
          }

          .number {
            color: ${({ theme }) => theme.font_low};
            padding: 0 0.5rem;
          }
        }
      }
    }
  }
`;
