import React from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

interface IProps {
  hide?: () => any;
}

// Feature identification
const featureKey = '@create_order/OWN_STOCK_CONFIRMATION_MODAL';

const OwnStockConfirmationModal: React.FC<IProps> = ({ hide }) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="titleRow">{t('OWN_STOCK', 'Levar Agora!')}</div>
      <p className="textRow">
        {t(
          'AVAILABILITY_NOTICE',
          'Os produtos selecionados para entrega de\nLevar Agora\nDevem ser verificados no estoque de loja antes do envio de disponibilidade para sua cliente.',
        )}
      </p>
      <div className="actionArea">
        <Button
          onClick={() => {
            localStorage.setItem('ownStockWarning', 'seen');
            if (typeof hide === 'function') hide();
          }}
        >
          {t('CLOSE_MODAL', 'ENTENDI')}
        </Button>
      </div>
      <button
        className="noRepeatRow"
        type="button"
        onClick={() => {
          localStorage.setItem('ownStockWarning', 'seen');
          if (typeof hide === 'function') hide();
        }}
      >
        {t('DONT_SHOW_AGAIN', 'NÃO MOSTRAR NOVAMENTE')}
      </button>
    </Container>
  );
};

export { OwnStockConfirmationModal };
