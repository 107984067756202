import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 50%;
  gap: 0.25rem;

  div {
    span {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    gap: 0.5rem;
  }
`;
