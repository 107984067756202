import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  MdOutlineShoppingCart,
  MdOutlineShoppingBag,
  MdInfoOutline,
} from 'react-icons/md';
import { Locale, getDate, parseJSON, format } from 'date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import { useDebounce } from 'use-lodash-debounce';

// Asset import
import { ReactComponent as Tooltip } from '@assets/tooltip.svg';
import DefaultProductImage from '@assets/defaultProductImage.png';
import { InvoiceIcon, SwapHorizontalCircle } from '@assets/index';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';
import { Button } from '@components/Button';
import { Modal, IModalRef } from '@components/Modal';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';
import { OrderProvider, useOrder } from '@hooks/useOrder';
import { useWindowSize } from '@hooks/useWindowSize';

// Service import
import { invoiceApi } from '@services/invoiceApi';
import { api, getResponseError } from '@services/api';
import { Analytics } from '@services/analytics';

import { setBagId } from '@store/modules/ui/actions';
import {
  IOrderBrandProviderPackage,
  IOrderPayment,
} from '@store/modules/auth/types';

// Page import
import {
  ManualPhysicalCardPayment,
  IManualPhysicalCardModalRef,
} from './ManualPhysicalCardPayment';
import { CancelOrder, ICancelOrderModalRef } from './CancelOrder';
import { RedoService, IRedoServiceModalRef } from './RedoService';
import { PrintFileModal } from './PrintFileModal';
import { AwaitInvoiceModal } from './AwaitInvoiceModal';
import { DocumentAvailabilityWarning } from './DocumentAvailabilityWarning';
import { PaymentDataModal, IPaymentDataModalRef } from './PaymentDataModal';
import { DisconnectedPrinterModal } from './DisconnectedPrinterModal';
import { ClientMetadata } from './ClientMetadata';

// Style import
import {
  Container,
  Payments,
  Floating,
  Placeholder,
  Time,
  Status,
  AdditionalInformation,
  ProductItemDesktop,
  Totals,
  TotalsMobile,
  AdditionalInformationMobile,
} from './styles';

interface IPaymentType {
  id: string;
  code: string;
  name: string;
  created_at: Date;
  updated_at: Date;
}

// Feature identification
const featureKey = '@order/INDEX';

const Content: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const { id } = useParams<{ id?: string }>();
  const order = useOrder(id);
  const [width] = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();

  // Global states
  const language = useSelector(state => state.ui.language);

  // Local refs
  const cancelModalRef = useRef<ICancelOrderModalRef>(null);
  const paymentModalModalRef = useRef<IPaymentDataModalRef>(null);
  const redoServiceModalRef = useRef<IRedoServiceModalRef>(null);
  const manualPhysicalCardModalRef = useRef<IManualPhysicalCardModalRef>(null);
  const invoiceModalRef = useRef<IModalRef>(null);
  const exchangeCouponModalRef = useRef<IModalRef>(null);
  const awaitInvoiceModalRef = useRef<IModalRef>(null);
  const awaitExchangeCouponModalRef = useRef<IModalRef>(null);
  const disconnectedPrinterModalRef = useRef<IModalRef>(null);

  // Local states
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);
  const [errorInfoSection, setErrorInfoSection] = useState<boolean>(false);
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();
  const [invoiceRef, setInvoiceRef] = useState('');

  const tooltipTimerDebounce = useDebounce(errorInfoSection, 5000);

  const orderData: string = order.data?.order.id || '';
  const paidAmount: number = order.data?.status.paid_amount || 0;
  const orderOwnStockPackage = order?.data?.order?.packages?.find(
    _packages => _packages.package_type.code === 'own_stock',
  );
  const invoiceCode = useMemo(() => {
    return orderOwnStockPackage?.invoices?.[0]?.key;
  }, [orderOwnStockPackage?.invoices]);

  useEffect(() => {
    if (tooltipTimerDebounce === true) {
      setErrorInfoSection(false);
    }
  }, [tooltipTimerDebounce]);

  const quantity = order.data?.order.packages
    .map(order_package =>
      order_package.items
        .map(item => item.quantity)
        .reduce((subTotal, subCurrent) => subTotal + subCurrent, 0),
    )
    .reduce((total, current) => total + current, 0);

  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  useEffect(() => {
    const getInvoice = async () => {
      setInvoiceLoading(true);
      try {
        const response = await invoiceApi.get(
          `/v1/NotaFiscal/pdfNota/${invoiceCode}`,
          { responseType: 'blob' },
        );

        const createBlob = new Blob([response.data]);
        const newURL = window.URL.createObjectURL(createBlob);

        setInvoiceLoading(false);
        setInvoiceRef(newURL);
      } catch {
        toast.error(t('INVOICE_ERROR', 'Erro obtendo PDF da nota fiscal'));
        setInvoiceLoading(false);
      }
    };

    if (invoiceCode) {
      getInvoice();
    }
  }, [invoiceCode, t]);

  // Status badge
  const status = useMemo(() => {
    switch (order.data?.order.status) {
      case 'WAIT_ADJUST':
        return (
          <Status className="error">
            <span>{t('ORDER_HAS_ERROR', 'Pedido está sendo ajustado')}</span>
          </Status>
        );
      case 'CANCELED':
        if (order?.data.order.payments.every(item => item.completed_at)) {
          return (
            <Status className="canceled">
              <span>{t('ORDER_PAID_CANCELED', 'Pedido pago e cancelado')}</span>
            </Status>
          );
        }
        return (
          <Status className="canceled">
            <span>
              {t('ORDER_CANCELED', 'Pedido cancelado sem pagamento realizado.')}
            </span>
          </Status>
        );
      case 'COMPLETED':
        return (
          <Status className="success">
            <span>
              {t('ORDER_COMPLETED', 'Pedido finalizado com sucesso.')}
            </span>
          </Status>
        );
      case 'PENDING':
        return (
          <Status className="pending">
            <span>{order.statusPendingText}</span>
          </Status>
        );
      default:
        return <></>;
    }
  }, [t, order.data, order.statusPendingText]);

  const getPaymentName = (paymentType: IPaymentType) => {
    switch (paymentType.code) {
      case 'payment_link':
        return 'Link';
      case 'physical_card':
        return 'POS';
      case 'store_credit':
        return 'Saldo de Créditos';

      default:
        return paymentType?.name;
    }
  };

  const modals = useMemo(
    () => (
      <>
        <Modal ref={invoiceModalRef} size="smaller">
          <PrintFileModal
            hide={() => invoiceModalRef.current?.hide()}
            orderId={order.data?.order.id}
            type="invoice"
            invoiceURL={invoiceRef}
            orderData={order?.data}
            invoiceLoading={invoiceLoading}
            disconnectedPrinterModalRef={disconnectedPrinterModalRef}
          />
        </Modal>
        <Modal ref={exchangeCouponModalRef} size="smaller">
          <PrintFileModal
            hide={() => exchangeCouponModalRef.current?.hide()}
            orderId={order.data?.order.id}
            type="voucher"
            orderData={order?.data}
            disconnectedPrinterModalRef={disconnectedPrinterModalRef}
          />
        </Modal>
        <Modal ref={awaitInvoiceModalRef} size="smaller">
          <AwaitInvoiceModal
            hide={() => awaitInvoiceModalRef.current?.hide()}
            type="invoice"
          />
        </Modal>
        <Modal ref={awaitExchangeCouponModalRef} size="smaller">
          <AwaitInvoiceModal
            hide={() => awaitExchangeCouponModalRef.current?.hide()}
            type="exchangeCoupon"
          />
        </Modal>
        <Modal ref={disconnectedPrinterModalRef} size="small-1">
          <DisconnectedPrinterModal
            hide={() => disconnectedPrinterModalRef.current?.hide()}
          />
        </Modal>
      </>
    ),
    [invoiceRef, order?.data, invoiceLoading],
  );

  // If id is not set
  if (!id || !order.data) {
    if (order.dataLoading)
      return (
        <Placeholder>
          <LoadingIndicator />
          <PaymentDataModal />
        </Placeholder>
      );

    return (
      <Placeholder>
        <span>
          {t('FETCH_FAILED', 'Não foi possível obter os dados deste pedido!')}
        </span>
        {!!id && (
          <Button onClick={() => order.load(id)} noFullWidth>
            CARREGAR
          </Button>
        )}
      </Placeholder>
    );
  }

  const time = ((!!order.orderUntil && !!order.timePendingText) ||
    order.data?.order.status === 'WAIT_ADJUST' ||
    order.data?.order.status === 'CANCELED' ||
    order.data?.order.status === 'COMPLETED') && (
    <Time
      className={
        order.data?.order.status === 'WAIT_ADJUST'
          ? 'error'
          : order.data?.order.status === 'CANCELED'
          ? 'canceled'
          : order.data?.order.status === 'COMPLETED'
          ? 'success'
          : 'pending'
      }
    >
      {order.data?.order.status === 'WAIT_ADJUST'
        ? t('TO_ADJUST_ORDER', 'Pedido Cancelado a Ajustar')
        : order.data?.order.status === 'CANCELED'
        ? t('EXPIRED_ORDER', 'Pedido Cancelado')
        : order.data?.order.status === 'COMPLETED'
        ? t('PAYMENT_DETECTED', 'Pagamento Finalizado')
        : order.timePendingText}
    </Time>
  );

  const getPaymentCode = () => {
    switch (order.data?.order.status) {
      case 'WAIT_ADJUST':
        return 'to_adjust';
      case 'CANCELED':
        return 'canceled';
      case 'PENDING':
        return 'pending';
      case 'COMPLETED':
        return 'paid';
      default:
        return 'pending';
    }
  };

  const getPaymentStatus = () => {
    const findCanceledPayments = order?.data?.order.payments.some(
      item => item.canceled_at,
    );
    const findCompletedPayments = order?.data?.order.payments.some(
      item => item.completed_at,
    );

    if (order.data?.order.status === 'WAIT_ADJUST')
      return t('PAYMENT_TO_ADJUST', 'Pagamento a ajustar');
    if (findCanceledPayments && findCompletedPayments)
      return t('PAYMENT_RETURNED', 'Estorno realizado');
    if (findCanceledPayments)
      return t('PAYMENT_CANCELED', 'Pagamento não realizado');
    if (findCompletedPayments || paidAmount === order.data?.order.total)
      return t('PAYMENT_COMPLETED', 'Pagamento realizado');
    return (
      <button
        type="button"
        onClick={() => paymentModalModalRef.current?.show()}
      >
        {t('RESEND_PAYMENT', 'Reenviar Pagamento')}
      </button>
    );
  };

  const getPaymentTypeTitle = (payment: IOrderPayment) => {
    return (
      <span className="type">
        Pagamento por {getPaymentName(payment.payment_type)}{' '}
      </span>
    );
  };

  const payments = (
    <>
      <Payments>
        <ul>
          {order.data.order.payments.map((item, index) => (
            <li>
              <div className="left">
                <div className="info">
                  <span className="count">#{index + 1}</span>
                  <div>
                    {getPaymentTypeTitle(item)}
                    <span className={`status ${getPaymentCode()}`}>
                      <div className="statusRow">
                        <div>{getPaymentStatus()}</div>
                        <div className="errorSection">
                          {order.data?.order.status === 'WAIT_ADJUST' && (
                            <>
                              <button
                                type="button"
                                onClick={() =>
                                  setErrorInfoSection(!errorInfoSection)
                                }
                              >
                                <div>
                                  <MdInfoOutline />
                                </div>
                              </button>
                              {errorInfoSection && (
                                <div className="tooltip">
                                  <Tooltip />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="total">
                  <span className="label">
                    {t('PAYMENT_TOTAL', 'Valor a pagar')}
                  </span>
                  {order.data && (
                    <span className="value">
                      R$ {(item.amount / 100).toFixed(2)}
                    </span>
                  )}
                </div>
                <div className="paid">
                  <span className="label">
                    {t('PAYMENT_PAID', 'Total pago')}
                  </span>
                  <span className="value">
                    R$ {(paidAmount / 100).toFixed(2)}
                  </span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </Payments>
    </>
  );

  const additionalInformation = (
    <>
      {width > 768 ? (
        <AdditionalInformation>
          <div className="infoRow">
            <div>
              <span className="attendance">
                {t('SERVICE_DONE', 'Atendimento Feito: ')}
              </span>

              <span className="info">
                {getDate(parseJSON(order.data.order.created_at))}{' '}
                {format(parseJSON(order.data.order.created_at), 'MMM', {
                  locale: dateLanguagePack,
                })}{' '}
                {format(parseJSON(order.data.order.created_at), 'yyyy', {
                  locale: dateLanguagePack,
                })}
              </span>
            </div>

            <div className="divider" />

            <div>
              <span>Cupom: </span>
              {order.data.order.coupon ? (
                <span className="info">{order.data.order.coupon}</span>
              ) : (
                <span className="info">-</span>
              )}
            </div>
          </div>
        </AdditionalInformation>
      ) : (
        <AdditionalInformationMobile>
          <div className="divider" />
          <div className="rowSection">
            <p className="title">Atendimento feito:</p>
            <p className="info">
              {getDate(parseJSON(order.data.order.created_at))}{' '}
              {format(parseJSON(order.data.order.created_at), 'MMM', {
                locale: dateLanguagePack,
              })}{' '}
              {format(parseJSON(order.data.order.created_at), 'yyyy', {
                locale: dateLanguagePack,
              })}
            </p>
          </div>
          <div className="rowSection">
            <p className="title">Cupom:</p>
            {order.data.order.coupon ? (
              <p className="info">{order.data.order.coupon}</p>
            ) : (
              <p className="info">-</p>
            )}
          </div>
          <div className="divider" />
        </AdditionalInformationMobile>
      )}
    </>
  );

  const totals = (
    <>
      {width > 768 ? (
        <Totals>
          <div className="panel">
            <div className="panelRow">
              <div>
                <p>{quantity} produtos</p>
              </div>
              <div>
                <p>
                  <b>
                    R${' '}
                    {(order.data.order.total_original_price / 100).toFixed(2)}
                  </b>
                </p>
              </div>
            </div>
            <div className="panelRow">
              <div>
                <p>entrega</p>
              </div>
              <div>
                <p>
                  <b>
                    R${' '}
                    {(order.data.order.shipping_current_price / 100).toFixed(2)}
                  </b>
                </p>
              </div>
            </div>
            <div className="panelRow">
              <div>
                <p>descontos</p>
              </div>

              <div>
                {order.data.order.total_original_price -
                  order.data.order.total_current_price >
                0 ? (
                  <p className="discountValue">
                    <b>
                      - R${' '}
                      {(
                        (order.data.order.total_original_price -
                          order.data.order.total_current_price) /
                        100
                      ).toFixed(2)}
                    </b>
                  </p>
                ) : (
                  <p>
                    <b>
                      - R${' '}
                      {(
                        (order.data.order.total_original_price -
                          order.data.order.total_current_price) /
                        100
                      ).toFixed(2)}
                    </b>
                  </p>
                )}
              </div>
            </div>
            <div className="panelRow">
              <div>
                <p>total</p>
              </div>
              <div>
                <p>
                  <b>R$ {(order.data.order.total / 100).toFixed(2)}</b>
                </p>
              </div>
            </div>
          </div>
        </Totals>
      ) : (
        <TotalsMobile>
          <div className="panel">
            <div className="panelRow">
              <div>
                <p>{quantity} produtos</p>
              </div>
              <div>
                <p>
                  <b>
                    R${' '}
                    {(order.data.order.total_original_price / 100).toFixed(2)}
                  </b>
                </p>
              </div>
            </div>
            <div className="panelRow">
              <div>
                <p>entrega</p>
              </div>
              <div>
                <p>
                  <b>
                    R${' '}
                    {(order.data.order.shipping_current_price / 100).toFixed(2)}
                  </b>
                </p>
              </div>
            </div>
            <div className="panelRow">
              <div>
                <p>descontos</p>
              </div>

              <div>
                {order.data.order.total_original_price -
                  order.data.order.total_current_price >
                0 ? (
                  <p className="discountValue">
                    <b>
                      - R${' '}
                      {(
                        (order.data.order.total_original_price -
                          order.data.order.total_current_price) /
                        100
                      ).toFixed(2)}
                    </b>
                  </p>
                ) : (
                  <p>
                    <b>
                      - R${' '}
                      {(
                        (order.data.order.total_original_price -
                          order.data.order.total_current_price) /
                        100
                      ).toFixed(2)}
                    </b>
                  </p>
                )}
              </div>
            </div>
            <div className="panelRow">
              <div>
                <p>total</p>
              </div>
              <div>
                <p>
                  <b>R$ {(order.data.order.total / 100).toFixed(2)}</b>
                </p>
              </div>
            </div>
          </div>
        </TotalsMobile>
      )}
    </>
  );

  const infiniteAislePackageItems = order.data.order.packages.filter(
    packageItem => packageItem.package_type.code === 'infinite_aisle',
  );

  const infiniteAisleOrders = !!infiniteAislePackageItems && (
    <>
      <div className="infinite_aisle">
        <ul>
          {infiniteAislePackageItems.map((packageItem, index, arr) => (
            <li>
              <div className="infinite_aisle_package">
                <div className="packageHeader">
                  <div>
                    <div className="title">
                      <MdOutlineShoppingCart size={20} />
                      <span>{t('RECEIVE_AT_HOME', 'Receber em Casa')}</span>
                    </div>
                    <div className="packageCount">
                      <span>
                        {t(
                          'PACKAGE_INDEX_OF_LENGTH',
                          'Pacote {{ index }} de {{ length }}',
                          {
                            index: index + 1,
                            length: arr.length,
                          },
                        )}
                      </span>
                      <span
                        className={`status ${
                          order.data?.order.status === 'WAIT_ADJUST'
                            ? 'error'
                            : packageItem.canceled_at
                            ? 'canceled'
                            : packageItem.completed_at
                            ? 'finished'
                            : 'pending'
                        }`}
                      >
                        {!!packageItem.code && ` (${packageItem.code})`}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="deliveryEstimate">
                      <span>Estimativa de entrega: </span>
                      <span>
                        {packageItem.shipping_business_days_to_delivery
                          ? `${
                              packageItem.shipping_business_days_to_delivery
                            } ${
                              packageItem.shipping_business_days_to_delivery > 1
                                ? t('BUSINESS_DAYS', 'dias úteis')
                                : t('BUSINESS_DAY', 'dia útil')
                            }`
                          : ''}
                      </span>
                    </div>
                    <div className="invoicing">
                      <span>Faturamento: </span>
                      {packageItem.completed_at ? (
                        <span>
                          {getDate(parseJSON(packageItem.completed_at))}{' '}
                          {format(parseJSON(packageItem.completed_at), 'MMM', {
                            locale: dateLanguagePack,
                          })}{' '}
                          {format(parseJSON(packageItem.completed_at), 'yyyy', {
                            locale: dateLanguagePack,
                          })}
                        </span>
                      ) : (
                        <span>-</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="productsSection">
                  <div className="productItemRow">
                    {packageItem.items.map(item => (
                      <ProductItemDesktop key={item.code}>
                        <div className="image">
                          {item.image_url ? (
                            <img src={item.image_url} alt={item.name} />
                          ) : (
                            <img src={DefaultProductImage} alt={item.name} />
                          )}
                        </div>

                        <div className="info">
                          <div className="name">
                            <p>{item.name}</p>
                          </div>
                          <div className="infoRow">
                            <p>
                              {t('BRAND', 'Marca:')} {packageItem.brand.name}
                            </p>
                            <p>
                              {t('REFERENCE', 'Ref.:')} {item.reference}
                            </p>
                          </div>
                          <div className="colorAndSizeRow">
                            <p>
                              {t('COLOR', 'Cor:')} {item.color}
                            </p>
                            <p className="size">
                              {t('SIZE', 'Tamanho')} {item.size}
                            </p>
                          </div>

                          <div className="price">
                            {item.total_original_price !==
                              item.total_current_price && (
                              <span className="original_price">
                                R${' '}
                                {(item.total_original_price / 100).toFixed(2)}
                              </span>
                            )}
                            <span className="current_price">
                              R$ {(item.total_current_price / 100).toFixed(2)}
                            </span>
                          </div>

                          <div className="quantityRow">
                            <p>{item.quantity} unidade(s)</p>
                          </div>
                        </div>
                      </ProductItemDesktop>
                    ))}
                  </div>
                </div>
              </div>
              <div className="divider" />
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  const getOwnStockPackageClassName = (
    packageItem: IOrderBrandProviderPackage,
    type: string,
  ) => {
    const packageInvoiceIsUnavailable =
      !packageItem?.invoices?.[0]?.completed_at;
    const unavailableInvoice =
      (!invoiceCode || packageInvoiceIsUnavailable) && type === 'invoice';
    const unavailableCoupon =
      type === 'coupon' && (!order.data || !packageItem.invoiced_at);

    if (unavailableCoupon || unavailableInvoice) return 'unavailable';
    return '';
  };

  const ownStockPackageItems = order.data.order.packages.filter(
    packageItem => packageItem.package_type.code === 'own_stock',
  );

  const ownStockOrders = !!ownStockPackageItems.length && (
    <>
      <div className="own_stock">
        <ul>
          {ownStockPackageItems.map(packageItem => (
            <li>
              <div className="infinite_aisle_package">
                <div className="packageHeader">
                  <div>
                    <div className="title">
                      <MdOutlineShoppingBag size={20} />
                      <span>{t('TAKE_IT_NOW', 'Levar Agora')}</span>
                    </div>
                    <div className="packageCount">
                      <span>{t('TICKET_ID', 'Número do ticket: ')}</span>
                      <span className="status finished">
                        {' '}
                        {packageItem?.code ? `${packageItem.code}` : '-'}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="immediateDelivery">
                      {packageItem.shipping_selected_id === 'in_hands' ||
                      packageItem.shipping_selected_id === 'own_stock' ? (
                        <span>
                          {t('IMMEDIATE_DELIVERY', 'Entrega imediata')}
                        </span>
                      ) : (
                        <span>
                          {t('DELIVERY_CARRIER', 'Delivery/Portador')}
                        </span>
                      )}
                    </div>
                    {!!packageItem?.completed_at && (
                      <div className="documents">
                        <button
                          type="button"
                          className={`invoiceDocument ${getOwnStockPackageClassName(
                            packageItem,
                            'invoice',
                          )}`}
                          onClick={() => {
                            if (
                              !invoiceCode ||
                              !packageItem?.invoices?.[0]?.completed_at
                            ) {
                              awaitInvoiceModalRef.current?.show();
                            } else {
                              invoiceModalRef.current?.show();
                            }
                          }}
                        >
                          <InvoiceIcon />
                          <p>{t('OPEN_NF_MODAL', 'Nota Fiscal')}</p>
                        </button>
                        <div className="documentDivider" />{' '}
                        <button
                          type="button"
                          className={`invoiceDocument ${getOwnStockPackageClassName(
                            packageItem,
                            'coupon',
                          )}`}
                          onClick={() => {
                            if (!order.data || !packageItem.invoiced_at) {
                              awaitExchangeCouponModalRef.current?.show();
                            } else {
                              exchangeCouponModalRef.current?.show();
                            }
                          }}
                        >
                          <SwapHorizontalCircle />
                          <p>{t('OPEN_EXCHANGE_MODAL', 'Cupom de troca')}</p>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="productsSection">
                  <div className="productItemRow">
                    {packageItem.items.map(item => (
                      <ProductItemDesktop key={item.code}>
                        <div className="image">
                          {item.image_url ? (
                            <img src={item.image_url} alt={item.name} />
                          ) : (
                            <img src={DefaultProductImage} alt={item.name} />
                          )}
                        </div>
                        <div className="info">
                          <div className="name">
                            <p>{item.name}</p>
                          </div>
                          <div className="infoRow">
                            <p>
                              {t('BRAND', 'Marca:')} {packageItem.brand.name}
                            </p>
                            <p>
                              {t('REFERENCE', 'Ref.:')} {item.reference}
                            </p>
                          </div>
                          <div className="colorAndSizeRow">
                            <p>
                              {t('COLOR', 'Cor:')} {item.color}
                            </p>
                            <p className="size">
                              {t('SIZE', 'Tamanho')} {item.size}
                            </p>
                          </div>

                          <div className="price">
                            {item.total_original_price !==
                              item.total_current_price && (
                              <span className="original_price">
                                R${' '}
                                {(item.total_original_price / 100).toFixed(2)}
                              </span>
                            )}
                            <span className="current_price">
                              R$ {(item.total_current_price / 100).toFixed(2)}
                            </span>
                          </div>

                          <div className="quantityRow">
                            <p>{item.quantity} unidade(s)</p>
                          </div>
                        </div>
                      </ProductItemDesktop>
                    ))}
                  </div>
                </div>
              </div>
              <div className="divider" />
            </li>
          ))}
        </ul>
      </div>
      {modals}
    </>
  );

  const handleRemakeOrderButton = () => {
    if (order?.data?.order?.status === 'COMPLETED') {
      cloneAsCart();
    } else {
      redoServiceModalRef.current?.show();
    }
  };

  const cloneAsCart = async () => {
    setLoading(true);

    try {
      // API call
      const response = await api.patch(
        `v1/order/${order.data?.order.id}/clone-as-cart`,
      );

      const new_response = response.data;

      dispatch(setBagId(new_response.cartId));
      history.push(`/order/new`);
      setLoading(false);
    } catch (err: any) {
      const { message, error } = getResponseError(err, t);
      toast.error(message);
      Analytics.trackEvent({
        featureKey,
        category: 'GET_PRODUCT',
        action: message,
        label: error,
      });
      setLoading(false);

      throw err;
    }
  };

  const floatingMenu = (
    <>
      {width > 768 ? (
        <>
          {order.data.order.status === 'PENDING' && (
            <Floating>
              <div className="buttonSection">
                <Button
                  color="danger"
                  onClick={() => cancelModalRef.current?.show()}
                  loading={order.cancelLoading}
                >
                  <p className="buttonText">
                    {t('CANCEL_ORDER', 'CANCELAR PEDIDO')}
                  </p>
                </Button>
              </div>
            </Floating>
          )}
          {orderData &&
            (order.data.order.status === 'COMPLETED' ||
              order.data.order.status === 'CANCELED' ||
              order.data.order.status === 'WAIT_ADJUST') && (
              <Floating>
                <div className="buttonSection">
                  <Button
                    color="primary"
                    onClick={() => handleRemakeOrderButton()}
                    loading={order.cancelLoading}
                  >
                    <p>{t('REMAKE_BAG', 'REFAZER ATENDIMENTO')}</p>
                  </Button>
                </div>
              </Floating>
            )}
        </>
      ) : (
        <>
          {order.data.order.status === 'PENDING' && (
            <div className="cancelButtonMobile">
              <Button
                color="danger"
                onClick={() => cancelModalRef.current?.show()}
                loading={order.cancelLoading}
              >
                <p className="buttonText">
                  {t('CANCEL_ORDER', 'CANCELAR PEDIDO')}
                </p>
              </Button>
            </div>
          )}
          {orderData &&
            (order.data.order.status === 'COMPLETED' ||
              order.data.order.status === 'CANCELED' ||
              order.data.order.status === 'WAIT_ADJUST') && (
              <div className="cancelButtonMobile">
                <Button
                  color="primary"
                  onClick={() => handleRemakeOrderButton()}
                  loading={order.cancelLoading}
                >
                  <p className="remakeText">
                    {t('REMAKE_BAG', 'REFAZER ATENDIMENTO')}
                  </p>
                </Button>
              </div>
            )}
        </>
      )}
    </>
  );

  return (
    <>
      <Container>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {time}
            {status}
            {payments}
            <div className="metadataSection">
              <ClientMetadata />
              {additionalInformation}
            </div>
            {ownStockOrders}
            {infiniteAisleOrders}
            {totals}
            {floatingMenu}
          </>
        )}
      </Container>
      {/** Modals */}
      <PaymentDataModal ref={paymentModalModalRef} />
      <DocumentAvailabilityWarning />
      <CancelOrder ref={cancelModalRef} />
      <RedoService ref={redoServiceModalRef} />
      <ManualPhysicalCardPayment ref={manualPhysicalCardModalRef} />
    </>
  );
};

const ViewOrder: React.FC = () => (
  <OrderProvider>
    <Content />
  </OrderProvider>
);

export { ViewOrder };
