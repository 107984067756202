const getNPSAssessmentDates = (date: string) => {
  const currentDate = new Date();
  const startOfAssessmentPeriod = new Date();
  const endOfAssessmentPeriod = new Date();
  const givenDate = new Date(date);

  if (currentDate.getDate() >= 27) {
    startOfAssessmentPeriod.setDate(27);
    endOfAssessmentPeriod.setMonth(currentDate.getMonth() + 1, 10);
  }
  if (currentDate.getDate() <= 10) {
    startOfAssessmentPeriod.setMonth(currentDate.getMonth() - 1, 27);
    endOfAssessmentPeriod.setDate(10);
  }

  const givenDateIsAfterStart =
    givenDate.getTime() >= startOfAssessmentPeriod.getTime();
  const givenDateIsBeforeEnd =
    givenDate.getTime() <= endOfAssessmentPeriod.getTime();

  if (givenDateIsAfterStart && givenDateIsBeforeEnd) {
    return true;
  }
  return false;
};

export { getNPSAssessmentDates };
