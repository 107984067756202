const ddiList = [
  {
    continent: 'Ásia',
    country: 'Afeganistão',
    countryDDI: 'Afeganistão (+93)',
    ddi: '+93',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_the_Taliban.svg/23px-Flag_of_the_Taliban.svg.png',
  },
  {
    continent: 'África',
    country: 'África do Sul',
    countryDDI: 'África do Sul (+27)',
    ddi: '+27',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Flag_of_South_Africa.svg/23px-Flag_of_South_Africa.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Albânia',
    countryDDI: 'Albânia (+355)',
    ddi: '+355',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Flag_of_Albania.svg/21px-Flag_of_Albania.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Alemanha',
    countryDDI: 'Alemanha (+49)',
    ddi: '+49',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Germany.svg/23px-Flag_of_Germany.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Andorra',
    countryDDI: 'Andorra (+376)',
    ddi: '+376',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Flag_of_Andorra.svg/22px-Flag_of_Andorra.svg.png',
  },
  {
    continent: 'África',
    country: 'Angola',
    countryDDI: 'Angola (+244)',
    ddi: '+244',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Flag_of_Angola.svg/23px-Flag_of_Angola.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Anguilla',
    countryDDI: 'Anguilla (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Anguilla.svg/23px-Flag_of_Anguilla.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Antígua e Barbuda',
    countryDDI: 'Antígua e Barbuda (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Flag_of_Antigua_and_Barbuda.svg/23px-Flag_of_Antigua_and_Barbuda.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Antilhas Holandesas',
    countryDDI: 'Antilhas Holandesas (+599)',
    ddi: '+599',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Flag_of_the_Netherlands_Antilles_%281986%E2%80%932010%29.svg/23px-Flag_of_the_Netherlands_Antilles_%281986%E2%80%932010%29.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Arábia Saudita',
    countryDDI: 'Arábia Saudita (+966)',
    ddi: '+966',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Flag_of_Saudi_Arabia.svg/23px-Flag_of_Saudi_Arabia.svg.png',
  },
  {
    continent: 'África',
    country: 'Argélia',
    countryDDI: 'Argélia (+213)',
    ddi: '+213',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Flag_of_Algeria.svg/23px-Flag_of_Algeria.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Argentina',
    countryDDI: 'Argentina (+54)',
    ddi: '+54',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_Argentina.svg/23px-Flag_of_Argentina.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Armênia',
    countryDDI: 'Armênia (+374)',
    ddi: '+374',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Flag_of_Armenia.svg/23px-Flag_of_Armenia.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Aruba',
    countryDDI: 'Aruba (+297)',
    ddi: '+297',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Aruba.svg/23px-Flag_of_Aruba.svg.png',
  },
  {
    continent: 'África',
    country: 'Ascensão',
    countryDDI: 'Ascensão (+247)',
    ddi: '+247',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_Ascension_Island.svg/20px-Flag_of_Ascension_Island.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Austrália',
    countryDDI: 'Austrália (+61)',
    ddi: '+61',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Australia.svg/23px-Flag_of_Australia.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Áustria',
    countryDDI: 'Áustria (+43)',
    ddi: '+43',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Austria.svg/23px-Flag_of_Austria.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Azerbaijão',
    countryDDI: 'Azerbaijão (+994)',
    ddi: '+994',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Flag_of_Azerbaijan.svg/23px-Flag_of_Azerbaijan.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Bahamas',
    countryDDI: 'Bahamas (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Bahamas_Flag.svg/23px-Bahamas_Flag.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Bangladesh',
    countryDDI: 'Bangladesh (+880)',
    ddi: '+880',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Flag_of_Bangladesh.svg/23px-Flag_of_Bangladesh.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Barbados',
    countryDDI: 'Barbados (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Flag_of_Barbados.svg/23px-Flag_of_Barbados.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Bahrein',
    countryDDI: 'Bahrein (+973)',
    ddi: '+973',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_Bahrain.svg/23px-Flag_of_Bahrain.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Bélgica',
    countryDDI: 'Bélgica (+32)',
    ddi: '+32',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Flag_of_Belgium_%28civil%29.svg/23px-Flag_of_Belgium_%28civil%29.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Belize',
    countryDDI: 'Belize (+501)',
    ddi: '+501',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Flag_of_Belize.svg/23px-Flag_of_Belize.svg.png',
  },
  {
    continent: 'África',
    country: 'Benim',
    countryDDI: 'Benim (+229)',
    ddi: '+229',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Flag_of_Benin.svg/23px-Flag_of_Benin.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Bermudas',
    countryDDI: 'Bermudas (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Flag_of_Bermuda.svg/23px-Flag_of_Bermuda.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Bielorrússia',
    countryDDI: 'Bielorrússia (+375)',
    ddi: '+375',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Flag_of_Belarus.svg/23px-Flag_of_Belarus.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Bolívia',
    countryDDI: 'Bolívia (+591)',
    ddi: '+591',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Bolivia.svg/22px-Flag_of_Bolivia.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Bósnia e Herzegovina',
    countryDDI: 'Bósnia e Herzegovina (+387)',
    ddi: '+387',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Flag_of_Bosnia_and_Herzegovina.svg/23px-Flag_of_Bosnia_and_Herzegovina.svg.png',
  },
  {
    continent: 'África',
    country: 'Botswana',
    countryDDI: 'Botswana (+267)',
    ddi: '+267',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_Botswana.svg/23px-Flag_of_Botswana.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Brasil',
    countryDDI: 'Brasil (+55)',
    ddi: '+55',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Brazil.svg/22px-Flag_of_Brazil.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Brunei',
    countryDDI: 'Brunei (+673)',
    ddi: '+673',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Brunei.svg/23px-Flag_of_Brunei.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Bulgária',
    countryDDI: 'Bulgária (+359)',
    ddi: '+359',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Bulgaria.svg/23px-Flag_of_Bulgaria.svg.png',
  },
  {
    continent: 'África',
    country: 'Burkina Faso',
    countryDDI: 'Burkina Faso (+226)',
    ddi: '+226',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Flag_of_Burkina_Faso.svg/23px-Flag_of_Burkina_Faso.svg.png',
  },
  {
    continent: 'África',
    country: 'Burundi',
    countryDDI: 'Burundi (+257)',
    ddi: '+257',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/Flag_of_Burundi.svg/23px-Flag_of_Burundi.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Butão',
    countryDDI: 'Butão (+975)',
    ddi: '+975',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Flag_of_Bhutan.svg/23px-Flag_of_Bhutan.svg.png',
  },
  {
    continent: 'África',
    country: 'Cabo Verde',
    countryDDI: 'Cabo Verde (+238)',
    ddi: '+238',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Flag_of_Cape_Verde.svg/23px-Flag_of_Cape_Verde.svg.png',
  },
  {
    continent: 'África',
    country: 'Camarões',
    countryDDI: 'Camarões (+237)',
    ddi: '+237',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Flag_of_Cameroon.svg/23px-Flag_of_Cameroon.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Camboja',
    countryDDI: 'Camboja (+855)',
    ddi: '+855',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/23px-Flag_of_Cambodia.svg.png',
  },
  {
    continent: 'América do Norte',
    country: 'Canadá',
    countryDDI: 'Canadá (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Canada_%28Pantone%29.svg/23px-Flag_of_Canada_%28Pantone%29.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Cazaquistão',
    countryDDI: 'Cazaquistão (+7)',
    ddi: '+7',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Flag_of_Kazakhstan.svg/23px-Flag_of_Kazakhstan.svg.png',
  },
  {
    continent: 'África',
    country: 'Chade',
    countryDDI: 'Chade (+235)',
    ddi: '+235',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Flag_of_Chad.svg/23px-Flag_of_Chad.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Chile',
    countryDDI: 'Chile (+56)',
    ddi: '+56',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Flag_of_Chile.svg/23px-Flag_of_Chile.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'República Popular da China',
    countryDDI: 'República Popular da China (+86)',
    ddi: '+86',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_the_People%27s_Republic_of_China.svg/23px-Flag_of_the_People%27s_Republic_of_China.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Chipre',
    countryDDI: 'Chipre (+357)',
    ddi: '+357',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Flag_of_Cyprus.svg/23px-Flag_of_Cyprus.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Colômbia',
    countryDDI: 'Colômbia (+57)',
    ddi: '+57',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Colombia.svg/23px-Flag_of_Colombia.svg.png',
  },
  {
    continent: 'África',
    country: 'Comores',
    countryDDI: 'Comores (+269)',
    ddi: '+269',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Flag_of_the_Comoros.svg/23px-Flag_of_the_Comoros.svg.png',
  },
  {
    continent: 'África',
    country: 'CongoBrazzaville',
    countryDDI: 'CongoBrazzaville (+242)',
    ddi: '+242',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Flag_of_the_Republic_of_the_Congo.svg/23px-Flag_of_the_Republic_of_the_Congo.svg.png',
  },
  {
    continent: 'África',
    country: 'CongoKinshasa',
    countryDDI: 'CongoKinshasa (+243)',
    ddi: '+243',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Flag_of_the_Democratic_Republic_of_the_Congo.svg/20px-Flag_of_the_Democratic_Republic_of_the_Congo.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Coreia do Norte',
    countryDDI: 'Coreia do Norte (+850)',
    ddi: '+850',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Flag_of_North_Korea.svg/23px-Flag_of_North_Korea.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Coreia do Sul',
    countryDDI: 'Coreia do Sul (+82)',
    ddi: '+82',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_South_Korea.svg/23px-Flag_of_South_Korea.svg.png',
  },
  {
    continent: 'África',
    country: 'Costa do Marfim',
    countryDDI: 'Costa do Marfim (+225)',
    ddi: '+225',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_C%C3%B4te_d%27Ivoire.svg/23px-Flag_of_C%C3%B4te_d%27Ivoire.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Costa Rica',
    countryDDI: 'Costa Rica (+506)',
    ddi: '+506',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/Flag_of_Costa_Rica.svg/23px-Flag_of_Costa_Rica.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Croácia',
    countryDDI: 'Croácia (+385)',
    ddi: '+385',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Flag_of_Croatia.svg/23px-Flag_of_Croatia.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Cuba',
    countryDDI: 'Cuba (+53)',
    ddi: '+53',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Flag_of_Cuba.svg/23px-Flag_of_Cuba.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Dinamarca',
    countryDDI: 'Dinamarca (+45)',
    ddi: '+45',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/20px-Flag_of_Denmark.svg.png',
  },
  {
    continent: 'África',
    country: 'Djibuti',
    countryDDI: 'Djibuti (+253)',
    ddi: '+253',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Flag_of_Djibouti.svg/23px-Flag_of_Djibouti.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Dominica',
    countryDDI: 'Dominica (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Flag_of_Dominica.svg/23px-Flag_of_Dominica.svg.png',
  },
  {
    continent: 'África/Ásia',
    country: 'Egipto',
    countryDDI: 'Egipto (+20)',
    ddi: '+20',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Egypt.svg/23px-Flag_of_Egypt.svg.png',
  },
  {
    continent: 'América Central',
    country: 'El Salvador',
    countryDDI: 'El Salvador (+503)',
    ddi: '+503',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Flag_of_El_Salvador.svg/23px-Flag_of_El_Salvador.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Emirados Árabes Unidos',
    countryDDI: 'Emirados Árabes Unidos (+971)',
    ddi: '+971',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_United_Arab_Emirates.svg/23px-Flag_of_the_United_Arab_Emirates.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Equador',
    countryDDI: 'Equador (+593)',
    ddi: '+593',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Flag_of_Ecuador.svg/23px-Flag_of_Ecuador.svg.png',
  },
  {
    continent: 'África',
    country: 'Eritreia',
    countryDDI: 'Eritreia (+291)',
    ddi: '+291',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Flag_of_Eritrea.svg/23px-Flag_of_Eritrea.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Eslováquia',
    countryDDI: 'Eslováquia (+421)',
    ddi: '+421',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Flag_of_Slovakia.svg/23px-Flag_of_Slovakia.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Eslovénia',
    countryDDI: 'Eslovénia (+386)',
    ddi: '+386',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f0/Flag_of_Slovenia.svg/23px-Flag_of_Slovenia.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Espanha',
    countryDDI: 'Espanha (+34)',
    ddi: '+34',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Spain.svg/23px-Flag_of_Spain.svg.png',
  },
  {
    continent: 'América do Norte',
    country: 'Estados Unidos',
    countryDDI: 'Estados Unidos (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/23px-Flag_of_the_United_States.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Estónia',
    countryDDI: 'Estónia (+372)',
    ddi: '+372',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Flag_of_Estonia.svg/23px-Flag_of_Estonia.svg.png',
  },
  {
    continent: 'África',
    country: 'Etiópia',
    countryDDI: 'Etiópia (+251)',
    ddi: '+251',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Flag_of_Ethiopia.svg/23px-Flag_of_Ethiopia.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Fiji',
    countryDDI: 'Fiji (+679)',
    ddi: '+679',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Flag_of_Fiji.svg/23px-Flag_of_Fiji.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Filipinas',
    countryDDI: 'Filipinas (+63)',
    ddi: '+63',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_the_Philippines.svg/23px-Flag_of_the_Philippines.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Finlândia',
    countryDDI: 'Finlândia (+358)',
    ddi: '+358',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/23px-Flag_of_Finland.svg.png',
  },
  {
    continent: 'Europa',
    country: 'França',
    countryDDI: 'França (+33)',
    ddi: '+33',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_France.svg/23px-Flag_of_France.svg.png',
  },
  {
    continent: 'África',
    country: 'Gabão',
    countryDDI: 'Gabão (+241)',
    ddi: '+241',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Flag_of_Gabon.svg/20px-Flag_of_Gabon.svg.png',
  },
  {
    continent: 'África',
    country: 'Gâmbia',
    countryDDI: 'Gâmbia (+220)',
    ddi: '+220',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Flag_of_The_Gambia.svg/23px-Flag_of_The_Gambia.svg.png',
  },
  {
    continent: 'África',
    country: 'Gana',
    countryDDI: 'Gana (+233)',
    ddi: '+233',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Flag_of_Ghana.svg/23px-Flag_of_Ghana.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Geórgia',
    countryDDI: 'Geórgia (+995)',
    ddi: '+995',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Georgia.svg/23px-Flag_of_Georgia.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Gibraltar',
    countryDDI: 'Gibraltar (+350)',
    ddi: '+350',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/02/Flag_of_Gibraltar.svg/23px-Flag_of_Gibraltar.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Granada',
    countryDDI: 'Granada (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Grenada.svg/23px-Flag_of_Grenada.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Grécia',
    countryDDI: 'Grécia (+30)',
    ddi: '+30',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Greece.svg/23px-Flag_of_Greece.svg.png',
  },
  {
    continent: 'América do Norte',
    country: 'Groenlândia',
    countryDDI: 'Groenlândia (+299)',
    ddi: '+299',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_Greenland.svg/23px-Flag_of_Greenland.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Guadalupe',
    countryDDI: 'Guadalupe (+590)',
    ddi: '+590',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_France.svg/23px-Flag_of_France.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Guam',
    countryDDI: 'Guam (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/07/Flag_of_Guam.svg/23px-Flag_of_Guam.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Guatemala',
    countryDDI: 'Guatemala (+502)',
    ddi: '+502',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Flag_of_Guatemala.svg/23px-Flag_of_Guatemala.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Guiana',
    countryDDI: 'Guiana (+592)',
    ddi: '+592',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_Guyana.svg/23px-Flag_of_Guyana.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Guiana Francesa',
    countryDDI: 'Guiana Francesa (+594)',
    ddi: '+594',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Flag_of_French_Guiana.svg/23px-Flag_of_French_Guiana.svg.png',
  },
  {
    continent: 'África',
    country: 'Guiné',
    countryDDI: 'Guiné (+224)',
    ddi: '+224',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Flag_of_Guinea.svg/23px-Flag_of_Guinea.svg.png',
  },
  {
    continent: 'África',
    country: 'GuinéBissau',
    countryDDI: 'GuinéBissau (+245)',
    ddi: '+245',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Guinea-Bissau.svg/23px-Flag_of_Guinea-Bissau.svg.png',
  },
  {
    continent: 'África',
    country: 'Guiné Equatorial',
    countryDDI: 'Guiné Equatorial (+240)',
    ddi: '+240',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Flag_of_Equatorial_Guinea.svg/23px-Flag_of_Equatorial_Guinea.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Haiti',
    countryDDI: 'Haiti (+509)',
    ddi: '+509',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Haiti.svg/23px-Flag_of_Haiti.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Honduras',
    countryDDI: 'Honduras (+504)',
    ddi: '+504',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Flag_of_Honduras.svg/23px-Flag_of_Honduras.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Hong Kong',
    countryDDI: 'Hong Kong (+852)',
    ddi: '+852',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Flag_of_Hong_Kong.svg/23px-Flag_of_Hong_Kong.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Hungria',
    countryDDI: 'Hungria (+36)',
    ddi: '+36',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Flag_of_Hungary.svg/23px-Flag_of_Hungary.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Iêmen',
    countryDDI: 'Iêmen (+967)',
    ddi: '+967',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Flag_of_Yemen.svg/23px-Flag_of_Yemen.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Ilhas Cayman',
    countryDDI: 'Ilhas Cayman (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_the_Cayman_Islands.svg/23px-Flag_of_the_Cayman_Islands.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Ilha Christmas',
    countryDDI: 'Ilha Christmas (+672)',
    ddi: '+672',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Flag_of_Christmas_Island.svg/23px-Flag_of_Christmas_Island.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Ilhas Cocos',
    countryDDI: 'Ilhas Cocos (+672)',
    ddi: '+672',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg/23px-Flag_of_the_Cocos_%28Keeling%29_Islands.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Ilhas Cook',
    countryDDI: 'Ilhas Cook (+682)',
    ddi: '+682',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_the_Cook_Islands.svg/23px-Flag_of_the_Cook_Islands.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Ilhas Féroe',
    countryDDI: 'Ilhas Féroe (+298)',
    ddi: '+298',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Flag_of_the_Faroe_Islands.svg/21px-Flag_of_the_Faroe_Islands.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Ilha Heard e Ilhas McDonald',
    countryDDI: 'Ilha Heard e Ilhas McDonald (+672)',
    ddi: '+672',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Australia.svg/23px-Flag_of_Australia.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Maldivas',
    countryDDI: 'Maldivas (+960)',
    ddi: '+960',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Flag_of_Maldives.svg/23px-Flag_of_Maldives.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Ilhas Malvinas',
    countryDDI: 'Ilhas Malvinas (+500)',
    ddi: '+500',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_Falkland_Islands.svg/23px-Flag_of_the_Falkland_Islands.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Ilhas Marianas do Norte',
    countryDDI: 'Ilhas Marianas do Norte (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Flag_of_the_Northern_Mariana_Islands.svg/23px-Flag_of_the_Northern_Mariana_Islands.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Ilhas Marshall',
    countryDDI: 'Ilhas Marshall (+692)',
    ddi: '+692',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Flag_of_the_Marshall_Islands.svg/23px-Flag_of_the_Marshall_Islands.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Ilha Norfolk',
    countryDDI: 'Ilha Norfolk (+672)',
    ddi: '+672',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Norfolk_Island.svg/23px-Flag_of_Norfolk_Island.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Ilhas Salomão',
    countryDDI: 'Ilhas Salomão (+677)',
    ddi: '+677',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Flag_of_the_Solomon_Islands.svg/23px-Flag_of_the_Solomon_Islands.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Ilhas Virgens Americanas',
    countryDDI: 'Ilhas Virgens Americanas (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Flag_of_the_United_States_Virgin_Islands.svg/23px-Flag_of_the_United_States_Virgin_Islands.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Ilhas Virgens Britânicas',
    countryDDI: 'Ilhas Virgens Britânicas (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/42/Flag_of_the_British_Virgin_Islands.svg/23px-Flag_of_the_British_Virgin_Islands.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Índia',
    countryDDI: 'Índia (+91)',
    ddi: '+91',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_India.svg/23px-Flag_of_India.svg.png',
  },
  {
    continent: 'Ásia/Oceania',
    country: 'Indonésia',
    countryDDI: 'Indonésia (+62)',
    ddi: '+62',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/23px-Flag_of_Indonesia.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Irã',
    countryDDI: 'Irã (+98)',
    ddi: '+98',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Flag_of_Iran.svg/23px-Flag_of_Iran.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Iraque',
    countryDDI: 'Iraque (+964)',
    ddi: '+964',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/23px-Flag_of_Iraq.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Irlanda',
    countryDDI: 'Irlanda (+353)',
    ddi: '+353',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/45/Flag_of_Ireland.svg/23px-Flag_of_Ireland.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Islândia',
    countryDDI: 'Islândia (+354)',
    ddi: '+354',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Flag_of_Iceland.svg/21px-Flag_of_Iceland.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Israel',
    countryDDI: 'Israel (+972)',
    ddi: '+972',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Flag_of_Israel.svg/21px-Flag_of_Israel.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Itália',
    countryDDI: 'Itália (+39)',
    ddi: '+39',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Flag_of_Italy.svg/23px-Flag_of_Italy.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Jamaica',
    countryDDI: 'Jamaica (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Flag_of_Jamaica.svg/23px-Flag_of_Jamaica.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Japão',
    countryDDI: 'Japão (+81)',
    ddi: '+81',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Flag_of_Japan.svg/23px-Flag_of_Japan.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Jordânia',
    countryDDI: 'Jordânia (+962)',
    ddi: '+962',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Flag_of_Jordan.svg/23px-Flag_of_Jordan.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Kiribati',
    countryDDI: 'Kiribati (+686)',
    ddi: '+686',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Flag_of_Kiribati.svg/23px-Flag_of_Kiribati.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Kosovo',
    countryDDI: 'Kosovo (+383)',
    ddi: '+383',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Flag_of_Kosovo.svg/21px-Flag_of_Kosovo.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Kuwait',
    countryDDI: 'Kuwait (+965)',
    ddi: '+965',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Flag_of_Kuwait.svg/23px-Flag_of_Kuwait.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Laos',
    countryDDI: 'Laos (+856)',
    ddi: '+856',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Flag_of_Laos.svg/23px-Flag_of_Laos.svg.png',
  },
  {
    continent: 'África',
    country: 'Lesoto',
    countryDDI: 'Lesoto (+266)',
    ddi: '+266',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Flag_of_Lesotho.svg/23px-Flag_of_Lesotho.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Letônia',
    countryDDI: 'Letônia (+371)',
    ddi: '+371',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Latvia.svg/23px-Flag_of_Latvia.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Líbano',
    countryDDI: 'Líbano (+961)',
    ddi: '+961',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Flag_of_Lebanon.svg/23px-Flag_of_Lebanon.svg.png',
  },
  {
    continent: 'África',
    country: 'Libéria',
    countryDDI: 'Libéria (+231)',
    ddi: '+231',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Flag_of_Liberia.svg/23px-Flag_of_Liberia.svg.png',
  },
  {
    continent: 'África',
    country: 'Líbia',
    countryDDI: 'Líbia (+218)',
    ddi: '+218',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Libya.svg/23px-Flag_of_Libya.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Liechtenstein',
    countryDDI: 'Liechtenstein (+423)',
    ddi: '+423',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/Flag_of_Liechtenstein.svg/23px-Flag_of_Liechtenstein.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Lituânia',
    countryDDI: 'Lituânia (+370)',
    ddi: '+370',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Lithuania.svg/23px-Flag_of_Lithuania.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Luxemburgo',
    countryDDI: 'Luxemburgo (+352)',
    ddi: '+352',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Flag_of_Luxembourg.svg/23px-Flag_of_Luxembourg.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Macau',
    countryDDI: 'Macau (+853)',
    ddi: '+853',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Flag_of_Macau.svg/23px-Flag_of_Macau.svg.png',
  },
  {
    continent: 'Europa',
    country: 'República da Macedônia',
    countryDDI: 'República da Macedônia (+389)',
    ddi: '+389',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Flag_of_North_Macedonia.svg/23px-Flag_of_North_Macedonia.svg.png',
  },
  {
    continent: 'África',
    country: 'Madagascar',
    countryDDI: 'Madagascar (+261)',
    ddi: '+261',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Madagascar.svg/23px-Flag_of_Madagascar.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Malásia',
    countryDDI: 'Malásia (+60)',
    ddi: '+60',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Flag_of_Malaysia.svg/23px-Flag_of_Malaysia.svg.png',
  },
  {
    continent: 'África',
    country: 'Malawi',
    countryDDI: 'Malawi (+265)',
    ddi: '+265',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Flag_of_Malawi.svg/23px-Flag_of_Malawi.svg.png',
  },
  {
    continent: 'África',
    country: 'Mali',
    countryDDI: 'Mali (+223)',
    ddi: '+223',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Flag_of_Mali.svg/23px-Flag_of_Mali.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Malta',
    countryDDI: 'Malta (+356)',
    ddi: '+356',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flag_of_Malta.svg/23px-Flag_of_Malta.svg.png',
  },
  {
    continent: 'África',
    country: 'Marrocos',
    countryDDI: 'Marrocos (+212)',
    ddi: '+212',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_Morocco.svg/23px-Flag_of_Morocco.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Martinica',
    countryDDI: 'Martinica (+596)',
    ddi: '+596',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_France.svg/23px-Flag_of_France.svg.png',
  },
  {
    continent: 'África',
    country: 'Maurícia',
    countryDDI: 'Maurícia (+230)',
    ddi: '+230',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Flag_of_Mauritius.svg/23px-Flag_of_Mauritius.svg.png',
  },
  {
    continent: 'África',
    country: 'Mauritânia',
    countryDDI: 'Mauritânia (+222)',
    ddi: '+222',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/43/Flag_of_Mauritania.svg/23px-Flag_of_Mauritania.svg.png',
  },
  {
    continent: 'África',
    country: 'Mayotte',
    countryDDI: 'Mayotte (+269)',
    ddi: '+269',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_France.svg/23px-Flag_of_France.svg.png',
  },
  {
    continent: 'América do Norte',
    country: 'México',
    countryDDI: 'México (+52)',
    ddi: '+52',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Mexico.svg/23px-Flag_of_Mexico.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Estados Federados da Micronésia',
    countryDDI: 'Estados Federados da Micronésia (+691)',
    ddi: '+691',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg/23px-Flag_of_the_Federated_States_of_Micronesia.svg.png',
  },
  {
    continent: 'África',
    country: 'Moçambique',
    countryDDI: 'Moçambique (+258)',
    ddi: '+258',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Flag_of_Mozambique.svg/23px-Flag_of_Mozambique.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Moldávia',
    countryDDI: 'Moldávia (+373)',
    ddi: '+373',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Flag_of_Moldova.svg/23px-Flag_of_Moldova.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Mônaco',
    countryDDI: 'Mônaco (+377)',
    ddi: '+377',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Flag_of_Monaco.svg/19px-Flag_of_Monaco.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Mongólia',
    countryDDI: 'Mongólia (+976)',
    ddi: '+976',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Mongolia.svg/23px-Flag_of_Mongolia.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Montenegro',
    countryDDI: 'Montenegro (+382)',
    ddi: '+382',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Flag_of_Montenegro.svg/23px-Flag_of_Montenegro.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Montserrat',
    countryDDI: 'Montserrat (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Flag_of_Montserrat.svg/23px-Flag_of_Montserrat.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Myanmar',
    countryDDI: 'Myanmar (+95)',
    ddi: '+95',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Flag_of_Myanmar.svg/23px-Flag_of_Myanmar.svg.png',
  },
  {
    continent: 'África',
    country: 'Namíbia',
    countryDDI: 'Namíbia (+264)',
    ddi: '+264',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_Namibia.svg/23px-Flag_of_Namibia.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Nauru',
    countryDDI: 'Nauru (+674)',
    ddi: '+674',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/Flag_of_Nauru.svg/23px-Flag_of_Nauru.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Nepal',
    countryDDI: 'Nepal (+977)',
    ddi: '+977',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Flag_of_Nepal.svg/12px-Flag_of_Nepal.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Nicarágua',
    countryDDI: 'Nicarágua (+505)',
    ddi: '+505',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Flag_of_Nicaragua.svg/23px-Flag_of_Nicaragua.svg.png',
  },
  {
    continent: 'África',
    country: 'Níger',
    countryDDI: 'Níger (+227)',
    ddi: '+227',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Flag_of_Niger.svg/18px-Flag_of_Niger.svg.png',
  },
  {
    continent: 'África',
    country: 'Nigéria',
    countryDDI: 'Nigéria (+234)',
    ddi: '+234',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Flag_of_Nigeria.svg/23px-Flag_of_Nigeria.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Niue',
    countryDDI: 'Niue (+683)',
    ddi: '+683',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Niue.svg/23px-Flag_of_Niue.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Noruega',
    countryDDI: 'Noruega (+47)',
    ddi: '+47',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Norway.svg/21px-Flag_of_Norway.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Nova Caledônia',
    countryDDI: 'Nova Caledônia (+687)',
    ddi: '+687',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Flags_of_New_Caledonia.svg/40px-Flags_of_New_Caledonia.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Nova Zelândia',
    countryDDI: 'Nova Zelândia (+64)',
    ddi: '+64',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Flag_of_New_Zealand.svg/23px-Flag_of_New_Zealand.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Omã',
    countryDDI: 'Omã (+968)',
    ddi: '+968',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Flag_of_Oman.svg/23px-Flag_of_Oman.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Países Baixos',
    countryDDI: 'Países Baixos (+31)',
    ddi: '+31',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/23px-Flag_of_the_Netherlands.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Palau',
    countryDDI: 'Palau (+680)',
    ddi: '+680',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Palau.svg/23px-Flag_of_Palau.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Palestina',
    countryDDI: 'Palestina (+970)',
    ddi: '+970',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_Palestine.svg/23px-Flag_of_Palestine.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Panamá',
    countryDDI: 'Panamá (+507)',
    ddi: '+507',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Flag_of_Panama.svg/23px-Flag_of_Panama.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'PapuaNova Guiné',
    countryDDI: 'PapuaNova Guiné (+675)',
    ddi: '+675',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e3/Flag_of_Papua_New_Guinea.svg/20px-Flag_of_Papua_New_Guinea.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Paquistão',
    countryDDI: 'Paquistão (+92)',
    ddi: '+92',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Flag_of_Pakistan.svg/23px-Flag_of_Pakistan.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Paraguai',
    countryDDI: 'Paraguai (+595)',
    ddi: '+595',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Flag_of_Paraguay.svg/23px-Flag_of_Paraguay.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Peru',
    countryDDI: 'Peru (+51)',
    ddi: '+51',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Flag_of_Peru.svg/23px-Flag_of_Peru.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Polinésia Francesa',
    countryDDI: 'Polinésia Francesa (+689)',
    ddi: '+689',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Flag_of_French_Polynesia.svg/23px-Flag_of_French_Polynesia.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Polônia',
    countryDDI: 'Polônia (+48)',
    ddi: '+48',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Flag_of_Poland.svg/23px-Flag_of_Poland.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Porto Rico',
    countryDDI: 'Porto Rico (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Flag_of_Puerto_Rico.svg/23px-Flag_of_Puerto_Rico.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Portugal',
    countryDDI: 'Portugal (+351)',
    ddi: '+351',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/23px-Flag_of_Portugal.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Qatar',
    countryDDI: 'Qatar (+974)',
    ddi: '+974',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_Qatar.svg/23px-Flag_of_Qatar.svg.png',
  },
  {
    continent: 'África',
    country: 'Quênia',
    countryDDI: 'Quênia (+254)',
    ddi: '+254',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Kenya.svg/23px-Flag_of_Kenya.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Quirguistão',
    countryDDI: 'Quirguistão (+996)',
    ddi: '+996',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/Flag_of_Kyrgyzstan.svg/23px-Flag_of_Kyrgyzstan.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Reino Unido',
    countryDDI: 'Reino Unido (+44)',
    ddi: '+44',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/23px-Flag_of_the_United_Kingdom_%281-2%29.svg.png',
  },
  {
    continent: 'África',
    country: 'República CentroAfricana',
    countryDDI: 'República CentroAfricana (+236)',
    ddi: '+236',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Flag_of_the_Central_African_Republic.svg/23px-Flag_of_the_Central_African_Republic.svg.png',
  },
  {
    continent: 'América Central',
    country: 'República Dominicana',
    countryDDI: 'República Dominicana (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_the_Dominican_Republic.svg/23px-Flag_of_the_Dominican_Republic.svg.png',
  },
  {
    continent: 'Europa',
    country: 'República Tcheca',
    countryDDI: 'República Tcheca (+420)',
    ddi: '+420',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_Czech_Republic.svg/23px-Flag_of_the_Czech_Republic.svg.png',
  },
  {
    continent: 'África',
    country: 'Reunião',
    countryDDI: 'Reunião (+262)',
    ddi: '+262',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_France.svg/23px-Flag_of_France.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Romênia',
    countryDDI: 'Romênia (+40)',
    ddi: '+40',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Flag_of_Romania.svg/23px-Flag_of_Romania.svg.png',
  },
  {
    continent: 'África',
    country: 'Ruanda',
    countryDDI: 'Ruanda (+250)',
    ddi: '+250',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Flag_of_Rwanda.svg/23px-Flag_of_Rwanda.svg.png',
  },
  {
    continent: 'Europa/Ásia',
    country: 'Rússia',
    countryDDI: 'Rússia (+7)',
    ddi: '+7',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/23px-Flag_of_Russia.svg.png',
  },
  {
    continent: 'África',
    country: 'Saara Ocidental',
    countryDDI: 'Saara Ocidental (+212)',
    ddi: '+212',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Flag_of_the_Sahrawi_Arab_Democratic_Republic.svg/23px-Flag_of_the_Sahrawi_Arab_Democratic_Republic.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Samoa',
    countryDDI: 'Samoa (+685)',
    ddi: '+685',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Flag_of_Samoa.svg/23px-Flag_of_Samoa.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Samoa Americana',
    countryDDI: 'Samoa Americana (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Flag_of_American_Samoa.svg/23px-Flag_of_American_Samoa.svg.png',
  },
  {
    continent: 'África',
    country: 'Santa Helena território',
    countryDDI: 'Santa Helena território (+290)',
    ddi: '+290',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_Saint_Helena.svg/23px-Flag_of_Saint_Helena.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Santa Lúcia',
    countryDDI: 'Santa Lúcia (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Saint_Lucia.svg/23px-Flag_of_Saint_Lucia.svg.png',
  },
  {
    continent: 'América Central',
    country: 'São Cristóvão e Nevis',
    countryDDI: 'São Cristóvão e Nevis (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg/23px-Flag_of_Saint_Kitts_and_Nevis.svg.png',
  },
  {
    continent: 'Europa',
    country: 'São Marinho',
    countryDDI: 'São Marinho (+378)',
    ddi: '+378',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Flag_of_San_Marino.svg/20px-Flag_of_San_Marino.svg.png',
  },
  {
    continent: 'América do Norte',
    country: 'SaintPierre e Miquelon',
    countryDDI: 'SaintPierre e Miquelon (+508)',
    ddi: '+508',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_France.svg/23px-Flag_of_France.svg.png',
  },
  {
    continent: 'África',
    country: 'São Tomé e Príncipe',
    countryDDI: 'São Tomé e Príncipe (+239)',
    ddi: '+239',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Flag_of_S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe.svg/23px-Flag_of_S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe.svg.png',
  },
  {
    continent: 'América Central',
    country: 'São Vicente e Granadinas',
    countryDDI: 'São Vicente e Granadinas (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg/23px-Flag_of_Saint_Vincent_and_the_Grenadines.svg.png',
  },
  {
    continent: 'África',
    country: 'Seicheles',
    countryDDI: 'Seicheles (+248)',
    ddi: '+248',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Seychelles.svg/23px-Flag_of_Seychelles.svg.png',
  },
  {
    continent: 'África',
    country: 'Senegal',
    countryDDI: 'Senegal (+221)',
    ddi: '+221',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Flag_of_Senegal.svg/23px-Flag_of_Senegal.svg.png',
  },
  {
    continent: 'África',
    country: 'Serra Leoa',
    countryDDI: 'Serra Leoa (+232)',
    ddi: '+232',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Flag_of_Sierra_Leone.svg/23px-Flag_of_Sierra_Leone.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Sérvia',
    countryDDI: 'Sérvia (+381)',
    ddi: '+381',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Flag_of_Serbia.svg/23px-Flag_of_Serbia.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Singapura',
    countryDDI: 'Singapura (+65)',
    ddi: '+65',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Singapore.svg/23px-Flag_of_Singapore.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Síria',
    countryDDI: 'Síria (+963)',
    ddi: '+963',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Flag_of_Syria.svg/23px-Flag_of_Syria.svg.png',
  },
  {
    continent: 'África',
    country: 'Somália',
    countryDDI: 'Somália (+252)',
    ddi: '+252',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Flag_of_Somalia.svg/23px-Flag_of_Somalia.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Sri Lanka',
    countryDDI: 'Sri Lanka (+94)',
    ddi: '+94',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Sri_Lanka.svg/23px-Flag_of_Sri_Lanka.svg.png',
  },
  {
    continent: 'África',
    country: 'Suazilândia',
    countryDDI: 'Suazilândia (+268)',
    ddi: '+268',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Flag_of_Eswatini.svg/23px-Flag_of_Eswatini.svg.png',
  },
  {
    continent: 'África',
    country: 'Sudão',
    countryDDI: 'Sudão (+249)',
    ddi: '+249',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/Flag_of_Sudan.svg/23px-Flag_of_Sudan.svg.png',
  },
  {
    continent: 'África',
    country: 'Sudão do Sul',
    countryDDI: 'Sudão do Sul (+211)',
    ddi: '+211',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7a/Flag_of_South_Sudan.svg/23px-Flag_of_South_Sudan.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Suécia',
    countryDDI: 'Suécia (+46)',
    ddi: '+46',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Sweden.svg/23px-Flag_of_Sweden.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Suíça',
    countryDDI: 'Suíça (+41)',
    ddi: '+41',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/16px-Flag_of_Switzerland.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Suriname',
    countryDDI: 'Suriname (+597)',
    ddi: '+597',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Flag_of_Suriname.svg/23px-Flag_of_Suriname.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Tadjiquistão',
    countryDDI: 'Tadjiquistão (+992)',
    ddi: '+992',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Flag_of_Tajikistan.svg/23px-Flag_of_Tajikistan.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Tailândia',
    countryDDI: 'Tailândia (+66)',
    ddi: '+66',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/23px-Flag_of_Thailand.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'República da China',
    countryDDI: 'República da China (+886)',
    ddi: '+886',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Flag_of_the_Republic_of_China.svg/23px-Flag_of_the_Republic_of_China.svg.png',
  },
  {
    continent: 'África',
    country: 'Tanzânia',
    countryDDI: 'Tanzânia (+255)',
    ddi: '+255',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Flag_of_Tanzania.svg/23px-Flag_of_Tanzania.svg.png',
  },
  {
    continent: 'África',
    country: 'Território Britânico do Oceano Índico',
    countryDDI: 'Território Britânico do Oceano Índico (+246)',
    ddi: '+246',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg/23px-Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'TimorLeste',
    countryDDI: 'TimorLeste (+670)',
    ddi: '+670',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Flag_of_East_Timor.svg/23px-Flag_of_East_Timor.svg.png',
  },
  {
    continent: 'África',
    country: 'Togo',
    countryDDI: 'Togo (+228)',
    ddi: '+228',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/Flag_of_Togo.svg/23px-Flag_of_Togo.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Tokelau',
    countryDDI: 'Tokelau (+690)',
    ddi: '+690',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Flag_of_Tokelau.svg/23px-Flag_of_Tokelau.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Tonga',
    countryDDI: 'Tonga (+676)',
    ddi: '+676',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Flag_of_Tonga.svg/23px-Flag_of_Tonga.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Trinidad e Tobago',
    countryDDI: 'Trinidad e Tobago (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Flag_of_Trinidad_and_Tobago.svg/23px-Flag_of_Trinidad_and_Tobago.svg.png',
  },
  {
    continent: 'África',
    country: 'Tunísia',
    countryDDI: 'Tunísia (+216)',
    ddi: '+216',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Flag_of_Tunisia.svg/23px-Flag_of_Tunisia.svg.png',
  },
  {
    continent: 'América Central',
    country: 'Turcas e Caicos',
    countryDDI: 'Turcas e Caicos (+1)',
    ddi: '+1',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg/23px-Flag_of_the_Turks_and_Caicos_Islands.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Turquemenistão',
    countryDDI: 'Turquemenistão (+993)',
    ddi: '+993',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Flag_of_Turkmenistan.svg/23px-Flag_of_Turkmenistan.svg.png',
  },
  {
    continent: 'Ásia//Europa',
    country: 'Turquia',
    countryDDI: 'Turquia (+90)',
    ddi: '+90',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/23px-Flag_of_Turkey.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Tuvalu',
    countryDDI: 'Tuvalu (+688)',
    ddi: '+688',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Flag_of_Tuvalu.svg/23px-Flag_of_Tuvalu.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Ucrânia',
    countryDDI: 'Ucrânia (+380)',
    ddi: '+380',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/23px-Flag_of_Ukraine.svg.png',
  },
  {
    continent: 'África',
    country: 'Uganda',
    countryDDI: 'Uganda (+256)',
    ddi: '+256',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4e/Flag_of_Uganda.svg/23px-Flag_of_Uganda.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Uruguai',
    countryDDI: 'Uruguai (+598)',
    ddi: '+598',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fe/Flag_of_Uruguay.svg/23px-Flag_of_Uruguay.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Uzbequistão',
    countryDDI: 'Uzbequistão (+998)',
    ddi: '+998',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Uzbekistan.svg/23px-Flag_of_Uzbekistan.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Vanuatu',
    countryDDI: 'Vanuatu (+678)',
    ddi: '+678',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Vanuatu.svg/23px-Flag_of_Vanuatu.svg.png',
  },
  {
    continent: 'Europa',
    country: 'Vaticano',
    countryDDI: 'Vaticano (+379)',
    ddi: '+379',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Flag_of_the_Vatican_City.svg/15px-Flag_of_the_Vatican_City.svg.png',
  },
  {
    continent: 'América do Sul',
    country: 'Venezuela',
    countryDDI: 'Venezuela (+58)',
    ddi: '+58',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Flag_of_Venezuela.svg/23px-Flag_of_Venezuela.svg.png',
  },
  {
    continent: 'Ásia',
    country: 'Vietnã',
    countryDDI: 'Vietnã (+84)',
    ddi: '+84',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Vietnam.svg/23px-Flag_of_Vietnam.svg.png',
  },
  {
    continent: 'Oceania',
    country: 'Wallis e Futuna',
    countryDDI: 'Wallis e Futuna (+681)',
    ddi: '+681',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Flag_of_France.svg/23px-Flag_of_France.svg.png',
  },
  {
    continent: 'África',
    country: 'Zâmbia',
    countryDDI: 'Zâmbia (+260)',
    ddi: '+260',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Flag_of_Zambia.svg/23px-Flag_of_Zambia.svg.png',
  },
  {
    continent: 'África',
    country: 'Zimbábue',
    countryDDI: 'Zimbábue (+263)',
    ddi: '+263',
    img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/Flag_of_Zimbabwe.svg/23px-Flag_of_Zimbabwe.svg.png',
  },
];

export { ddiList };
