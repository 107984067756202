import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .fileToPrint {
    position: absolute;

    z-index: -99;
  }
  .ghost {
    position: absolute;
    z-index: -98;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.background_low};
  }
`;
