import styled from 'styled-components';
import { darken } from 'polished';

interface IContainerProps {
  disabled?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  transition: 0.25s;

  .dataColumn {
    display: flex;
    flex-direction: column !important;
  }

  align-self: stretch;
  height: fit-content;
  overflow: hidden;
  flex: 1;
  color: ${({ theme }) => theme.font_secondary};
  /* background: ${props => {
    if (props.disabled) {
      return ({ theme }) => darken(0.25, theme.background_secondary);
    }
    return '';
  }}; */

  svg {
    fill: ${({ theme }) => theme.font_secondary};
  }

  &.selected {
    color: ${({ theme }) => theme.font_low};
    background: ${({ theme }) => theme.background_secondary};

    svg {
      fill: ${({ theme }) => theme.font_low};
    }
  }

  span {
    text-align: center;
  }
`;

export const ButtonTitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex: 1;
  padding: 16px 16px 4px;
  height: fit-content;

  svg {
    max-height: fit-content;
  }
`;

export const CreditDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 0 0 24px 54px;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;

  b {
    font-weight: 700;
    font-size: 16px;
    word-spacing: 1px;
  }
`;
