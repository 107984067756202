/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { MdChevronRight, MdInfoOutline } from 'react-icons/md';
import { Locale, getDate, parseJSON, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

// Asset import
import { ReactComponent as Tooltip } from '@assets/tooltip.svg';

// Hook import
import { useTheme } from '@hooks/useTheme';
import { useTranslation } from '@hooks/useTranslation';

// Store import
import { INewFormatOrder } from '@store/modules/auth/types';

// Style import
import { Container, NoOrders } from './styles';

// Interfaces
interface IProps {
  orders: INewFormatOrder[];
}

interface IErrorInfoSection {
  active: boolean;
  orderId: string;
}

// Feature identification
const featureKey = '@order_list/INDEX';

const OrderList: React.FC<IProps> = ({ orders }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const history = useHistory();

  // Global states
  const language = useSelector(state => state.ui.language);

  // Local states
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);
  const [errorInfoSection, setErrorInfoSection] = useState<IErrorInfoSection>();

  const tooltipTimerDebounce = useDebounce(errorInfoSection, 5000);

  useEffect(() => {
    if (tooltipTimerDebounce) {
      setErrorInfoSection({
        active: false,
        orderId: tooltipTimerDebounce.orderId,
      });
    }
  }, [tooltipTimerDebounce]);

  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  if (!orders.length)
    return (
      <NoOrders>
        <p>{t('NO_ORDERS_CREATED', 'Ainda não há nenhum atendimento.')}</p>
      </NoOrders>
    );

  return (
    <Container>
      {orders?.map(order => {
        const quantity = order.packages
          ?.map(order_package =>
            order_package.items
              .map(item => item.quantity)
              .reduce((subTotal, subCurrent) => subTotal + subCurrent, 0),
          )
          .reduce((total, current) => total + current, 0);

        return (
          <li key={order.id}>
            <div className="main">
              {order.status === 'WAIT_ADJUST' && (
                <div className="errorSection">
                  <button
                    type="button"
                    onClick={() =>
                      setErrorInfoSection({
                        active: !errorInfoSection?.active,
                        orderId: order.id,
                      })
                    }
                  >
                    <MdInfoOutline />
                  </button>
                  {errorInfoSection?.active &&
                    errorInfoSection.orderId === order.id && (
                      <div className="tooltip">
                        <Tooltip />
                      </div>
                    )}
                </div>
              )}
              <button
                type="button"
                onClick={() => history.push(`/order/${order.id}`)}
              >
                <div className="left">
                  <span className="date">
                    {getDate(parseJSON(order.created_at))}
                  </span>
                  <span className="month">
                    {format(parseJSON(order.created_at), 'LLL', {
                      locale: dateLanguagePack,
                    })}
                  </span>
                </div>
                <div className="center">
                  <div className="statusRow">
                    <span
                      className={`status ${
                        order.status === 'WAIT_ADJUST'
                          ? 'error'
                          : order.status === 'CANCELED'
                          ? 'canceled'
                          : order.status === 'COMPLETED'
                          ? 'finished'
                          : 'pending'
                      }`}
                    >
                      {t('STATUS', 'Status')}:{' '}
                      {order.status === 'WAIT_ADJUST'
                        ? t('ORDER_HAS_ERROR', 'Cancelado a ajustar')
                        : order.status === 'CANCELED'
                        ? t('PAYMENT_CANCELED', 'Cancelado')
                        : order.status === 'COMPLETED'
                        ? t('PAYMENT_CANCELED', 'Finalizado')
                        : t('PAYMENT_PENDING', 'Pagamento pendente')}
                    </span>
                  </div>
                  <span className="name">
                    {order.customer_name} {order.customer_surname}
                  </span>
                  <span className="salecode">
                    {order.sale_user_code} - {order.sale_user_name}
                  </span>
                  <span className="products">
                    {quantity}{' '}
                    {quantity > 1
                      ? t('PRODUCTS', 'Produtos')
                      : t('PRODUCT', 'Produto')}
                    : R$ {(order.total / 100).toFixed(2)}
                  </span>
                </div>
                <MdChevronRight color={theme.font_secondary} size={32} />
              </button>
            </div>
          </li>
        );
      })}
    </Container>
  );
};

export { OrderList };
