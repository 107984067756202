import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem 0;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    button {
      .closeIcon {
        color: ${({ theme }) => theme.button_primary};
        font-size: 1.75rem;
      }
    }
  }

  .textRow {
    font-weight: 700;
    font-size: 1.125rem;
    color: #f2f2f2;
  }

  .actionArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1.75rem;

    button {
      border-radius: 0.5rem;
    }
  }
`;
