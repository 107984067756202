import styled from 'styled-components';

interface IContainerProps {
  size?: 'small' | 'normal';
}

export const Container = styled.label<IContainerProps>`
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 1rem;

  input[type='checkbox'] {
    visibility: hidden;
  }

  .checkboxContainer {
    position: absolute;
    height: ${props => {
      if (props.size === 'small') return '1rem';
      if (props.size === 'normal') return '1.5rem';
      return '1.5rem';
    }};
    width: ${props => {
      if (props.size === 'small') return '1rem';
      if (props.size === 'normal') return '1.5rem';
      return '1.5rem';
    }};
    border: 0.18rem solid ${({ theme }) => theme.background_secondary};
    top: 0;
    left: 0;
    border-radius: 0.25rem;
    transition: 300ms;

    ::after {
      content: '';
      position: absolute;
      display: none;
      left: ${props => {
        if (props.size === 'small') return '3px';
        if (props.size === 'normal') return '6px';
        return '1.5rem';
      }};
      top: ${props => {
        if (props.size === 'small') return '';
        if (props.size === 'normal') return '3px';
        return '3px';
      }};
      width: ${props => {
        if (props.size === 'small') return '4px';
        if (props.size === 'normal') return '6px';
        return '6px';
      }};
      height: ${props => {
        if (props.size === 'small') return '8px';
        if (props.size === 'normal') return '10px';
        return '10px';
      }};
      border: solid ${({ theme }) => theme.font_low};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkboxContainer {
    background-color: ${({ theme }) => theme.button_secondary};
  }

  & input:active ~ .checkboxContainer {
    background-color: ${({ theme }) => theme.button_disabled};
  }

  & input:checked ~ .checkboxContainer {
    border: none;
    background-color: ${({ theme }) => theme.background_secondary};
    border: 0.18rem solid ${({ theme }) => theme.font_low};
  }

  & input:checked ~ .checkboxContainer::after {
    display: block;
  }
`;
