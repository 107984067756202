const validateCpf = ({ code }: { code: string }): boolean => {
  // console.log(code);
  let sum;
  let rest;
  sum = 0;
  const document = String(code).match(/\d+/g)?.join('') || '';

  const invalidValues = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  if (document.length !== 11 || invalidValues.includes(document)) return false;

  for (let i = 1; i <= 9; i += 1)
    sum += parseInt(document.substring(i - 1, i), 10) * (11 - i);

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(document.substring(9, 10), 10)) return false;

  sum = 0;
  for (let i = 1; i <= 10; i += 1)
    sum += parseInt(document.substring(i - 1, i), 10) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(document.substring(10, 11), 10)) return false;
  return true;
};

export { validateCpf };
