import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .closeIcon {
      color: ${({ theme }) => theme.button_primary};
      font-size: 1.75rem;
    }
  }

  .titleRow {
    p {
      color: ${({ theme }) => theme.font_secondary};
      font-weight: 600;
      font-size: 1.25rem;
      margin-top: 1.75rem;
      margin-bottom: 1.5rem;
      white-space: pre-line;
    }
  }
  .textRow {
    p {
      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 2rem;
      white-space: pre-line;
    }
  }
  button {
    border-radius: 0.5rem;
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 1.125rem;
  }
  span:last-child {
    margin-top: 1rem;
    width: 100%;
  }
`;
