import React, { useState, useEffect } from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Component import
import { Input } from '@components/Input';
import { Button } from '@components/Button';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Util import
import { formatValueToString } from '@util/handleOrderValues';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  payment?: any;
  currentPaymentAmount?: any;
}

// Feature identification
const featureKey = '@create_order/EDIT_PAYMENT_AMAOUNT_MODAL';

const EditPaymentAmountModal: React.FC<IProps> = ({
  hide,
  payment,
  currentPaymentAmount,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();

  const [inputValue, setInputValue] = useState(
    `${(currentPaymentAmount / 100).toFixed(2)}`,
  );
  const [restValue, setRestValue] = useState<number>();

  const [errorMessage, setErrorMessage] = useState(false);

  const handleSubmit = () => {
    const getInputValue = inputValue.replace(',', '.');
    const floatInput = parseFloat(getInputValue);
    const formattedInput = Math.round(floatInput * 100);
    const inputValueExceedsOrderTotal = formattedInput >= order.totals.total;

    if (inputValueExceedsOrderTotal) {
      setErrorMessage(true);
      return;
    }

    const paymentsList = order.payments;

    const newPaymentValue = order.totals.total - formattedInput;

    const otherNewPaymentValue = order.totals.total - newPaymentValue;

    const newPaymentsList = paymentsList.map(item => {
      if (item.code === payment.code) {
        return {
          ...item,
          amount: otherNewPaymentValue,
        };
      }
      return {
        ...item,
        amount: newPaymentValue,
      };
    });

    order.setPayments(newPaymentsList);
    if (typeof hide === 'function') hide();
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    // Clear whitespaces
    e.currentTarget.value = e.currentTarget.value.trim();
    const editedInput = e.currentTarget.value.replace(/[^,\d]/g, '');
    const inputToNumber = (Number(editedInput) / 100).toFixed(2);

    e.currentTarget.value = editedInput;
    setInputValue(inputToNumber);
    return e;
  };

  const getErrorMessage = () => {
    const getInputValue = inputValue.replace(',', '.');
    const floatInput = parseFloat(getInputValue);
    const formattedInput = Math.round(floatInput * 100);
    if (formattedInput > payment.payload.total) {
      return (
        <p className="errorMessage">
          Valor excede o valor disponível de R${' '}
          {formatValueToString(payment.payload.total)}
        </p>
      );
    }
    return (
      <p className="errorMessage">
        Valor excede o preço do produto de R${' '}
        {formatValueToString(order.totals.total)}
      </p>
    );
  };

  useEffect(() => {
    const newValue = order.totals.total - Number(inputValue) * 100;
    setRestValue(newValue);
  }, [inputValue, order.totals.total]);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="titleRow">
        {t('AMOUNT_TO_PAY', 'Valor a pagar no ')} {payment?.name}
      </div>
      <div className="textRow">
        {t(
          'CONFIRM_VALUE',
          'Digite o valor que o(a) cliente deseja pagar nessa forma de pagamento.',
        )}
      </div>
      <div className="inputRow">
        <Input
          label="Digite o valor"
          value={inputValue}
          error={errorMessage}
          inputMode="numeric"
          pattern="[0-9]*"
          startAdornment="R$"
          onKeyUp={(e: React.FormEvent<HTMLInputElement>) =>
            handleInputChange(e)
          }
        />
        {errorMessage ? (
          <p className="errorMessage">{getErrorMessage()}</p>
        ) : (
          <>
            {restValue && (
              <p className="inputMessage">
                {t('ALL_YOU_HAVE_TO_PAY', 'Resta pagar: ')}
                R$ {formatValueToString(restValue)}
              </p>
            )}
          </>
        )}
      </div>
      <Button
        onClick={() => {
          handleSubmit();
        }}
        disabled={!inputValue.length || inputValue === '0.00'}
      >
        {t('CONFIRM', 'CONFIRMAR')}
      </Button>
    </Container>
  );
};

export { EditPaymentAmountModal };
