import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    max-height: 1rem;
    width: 100%;

    button {
      .closeIcon {
        color: ${({ theme }) => theme.button_high};
        font-size: 1.5rem;
      }
    }
  }

  .titleRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .step {
      color: ${({ theme }) => theme.font_primary};
      font-weight: 600;
      font-size: 1.5rem;
      text-align: center;

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    .titleText {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      color: ${({ theme }) => theme.font_secondary};

      @media screen and (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    p {
      font-weight: 600;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.font_secondary};
      text-align: center;
    }
  }

  .menuRow {
    display: flex;
    flex-direction: row;
    /* gap: 3rem; */
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;

    .option {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.75rem;

      p {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.25rem;
        color: ${({ theme }) => theme.font_secondary};
      }

      @media screen and (max-width: 768px) {
        svg {
          width: 1.563rem;
          height: 1.563rem;
        }
        p {
          font-weight: 400;
          font-size: 0.625rem;
          line-height: 1rem;
          color: ${({ theme }) => theme.font_secondary};
        }
      }
    }
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .actionArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1.75rem;
    margin-bottom: 1rem;

    div {
      /* padding: 0 2.75rem; */

      button {
        border-radius: 0.5rem;
      }
    }
  }
`;
