import React from 'react';
import { useSelector } from 'react-redux';

// Module import
import { Header } from '@modules/Header';
import { Sidebar } from '@modules/Sidebar';
import { OrderSummary } from '@modules/OrderSummary';
import { BagSummary } from '@modules/BagSummary';
import { PrinterConfiguration } from '@modules/PrinterConfiguration';

// Hook import
import { OrderCreationProvider } from '@hooks/useOrderCreation';

// Style import
import { Container, Main, Frame, Content, Wrapper } from './styles';

const Layout: React.FC = ({ children }) => {
  // Global states
  const leftMenu = useSelector(state => state.ui.left_menu);
  const orderSummary = useSelector(state => state.ui.order_summary);
  const bagSummary = useSelector(state => state.ui.bag_summary);
  const printerConfig = useSelector(state => state.ui.printer_config);

  return (
    <Container>
      <OrderCreationProvider>
        <Header />
        {orderSummary && <OrderSummary />}
        {bagSummary && <BagSummary />}
        {printerConfig && <PrinterConfiguration />}
        <Main>
          <Sidebar />
          <Frame
            leftMenu={leftMenu}
            mobileSize={
              Math.max(
                document.documentElement.clientWidth,
                window.innerWidth || 0,
              ) <= 768
            }
          >
            <Content>
              <Wrapper>{children}</Wrapper>
            </Content>
          </Frame>
        </Main>
      </OrderCreationProvider>
    </Container>
  );
};

export { Layout };
