/* eslint-disable react/no-array-index-key */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { MdPersonOutline } from 'react-icons/md';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// Component import
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { CountryCodeSelect } from '@components/CountryCodeSelect';

// Hook import
import { useTheme } from '@hooks/useTheme';
import { useTranslation } from '@hooks/useTranslation';

// Util import
import { validateCpf } from '@util/validateCpf';
import { getFormErrors } from '@util/getFormErrors';
import { StringMask } from '@util/stringMask';
import { ddiList } from '@util/ddiList';

// Style import
import { Container, PhoneInputContainer } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  selectedClientInfo: IClientData | undefined;
  clientList: IClientData[];
  initialClientDocument: string;
  found: boolean | undefined;
  searchDocumentNumber: string;
  setSelectedClientInfo: React.Dispatch<
    React.SetStateAction<IClientData | undefined>
  >;
  setClientList: React.Dispatch<React.SetStateAction<IClientData[]>>;
  setInitialClientDocument: React.Dispatch<React.SetStateAction<string>>;
  setFound: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setCloseAllModals: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IClientData {
  document: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  birthdate?: string;
  brandcode?: number;
  code?: string;
}

// Feature identification
const featureKey = '@create_order/CUSTOMER';

const Customer: React.FC<IProps> = ({
  hide,
  selectedClientInfo,
  clientList,
  initialClientDocument,
  found,
  searchDocumentNumber,
  setSelectedClientInfo,
  setClientList,
  setFound,
  setCloseAllModals,
}) => {
  // Hooks
  const theme = useTheme();
  const { t } = useTranslation(featureKey);

  // Local refs
  const formClientDataRef = useRef<FormHandles>(null);

  const nameInputRef = useRef<HTMLInputElement>(null);
  const surnameInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const birthdayInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const [customerCountryCode, setCustomerCountryCode] = useState('+55');
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');

  const getAutoFillData = () => {
    const data = {
      name: '',
      surname: '',
      email: '',
      phone: '',
      birthdate: '',
    };

    return data;
  };
  const autoFillData = getAutoFillData();

  // Mask instance
  const phoneMaskPattern =
    customerCountryCode === '+55' ? '(99) 9 9999-9999' : '99999-99999-9999';

  const formatDocument = (e: any) => {
    const finalString = e?.match(/\d/g)?.join('');

    return finalString;
  };

  const formatSimulationBirthdate = () => {
    const birthdate = autoFillData?.birthdate?.slice(0, 10);
    const birthdatePeriods = birthdate?.split('-');
    const formattedBirthDate = `${birthdatePeriods[2]}/${birthdatePeriods[1]}/${birthdatePeriods[0]}`;

    return formattedBirthDate;
  };
  const inputtedBirthdate = autoFillData?.birthdate
    ? formatSimulationBirthdate()
    : '';

  // Client data submit
  const handleClientDataSubmit: SubmitHandler<{
    document: string;
    name: string;
    surname: string;
    phone: string;
    email: string;
    birthdate: string;
  }> = useCallback(
    async data => {
      // Setup a schema to be validated
      const schema = Yup.object()
        .shape({
          name: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CUSTOMER_NAME_TYPE',
                'O nome do cliente deve ser composto por caracteres.',
              ),
            )
            .required(
              t('REQUIRED_CUSTOMER_NAME', 'Informe o nome do cliente.'),
            ),
          surname: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CUSTOMER_SURNAME_TYPE',
                'O sobrenome do cliente deve ser composto por caracteres.',
              ),
            )
            .required(
              t('REQUIRED_CUSTOMER_SURNAME', 'Informe o sobrenome do cliente.'),
            ),
          birthdate: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CUSTOMER_BIRTHDATE_TYPE',
                'O aniversário do cliente deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(
              t(
                'REQUIRED_CUSTOMER_SURNAME',
                'Informe a data de aniversário do cliente.',
              ),
            ),
          email: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CUSTOMER_EMAIL_TYPE',
                'O e-mail do cliente deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .email(
              t('INVALID_CUSTOMER_EMAIL', 'O e-mail do cliente é inválido.'),
            )
            .required(
              t('REQUIRED_CUSTOMER_EMAIL', 'Informe o e-mail do cliente.'),
            ),
          phone: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CUSTOMER_PHONE_TYPE',
                'O telefone do cliente deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(
              t('REQUIRED_CUSTOMER_PHONE', 'Informe o telefone do cliente.'),
            ),
          document: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CUSTOMER_DOCUMENT_TYPE',
                'O CPF do cliente deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(
              t('REQUIRED_CUSTOMER_DOCUMENT', 'Informe o CPF do cliente.'),
            ),
        })
        .noUnknown(true, t('INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        getFormErrors(err, ({ form, errors }) => {
          formClientDataRef.current?.setErrors(form);
          toast.error(errors[0]);
        });
        return;
      }

      // Document validation
      if (!validateCpf({ code: data.document })) {
        const error = t('INVALID_CPF', 'CPF inválido.');
        formClientDataRef.current?.setFieldError('document', error);
        return;
      }

      // Format Phone
      data.phone = data.phone.replace(/[^0-9]/g, '');

      // Add country code to phone
      data.phone = customerCountryCode + data.phone;

      const isNumberBrazilian = customerCountryCode === '+55';

      // Format birthdate
      data.birthdate = data.birthdate.replaceAll('_', '');

      const separateBirthdateNumbers = data.birthdate.split('/');
      data.birthdate = `${separateBirthdateNumbers[2]}-${separateBirthdateNumbers[1]}-${separateBirthdateNumbers[0]}`;

      if (data.birthdate.length < 10) {
        toast.error(
          t(
            'INVALID_BIRTHDATE',
            `Data de aniversário deve ter formato DD/MM/AAAA.`,
          ),
        );
        return;
      }

      // Handle phone length
      if (!isNumberBrazilian && data.phone.length < 8) {
        toast.error(
          t(
            'INVALID_PHONE_NUMBER',
            `Número de telefone deve ter no mínimo 7 dígitos.`,
          ),
        );
        return;
      }

      if (!isNumberBrazilian && data.phone.length > 16) {
        toast.error(
          t(
            'INVALID_PHONE_NUMBER',
            'Número de telefone deve ter no máximo 15 dígitos.',
          ),
        );
        return;
      }

      if (isNumberBrazilian && data.phone.length < 13) {
        toast.error(t('INVALID_PHONE_NUMBER', 'Número de telefone inválido.'));
        return;
      }

      // Format Document
      data.document = formatDocument(String(data.document).trim());

      const selectedClientBrandCode = selectedClientInfo?.brandcode;
      const selectedClientCode = selectedClientInfo?.code;

      const newSelectedData = {
        ...data,
        brandcode: selectedClientBrandCode,
        code: selectedClientCode,
      };

      // Dispatch data
      setSelectedClientInfo(newSelectedData);

      // Hide modal
      if (typeof hide === 'function') hide();
    },
    [
      t,
      customerCountryCode,
      setSelectedClientInfo,
      hide,
      selectedClientInfo?.brandcode,
      selectedClientInfo?.code,
    ],
  );

  const handleClientSelect = (selectedClient: IClientData) => {
    if (selectedClient.birthdate) {
      const birthdate = selectedClient?.birthdate?.slice(0, 10);
      const birthdatePeriods = birthdate?.split('-');
      const formattedBirthDate = `${birthdatePeriods[2]}/${birthdatePeriods[1]}/${birthdatePeriods[0]}`;

      setSelectedClientInfo({
        ...selectedClient,
        birthdate: formattedBirthDate,
      });

      return;
    }
    setSelectedClientInfo(selectedClient);
  };

  // Handle phone data
  useEffect(() => {
    const getPhoneData = () => {
      const brazilPhonePattern = '(00) 0 0000-0000';
      const internationalPhonePattern = '00000-00000-00000';

      let maskInstance = new StringMask(brazilPhonePattern, {});

      const phoneNumber = selectedClientInfo?.phone || autoFillData?.phone;

      const findClientDDI = ddiList.filter(item =>
        phoneNumber.includes(item.ddi),
      );

      if (!findClientDDI.length) {
        const newCustomerPhoneNumber = maskInstance.proccess(
          String(phoneNumber),
        ).result;
        setCustomerPhoneNumber(newCustomerPhoneNumber);
        return;
      }
      setCustomerCountryCode(findClientDDI[0].ddi);

      const customerDDI = findClientDDI[0].ddi;
      const ddiLength = customerDDI.length;
      const customerPhoneString = String(phoneNumber.slice(ddiLength));

      let newCustomerPhoneNumber;

      if (customerDDI === '+55') {
        newCustomerPhoneNumber =
          maskInstance.proccess(customerPhoneString).result;
      } else {
        maskInstance = new StringMask(internationalPhonePattern, {});
        newCustomerPhoneNumber =
          maskInstance.proccess(customerPhoneString).result;
      }
      setCustomerPhoneNumber(newCustomerPhoneNumber);
    };
    getPhoneData();
  }, [selectedClientInfo, autoFillData?.phone]);

  // Not found
  if (!found)
    return (
      <Container>
        <div className="customerNotFound">
          <span>
            {t('CUSTOMER_NOT_FOUND', 'Não foi localizado nenhum cliente o CPF')}{' '}
            {initialClientDocument}
          </span>
          <Button
            // disabled={order.loading}
            onClick={() => {
              setFound(true);
            }}
          >
            {t('IDENTIFY_CUSTOMER', 'IDENTIFICAR CLIENTE')}
          </Button>
          <Button
            // disabled={order.loading}
            onClick={() => {
              setFound(undefined);
              setCloseAllModals(true);
              if (typeof hide === 'function') hide();
            }}
            variant="outlined"
          >
            {t('SEARCH_OTHER', 'CONSULTAR OUTRO CPF')}
          </Button>
        </div>
      </Container>
    );

  // Find with many clients
  if (clientList.length > 1 && selectedClientInfo === undefined)
    return (
      <Container>
        <ul>
          {clientList.slice(0, 5).map((client, index) => (
            <>
              {index === 0 ? (
                <div className="onboardModalStep">
                  <p>
                    {t(
                      'SELECT_THE_CUSTOMERS_EMAIL',
                      'SELECIONE O E-MAIL DA CLIENTE',
                    )}
                  </p>
                  <li
                    key={`${client.email}-${index}`}
                    style={{ marginBottom: 0 }}
                  >
                    <button
                      type="button"
                      onClick={() => handleClientSelect(client)}
                    >
                      <div className="icon">
                        <MdPersonOutline color={theme.font_low} size={30} />
                      </div>
                      <div className="info">
                        <span className="name">
                          {client.name} {client.surname}
                        </span>
                        <span className="email">{client.email}</span>
                      </div>
                    </button>
                  </li>
                </div>
              ) : (
                // eslint-disable-next-line react/no-array-index-key
                <li
                  key={`${client.email}-${index}`}
                  style={index === 1 ? { marginTop: '1rem' } : {}}
                >
                  <button
                    type="button"
                    onClick={() => handleClientSelect(client)}
                  >
                    <div className="icon">
                      <MdPersonOutline color={theme.font_low} size={30} />
                    </div>
                    <div className="info">
                      <span className="name">
                        {client.name} {client.surname}
                      </span>
                      <span className="email">{client.email}</span>
                    </div>
                  </button>
                </li>
              )}
            </>
          ))}
        </ul>
        <Button
          // disabled={order.loading}
          onClick={() => {
            setClientList([]);
            setFound(true);
            setSelectedClientInfo(undefined);
          }}
        >
          {t('USE_ANOTHER_EMAIL', 'UTILIZAR OUTRO E-MAIL')}
        </Button>
      </Container>
    );

  // Client full form
  return (
    <Container>
      <div className="clientFullForm">
        <Form
          key="formClientData"
          ref={formClientDataRef}
          onSubmit={handleClientDataSubmit}
          autoComplete="off"
        >
          <Input
            name="document"
            label={t('DOCUMENT', 'CPF')}
            value={selectedClientInfo?.document || searchDocumentNumber || ''}
            // disabled={order.loading}
            onEnterKey={() => nameInputRef.current?.focus()}
            mask="999.999.999-99"
            type="tel"
            inputMode="numeric"
            pattern="[0-9]*"
          />
          <Input
            ref={nameInputRef}
            name="name"
            label={t('NAME', 'Nome')}
            value={selectedClientInfo?.name || autoFillData?.name || ''}
            // disabled={order.loading}
            onEnterKey={() => surnameInputRef.current?.focus()}
          />
          <Input
            ref={surnameInputRef}
            name="surname"
            label={t('SURNAME', 'Sobrenome')}
            value={selectedClientInfo?.surname || autoFillData?.surname || ''}
            // disabled={order.loading}
            onEnterKey={() => birthdayInputRef.current?.focus()}
          />
          <Input
            ref={birthdayInputRef}
            name="birthdate"
            label={t('BIRTHDATE', 'Data de Aniversário')}
            value={selectedClientInfo?.birthdate || inputtedBirthdate || ''}
            // disabled={order.loading}
            onEnterKey={() => phoneInputRef.current?.focus()}
            mask="99/99/9999"
            inputMode="numeric"
            pattern="[0-9]*"
          />
          <PhoneInputContainer>
            <CountryCodeSelect
              customerCountryCode={customerCountryCode}
              setCustomerCountryCode={setCustomerCountryCode}
            />
            <Input
              ref={phoneInputRef}
              name="phone"
              label={t('PHONE', 'Telefone com DDD')}
              value={customerPhoneNumber || ''}
              // disabled={order.loading}
              onEnterKey={() => emailInputRef.current?.focus()}
              type="tel"
              inputMode="numeric"
              pattern="[0-9]*"
              mask={phoneMaskPattern}
            />
          </PhoneInputContainer>
          <Input
            ref={emailInputRef}
            type="email"
            autoCorrect="off"
            autoCapitalize="none"
            name="email"
            label={t('EMAIL', 'E-mail')}
            value={selectedClientInfo?.email || autoFillData?.email || ''}
            // disabled={order.loading}
            onEnterKey={() => formClientDataRef.current?.submitForm()}
          />
        </Form>
        <Button
          // disabled={order.loading}
          onClick={() => formClientDataRef.current?.submitForm()}
        >
          {t('CONFIRM', 'CONFIRMAR')}
        </Button>
        <Button
          // disabled={order.loading}
          onClick={() => {
            setCloseAllModals(true);
            if (typeof hide === 'function') hide();
          }}
          variant="outlined"
        >
          {t('SEARCH_ANOTHER_DOCUMENT', 'CONSULTAR OUTRO CPF')}
        </Button>
      </div>
    </Container>
  );
};

export { Customer };
