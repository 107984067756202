import styled from 'styled-components';
import { motion } from 'framer-motion';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding: 2rem;
  flex: 1;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.background_primary};
    border-radius: 0.25rem;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .titleRow {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media screen and (min-width: 768px) {
      margin-bottom: 2rem;
    }

    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      button {
        margin-right: 0;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    button {
      margin-right: 1rem;
      color: ${({ theme }) => theme.button_high};

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    p {
      font-weight: 600;
      font-size: 1.25rem;
    }
  }

  .deliverySection {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .sectionTitle {
      margin-bottom: 1rem;
      p {
        font-size: 1rem;
        font-weight: 600;
      }
    }

    .tabMenu {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.5rem;

      .disabledButton {
        opacity: 0.75;
        border-bottom: solid 1px ${({ theme }) => theme.button_primary};
      }

      button {
        width: 50%;
        color: ${({ theme }) => theme.button_primary};
        border-bottom: solid 2px ${({ theme }) => theme.button_primary};
        padding-bottom: 0.95rem;

        @media screen and (max-width: 760px) {
          padding-bottom: 0.5rem;
        }

        .buttonContent {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          font-weight: 500;

          p {
            margin-left: 0.5rem;
            font-size: 0.875rem;
          }
        }
      }
    }

    .deliveryContent {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      gap: 3rem;

      @media screen and (max-width: 760px) {
        flex-direction: column;
        gap: 0;
      }

      .packageSection {
        display: flex;
        flex-direction: column;
        width: 45%;

        @media screen and (max-width: 760px) {
          width: 100%;
        }
        .packages {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          height: 100%;
          .package {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding-bottom: 1rem;
            border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};
            .packageTitle {
              p {
                font-weight: 600;
                font-size: 1rem;
              }
            }
            .packageContent {
              p {
                font-weight: 500;
                font-size: 0.875rem;
                color: ${({ theme }) => theme.font_secondary};
              }
            }
          }

          .package:last-child {
            border-bottom: none;
          }
        }
      }
      .totalsSection {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 50%;
        margin-bottom: 0;
        margin-top: 0;

        @media screen and (max-width: 760px) {
          width: 100%;
        }
        .title {
          align-self: flex-end;
          margin-bottom: 2.5rem;
          button {
            p {
              text-decoration: underline;
              color: ${({ theme }) => theme.button_primary};
              font-weight: 400;
              font-size: 0.75rem;
            }
          }

          @media screen and (max-width: 760px) {
            align-self: flex-start;
          }
        }
      }
      .addressSection {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        margin: 1rem 0;
        border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};
        .addresTitle {
          height: fit-content;
          p {
            font-size: 0.75rem;
            font-weight: 500;
          }
        }

        .addressContent {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: flex-start;
          .addressContentText {
            margin-right: 1rem;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1.25rem;
          }
        }
        .editButtonMobile {
          margin-bottom: 1rem;
          button {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.button_primary};
            p {
              font-size: 0.75rem;
              font-weight: 500;
              text-decoration: underline;
              margin-right: 0.25rem;
            }
          }
        }

        .editButton {
          display: none;
        }
      }
    }
    .addressSection {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
      margin-bottom: 1rem;
      max-width: 450px;

      @media screen and (max-width: 760px) {
        max-width: 100%;
        border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};
      }

      .addressTitle {
        display: flex;
        flex-direction: row;
        gap: 8px;

        p {
          font-size: 0.75rem;
          font-weight: 500;
        }

        .editButton {
          button {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.button_primary};
            p {
              font-size: 0.75rem;
              font-weight: 500;
              text-decoration: underline;
              margin-right: 0.25rem;
            }
          }

          @media screen and (max-width: 760px) {
            margin-bottom: 1rem;
          }
        }
      }

      .editButtonMobile {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }

      .addressContent {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        .addressContentText {
          margin-right: 1rem;
          font-size: 0.75rem;
          font-weight: 500;

          @media screen and (max-width: 760px) {
            line-height: 1.25rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1800px) {
    ul {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 1440px) {
    ul {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    gap: 2rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  align-self: stretch;

  margin: 1rem 2rem 0;

  border-top: 0.188rem solid ${({ theme }) => theme.font_secondary};
  border-radius: 0.125rem;

  padding: 1rem 0;

  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 1rem 0;
    border: none;
    border-radius: 0;
    padding: 0 0.5rem;
  }

  .action {
    width: 21rem;
    max-width: 100%;
  }

  .removeUnavailable {
    color: ${({ theme }) => theme.font_high};
  }
`;
