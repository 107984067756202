import React from 'react';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';
import { IPayment } from '@hooks/useOrderCreation/models';

// Style import
import { Container } from './styles';

interface IProps {
  step: number;
  handleCouponClick: () => void;
}

// Feature identification
const featureKey = '@create_order/CART_PRICING_SUMMARY';

const CartPricingSummary: React.FC<IProps> = ({ step, handleCouponClick }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();

  const hasStoreCreditBeenSelected: IPayment[] = order.payments.filter(
    item => item.code === 'store_credit',
  );
  const storeCreditTotalAmount = hasStoreCreditBeenSelected?.[0]?.amount || 0;
  const getCouponProductDiscount = order.coupon?.productDiscount
    ? order.coupon.productDiscount
    : 0;

  const getShippingText = () => {
    if (step === 1) return 'a calcular';
    if (order.totals.shipping_current_price === 0) return 'grátis';
    const formatPrice = (order.totals.shipping_current_price / 100).toFixed(2);

    return `R$ ${formatPrice}`;
  };

  const getCouponText = () => {
    if (order.coupon?.name && step !== 2) return 'cupom aplicado';
    return 'adicionar cupom';
  };

  const couponValue = () => {
    if (step === 3) {
      return (
        <p className="discountValue">
          R${(getCouponProductDiscount / 100).toFixed(2)}
        </p>
      );
    }
    return <p>{order.coupon?.name}</p>;
  };

  const getTotalValueText = () => {
    if (!!hasStoreCreditBeenSelected && step === 4) {
      return (
        <p>
          R$ {((order.totals.total - storeCreditTotalAmount) / 100).toFixed(2)}
        </p>
      );
    }
    if (step === 1) {
      return <p>R$ {(order.totals.total_current_price / 100).toFixed(2)}</p>;
    }
    return <p>R$ {(order.totals.total / 100).toFixed(2)}</p>;
  };

  return (
    <Container>
      <div className="regularRow">
        <p>{t('SUBTOTAL', 'sub total')}</p>
        <p>
          R${' '}
          {(
            (order.totals.total_current_price + getCouponProductDiscount) /
            100
          ).toFixed(2)}
        </p>
      </div>
      <div className="regularRow">
        <p>{t('DELIVERY', 'entrega')}</p>
        <p>{getShippingText()}</p>
      </div>
      {!!hasStoreCreditBeenSelected.length &&
        hasStoreCreditBeenSelected?.[0]?.amount !== 0 &&
        step === 4 && (
          <div className="regularRow">
            <p>{t('STORE_CREDIT', 'saldo de créditos')}</p>
            <p className="discountValue">
              -R$ {(storeCreditTotalAmount / 100).toFixed(2)}
            </p>
          </div>
        )}
      <div className="regularRow">
        {!order.coupon?.name && <div />}
        <div className="cupom">
          <button
            type="button"
            onClick={() => {
              handleCouponClick();
              order.setPayments([]);
            }}
          >
            <p>{getCouponText()}</p>
          </button>
        </div>
        {order?.coupon?.name && <>{couponValue()}</>}
      </div>
      <div className="totalRow">
        <p>{t('TOTAL', 'total')}</p>
        <p>{getTotalValueText()}</p>
      </div>
    </Container>
  );
};

export { CartPricingSummary };
