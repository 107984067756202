import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Locale, parseJSON, format } from 'date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import { PDFExport } from '@progress/kendo-react-pdf';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

import { IOrder } from '@store/modules/auth/types';

// Style import
import { Container } from './styles';

interface PrintableComponentProps {
  order: IOrder;
}

// Feature identification
const featureKey = '@EXCHANGE_COUPON/INDEX';

const ExchangeCoupon: React.FC<PrintableComponentProps> = ({ order }) => {
  // Local refs
  const pdfExportComponent = useRef<any>(null);

  // Local states
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);

  // Global states
  const language = useSelector(state => state.ui.language);

  const { t } = useTranslation(featureKey);

  const ownStockPackage = order.order.packages.filter(
    packageitem => packageitem.package_type.code === 'own_stock',
  );

  const brand_name = ownStockPackage[0].brand.name;

  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  const exportPDFWithComponent = () => {
    if (pdfExportComponent && pdfExportComponent.current) {
      pdfExportComponent?.current?.save();
    }
  };

  const pdfFileName = `cupom_de_troca_${ownStockPackage[0]?.code}`;

  return (
    <Container>
      <PDFExport
        forcePageBreak=".page-break"
        ref={pdfExportComponent}
        fileName={pdfFileName}
      >
        <div
          className="fileToPrint"
          style={{
            fontSize: '0.5rem',

            backgroundColor: '#fff',
            color: '#000',

            position: 'absolute',
            zIndex: -99,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.94rem',
            padding: '1.37rem 0.88rem',
          }}
        >
          {ownStockPackage[0].items.map((item, index) => (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '.25rem',
                  gap: '1.5rem',
                }}
              >
                <p
                  style={{
                    fontFamily: 'courier',
                  }}
                >
                  {brand_name}
                </p>
                <p
                  style={{
                    fontFamily: 'courier',
                  }}
                >
                  {t('EXCHANGE_COUPON', 'CUPOM DE TROCA')}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  borderTop: 'dotted 1px',
                  borderBottom: 'dotted 1px',
                  padding: '1rem 0',
                  gap: '0.74rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'courier',
                    }}
                  >
                    {t('SALES_DATE', 'Data Venda:')}&nbsp;
                  </p>
                  <p
                    style={{
                      fontFamily: 'courier',
                    }}
                  >
                    {ownStockPackage.length && ownStockPackage[0].completed_at && (
                      <>
                        {format(
                          parseJSON(ownStockPackage[0].completed_at),
                          'dd',
                          {
                            locale: dateLanguagePack,
                          },
                        )}
                        /
                        {format(
                          parseJSON(ownStockPackage[0].completed_at),
                          'MM',
                          {
                            locale: dateLanguagePack,
                          },
                        )}
                        /
                        {format(
                          parseJSON(ownStockPackage[0].completed_at),
                          'yyyy',
                          {
                            locale: dateLanguagePack,
                          },
                        )}
                      </>
                    )}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        fontFamily: 'courier',
                      }}
                    >
                      {t('STORE', 'Loja:')}&nbsp;
                    </p>
                    <p
                      style={{
                        fontFamily: 'courier',
                      }}
                    >
                      {order.order.store.code}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        fontFamily: 'courier',
                      }}
                    >
                      {t('TICKET', 'Ticket:')}&nbsp;
                    </p>
                    <p
                      style={{
                        fontFamily: 'courier',
                      }}
                    >
                      {ownStockPackage[0].code}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.84rem',
                }}
              >
                <p
                  style={{
                    fontFamily: 'courier',
                    textAlign: 'center',
                  }}
                >
                  {t('ESSENTIAL_COUPON', 'ESTE CUPOM E INDISPENSAVEL')}
                  <br />
                  {t('TO_MAKE_THE_EXCHANGE', 'PARA REALIZAR A TROCA')}
                </p>
                <p
                  style={{
                    fontFamily: 'courier',
                    fontWeight: 700,
                  }}
                >
                  {t('EXCHANGE_DEADLINE_30_DAYS', 'PRAZO DE TROCA: 30 DIAS')}
                </p>
              </div>
              {index !== ownStockPackage[0].items.length - 1 && (
                <div className="page-break" />
              )}
            </>
          ))}
        </div>
      </PDFExport>
      <div className="ghost" />

      <Button variant="outlined" onClick={exportPDFWithComponent}>
        {t('DOWNLOAD', 'BAIXAR')}
      </Button>
    </Container>
  );
};
export { ExchangeCoupon };
