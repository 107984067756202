import { useLayoutEffect } from 'react';

// Store import
import { store } from '@store/index';
import { setActivePage } from '@store/modules/ui/actions';

const usePagePath = (categoryAtFeature: string) => {
  useLayoutEffect(() => {
    store.dispatch(setActivePage(categoryAtFeature));
  }, [categoryAtFeature]);
};

export { usePagePath };
