import styled from 'styled-components';

export const Container = styled.fieldset`
  border: solid 0.063rem ${({ theme }) => theme.font_secondary};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  width: 100%;

  legend {
    color: ${({ theme }) => theme.font_secondary};
    font-size: 0.75rem;
    font-weight: 400;
  }

  .optionsSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    button {
      &:hover {
        background: ${({ theme }) => theme.button_hover};
      }
    }

    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: fit-content;
      position: relative;

      .optionIcon {
        font-size: 1.25rem;
        margin-right: 0.75rem;
        color: ${({ theme }) => theme.font_secondary};
      }

      .optionText {
        font-size: 1rem;
        color: ${({ theme }) => theme.font_secondary};
      }
    }

    .selectedOption {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: ${({ theme }) => theme.background_secondary};

      .optionIcon {
        font-size: 1.25rem;
        margin-right: 0.75rem;
        color: ${({ theme }) => theme.font_low};
      }

      .optionText {
        font-size: 1rem;
        color: ${({ theme }) => theme.font_low};
      }
    }
  }
`;
