import React, { useState } from 'react';
import { ShepherdTour } from 'react-shepherd';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';
import { toast } from 'react-toastify';
import { MdAttachMoney } from 'react-icons/md';

import {
  deliverySteps,
  onlyOwnStockDeliverySteps,
} from '@pages/CreateOrder/Cart/OnboardingSteps/deliverySteps';

// Component import
import { OnboardingTourButton } from '@components/OnboardingTourButton';
import { Button } from '@components/Button';
import { CartStepper } from '@components/CartStepper';
import { RadioButtonMenu } from '@components/RadioButtonMenu';
import { PackagesList } from '@components/PackagesList';
import { IModalRef } from '@components/Modal';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';
import { useWindowSize } from '@hooks/useWindowSize';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { IPaymentTypes, IMenuItems, IShippingTypes } from '@util/CartModels';
import { SetItemsShippingData } from '@util/handleShippingData';
import {
  hasMultipleOwnStockOptions,
  inHandsOptionIsEnabled,
  deliveryOptionIsEnabled,
} from '@util/usersInformations';

// Local component import
import { SectionTitle } from './SectionTitle';
import { OwnStockTabMenu } from './OwnStockTabMenu';
import { CartAddress } from './CartAddress';
import { CartPricing } from './CartPricing';

// Style import
import { Container, Footer } from './styles';

// Feature identification
const featureKey = '@create_order/CART_DELIVERY';

interface IProps {
  onlyOwnStockDeliveryTourButtonRef: React.RefObject<HTMLButtonElement>;
  deliveryTourButtonRef: React.RefObject<HTMLButtonElement>;
  addressSelectorModalRef: React.RefObject<IModalRef>;
  setStatusState: (
    value: 'bag' | 'identification' | 'delivery' | 'payments',
  ) => void;
  setLoading: (value: boolean) => void;
  setAvailablePaymentTypes: (value: IPaymentTypes[]) => void;
  selectedShipping: IShippingTypes;
  handleCouponClick: () => void;
}

const CartDelivery: React.FC<IProps> = ({
  onlyOwnStockDeliveryTourButtonRef,
  deliveryTourButtonRef,
  addressSelectorModalRef,
  setStatusState,
  setLoading,
  setAvailablePaymentTypes,
  selectedShipping,
  handleCouponClick,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const user = useSelector(state => state.auth);
  const [width] = useWindowSize();

  // Local states
  const [selectedTab, setSelectedTab] = useState<
    'infinite_aisle' | 'own_stock'
  >('own_stock');
  const [ownStockTabMenuList, setOwnStockTabMenuList] = useState<IMenuItems[]>([
    {
      title: 'Entrega Imediata',
      enabled: inHandsOptionIsEnabled(user),
      selected: order.items.every(
        item => item.shipping.selected_id === 'in_hands',
      ),
      subTitle: 'Produto será entregue para cliente em loja',
      id: 'in_hands',
    },
    {
      title: 'Delivery/Portador',
      enabled: deliveryOptionIsEnabled(user),
      selected: order.items.every(
        item => item.shipping.selected_id === 'delivery',
      ),
      subTitle: 'Produto será entregue por terceiros no endereço',
      id: 'delivery',
    },
  ]);

  const cartId = useDebounce(order?.orderCode, 1000);
  const ownStockItems = order.items.filter(
    item => item.shipping.selected_type === 'OWN_STOCK',
  );
  const deliveryItems = order.items.filter(
    item => item.shipping.selected_type === 'DELIVERY',
  );
  const cartWithOnlyOwnStockItems = !!(
    ownStockItems.length > 0 && deliveryItems.length === 0
  );
  const cartWithOnlyDeliveryItems = !!(
    ownStockItems.length === 0 && deliveryItems.length > 0
  );

  // Setup delivery onboard shepherd
  deliverySteps[4].when = {
    show: () => {
      setSelectedTab('infinite_aisle');
    },
  };

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: false,
      },
    },
    useModalOverlay: true,
  };

  const getSelectedShipping = (list: IShippingTypes[]) => {
    const filterSelected = list.filter((item: any) => item.selected);
    return filterSelected[0];
  };

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      // API call
      const response = await api.get(`/v1/cart/${cartId}/payment`);

      // Check if is valid
      if (!Array.isArray(response.data)) throw new Error();

      const sortPaymentData = response.data.reverse();

      setAvailablePaymentTypes(sortPaymentData);
      setLoading(false);
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);
      setLoading(false);
    }
  };

  const handleOwnStockModeClick = (title: string) => {
    const selectedOwnStockOption = ownStockTabMenuList.find(
      item => item.title === title,
    );
    const orderDeliveryItems = order.items.filter(
      item => item.shipping.selected_type === 'DELIVERY',
    );
    const orderOwnStockItems = order.items.filter(
      item => item.shipping.selected_type === 'OWN_STOCK',
    );
    const newOrderOwnStockItems = orderOwnStockItems.map(item => {
      return {
        ...item,
        shipping: {
          ...item.shipping,
          selected_id: selectedOwnStockOption?.id,
        },
      };
    });
    if (selectedOwnStockOption?.title === title) {
      order.setItems(orderDeliveryItems.concat(newOrderOwnStockItems));
      setOwnStockTabMenuList(
        ownStockTabMenuList.map(item => {
          return { ...item, selected: item.title === title && !item.selected };
        }),
      );
    }
  };

  const handleShippingButtonClick = (title: string) => {
    const changeSelectedShipping = order?.shippingTypes.map(item =>
      item.title === title
        ? { ...item, selected: true }
        : { ...item, selected: false },
    );

    order?.setShippingTypes(changeSelectedShipping);
    selectedShipping = getSelectedShipping(changeSelectedShipping);

    const addShippingDataToItems = SetItemsShippingData(
      order?.items,
      selectedShipping,
    );

    order.setItems(addShippingDataToItems);
  };

  const getMenuList = () => {
    const orderHasOwnStockItem =
      selectedTab === 'own_stock' && !!ownStockItems.length;

    const ownStockMenuList = ownStockTabMenuList.filter(
      option => option.enabled,
    );

    if (orderHasOwnStockItem || cartWithOnlyOwnStockItems)
      return ownStockMenuList;
    return order?.shippingTypes;
  };

  const handleModeClick = (title: string) => {
    const orderHasOwnStockItem =
      selectedTab === 'own_stock' && !!ownStockItems.length;
    if (orderHasOwnStockItem || cartWithOnlyOwnStockItems) {
      handleOwnStockModeClick(title);
      return;
    }
    handleShippingButtonClick(title);
  };

  const renderPageBody = () => {
    if (width > 768) {
      return (
        <>
          <div className="deliveryContent">
            <PackagesList selectedMethod={selectedShipping} />
            <CartPricing handleCouponClick={handleCouponClick} />
          </div>
          <CartAddress addressSelectorModalRef={addressSelectorModalRef} />
        </>
      );
    }
    return (
      <div className="deliveryContent">
        <PackagesList selectedMethod={selectedShipping} />
        <CartAddress addressSelectorModalRef={addressSelectorModalRef} />
        <CartPricing handleCouponClick={handleCouponClick} />
      </div>
    );
  };

  return (
    <>
      <Container>
        <>
          {!cartWithOnlyOwnStockItems && (
            <ShepherdTour
              steps={onlyOwnStockDeliverySteps}
              tourOptions={tourOptions}
            >
              <OnboardingTourButton
                reference={onlyOwnStockDeliveryTourButtonRef}
                completeTourKey="onlyOwnStockDeliveryTourComplete"
                cancelTourKey="onlyOwnStockDeliveryTourCancel"
              />
            </ShepherdTour>
          )}

          {!cartWithOnlyOwnStockItems && !cartWithOnlyDeliveryItems && (
            <ShepherdTour steps={deliverySteps} tourOptions={tourOptions}>
              <OnboardingTourButton
                reference={deliveryTourButtonRef}
                completeTourKey="deliveryTourComplete"
                cancelTourKey="deliveryTourCancel"
              />
            </ShepherdTour>
          )}
        </>
        <SectionTitle setStatusState={setStatusState} />
        <CartStepper step={3} />
        <div className="deliverySection">
          <div className="sectionTitle">
            <p>
              {t(
                'SET_DELIVERY_PREFERENCES',
                'Defina as Preferências de entrega:',
              )}
            </p>
          </div>
          <div className="onboardTabMenu">
            {!cartWithOnlyDeliveryItems && !cartWithOnlyOwnStockItems && (
              <OwnStockTabMenu
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            )}
            <RadioButtonMenu
              menuList={getMenuList()}
              handleClick={handleModeClick}
            />
          </div>

          {renderPageBody()}
        </div>
      </Container>

      <Footer>
        <Button
          className="action"
          startIcon={<MdAttachMoney />}
          onClick={() => {
            setStatusState('payments');
            order.setPayments([]);
            getPaymentMethods();
          }}
          disabled={
            !!order.items.filter(item => item.shipping.available === false)
              .length ||
            order.loading ||
            (ownStockTabMenuList.every(item => !item.selected) &&
              !cartWithOnlyDeliveryItems &&
              hasMultipleOwnStockOptions(user))
          }
          color="primary"
        >
          <p>{t('GO_TO_PAYMENT', 'IR PARA PAGAMENTO')}</p>
        </Button>
      </Footer>
    </>
  );
};

export { CartDelivery };
