import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  &.invisible {
    opacity: 0;
  }

  .title {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.font_secondary};
    margin-bottom: 0.875rem;
  }

  .availableSizes {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .size {
      border: solid 0.063rem ${({ theme }) => theme.font_secondary};
      color: ${({ theme }) => theme.font_secondary};
      border-radius: 0.5rem;
      width: 2.813rem;
      height: 2.813rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin: 0 1rem 1rem 0;

      &:hover {
        background: ${({ theme }) => theme.button_hover};
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.font_secondary};
      }
    }

    .selectedSize {
      border: solid 0.063rem ${({ theme }) => theme.font_secondary};
      background-color: ${({ theme }) => theme.background_secondary};
      color: ${({ theme }) => theme.background_primary};
      border-radius: 0.5rem;
      width: 2.813rem;
      height: 2.813rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin-right: 1rem;

      p {
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    .unavailableSize {
      border: solid 0.063rem ${({ theme }) => theme.font_secondary};
      color: ${({ theme }) => theme.font_secondary};
      border-radius: 0.5rem;
      width: 2.813rem;
      height: 2.813rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin-right: 1rem;

      pointer-events: none;

      div {
        border: solid 0.063rem ${({ theme }) => theme.font_secondary};
        height: 90%;
        -webkit-transform: skew(45deg);
      }

      p {
        position: absolute;
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.font_secondary};
      }
    }
  }
`;
