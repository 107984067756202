import { useEffect } from 'react';
import { createPortal } from 'react-dom';

// Interfaces
interface IPortalProps {
  children: React.ReactNode;
}

const Portal: React.FC<IPortalProps> = ({ children }) => {
  // Get app root
  const app = document.getElementById('root');

  // Create a element to append
  const element = document.createElement('div');

  // Make the portal content
  useEffect(() => {
    app?.appendChild(element);
    return () => {
      app?.removeChild(element);
    };
  });

  return createPortal(children, element);
};

export { Portal };
