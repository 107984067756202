import React from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

// Interfaces
interface IProps {
  barcodeResult?: any;
  setBarcodeResult: React.Dispatch<any>;
}

const BarcodeScanner: React.FC<IProps> = ({ setBarcodeResult }) => {
  return (
    <BarcodeScannerComponent
      width={500}
      height={500}
      onUpdate={(err, result: any) => {
        if (result) {
          setBarcodeResult(result.text);
        } else setBarcodeResult('Not Found');
      }}
    />
  );
};

export { BarcodeScanner };
