import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  width: 100%;
  height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.background_primary};

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 30rem;
    max-width: 90vw;
    border-radius: 0.313rem;
    filter: ${({ theme }) =>
      `drop-shadow(0px 0.0625rem 0.125rem ${rgba(
        theme.background_low,
        0.25,
      )})`};

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 5rem;
      width: 100%;

      img {
        width: 80%;
      }
    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      flex-direction: column;
      margin-bottom: 0.5rem;
    }

    button.forgot {
      margin: 0;
      padding: 0;
      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.825rem;
      margin-bottom: 1rem;
    }
  }
`;

export const PasswordVisibleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
