import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  MdAdd,
  MdArrowBack,
  MdOutlineShoppingCart,
  MdClose,
} from 'react-icons/md';
import { ShepherdTour } from 'react-shepherd';

// Cart Onboarding import
import {
  identificationSteps,
  emailModalSteps,
  newCLientModalSteps,
} from '@pages/CreateOrder/Cart/OnboardingSteps/identificationSteps';

// Component import
import { OnboardingTourButton } from '@components/OnboardingTourButton';
import { Button } from '@components/Button';
import { CartStepper } from '@components/CartStepper';
import { Input } from '@components/Input';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { IModalRef } from '@components/Modal';
import { CartSaleCode } from '@components/CartSaleCode';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';
import { useTheme } from '@hooks/useTheme';
import { useWindowSize } from '@hooks/useWindowSize';

import { ClientInfo } from './ClientInfo';

import { Container, Footer } from './styles';

// Feature identification
const featureKey = '@create_order/BAG_STAGE';

// Interfaces
interface IProps {
  customerModalRef: React.RefObject<IModalRef>;
  addressmodalRef: React.RefObject<IModalRef>;
  identificationRef: React.RefObject<HTMLButtonElement>;
  emailOnboardModalRef: React.RefObject<HTMLButtonElement>;
  CPFOnboardModalRef: React.RefObject<HTMLButtonElement>;
  setStage: React.Dispatch<
    React.SetStateAction<'bag' | 'identification' | 'delivery' | 'payments'>
  >;
  getShippingData: () => Promise<void>;
  loading: boolean;
  documentError: string;
  searchDocumentNumber: string;
  setSearchDocumentNumber: React.Dispatch<React.SetStateAction<string>>;
  bagCustomerDocument: string;
  setBagCustomerDocument: React.Dispatch<React.SetStateAction<string>>;
  localStorageClientDocument: string;
}

const CartIdentification: React.FC<IProps> = ({
  customerModalRef,
  addressmodalRef,
  identificationRef,
  emailOnboardModalRef,
  CPFOnboardModalRef,
  loading,
  documentError,
  setStage,
  getShippingData,
  searchDocumentNumber,
  setSearchDocumentNumber,
  bagCustomerDocument,
  setBagCustomerDocument,
  localStorageClientDocument,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const user = useSelector(state => state.auth);
  const theme = useTheme();
  const [width] = useWindowSize();

  // Local states
  const [document, setDocument] = useState<string>(
    searchDocumentNumber ||
      bagCustomerDocument ||
      localStorageClientDocument ||
      ' ',
  );

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: false,
      },
    },
    useModalOverlay: true,
  };

  const formatDocument = (e: any) => {
    const finalString = e?.match(/\d/g)?.join('');

    return finalString;
  };

  const documentInputEndAdornment =
    searchDocumentNumber ||
    bagCustomerDocument ||
    localStorageClientDocument ? (
      <button
        type="button"
        className="deleteButton"
        onClick={() => {
          setDocument('');
          setSearchDocumentNumber('');
          setBagCustomerDocument('');
        }}
      >
        <MdClose />
      </button>
    ) : (
      <div className="endAdornmentSpace" />
    );

  useEffect(() => {
    if (order.customer.name && !order.address.street) {
      addressmodalRef.current?.show();
    }
  }, [addressmodalRef, order.address.street, order.customer.name]);

  return (
    <>
      <Container>
        <>
          <ShepherdTour steps={identificationSteps} tourOptions={tourOptions}>
            <OnboardingTourButton
              reference={identificationRef}
              completeTourKey="identificationTourComplete"
              cancelTourKey="identificationTourCancel"
            />
          </ShepherdTour>
          <ShepherdTour steps={emailModalSteps} tourOptions={tourOptions}>
            <OnboardingTourButton
              reference={emailOnboardModalRef}
              completeTourKey="emailModalTourComplete"
            />
          </ShepherdTour>
          <ShepherdTour steps={newCLientModalSteps} tourOptions={tourOptions}>
            <OnboardingTourButton
              reference={CPFOnboardModalRef}
              completeTourKey="CPFmodalTourComplete"
            />
          </ShepherdTour>
        </>
        <div className="titleRow">
          <button
            type="button"
            onClick={() => {
              setStage('bag');
            }}
          >
            <MdArrowBack />
          </button>
          <div className="title">
            {width > 768 && <p>{t('IDENTIFICATION', 'Identificação')}</p>}

            {user.sale_codes && (
              <>
                {order.saleCode.code &&
                  order.saleCode.name &&
                  user.sale_codes.length > 1 && <CartSaleCode />}
              </>
            )}
          </div>
        </div>
        <CartStepper step={2} />
        <div className="idInfoSection">
          <div className="title">
            <p>{t('INSERT_CPF', 'Inserir CPF de Cliente:')}</p>
          </div>
          <div className="content">
            <div className="cpfForm">
              <form>
                <Input
                  label={t('DOCUMENT', 'CPF')}
                  disabled={order.loading || loading}
                  error={documentError}
                  onChange={(value: any) => {
                    setDocument(formatDocument(String(value).trim()));
                    setSearchDocumentNumber(
                      formatDocument(String(value).trim()),
                    );
                    setBagCustomerDocument('');
                  }}
                  value={document}
                  mask="999.999.999-99"
                  type="tel"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  endAdornment={documentInputEndAdornment}
                />
              </form>
              {loading && (
                <LoadingIndicator color={theme.font_secondary} size={1.8} />
              )}
            </div>
            {((searchDocumentNumber &&
              searchDocumentNumber === order.customer.document) ||
              bagCustomerDocument ||
              localStorageClientDocument) && (
              <ClientInfo
                customerModalRef={customerModalRef}
                addressmodalRef={addressmodalRef}
              />
            )}
          </div>
        </div>
      </Container>

      <Footer>
        <Button
          className="action"
          startIcon={<MdAdd />}
          onClick={() => {
            order.setAddingProduct(true);
          }}
          variant="outlined"
          color="primary"
        >
          <p>{t('ADD_PRODUCT', 'ADICIONAR PRODUTO')}</p>
        </Button>
        <Button
          className="action"
          startIcon={<MdOutlineShoppingCart />}
          disabled={
            !(
              order.address.zipcode &&
              order.address.city &&
              order.customer.document &&
              (searchDocumentNumber?.length === 11 ||
                bagCustomerDocument ||
                localStorageClientDocument)
            ) || order.loading
          }
          onClick={() => {
            setStage('delivery');
            getShippingData();
          }}
          color="primary"
        >
          <p>{t('SET_DELIVERY', 'DEFINIR ENTREGA')}</p>
        </Button>
      </Footer>
    </>
  );
};

export { CartIdentification };
