import React, { useRef, useCallback, forwardRef } from 'react';
import { MdInfoOutline } from 'react-icons/md';

// Component import
import { Button } from '@components/Button';
import { IModalRef, Modal } from '@components/Modal';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useForwardRef } from '@hooks/useForwardRef';
import { useOrder } from '@hooks/useOrder';
import { useTheme } from '@hooks/useTheme';

// Style import
import { Cancel } from './styles';

// Interfaces
interface ICancelOrderModalRef {
  show: () => any;
  hide: () => any;
}

// Feature identification
const featureKey = '@order/CANCEL';

const CancelOrder = forwardRef<ICancelOrderModalRef>((_, forwardedRef) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const order = useOrder();

  // Local refs
  const modalRef = useRef<IModalRef>(null);

  const show = useCallback(() => {
    modalRef.current?.show();
  }, []);
  const hide = useCallback(() => {
    modalRef.current?.hide();
  }, []);

  // Merge local ref with forward ref
  useForwardRef(forwardedRef, {
    show,
    hide,
  });

  return (
    <Modal ref={modalRef} size="small">
      <Cancel>
        <MdInfoOutline color={theme.background_secondary} size={50} />
        <p>
          {t(
            'ORDER_CANCELATION_HINT',
            'Tem certeza que deseja cancelar o pedido? Esta ação não pode ser desfeita.',
          )}
        </p>
        <Button
          disabled={order.cancelLoading}
          color={theme.background_secondary}
          onClick={() => modalRef.current?.hide()}
        >
          {t('CANCEL_ORDER_CANCELATION', 'NÃO CANCELAR')}
        </Button>
        <Button
          loading={order.cancelLoading}
          color={theme.background_danger}
          onClick={() =>
            !!order.data?.order.id &&
            order
              .cancel(order.data?.order.id)
              .then(() => modalRef.current?.hide())
          }
        >
          {t('CONFIRM_ORDER_CANCELATION', 'SIM. CANCELAR O PEDIDO.')}
        </Button>
      </Cancel>
    </Modal>
  );
});

export type { ICancelOrderModalRef };
export { CancelOrder };
