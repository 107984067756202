const getQueryStringValues = (
  url: string,
): {
  [key: string]: any;
} => {
  try {
    const match = decodeURIComponent(url).split('?')[1];
    return JSON.parse(
      `{"${decodeURI(match)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`,
    );
  } catch {
    return {};
  }
};

export { getQueryStringValues };
