import React from 'react';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useWindowSize } from '@hooks/useWindowSize';

// Style import
import { Container, ShippingDescription } from './styles';

// Feature identification
const featureKey = '@packages_list/CART_INDEX';

interface IShippingTypes {
  title: string;
  method: string;
  enabled: boolean;
  selected: boolean;
  tagCode: string;
  data: any;
}

interface IProps {
  selectedMethod: IShippingTypes;
}

const PackagesList: React.FC<IProps> = ({ selectedMethod }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const [width] = useWindowSize();

  const getPackageText = (packages: any) => {
    if (packages.selected_type === 'OWN_STOCK') {
      return (
        <div className="productShippingQuantity">
          {packages.items.length} Produtos - Levar agora
        </div>
      );
    }
    return (
      <>
        {width > 900 ? (
          <div className="productShippingQuantity">
            {packages.items.length} Produtos - Receber em casa -
            <span> Em até {packages.business_days_to_delivery} dias úteis</span>
          </div>
        ) : (
          <>
            <div className="productShippingQuantity">
              {packages.items.length} Produtos - Receber em casa
            </div>
            <div className="shippingPrice">
              {' '}
              Em até {packages.business_days_to_delivery} dias úteis
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <Container>
      <div className="packages">
        {selectedMethod?.data?.packages?.map((packages: any, index: number) => (
          <div className="package">
            <div className="packageTitle">
              <p>{t(`PACKAGE_${index + 1}`, `Pacote ${index + 1}:`)}</p>
            </div>
            <ShippingDescription>
              {getPackageText(packages)}
            </ShippingDescription>
          </div>
        ))}
      </div>
    </Container>
  );
};

export { PackagesList };
