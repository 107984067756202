import { StringMask } from '@util/stringMask';

const getCEPFormat = (cep: string) => {
  const formattedCEP = `${cep.slice(0, 5)}-${cep.slice(5, 8)} `;
  return formattedCEP;
};

const getCPFFormat = (cpf: string) => {
  const formattedCPF = `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(
    6,
    9,
  )}-${cpf.slice(9, 11)}`;
  return formattedCPF;
};

const formatDocument = (e: any) => {
  const finalString = e?.match(/\d/g)?.join('');

  return finalString;
};

const getPhoneFormat = (phone: string) => {
  const brazilPhonePattern = '00 (00) 0 0000-0000';
  const internationalPhonePattern = '000-0000-#';
  let maskInstance;
  if (phone.includes('+55')) {
    maskInstance = new StringMask(brazilPhonePattern, {});
  } else {
    maskInstance = new StringMask(internationalPhonePattern, {});
  }
  const adjustedPhoneString = phone.slice(1);
  const formattedPhone = maskInstance.proccess(adjustedPhoneString).result;
  return `+${formattedPhone}`;
};

export { getCEPFormat, formatDocument, getPhoneFormat, getCPFFormat };
