import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 3rem;

  .listHeader {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1.1rem;
    border-bottom: 0.0625rem solid ${({ theme }) => theme.font_secondary};

    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;

      p {
        font-size: 1.25rem;
        font-weight: 600;
        color: ${({ theme }) => theme.font_secondary};
        white-space: nowrap;

        &.icon {
          font-size: 1.5rem;
          transition: 300ms;

          &.open {
            transform: rotateZ(180deg);
          }
        }
      }
    }

    .subtitle {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${({ theme }) => theme.font_primary};
    }
  }

  .listComponent {
    display: flex;
    flex-direction: column;

    li {
      padding: 1rem 0;
      list-style: none;
      border-bottom: 0.0625rem solid ${({ theme }) => theme.font_secondary};
      display: flex;
      justify-content: space-between;
      width: 100%;

      .itemButton {
        margin-right: 1rem;
        height: fit-content;
        .MuiRadio-colorPrimary {
          color: ${({ theme }) => theme.button_primary};
        }
        .MuiButtonBase-root {
          padding: 0;
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }

      .printerName {
        font-size: 1rem;
        font-weight: 400;

        &.disabled {
          opacity: 0.4;
        }
      }
    }

    li.unavailableList {
      padding: 1rem 0 0;
      list-style: none;
      border-bottom: 0.0625rem solid ${({ theme }) => theme.font_secondary};
      display: flex;
      flex-direction: column;
      width: 100%;

      .listItemInfo {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .itemButton {
          margin-right: 1rem;
          pointer-events: none;
          opacity: 0.4;
          height: fit-content;
          .MuiRadio-colorPrimary {
            color: ${({ theme }) => theme.button_primary};
          }
          .MuiButtonBase-root {
            padding: 0;
          }
        }

        .printerName {
          font-size: 1rem;
          font-weight: 400;
          opacity: 0.4;
        }
      }

      .unavailableSubtitle {
        color: ${({ theme }) => theme.background_danger};
        width: 100%;
        font-size: 0.625rem;
        margin: 0.3rem 0;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .loadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
`;
