import styled from 'styled-components';
import { darken } from 'polished';

interface IContainerProps {
  disabled?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  min-width: 100%;
  padding: 1rem;
  flex: 1;
  transition: 0.25s;
  color: ${({ theme }) => theme.font_secondary};
  /* background: ${props => {
    if (props.disabled) {
      return ({ theme }) => darken(0.25, theme.background_secondary);
    }
    return '';
  }}; */

  .paymentContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .contentColumn {
      width: 100%;
      display: flex;
      flex-direction: column;

      .paymentTitleRow {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          margin-left: 0.5rem;
        }
      }

      .valueRow {
        margin-left: 2.25rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .value {
          font-weight: 600;
        }

        button {
        }
      }
    }
  }

  .actionColumn {
    .itemCheckbox {
    }

    .itemButton {
      margin-right: 0.5rem;
      height: fit-content;

      .MuiSvgIcon {
        color: ${({ theme }) => theme.button_primary};
      }

      .MuiRadio-colorPrimary {
        color: ${({ theme }) => theme.button_primary};
      }
      .MuiButtonBase-root {
        padding: 0;
      }

      .Mui-checked {
        color: ${({ theme }) => theme.background_primary};
      }
    }
  }

  &:hover {
    background: ${({ theme }) => theme.button_hover};
  }

  span {
    text-align: center;
  }

  &.selected {
    color: ${({ theme }) => theme.font_low};
    background: ${({ theme }) => theme.background_secondary};

    &:hover {
      background: ${({ theme }) => theme.button_hover};
    }
  }
`;
