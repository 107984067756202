import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useSelector } from 'react-redux';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Module import
import { IPrinterList, ISelectedPrinters } from '../models';
// Inner component import
import { ListComponent } from './ListComponent';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@component/PRINTER_CONFIG';

interface IProps {
  printerType: string;
  printerList?: IPrinterList[];
  selectedPrinters?: ISelectedPrinters;
  setSelectedPrinters: (printer: ISelectedPrinters) => void;
  loading: boolean;
}

const PrinterList: React.FC<IProps> = ({
  printerType,
  printerList,
  selectedPrinters,
  setSelectedPrinters,
  loading,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  // Global states
  const user = useSelector(state => state.auth);

  // Local states
  const [menuOpen, setMenuOpen] = useState(false);
  const classForOpenList = menuOpen ? 'open' : '';

  const listHeaderTitle =
    printerType === 'regular'
      ? t('PRINTER_FOR_A4', 'Impressora para papel A4')
      : t('THERMAL_PRINTER', 'Impressora térmica');
  const listHeaderSubtitle =
    printerType === 'regular'
      ? t(
          'SELECT_PRINTER',
          'Selecione qual impressora será usada para papel comum',
        )
      : t(
          'THERMAL_PRINTER',
          'Selecione qual impressora será usada para impressão térmica',
        );

  useEffect(() => {
    if (!loading && printerList?.length) {
      const userConfiguredPrinter = user.store?.printers;
      const configuredPrinterType =
        printerType === 'regular'
          ? userConfiguredPrinter?.regular
          : userConfiguredPrinter?.thermal;

      const isConfiguredPrinterTypeInList = printerList?.find(
        printer => printer.name === configuredPrinterType,
      );

      if (!isConfiguredPrinterTypeInList) {
        setMenuOpen(true);
      }
    }
  }, [loading, printerList, printerType, user.store?.printers]);

  return (
    <Container>
      <button
        className="listHeader"
        type="button"
        onClick={() => setMenuOpen(prev => !prev)}
      >
        <div className="title">
          <p>{listHeaderTitle}</p>
          <p className={`icon ${classForOpenList}`}>
            <MdKeyboardArrowDown />
          </p>
        </div>
        <div className="subtitle">{listHeaderSubtitle}</div>
      </button>
      {menuOpen && loading && (
        <div className="loadingContainer">
          <LoadingIndicator size={1.5} />
        </div>
      )}
      {menuOpen && !loading && (
        <ListComponent
          printerType={printerType}
          printerList={printerList}
          selectedPrinters={selectedPrinters}
          setSelectedPrinters={setSelectedPrinters}
          menuOpen={menuOpen}
        />
      )}
    </Container>
  );
};

export { PrinterList };
