import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useDebounce } from 'use-lodash-debounce';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import 'shepherd.js/dist/css/shepherd.css';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';
import { Modal, IModalRef } from '@components/Modal';
import { MobileSummary } from '@components/MobileSummary';
import { CartPayment } from '@components/CartStages/CartPayment';
import { CartDelivery } from '@components/CartStages/CartDelivery';
import { CartBag } from '@components/CartStages/CartBag';
import { CartIdentification } from '@components/CartStages/CartIdentification';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTheme } from '@hooks/useTheme';
import { AddProductProvider } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Action import
import { setSavedBagAddingProduct, setBagId } from '@store/modules/ui/actions';

// Util import
import { validateCpf } from '@util/validateCpf';
import {
  AddDataToShippingList,
  SetItemsShippingData,
} from '@util/handleShippingData';
import {
  IShippingTypes,
  IClientData,
  IBagItems,
  IPaymentTypes,
} from '@util/CartModels';

// Page import
import { Coupon } from '../Coupon';
import { Customer } from '../Customer';
import { Address } from '../Address';
import { Product } from '../Product';
import { EditionScreen } from '../Product/EditionScreen';
import { CartEmpty } from './CartEmpty';

// Style import
import { LoadingIndicatorContainer } from './styles';

import './styles.css';

// Feature identification
const featureKey = '@create_order/CART_INDEX';

const Cart: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const bag_id = useSelector(state => state.ui.bag_id);
  const user = useSelector(state => state.auth);
  const SavedBagAddingProduct = useSelector(
    state => state.ui.saved_bag_adding_product,
  );

  // Local refs
  const customerSelectorModalRef = useRef<IModalRef>(null);
  const addressSelectorModalRef = useRef<IModalRef>(null);
  const couponSelectorModalRef = useRef<IModalRef>(null);
  const bagTourButtonRef = useRef<HTMLButtonElement>(null);
  const emailModalTourButtonRef = useRef<HTMLButtonElement>(null);
  const CPFModalTourButtonRef = useRef<HTMLButtonElement>(null);
  const identificationTourButtonRef = useRef<HTMLButtonElement>(null);
  const deliveryTourButtonRef = useRef<HTMLButtonElement>(null);
  const onlyOwnStockDeliveryTourButtonRef = useRef<HTMLButtonElement>(null);
  const paymentTourButtonRef = useRef<HTMLButtonElement>(null);
  const paymentTourButtonWithoutStoreCreditRef =
    useRef<HTMLButtonElement>(null);
  const multiplePaymentTourButtonRef = useRef<HTMLButtonElement>(null);
  const storeCreditTourButtonRef = useRef<HTMLButtonElement>(null);

  // Local states
  const [loading, setLoading] = useState(false);
  const [customerSelectorModal, setCustomerSelectorModal] = useState<
    'close' | 'open'
  >('close');
  const [addressSelectorModal, setAddressSelectorModal] = useState<
    'close' | 'open'
  >('close');
  const [statusState, setStatusState] = useState<
    'bag' | 'identification' | 'delivery' | 'payments'
  >('bag');
  const [found, setFound] = useState<boolean | undefined>(undefined);
  const [initialClientDocument, setInitialClientDocument] = useState('');
  const [clientList, setClientList] = useState<IClientData[]>([]);
  const [selectedClientInfo, setSelectedClientInfo] = useState<
    IClientData | undefined
  >(order.customer);
  const [mobileOrderSummary, setMobileOrderSummary] = useState<boolean>(false);
  const [closeAllModals, setCloseAllModals] = useState<boolean>(false);
  const [searchDocumentNumber, setSearchDocumentNumber] = useState<string>('');
  const [documentError, setDocumentError] = useState<string>('');
  const [formattedItems, setFormattedItems] = useState<IBagItems[]>();
  // Document number from saved bag, created to autofill input without showing modal
  const [bagCustomerDocument, setBagCustomerDocument] = useState<string>('');
  const [availablePaymentTypes, setAvailablePaymentTypes] = useState<
    IPaymentTypes[]
  >([]);
  const [localStorageClientDocument, setLocalStorageClientDocument] =
    useState<string>('');

  const searchParam = useDebounce(searchDocumentNumber, 1000);
  const cartId = useDebounce(order?.orderCode, 1000);

  const ownStockItems = order.items.filter(
    item => item.shipping.selected_type === 'OWN_STOCK',
  );

  const deliveryItems = order.items.filter(
    item => item.shipping.selected_type === 'DELIVERY',
  );

  const cartWithOnlyDeliveryItems = !!(
    ownStockItems.length === 0 && deliveryItems.length > 0
  );

  const cartWithOnlyOwnStockItems = !!(
    ownStockItems.length > 0 && deliveryItems.length === 0
  );

  // Onboard shepherd constants
  const shouldShowBagTour = !(
    localStorage.getItem('bagTourComplete') === 'seen' ||
    Number(localStorage.getItem('bagTourCancel')) >= 10
  );
  const shouldShowEmailModalTour = !(
    localStorage.getItem('emailModalTourComplete') === 'seen'
  );
  const shouldShowCPFModalTour = !(
    localStorage.getItem('CPFmodalTourComplete') === 'seen'
  );
  const shouldShowIdentificationTour = !(
    localStorage.getItem('identificationTourComplete') === 'seen' ||
    Number(localStorage.getItem('identificationTourCancel')) >= 10
  );
  const shouldShowDeliveryTour = !(
    localStorage.getItem('deliveryTourComplete') === 'seen' ||
    Number(localStorage.getItem('deliveryTourCancel')) >= 10
  );
  const shouldShowOnlyOwnStockDeliveryTour = !(
    localStorage.getItem('onlyOwnStockDeliveryTourComplete') === 'seen' ||
    Number(localStorage.getItem('onlyOwnStockDeliveryTourCancel')) >= 10
  );
  const shouldShowPaymentTour = !(
    localStorage.getItem('paymentTourComplete') === 'seen' ||
    Number(localStorage.getItem('paymentTourCancel')) >= 10
  );
  const shouldShowMultiplePaymentTour = !(
    localStorage.getItem('multiplePaymentTourComplete') === 'seen'
  );

  const shouldShowStoreCreditTour = !(
    localStorage.getItem('storeCreditTourComplete') === 'seen'
  );

  const getSelectedShipping = (list: IShippingTypes[]) => {
    const filterSelected = list.filter((item: any) => item.selected);
    return filterSelected[0];
  };

  let selectedShipping: IShippingTypes = getSelectedShipping(
    order?.shippingTypes,
  );

  const GetShippingData = async () => {
    try {
      // API call
      const response = await api.get(`/v1/cart/${cartId}/shipping`);

      const newShippingData = AddDataToShippingList(
        response.data,
        order?.shippingTypes,
      );

      order?.setShippingTypes(newShippingData);

      selectedShipping = getSelectedShipping(newShippingData);

      const addShippingDataToItems = SetItemsShippingData(
        order?.items,
        selectedShipping,
      );
      order.setItems(addShippingDataToItems);
    } catch (err) {
      const { message } = getResponseError(err, t);
      toast.error(message);
    }
  };

  const searchDocument = useCallback(async () => {
    try {
      setLoading(true);

      // API call
      const response = await api.get('/customer', {
        params: {
          document: searchDocumentNumber,
        },
      });

      // Check if is valid
      if (!Array.isArray(response.data)) throw new Error();

      setLoading(false);

      // Set selected document
      setInitialClientDocument(searchDocumentNumber);

      // Check if found
      if (!response.data.length) {
        setClientList([]);
        setSelectedClientInfo({
          document: searchDocumentNumber,

          email: '',
          name: '',
          phone: '',
          surname: '',
          birthdate: '',
        });
        setFound(false);
        customerSelectorModalRef.current?.show();
        return;
      }

      // Assign
      setFound(true);
      setClientList(
        response.data.map(client => ({
          document: client.document,
          name: client.name,
          surname: client.surname,
          email: client.email,
          phone: client.phone,
          brandcode: client.brand.code,
          code: client.code,
          birthdate: client.birthdate,
        })),
      );

      // Check if has one registry
      if (response.data.length === 1) {
        let selectedInfo = {
          document: response.data[0].document,
          name: response.data[0].name,
          surname: response.data[0].surname,
          email: response.data[0].email,
          phone: response.data[0].phone,
          brandcode: response.data[0].brand.code,
          code: response.data[0].code,
          birthdate: response.data[0].birthdate,
        };

        if (selectedInfo.birthdate) {
          const splitBirthdate = selectedInfo.birthdate.slice(0, 10).split('-');

          const formattedBirthDate = `${splitBirthdate[2]}/${splitBirthdate[1]}/${splitBirthdate[0]}`;

          selectedInfo = {
            ...selectedInfo,
            birthdate: formattedBirthDate,
          };
        }

        setSelectedClientInfo(selectedInfo);
      } else {
        setSelectedClientInfo(undefined);
      }
      customerSelectorModalRef.current?.show();
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);
      setLoading(false);
    }
  }, [searchDocumentNumber, t]);

  const handleCouponClick = () => {
    couponSelectorModalRef.current?.show();
  };

  const getBagDetails = useCallback(async () => {
    setLoading(true);
    try {
      // API call
      const response = await api.get(`/v1/cart/${bag_id}/updated`);

      const new_response = response.data;

      const formatted_items = new_response.items.map((item: IBagItems) => {
        if (item.payload.attachments && item.payload.attachments.length) {
          return {
            ...item,
            brand_code: item.brand.code,
            attachments: {
              name: item.payload.attachments[0].content.GiftCardName,
              email: item.payload.attachments[0].content.GiftCardEmail,
              date: item.payload.attachments[0].content.GiftCardSchedule,
            },
          };
        }
        return {
          ...item,
          brand_code: item.brand.code,
        };
      });

      order.setSaleCode({
        code: new_response.sale_user_code,
        name: new_response.sale_user_name,
      });

      order.setOrderCode(new_response.code);
      order.setCoupon({ name: new_response.coupon });
      order.setAddress(new_response.address);
      order.setCustomer(new_response.customer);
      setBagCustomerDocument(new_response.customer?.document);
      setFormattedItems(formatted_items);

      if (new_response.customer?.document) {
        setFound(true);

        let selectedInfo = {
          document: new_response.customer?.document,
          name: new_response.customer?.name,
          surname: new_response.customer?.surname,
          email: new_response.customer?.email,
          phone: new_response.customer?.phone,
          brandcode: formatted_items?.[0]?.brand.code,
          birthdate: new_response.customer?.birthdate,
        };

        if (selectedInfo.birthdate) {
          const splitBirthdate = selectedInfo.birthdate.slice(0, 10).split('-');

          const formattedBirthDate = `${splitBirthdate[2]}/${splitBirthdate[1]}/${splitBirthdate[0]}`;

          selectedInfo = {
            ...selectedInfo,
            birthdate: formattedBirthDate,
          };
        }

        setSelectedClientInfo(selectedInfo);
      }

      // order.setItems(formatted_items).catch(() => null);

      if (SavedBagAddingProduct === true) {
        dispatch(setSavedBagAddingProduct(false));
        order.setAddingProduct(true);
      }

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      throw err;
    }
  }, [SavedBagAddingProduct, bag_id, dispatch, order]);

  useEffect(() => {
    const startOnboarding = () => {
      switch (statusState) {
        case 'bag':
          if (shouldShowBagTour) {
            bagTourButtonRef?.current?.click();
          }
          break;
        case 'identification':
          if (
            !order.loading &&
            clientList.length === 0 &&
            customerSelectorModal === 'open' &&
            shouldShowCPFModalTour
          ) {
            CPFModalTourButtonRef?.current?.click();
          } else if (
            !order.loading &&
            clientList.length > 1 &&
            customerSelectorModal === 'open' &&
            shouldShowEmailModalTour
          ) {
            emailModalTourButtonRef?.current?.click();
          } else if (
            !order.loading &&
            order.customer.email &&
            order.address.street &&
            customerSelectorModal === 'close' &&
            addressSelectorModal === 'close' &&
            shouldShowIdentificationTour
          ) {
            identificationTourButtonRef?.current?.click();
          }
          break;
        case 'delivery':
          if (
            !order.loading &&
            cartWithOnlyOwnStockItems &&
            shouldShowOnlyOwnStockDeliveryTour
          ) {
            onlyOwnStockDeliveryTourButtonRef?.current?.click();
          } else if (
            !order.loading &&
            !cartWithOnlyOwnStockItems &&
            !cartWithOnlyDeliveryItems &&
            shouldShowDeliveryTour
          ) {
            deliveryTourButtonRef?.current?.click();
          }
          break;
        case 'payments':
          if (
            !order.loading &&
            shouldShowPaymentTour &&
            !(order.payments.length >= 1)
          ) {
            if (
              user.store?.payment_types.some(
                item =>
                  item.enabled === true &&
                  item.payment_type.code === 'store_credit',
              )
            ) {
              paymentTourButtonRef?.current?.click();
            } else {
              paymentTourButtonWithoutStoreCreditRef?.current?.click();
            }
          }
          if (
            !order.loading &&
            order.payments.length >= 2 &&
            shouldShowMultiplePaymentTour
          ) {
            multiplePaymentTourButtonRef?.current?.click();
          }
          if (
            !order.loading &&
            order.payments.some(item => item.code === 'store_credit') &&
            shouldShowStoreCreditTour
          ) {
            storeCreditTourButtonRef?.current?.click();
          }
          break;
        default:
          break;
      }
    };

    const timeoutId = setTimeout(() => {
      startOnboarding();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [
    statusState,
    order.loading,
    order.customer.email,
    order.address.street,
    clientList.length,
    customerSelectorModal,
    addressSelectorModal,
    cartWithOnlyOwnStockItems,
    cartWithOnlyDeliveryItems,
    shouldShowBagTour,
    shouldShowIdentificationTour,
    shouldShowOnlyOwnStockDeliveryTour,
    shouldShowDeliveryTour,
    shouldShowPaymentTour,
    shouldShowCPFModalTour,
    shouldShowEmailModalTour,
    shouldShowMultiplePaymentTour,
    shouldShowStoreCreditTour,
    order.payments.length,
    order.payments,
    user.store?.payment_types,
  ]);

  useEffect(() => {
    setDocumentError('');
    if (searchParam && searchDocumentNumber?.length === 11) {
      // Document validation
      if (!validateCpf({ code: searchDocumentNumber })) {
        const error = t('CHECK_DOCUMENT_INVALID_CPF', 'CPF inválido.');
        setDocumentError(error);
        return;
      }

      searchDocument();
    }
  }, [
    searchDocument,
    searchParam,
    searchDocumentNumber,
    searchDocumentNumber?.length,
    t,
  ]);

  useEffect(() => {
    if (bag_id) {
      getBagDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bag_id]);

  useEffect(() => {
    const savedOrderCode = sessionStorage.getItem('savedOrderCode');
    if (savedOrderCode && order.orderCode !== savedOrderCode) {
      dispatch(setBagId(savedOrderCode));
    }
  }, [dispatch, order.orderCode]);

  useEffect(() => {
    if (formattedItems) {
      order.setItems(formattedItems);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedItems]);

  useEffect(() => {
    const hasUnavailableShippingItems = !!order.items.filter(
      item => item.shipping.available === false,
    ).length;
    if (statusState === 'payments' && hasUnavailableShippingItems) {
      setStatusState('delivery');
      GetShippingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.items, statusState]);

  const getLocalStorageClientInfo = useCallback(() => {
    const localClientInfo = localStorage.getItem('savedClientInfo');
    if (localClientInfo) {
      const clientInfo = JSON.parse(localClientInfo);
      const customerSimulationInfo = {
        document: clientInfo?.document,
        name: clientInfo?.name,
        surname: clientInfo?.surname,
        email: clientInfo?.email,
        phone: clientInfo?.phone,
        birthdate: clientInfo?.birthdate,
      };

      order.setCustomer(customerSimulationInfo);
      order.createBag(clientInfo);
      order.setAddingProduct(true);
      localStorage.removeItem('savedClientInfo');

      setLocalStorageClientDocument(clientInfo.document);

      setFound(true);
      let selectedInfo = {
        document: clientInfo?.document,
        name: clientInfo?.name,
        surname: clientInfo?.surname,
        email: clientInfo?.email,
        phone: clientInfo?.phone,
        birthdate: clientInfo?.birthdate,
        code: clientInfo?.code,
        brandcode: clientInfo?.brandcode,
      };

      if (selectedInfo.birthdate) {
        const splitBirthdate = selectedInfo.birthdate.slice(0, 10).split('-');

        const formattedBirthDate = `${splitBirthdate[2]}/${splitBirthdate[1]}/${splitBirthdate[0]}`;

        selectedInfo = {
          ...selectedInfo,
          birthdate: formattedBirthDate,
        };
      }

      setSelectedClientInfo(selectedInfo);
    }
  }, [order, setLocalStorageClientDocument]);

  useEffect(() => {
    getLocalStorageClientInfo();
  }, [getLocalStorageClientInfo]);

  const modals = useMemo(
    () => (
      <>
        <Modal
          ref={customerSelectorModalRef}
          size="small"
          onHide={() => {
            addressSelectorModalRef.current?.show();
            setCustomerSelectorModal('close');
          }}
          onShow={() => {
            setCustomerSelectorModal('open');
          }}
        >
          <Customer
            hide={() => customerSelectorModalRef.current?.hide()}
            selectedClientInfo={selectedClientInfo}
            clientList={clientList}
            initialClientDocument={initialClientDocument}
            found={found}
            setSelectedClientInfo={setSelectedClientInfo}
            setClientList={setClientList}
            setInitialClientDocument={setInitialClientDocument}
            setFound={setFound}
            setCloseAllModals={setCloseAllModals}
            searchDocumentNumber={searchDocumentNumber}
          />
        </Modal>
        <Modal
          ref={addressSelectorModalRef}
          size="small"
          onHide={() => {
            setAddressSelectorModal('close');
          }}
          onShow={() => {
            setAddressSelectorModal('open');
          }}
        >
          <Address
            hide={() => addressSelectorModalRef.current?.hide()}
            selectedClientInfo={selectedClientInfo}
            setSelectedClientInfo={setSelectedClientInfo}
            closeAllModals={closeAllModals}
            setCloseAllModals={setCloseAllModals}
            searchDocumentNumber={searchDocumentNumber}
            localStorageClientDocument={localStorageClientDocument}
          />
        </Modal>
        <Modal ref={couponSelectorModalRef} size="small">
          <Coupon hide={() => couponSelectorModalRef.current?.hide()} />
        </Modal>
      </>
    ),
    [
      clientList,
      closeAllModals,
      found,
      initialClientDocument,
      searchDocumentNumber,
      selectedClientInfo,
      localStorageClientDocument,
    ],
  );

  if (order.addingProduct)
    return (
      <AddProductProvider>
        <Product />
      </AddProductProvider>
    );

  return (
    <AddProductProvider>
      {order.editingProduct ? (
        <EditionScreen />
      ) : (
        <>
          <MobileSummary
            mobileOrderSummary={mobileOrderSummary}
            setMobileOrderSummary={setMobileOrderSummary}
          />
          {order.items.length ? (
            <>
              {statusState === 'bag' && (
                <CartBag
                  reference={bagTourButtonRef}
                  couponModalRef={couponSelectorModalRef}
                  setStage={setStatusState}
                  setSelectedClientInfo={setSelectedClientInfo}
                  setFound={setFound}
                  setLocalStorageClientDocument={setLocalStorageClientDocument}
                />
              )}
              {statusState === 'identification' && (
                <CartIdentification
                  customerModalRef={customerSelectorModalRef}
                  addressmodalRef={addressSelectorModalRef}
                  identificationRef={identificationTourButtonRef}
                  emailOnboardModalRef={emailModalTourButtonRef}
                  CPFOnboardModalRef={CPFModalTourButtonRef}
                  loading={loading}
                  documentError={documentError}
                  setStage={setStatusState}
                  getShippingData={GetShippingData}
                  searchDocumentNumber={searchDocumentNumber}
                  setSearchDocumentNumber={setSearchDocumentNumber}
                  bagCustomerDocument={bagCustomerDocument}
                  setBagCustomerDocument={setBagCustomerDocument}
                  localStorageClientDocument={localStorageClientDocument}
                />
              )}
              {statusState === 'delivery' && (
                <CartDelivery
                  onlyOwnStockDeliveryTourButtonRef={
                    onlyOwnStockDeliveryTourButtonRef
                  }
                  deliveryTourButtonRef={deliveryTourButtonRef}
                  addressSelectorModalRef={addressSelectorModalRef}
                  setStatusState={setStatusState}
                  setLoading={setLoading}
                  setAvailablePaymentTypes={setAvailablePaymentTypes}
                  selectedShipping={selectedShipping}
                  handleCouponClick={handleCouponClick}
                />
              )}
              {statusState === 'payments' && (
                <CartPayment
                  setStatusState={setStatusState}
                  availablePaymentTypes={availablePaymentTypes}
                  handleCouponClick={handleCouponClick}
                  loading={loading}
                  selectedShipping={selectedShipping}
                  onboardingReference={paymentTourButtonRef}
                  onboardingWithoutStoreCreditReference={
                    paymentTourButtonWithoutStoreCreditRef
                  }
                  onboardingMultiplePaymentReference={
                    multiplePaymentTourButtonRef
                  }
                  onboardingStoreCreditReference={storeCreditTourButtonRef}
                />
              )}
            </>
          ) : (
            <>
              {loading ? (
                <LoadingIndicatorContainer>
                  <LoadingIndicator color={theme.font_secondary} size={3} />
                </LoadingIndicatorContainer>
              ) : (
                <CartEmpty />
              )}
            </>
          )}
          {modals}
        </>
      )}
    </AddProductProvider>
  );
};

export { Cart };
