import { AnyAction } from 'redux';

// Type import
import { UiActionTypes } from './types';

export function setLeftMenu(open: boolean): AnyAction {
  return {
    type: UiActionTypes.LEFT_MENU_SET,
    payload: {
      open,
    },
  };
}

export function setOrderSummary(summary: boolean): AnyAction {
  return {
    type: UiActionTypes.ORDER_SUMMARY_SET,
    payload: {
      summary,
    },
  };
}

export function setPrinterConfig(summary: boolean): AnyAction {
  return {
    type: UiActionTypes.PRINTER_CONFIG_SET,
    payload: {
      summary,
    },
  };
}

export function setBagSummary(summary: boolean): AnyAction {
  return {
    type: UiActionTypes.BAG_SUMMARY_SET,
    payload: {
      summary,
    },
  };
}

export function setSavedBagAddingProduct(summary: boolean): AnyAction {
  return {
    type: UiActionTypes.SAVED_BAG_ADDING_PRODUCT_SET,
    payload: {
      summary,
    },
  };
}

export function setLanguage(language: string): AnyAction {
  return {
    type: UiActionTypes.LANGUAGE_SET,
    payload: {
      language,
    },
  };
}

export function setActivePage(page: string): AnyAction {
  return {
    type: UiActionTypes.ACTIVE_PAGE_SET,
    payload: {
      page,
    },
  };
}

export function setBagId(bag_id: string): AnyAction {
  return {
    type: UiActionTypes.BAG_ID_SET,
    payload: {
      bag_id,
    },
  };
}

export function setHelpMode(mode: boolean): AnyAction {
  return {
    type: UiActionTypes.HELP_MODE_SET,
    payload: {
      mode,
    },
  };
}
