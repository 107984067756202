import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdAdd, MdPersonOutline } from 'react-icons/md';
import { ShepherdTour } from 'react-shepherd';

// Cart Onboarding import
import { bagSteps } from '@pages/CreateOrder/Cart/OnboardingSteps/bagSteps';

// Component import
import { OnboardingTourButton } from '@components/OnboardingTourButton';
import { Button } from '@components/Button';
import { CartStepper } from '@components/CartStepper';
import { CartPricingSummary } from '@components/CartPricingSummary';
import { IModalRef } from '@components/Modal';
import { CartSaleCode } from '@components/CartSaleCode';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';
import { useWindowSize } from '@hooks/useWindowSize';

// Util import
import { IClientData } from '@util/CartModels';

import { CartList } from './CartList';

import { Container, Footer } from './styles';

// Feature identification
const featureKey = '@create_order/BAG_STAGE';

// Interfaces
interface IProps {
  reference: React.RefObject<HTMLButtonElement>;
  couponModalRef: React.RefObject<IModalRef>;
  setStage: React.Dispatch<
    React.SetStateAction<'bag' | 'identification' | 'delivery' | 'payments'>
  >;
  setFound: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setSelectedClientInfo: React.Dispatch<
    React.SetStateAction<IClientData | undefined>
  >;
  setLocalStorageClientDocument: React.Dispatch<React.SetStateAction<string>>;
}

const CartBag: React.FC<IProps> = ({
  reference,
  couponModalRef,
  setStage,
  setSelectedClientInfo,
  setFound,
  setLocalStorageClientDocument,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const user = useSelector(state => state.auth);
  const [width] = useWindowSize();

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: false,
      },
    },
    useModalOverlay: true,
  };

  const handleCouponClick = () => {
    couponModalRef.current?.show();
  };

  const removeUnavailableitems = () => {
    const availableItems = order.items.filter(
      item => !!item.available && !!item.shipping.available,
    );

    order.setItems(availableItems);
  };

  const getLocalStorageClientInfo = useCallback(() => {
    const localClientInfo = localStorage.getItem('savedClientInfo');
    if (localClientInfo) {
      const clientInfo = JSON.parse(localClientInfo);
      const customerSimulationInfo = {
        document: clientInfo?.document,
        name: clientInfo?.name,
        surname: clientInfo?.surname,
        email: clientInfo?.email,
        phone: clientInfo?.phone,
        birthdate: clientInfo?.birthdate,
      };

      order.setCustomer(customerSimulationInfo);

      setFound(true);
      let selectedInfo = {
        document: clientInfo?.document,
        name: clientInfo?.name,
        surname: clientInfo?.surname,
        email: clientInfo?.email,
        phone: clientInfo?.phone,
        birthdate: clientInfo?.birthdate,
        code: clientInfo?.code,
        brandcode: clientInfo?.brandcode,
      };

      if (selectedInfo.birthdate) {
        const splitBirthdate = selectedInfo.birthdate.slice(0, 10).split('-');

        const formattedBirthDate = `${splitBirthdate[2]}/${splitBirthdate[1]}/${splitBirthdate[0]}`;

        selectedInfo = {
          ...selectedInfo,
          birthdate: formattedBirthDate,
        };
      }

      setSelectedClientInfo(selectedInfo);
      localStorage.removeItem('savedClientInfo');
      setLocalStorageClientDocument(JSON.parse(localClientInfo).document);
    }
  }, [order, setLocalStorageClientDocument, setFound, setSelectedClientInfo]);

  useEffect(() => {
    getLocalStorageClientInfo();
  }, [getLocalStorageClientInfo]);

  return (
    <>
      {!order.loading ? (
        <>
          <Container>
            <ShepherdTour steps={bagSteps} tourOptions={tourOptions}>
              <OnboardingTourButton
                reference={reference}
                completeTourKey="bagTourComplete"
                cancelTourKey="bagTourCancel"
              />
            </ShepherdTour>

            <div className="titleRow">
              <div className="title">
                {width > 768 && <p>{t('BAG', 'Sacola')}</p>}
                {user.sale_codes && (
                  <>
                    {order.saleCode.code &&
                      order.saleCode.name &&
                      user.sale_codes.length > 1 && <CartSaleCode />}
                  </>
                )}
              </div>
            </div>
            <CartStepper step={1} />

            {order.items.some(item => item.shipping.available === false) && (
              <div className="unavailableNotice">
                <p>
                  {t(
                    'UNAVAILABLE_NOTICE',
                    'Alguns produtos da sacola estão indisponíveis. Você precisa removê-los para prosseguir com a venda. ',
                  )}
                </p>
              </div>
            )}

            <div className="mainDesktop">
              {!order.editingProduct && <CartList />}
            </div>
            <div className="totalsSection">
              <CartPricingSummary
                step={1}
                handleCouponClick={handleCouponClick}
              />
            </div>
          </Container>
          <div className="shepherdFooter">
            <Footer>
              <Button
                className="action"
                startIcon={<MdAdd />}
                onClick={() => {
                  order.setAddingProduct(true);
                }}
                variant="outlined"
                color="primary"
              >
                <p>{t('ADD_PRODUCT', 'ADICIONAR PRODUTO')}</p>
              </Button>
              {order.items.some(item => item.shipping.available === false) ? (
                <Button
                  className="action"
                  onClick={() => {
                    removeUnavailableitems();
                  }}
                  color="danger"
                >
                  <p className="removeUnavailable">
                    {t('REMOVE_UNAVAILABLE', 'REMOVER INDISPONÍVES')}
                  </p>
                </Button>
              ) : (
                <Button
                  className="action"
                  startIcon={<MdPersonOutline />}
                  onClick={() => {
                    getLocalStorageClientInfo();
                    setStage('identification');
                  }}
                  color="primary"
                >
                  <p>{t('IDENTIFY_CUSTOMER', 'IDENTIFICAR CLIENTE')}</p>
                </Button>
              )}
            </Footer>
          </div>
        </>
      ) : (
        <Container>
          <LoadingIndicator size={1.8} />
        </Container>
      )}
    </>
  );
};

export { CartBag };
