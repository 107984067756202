import styled from 'styled-components';

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
  .itemButton {
    margin-right: 1rem;
    height: fit-content;
    .MuiRadio-colorPrimary {
      color: ${({ theme }) => theme.button_primary};
    }
    .MuiButtonBase-root {
      padding: 0;
    }
  }
  .itemText {
    p {
      font-weight: 500;
      font-size: 0.875rem;
      color: ${({ theme }) => theme.font_secondary};
      line-height: 1.5rem;
      letter-spacing: 0.00625rem;
    }

    p:last-child {
      font-weight: 400;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.font_primary};
      line-height: 1rem;
      letter-spacing: 0.00375rem;
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
