import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { addMinutes, format, isSameDay, isValid, parseJSON } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Interface import
import { IOrder } from '@store/modules/auth/types';

// Feature identification
const featureKey = '@order/CONTEXT';

// Context interface
export interface IOrderContextData {
  // Main state
  data?: IOrder;

  // Actions
  load: (order_id: string) => Promise<void>;
  cancel: (order_id: string) => Promise<void>;

  // Loading states
  loading: boolean;
  dataLoading: boolean;
  cancelLoading: boolean;

  // Other
  error: string;
  orderUntil?: Date;

  // View helper
  status: string;
  statusPendingText: string;
  timePendingText: string;
}

// Context
export const OrderContext = createContext<IOrderContextData>(
  {} as IOrderContextData,
);

// Provider
export const OrderProvider: React.FC = ({ children }) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  // Local states
  const [data, setData] = useState<IOrder | undefined>(undefined);
  const [dataLoading, setOrderLoading] = useState(false);
  const [cancelLoading, setOrderCancelLoading] = useState(false);
  const [error, setError] = useState('');
  const [orderUntil, setOrderUntil] = useState<Date | undefined>(undefined);
  const loading = useMemo(
    () => dataLoading || cancelLoading,
    [cancelLoading, dataLoading],
  );

  // Local refs
  const id = useRef<string>();

  // Get order information
  const load = useCallback(
    async (order_id: string) => {
      // Check if code is defined
      if (!order_id) {
        return;
      }

      setOrderLoading(true);
      setError('');

      try {
        // API call
        const response = await api.get(`/order/${order_id}`);

        setOrderLoading(false);

        // Validation
        if (response.data?.order?.id !== order_id) {
          const message = t(
            'INVALID_GET_ORDER_RESPONSE',
            'O servidor não conseguiu achar o pedido correto.',
          );
          setError(message);
          return;
        }

        setData(response.data);
      } catch (err: any) {
        const response = getResponseError(err, t);
        toast.error(response.message);

        setOrderLoading(false);
      }
    },
    [t],
  );

  // Handle cancel order
  const cancel = useCallback(
    async order_id => {
      setOrderCancelLoading(true);

      // Check order
      if (!data || !order_id || order_id !== data.order.id) return;

      try {
        // API call
        await api.delete(`/order/${order_id}`);

        setOrderCancelLoading(false);

        // Display message to user
        toast.success(t('CANCEL_SUCCESS', 'Pedido cancelado com sucesso.'));

        // Clear order time
        setOrderUntil(undefined);

        // Update data
        load(data.order.id);
      } catch (err: any) {
        setOrderCancelLoading(false);
      }
    },
    [data, load, t],
  );

  // Order status to be used on classes
  const status = useMemo(() => {
    if (data?.status.is_canceled) return 'canceled';
    if (data?.status.is_paid) return 'success';
    return 'pending';
  }, [data]);

  // A custom text for each payment type
  const statusPendingText = useMemo(() => {
    let result = t('WAITING_PAYMENT', 'Aguardando pagamento...');
    if (data?.order.payments.length === 1) {
      switch (data.order.payments[0].payment_type.code) {
        case 'physical_card':
          result = t('PHYSICAL_CARD_HEADER', 'Aguardando pagamento.');
          break;

        case 'manual_physical_card':
          result = t(
            'MANUAL_PHYSICAL_CARD_HEADER',
            'Realize o pagamento manualmente na máquina de cartão e informe o código no menu PAGAMENTOS',
          );
          break;

        case 'payment_link':
          result = t('PAYMENT_LINK_HEADER', 'Aguardando pagamento.');
          break;

        case 'pix':
          result = t('PIX_HEADER', 'Atendimento aguardando pagamento.');
          break;

        default:
          result = t('WAITING_PAYMENT', 'Aguardando pagamento...');
          break;
      }
    }

    return result;
  }, [t, data]);

  // A custom time text for each payment type
  const timePendingText = useMemo(() => {
    let result = '';

    if (!orderUntil || !isValid(orderUntil)) return result;

    const date = format(
      orderUntil,
      !isSameDay(new Date(), orderUntil) ? 'dd/MM/yyyy HH:mm' : 'HH:mm',
    );

    if (data?.order.payments.length === 1) {
      switch (data.order.payments[0].payment_type.code) {
        case 'physical_card':
          result = `${t(
            'PHYSICAL_CARD_UNTIL',
            'Receba o pagamento até as',
          )} ${date}`;
          break;

        case 'manual_physical_card':
          result = `${t(
            'MANUAL_PHYSICAL_CARD_UNTIL',
            'Envie o código de pagamento até',
          )} ${date}`;
          break;

        case 'payment_link':
          result = `${t('PAYMENT_LINK_UNTIL', 'Link válido até as')} ${date}`;
          break;

        case 'pix':
          result = `${t('PIX_UNTIL', 'Código PIX válido até')} ${date}`;
          break;

        default:
          result = `${t(
            'OTHER_PAYMENT_UNTIL',
            'Finalize o pagamento até',
          )} ${date}`;
          break;
      }
    }

    return result;
  }, [t, data, orderUntil]);

  // Calculate order status time
  useEffect(() => {
    // Only when order is pending
    if (
      !data?.status.is_canceled &&
      !data?.status.is_paid &&
      data?.order.payments.length
    ) {
      // Get user timezone (server time comes in UTC)
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Payment link has specific rule
      const linkPayments = data?.order.payments.filter(
        ({ payment_type }) => payment_type.code === 'payment_link',
      );
      if (linkPayments?.length) {
        setOrderUntil(
          linkPayments
            .filter(payment => !!payment.created_at)
            .map(payment => {
              const created_at = parseJSON(payment.created_at);

              if (!isValid(created_at) || !payment.payload?.expires_in)
                return undefined;

              return utcToZonedTime(
                addMinutes(created_at, payment.payload.expires_in),
                timezone,
              );
            })
            .filter(time => time instanceof Date)
            .sort((a, b) => {
              if (!a || !b) return -1;
              if (a > b) return 1;
              if (b < a) return -1;
              return 0;
            })
            .pop(),
        );
      }

      // Get most longest payment try until
      setOrderUntil(
        data?.order.payments
          .filter(payment => !!payment.try_until)
          .map(payment => parseJSON(payment.try_until))
          .filter(time => isValid(time))
          .map(time => utcToZonedTime(time, timezone))
          .sort((a, b) => {
            if (a > b) return 1;
            if (b < a) return -1;
            return 0;
          })
          .pop(),
      );
    }
  }, [data]);

  // Update timer
  useEffect(() => {
    const timer = setInterval(() => {
      if (id.current) load(id.current);
    }, 30000);
    return () => clearInterval(timer);
  }, [load]);

  // Update order_id
  useEffect(() => {
    id.current = data?.order.id;
  }, [data]);

  return (
    <OrderContext.Provider
      value={{
        // Main state
        data,

        // Actions
        load,
        cancel,

        // Loading states
        loading,
        dataLoading,
        cancelLoading,

        // Other
        error,
        orderUntil,

        // View helper
        status,
        statusPendingText,
        timePendingText,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

// Hook
export function useOrder(order_id?: string): IOrderContextData {
  // Get data from context
  const context = useContext(OrderContext);

  // When order_id changes
  useEffect(() => {
    if (order_id) context.load(order_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.load, order_id]);

  // If user is not using context provider (DEV purposes only)
  if (!context) throw new Error('useOrder must be used within a OrderProvider');

  return context;
}
