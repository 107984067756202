import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';

// Component import
import { PackageSummary } from '@components/PackagesSummary';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Action import
import { setOrderSummary } from '@store/modules/ui/actions';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@order_summary/INDEX';

const OrderSummary: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const dispatch = useDispatch();

  return (
    // This component doesn't work well in Web Safari. It doesn't cover all height screen
    <Container>
      <div className="orderSummaryComponent">
        <div className="orderSummaryHeader">
          <div className="backButton">
            <button
              type="button"
              onClick={() => {
                dispatch(setOrderSummary(false));
              }}
            >
              <p> {t('BACK_TO_BAG', 'Voltar para Sacola')}</p>
            </button>
          </div>
          <div className="closeButton">
            <button
              type="button"
              onClick={() => {
                dispatch(setOrderSummary(false));
              }}
            >
              <MdOutlineClose />
            </button>
          </div>
        </div>
        <div className="orderSummaryContent">
          <PackageSummary />
        </div>
      </div>
    </Container>
  );
};

export { OrderSummary };
