import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: 1rem; */
  gap: 0.5rem;
  max-width: 90%;
  transition: 0.25s;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .information {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: ${({ theme }) => theme.font_secondary};
    font-size: 0.875rem;

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .code {
      font-weight: bold;
      margin-right: 0.5rem;
      color: ${({ theme }) => theme.font_primary};
    }
  }
`;
