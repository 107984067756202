import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  position: relative;

  height: 100%;
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
  }
`;
