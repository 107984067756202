import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, MotionProps, usePresence } from 'framer-motion';
import { useHistory } from 'react-router-dom';

// Component import
import { Button } from '@components/Button';
import { Avatar } from '@components/Avatar';
import { Input } from '@components/Input';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTheme } from '@hooks/useTheme';
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';

// Store import
import { IUserSaleCodes } from '@store/modules/auth/types';

// Util import
import { removeAccents } from '@util/removeAccents';

// Style import
import { Container, ListItem } from './styles';

// Feature identification
const featureKey = '@create_order/SALE_CODE_SELECT_SCREEN';

const Salecode: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const theme = useTheme();
  const history = useHistory();

  // Global states
  const user = useSelector(state => state.auth);

  // Local states
  const [selected, set_selected] = useState<
    | {
        code: string;
        name?: string;
      }
    | undefined
  >(undefined);
  const [search, setSearch] = useState('');
  const [list, setList] = useState<IUserSaleCodes[]>([]);

  // Animated item
  const ListItemWrapper: React.FC<MotionProps> = ({ children, onTap }) => {
    const [isPresent] = usePresence();

    const options: MotionProps = {
      layout: true,
      initial: 'out',
      style: {
        position: isPresent ? 'static' : 'absolute',
        zIndex: isPresent ? 2 : 1,
      },
      animate: isPresent ? 'in' : 'out',
      whileTap: 'tapped',
      variants: {
        in: { opacity: 1 },
        out: { opacity: isPresent ? 1 : 0 },
      },
      transition: { type: 'spring', stiffness: 500, damping: 50, mass: 1 },
      onTap,
    };

    return <ListItem {...options}>{children}</ListItem>;
  };

  // Get list
  const saleCodeList = useMemo(
    () =>
      list.map(salecode => (
        <ListItemWrapper key={salecode.code}>
          <button
            type="button"
            className={selected?.code === salecode.code ? 'selected' : ''}
            onClick={() =>
              selected?.code === salecode.code
                ? set_selected(undefined)
                : set_selected({
                    code: salecode.code,
                    name: salecode.name,
                  })
            }
          >
            <Avatar
              name={salecode.name || t('SOMA_GROUP', 'Grupo Soma')}
              size={50}
              fixedColor={theme.font_low}
              noBackground
            />
            <div className="information">
              <span className="code">{salecode.code}</span>
              <span className="name">
                {salecode.name || t('NAME_NOT_PROVIDED', 'Nome não informado')}
              </span>
            </div>
          </button>
        </ListItemWrapper>
      )),
    [t, list, selected, theme],
  );

  // On search changes
  useEffect(() => {
    // Only works if user has sale codes
    if (user.sale_codes) {
      // Build new list
      const newList = user.sale_codes.filter(salecode =>
        search.length > 0
          ? removeAccents(salecode.code?.toLowerCase() || '').includes(
              removeAccents(search.toLowerCase()),
            ) ||
            removeAccents(salecode.name?.toLowerCase() || '').includes(
              removeAccents(search.toLowerCase()),
            )
          : true,
      );

      // Check length
      if (newList.length !== list.length) setList(newList);

      // Check each item
      if (
        list.some(
          (item, index) =>
            newList[index]?.code !== item.code ||
            newList[index]?.name !== item.name,
        )
      )
        setList(newList);
    }
  }, [search, list, user]);

  return (
    <Container>
      {user.sale_codes?.length ? (
        <>
          <section className="top">
            <h2>{t('SELECT_SALE_PERSON', 'SELECIONE O VENDEDOR DO PEDIDO')}</h2>
            <div className="search">
              <Input
                label={t('SEARCH_BY_NAME_OR_CODE', 'Buscar por nome ou código')}
                value={search}
                onChange={(value: string) => setSearch(value)}
              />
            </div>
            <ul>
              <AnimatePresence>{saleCodeList}</AnimatePresence>
            </ul>
          </section>
          <section className="bottom">
            {selected ? (
              <Button
                color="success"
                onClick={() => {
                  order.setSaleCode({
                    code: selected.code,
                    name: selected.name || 'Soma Store',
                  });
                }}
              >
                {t('CONFIRM', 'CONFIRMAR')}
              </Button>
            ) : (
              <span />
            )}
            <Button color="danger" onClick={() => history.push('/dashboard')}>
              {t('CANCEL', 'CANCELAR')}
            </Button>
          </section>
        </>
      ) : (
        <>
          <div className="error">
            <p>
              {t(
                'NO_SALE_CODE_IN_ACCOUNT',
                'Não há nenhum código de vendedor configurado para sua conta.',
              )}
            </p>
            <p>{t('CONTACT_SUPPORT', 'Entre em contato com o suporte.')}</p>
          </div>
          <Button onClick={() => history.push('/dashboard')} noFullWidth>
            {t('GO_TO_HOME', 'VOLTAR AO INÍCIO')}
          </Button>
        </>
      )}
    </Container>
  );
};

export { Salecode };
