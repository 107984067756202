import styled from 'styled-components';
import { motion } from 'framer-motion';
import CustomScrollBar from 'react-custom-scrollbars';

// Config import
import { defaultTransition } from '@config/transition';

interface IContentProps {
  mobileSize: boolean;
  leftMenu: boolean;
}

export const HEADER_SIZE = 3.75;
export const SIDEBAR_SIZE = 18.75;

export const Container = styled.div``;

export const Main = styled.div``;

export const Wrapper = styled.div`
  transition: 0.25s;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  height: 100%;
`;

export const Content = styled(CustomScrollBar).attrs(() => ({
  autoHide: true,
}))`
  transition: 0.25s;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  height: 100%;
  > div {
    margin: 0 !important;

    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }
  }
`;

export const Frame = styled(motion.div).attrs(defaultTransition)<IContentProps>`
  min-height: 100vh;
  padding-top: ${HEADER_SIZE}rem;
  padding-left: ${props =>
    props.mobileSize ? '0' : props.leftMenu ? `${SIDEBAR_SIZE}rem` : '0rem'};
  transition: 0.25s;

  display: flex;
  flex-direction: column;
`;
