import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;

  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;
  padding-left: 3rem;

  .saveButton {
    color: ${({ theme }) => theme.font_low};
  }

  .cancelButton {
    color: ${({ theme }) => theme.font_danger};
  }

  @media screen and (max-width: 425px) {
    padding-left: 0;

    .item {
      .info {
        .top {
          .name {
            font-size: 1rem;
          }

          .code,
          .price {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`;

export const ProductCardDesktop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  background: ${({ theme }) => theme.background_primary};
  border-radius: 0.5rem;
  width: 100%;
  max-width: 50rem;
  height: 23.375rem;

  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    align-self: stretch;
    max-width: 25%;

    img {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      object-fit: cover;
      flex: 1;
      align-self: stretch;
      max-width: 100%;
    }
  }

  .defaultImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    align-self: stretch;
    max-width: 30%;

    img {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      object-fit: cover;
      flex: 1;
      align-self: stretch;
      max-width: 100%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex: 1;
    gap: 1rem;
    padding: 1.5rem 1.5rem 1.5rem 0rem;
    overflow: hidden;

    .top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.5rem;

      .name {
        font-size: 1.25rem;
        font-weight: 600;
        color: ${({ theme }) => theme.font_primary};
      }

      .code {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        gap: 1rem;

        font-size: 1rem;
        font-weight: 600;
        color: ${({ theme }) => theme.font_secondary};

        .reference {
          color: ${({ theme }) => theme.font_secondary};
          font-size: 1rem;
          font-weight: 400;
        }
      }

      .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        font-size: 1rem;
        color: ${({ theme }) => theme.font_primary};
        font-weight: 400;

        .originalPrice {
          text-decoration: line-through;
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .quantitySection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 3rem;

        .quantityTitle {
          font-size: 0.875rem;
          font-weight: 500;
          max-width: fit-content;
          margin-bottom: 0.625rem;
        }

        .quantity {
          display: flex;
          flex-direction: row;
          align-items: center;
          align-self: center;
          justify-content: center;
          background: ${({ theme }) => theme.background_high};
          border-radius: 0.25rem;

          button {
            min-width: auto;
            width: 1.2rem;
            height: 1.2rem;
          }

          .number {
            color: ${({ theme }) => theme.font_low};
            padding: 0 0.5rem;
          }
        }
      }
    }
  }
`;

export const ProductCardMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  background: ${({ theme }) => theme.background_primary};
  border-radius: 0.5rem;
  width: 100%;
  max-width: 50rem;

  .imageAndInfoSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    .image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      align-self: stretch;
      max-width: 25%;

      img {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        object-fit: cover;
        flex: 1;
        align-self: stretch;
        max-width: 100%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
      flex: 1;
      gap: 1rem;
      padding: 1.5rem 1.5rem 1.5rem 0rem;
      overflow: hidden;

      .top {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;

        .name {
          font-size: 1.25rem;
          font-weight: 600;
          color: ${({ theme }) => theme.font_primary};
        }

        .code {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          gap: 1rem;

          font-size: 1rem;
          font-weight: 600;
          color: ${({ theme }) => theme.font_secondary};

          .reference {
            color: ${({ theme }) => theme.font_secondary};
            font-size: 1rem;
            font-weight: 400;
          }
        }

        .price {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;

          font-size: 1rem;
          color: ${({ theme }) => theme.font_primary};
          font-weight: 400;

          .originalPrice {
            text-decoration: line-through;
          }
        }
      }
    }
  }

  .varitationsSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1.5rem 1.5rem 1.5rem;

    .quantityRow {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .quantityTitle {
        font-size: 0.875rem;
        font-weight: 500;
        margin-right: 2rem;
      }

      .quantity {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        justify-content: center;
        background: ${({ theme }) => theme.background_high};
        border-radius: 0.25rem;

        button {
          min-width: auto;
          width: 1.2rem;
          height: 2rem;
        }

        .number {
          color: ${({ theme }) => theme.font_low};
          padding: 0 0.5rem;
        }
      }
    }
  }
`;
