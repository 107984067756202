import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    button {
      .closeIcon {
        color: ${({ theme }) => theme.button_high};
        font-size: 1.75rem;
      }
    }
  }

  .titleRow {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;

    @media screen and (max-width: 760px) {
      font-size: 1rem;
    }
  }

  .inputRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .MuiInputAdornment-root {
      font-size: 1rem;
    }

    p {
      font-size: 1rem;
    }

    p.inputMessage {
      margin-left: 12px;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.font_secondary};
    }

    p.errorMessage {
      margin-left: 12px;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.font_danger};
    }
  }

  .textRow {
    font-size: 1rem;
    max-width: 435px;
    text-align: center;
  }
`;
