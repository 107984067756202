import { ValidationError } from 'yup';

interface IValidationErrors {
  [key: string]: string;
}

interface IGetFormErrorsCallback {
  form: IValidationErrors;
  errors: string[];
}

const getFormErrors = (
  err: any,
  callback: (params: IGetFormErrorsCallback) => void,
) => {
  if (err instanceof ValidationError) {
    const validationErrors: IValidationErrors = {};
    const errors: string[] = [];
    err.inner.forEach(error => {
      errors.push(error.message);
      if (error.path) validationErrors[error.path] = error.message;
    });

    if (typeof callback === 'function')
      callback({
        form: validationErrors,
        errors,
      });
  }
};

export { getFormErrors };
