import styled from 'styled-components';
import { motion } from 'framer-motion';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .paymentTypeSectionTitle {
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
    margin-bottom: 2.5rem;

    @media screen and (max-width: 768px) {
      margin-bottom: 2.5rem;
      gap: 0;
    }

    p {
      font-size: 1.25rem;
      font-weight: 600;
    }
    p.paymentTypeSectionSubtitle {
      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  .multiplePaymentsSwitchSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    border-radius: 0.25rem;
    border: solid 0.125rem ${({ theme }) => theme.font_secondary};
    padding: 1rem;

    .title {
      p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        color: ${({ theme }) => theme.font_secondary};
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .onboardingOtherPayments {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 1rem;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: stretch;
    }
  }
`;
