import React, { useMemo, useRef } from 'react';
import { ShepherdTour } from 'react-shepherd';

import {
  paymentSteps,
  paymentWithoutStoreCreditSteps,
  multiplePaymentSteps,
  storeCreditSteps,
} from '@pages/CreateOrder/Cart/OnboardingSteps/paymentSteps';

// Component import
import { OnboardingTourButton } from '@components/OnboardingTourButton';
import { Button } from '@components/Button';
import { Modal, IModalRef } from '@components/Modal';
import { CartStepper } from '@components/CartStepper';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Util import
import { IPaymentTypes, IShippingTypes } from '@util/CartModels';

// Page import
import { PaymentModal } from './PaymentModal';
import { PaymentInfo } from './PaymentInfo';
import { SectionTitle } from './SectionTitle';
import { PaymentTypeSelector } from './PaymentTypeSelector';

// Style import
import { Container, Footer } from './styles';

// Feature identification
const featureKey = '@create_order/CART_PAYMENT';

interface IProps {
  setStatusState: (
    value: 'bag' | 'identification' | 'delivery' | 'payments',
  ) => void;
  availablePaymentTypes: IPaymentTypes[];
  handleCouponClick: () => void;
  loading: boolean;
  onboardingReference: React.RefObject<HTMLButtonElement>;
  onboardingWithoutStoreCreditReference: React.RefObject<HTMLButtonElement>;
  onboardingMultiplePaymentReference: React.RefObject<HTMLButtonElement>;
  onboardingStoreCreditReference: React.RefObject<HTMLButtonElement>;
  selectedShipping: IShippingTypes;
}

const CartPayment: React.FC<IProps> = ({
  setStatusState,
  availablePaymentTypes,
  handleCouponClick,
  loading,
  onboardingReference,
  onboardingWithoutStoreCreditReference,
  onboardingMultiplePaymentReference,
  onboardingStoreCreditReference,
  selectedShipping,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();

  // Local refs
  const paymentModalRef = useRef<IModalRef>(null);

  const isStoreCreditAvailable = availablePaymentTypes.filter(
    item => item.code === 'store_credit',
  ).length;
  const hasStoreCreditBeenSelected = order.payments.filter(
    item => item.code === 'store_credit',
  );
  const selectedStoreCreditAmount = hasStoreCreditBeenSelected[0]?.amount || 0;
  const isConfirmButtonDisabled =
    !!isStoreCreditAvailable &&
    !!hasStoreCreditBeenSelected.length &&
    order.payments.length === 1 &&
    selectedStoreCreditAmount < order.totals.total;
  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: false,
      },
    },
    useModalOverlay: true,
  };

  const handleConfirmCart = () => {
    const paymentMethodsWithConfirmation = [
      'payment_link',
      'physical_card',
      'pix',
      'store_credit',
    ];
    if (
      paymentMethodsWithConfirmation.includes(`${order?.payments[0]?.code}`)
    ) {
      paymentModalRef.current?.show();
      return;
    }
    sessionStorage.removeItem('savedOrderCode');
    order.confirmOrder();
  };

  const modals = useMemo(
    () => (
      <>
        <Modal ref={paymentModalRef} size="small" blockHide={order.loading}>
          <PaymentModal hide={() => paymentModalRef.current?.hide()} />
        </Modal>
      </>
    ),
    [order.loading],
  );

  return (
    <>
      {modals}
      <Container>
        <>
          <ShepherdTour steps={paymentSteps} tourOptions={tourOptions}>
            <OnboardingTourButton
              reference={onboardingReference}
              completeTourKey="paymentTourComplete"
              cancelTourKey="paymentTourCancel"
            />
          </ShepherdTour>
          <ShepherdTour
            steps={paymentWithoutStoreCreditSteps}
            tourOptions={tourOptions}
          >
            <OnboardingTourButton
              reference={onboardingWithoutStoreCreditReference}
              completeTourKey="paymentTourComplete"
              cancelTourKey="paymentTourCancel"
            />
          </ShepherdTour>
          <ShepherdTour steps={multiplePaymentSteps} tourOptions={tourOptions}>
            <OnboardingTourButton
              reference={onboardingMultiplePaymentReference}
              completeTourKey="multiplePaymentTourComplete"
            />
          </ShepherdTour>
          <ShepherdTour steps={storeCreditSteps} tourOptions={tourOptions}>
            <OnboardingTourButton
              reference={onboardingStoreCreditReference}
              completeTourKey="storeCreditTourComplete"
            />
          </ShepherdTour>
        </>
        <SectionTitle
          setStatusState={setStatusState}
          selectedShipping={selectedShipping}
        />
        <CartStepper step={4} />

        <div className="paymentSection">
          <div className="infoContainer">
            <PaymentInfo handleCouponClick={handleCouponClick} />
            <div className="divider" />
            <PaymentTypeSelector
              availablePaymentTypes={availablePaymentTypes}
              loading={loading}
            />
          </div>
        </div>
      </Container>

      <Footer>
        <Button
          className="action"
          onClick={() => {
            handleConfirmCart();
          }}
          color="success"
          disabled={
            order.loading || isConfirmButtonDisabled || !order.payments.length
          }
        >
          <p>{t('CONFIRM', 'CONFIRMAR')}</p>
        </Button>
      </Footer>
    </>
  );
};

export { CartPayment };
