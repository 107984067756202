import styled from 'styled-components';

export const Container = styled.fieldset`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border: none;

  .selectPackageType {
    width: 100%;
  }
`;
