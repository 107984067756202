import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  align-self: stretch;

  > span {
    align-self: stretch;
    color: ${({ theme }) => theme.font_secondary};
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    p {
      color: ${({ theme }) => theme.font_secondary};
      font-weight: 700;
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      margin-bottom: 1rem;

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 0.125rem;

        padding: 0.5rem;
        gap: 0.5rem;
        background: ${({ theme }) => theme.background_secondary};
        align-self: stretch;

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 0.25rem;
          overflow: hidden;

          span {
            overflow: hidden;
          }
        }
      }
    }
  }
  .customerNotFound {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > span {
      align-self: stretch;
      color: ${({ theme }) => theme.font_secondary};
      font-size: 1rem;
      text-align: center;
      font-weight: 600;
    }
  }

  .clientFullForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const PhoneInputContainer = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.625rem;
`;
