import React, { useState } from 'react';
import { MdSearch } from 'react-icons/md';
import Radio from '@material-ui/core/Radio';

// Component import
import { Input } from '@components/Input';

// Util import
import { ddiList } from '@util/ddiList';

// Style import
import { Container } from './styles';

interface IProps {
  visible: boolean;
  setVisibility(visible: boolean): void;
  customerCountryCode?: string;
  setCustomerCountryCode(code: string): void;
}

const DDIList: React.FC<IProps> = ({
  visible,
  setVisibility,
  customerCountryCode,
  setCustomerCountryCode,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredList, setFilteredList] = useState(ddiList);
  const setItemVisibility = visible ? '' : 'invisible';

  const isItemSelected = (itemCode: string) => {
    if (itemCode === customerCountryCode) return true;
    return false;
  };

  const handleListItemClass = (itemCode: string) => {
    if (itemCode === customerCountryCode) return 'selected';
    return '';
  };

  const handleSearch = (value: string) => {
    setInputValue(value);
    const trimmedValue = value.trim().toLowerCase();
    const newFilteredList = ddiList.filter(item =>
      item.countryDDI.toLowerCase().includes(trimmedValue),
    );
    setFilteredList(newFilteredList);
  };

  const handleListClick = (itemCode: string) => {
    setCustomerCountryCode(itemCode);
    setVisibility(false);
  };

  return (
    <Container className={setItemVisibility}>
      <Input
        label="Por país ou código numérico"
        value={inputValue}
        className="inputRow"
        onChange={(e: any) => handleSearch(e)}
        endAdornment={<MdSearch />}
      />
      <div className="codesList">
        {filteredList.map(item => (
          <li className={handleListItemClass(item.ddi)}>
            <div className="itemText">{item.countryDDI}</div>
            <div className="itemButton">
              <Radio
                color="primary"
                checked={isItemSelected(item.ddi)}
                onClick={() => handleListClick(item.ddi)}
              />
            </div>
          </li>
        ))}
      </div>
    </Container>
  );
};

export { DDIList };
