import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  MdExitToApp,
  MdHome,
  MdList,
  MdAddShoppingCart,
  MdSettings,
  MdChevronRight,
  MdLocalShipping,
  MdAttachMoney,
  MdOutlineLink,
  MdWifi,
  MdReceipt,
  MdStoreMallDirectory,
  MdKeyboardArrowRight,
  MdOutlineLocalShipping,
  MdOutlineFlag,
  MdInfoOutline,
  MdOutlineShoppingBag,
  MdOutlinePerson,
} from 'react-icons/md';
import { AiOutlinePrinter } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import { motion } from 'framer-motion';

// Constant import
import { supportUrl } from '@constants/supportUrl';
import { helpCenterUrl } from '@constants/helpCenterUrl';
import { maisVendasUrl } from '@constants/maisVendasUrl';

// Asset import
import { PixIcon } from '@assets/index';
import MaisVendasIcon from '@assets/maisVendasIcon.svg';

import { Modal, IModalRef } from '@components/Modal';
// Component import
import { Button } from '@components/Button';
import { Avatar } from '@components/Avatar';

// Hook import
import { useTheme } from '@hooks/useTheme';
import { useTranslation } from '@hooks/useTranslation';
import { useOrderCreation } from '@hooks/useOrderCreation';

// Action import
import { signOut } from '@store/modules/auth/actions';
import { setLeftMenu, setPrinterConfig } from '@store/modules/ui/actions';

import { SavedBagsModal } from './SavedBagsModal';

// Style import
import { Config, Container, ScrollBar } from './styles';

// Feature identification
const featureKey = '@sidebar/MAIN';

const Sidebar: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const history = useHistory();
  const order = useOrderCreation();
  const location = useLocation();

  // Local refs
  const savedBagsModalRef = useRef<IModalRef>(null);

  // Global states
  const leftMenu = useSelector(state => state.ui.left_menu);
  const activePage = useSelector(state => state.ui.active_page);
  const user = useSelector(state => state.auth);

  // Local states
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [signOutCondition, setSignOutCondition] = useState<boolean>(false);

  const [url, setUrl] = useState<string>();

  // Get sidebar ref
  const sidebarRef = useRef<HTMLDivElement>(null);

  const userIsManager = user.profile_type === 'store';

  // Check if is current screen
  const activeParent = (key: string) => activePage.startsWith(key);

  const modalCondition = !!(
    order.orderCode && location.pathname === '/order/new'
  );

  useEffect(() => {
    // Set initial leftMenu state
    dispatch(
      setLeftMenu(
        !(
          Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0,
          ) <= 768
        ),
      ),
    );
  }, [dispatch]);

  // Function to close sidebar on click (only on mobile)
  useEffect(() => {
    // Get sidebar ref
    const ref = sidebarRef.current;

    // Get all links
    const links = ref?.querySelectorAll('a, button:not(.config)');

    // Function to close sidebars
    function closeSidebar() {
      if (
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0,
        ) <= 768
      ) {
        dispatch(setLeftMenu(false));
      }
    }

    // Add event listener
    links?.forEach(element => {
      element.addEventListener('click', closeSidebar);
    });

    // When component unomount
    return () => {
      // Removes all event listeners
      links?.forEach(element => {
        element.removeEventListener('click', closeSidebar);
      });
    };
  });

  const navigationStyles = useMemo(
    () => ({
      justifyContent: 'flex-start',
      textTransform: 'unset',
      fontSize: '1rem',
      fontWeight: 'normal',
    }),
    [],
  );

  const current_time = new Date();
  const limit_time = new Date('2023-05-31T23:23:00');

  // Modals
  const modals = useMemo(
    () => (
      <Modal
        ref={savedBagsModalRef}
        size="small-2"
        onHide={() => setSignOutCondition(false)}
      >
        <SavedBagsModal
          hide={() => {
            savedBagsModalRef.current?.hide();
          }}
          url={url}
          orderId={order.orderCode}
          signOutCondition={signOutCondition}
        />
      </Modal>
    ),
    [order.orderCode, signOutCondition, url],
  );

  return (
    <Container
      ref={sidebarRef}
      open={leftMenu}
      mobileSize={
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0,
        ) <= 768
      }
    >
      <ScrollBar autoHide>
        <div className="content">
          <span className="margin" />
          <section className="head">
            <div>
              <Avatar
                name={user.name || t('SOMA_STORE', 'Soma Store')}
                size={40}
                fixedColor={theme.font_low}
                noBackground
                src={user.avatar_url}
              />
            </div>
            <div className="info">
              <span className="name">{user.name}</span>
              <span className="store">{user.store?.name}</span>
            </div>
          </section>
          <section className="navigation">
            <Button
              startIcon={<MdHome />}
              variant="text"
              color={
                activeParent('@dashboard')
                  ? theme.font_secondary
                  : theme.font_primary
              }
              onClick={async () => {
                localStorage.removeItem('savedClientInfo');
                if (modalCondition) {
                  await setUrl('/dashboard');
                  savedBagsModalRef.current?.show();
                } else {
                  history.push('/dashboard');
                }
              }}
              style={navigationStyles}
            >
              {t('HOME', 'Início')}
            </Button>
            <Button
              startIcon={<MdOutlineShoppingBag />}
              variant="text"
              color={
                activeParent('@saved_bags')
                  ? theme.font_secondary
                  : theme.font_primary
              }
              onClick={async () => {
                localStorage.removeItem('savedClientInfo');
                if (modalCondition) {
                  await setUrl('/carts');
                  savedBagsModalRef.current?.show();
                } else {
                  history.push('/carts');
                }
              }}
              style={navigationStyles}
            >
              {t('SAVED_BAGS', 'Sacolas Salvas')}
              {current_time < limit_time && (
                <div className="new">
                  <p>{t('NEW', 'Novo')}</p>
                </div>
              )}
            </Button>
            <Button
              startIcon={<MdAddShoppingCart />}
              variant="text"
              color={
                activeParent('@create_order')
                  ? theme.font_secondary
                  : theme.font_primary
              }
              onClick={() => {
                history.push('/order/new');
              }}
              style={navigationStyles}
            >
              {t('NEW_ORDER', 'Novo Atendimento')}
            </Button>
            <Button
              startIcon={<MdList />}
              variant="text"
              color={
                activeParent('@list_orders')
                  ? theme.font_secondary
                  : theme.font_primary
              }
              onClick={async () => {
                localStorage.removeItem('savedClientInfo');
                if (modalCondition) {
                  await setUrl('/orders');
                  savedBagsModalRef.current?.show();
                } else {
                  history.push('/orders');
                }
              }}
              style={navigationStyles}
            >
              {t('ORDERS', 'Meus Atendimentos')}
            </Button>
            <Button
              startIcon={<MdOutlinePerson />}
              variant="text"
              color={
                activeParent('@client')
                  ? theme.font_secondary
                  : theme.font_primary
              }
              onClick={async () => {
                localStorage.removeItem('savedClientInfo');
                if (modalCondition) {
                  await setUrl('/client');
                  savedBagsModalRef.current?.show();
                } else {
                  history.push('/client');
                }
              }}
              style={navigationStyles}
            >
              {t('CLIENT', 'Identificação de Cliente')}
            </Button>
            <Button
              startIcon={
                <img
                  src={MaisVendasIcon}
                  style={{ height: 20, width: 20 }}
                  alt="Ícone +Vendas"
                />
              }
              variant="text"
              color={theme.font_primary}
              onClick={() => {
                window.open(maisVendasUrl, '_blank')?.focus();
              }}
              style={navigationStyles}
            >
              {t('MAIS_VENDAS', '+Vendas')}
            </Button>
            <Button
              startIcon={<FaWhatsapp />}
              variant="text"
              color={theme.font_primary}
              onClick={() => {
                window.open(supportUrl, '_blank')?.focus();
              }}
              style={navigationStyles}
            >
              {t('SUPPORT', 'Suporte')}
            </Button>
            <Button
              startIcon={<MdInfoOutline />}
              variant="text"
              color={theme.font_primary}
              onClick={() => {
                window.open(helpCenterUrl, '_blank')?.focus();
              }}
              style={navigationStyles}
            >
              {t('HELP_CENTER', 'Central de Ajuda')}
            </Button>
          </section>
          <section className="footer">
            <span className="divisor" />
            <Button
              className="config"
              startIcon={<MdSettings />}
              variant="text"
              color={theme.font_primary}
              onClick={() => {
                setIsConfigOpen(!isConfigOpen);
              }}
              style={navigationStyles}
            >
              {t('SETTINGS', 'Configurações')}
              <span
                style={{
                  flex: 1,
                  margin: 0,
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: 25,
                  height: 25,
                }}
              >
                <motion.span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  initial="closed"
                  className={isConfigOpen ? 'open' : 'closed'}
                  animate={isConfigOpen ? 'open' : 'closed'}
                  variants={{
                    open: { rotate: 90 },
                    closed: { rotate: 270 },
                  }}
                >
                  <MdChevronRight color={theme.font_primary} size={25} />
                </motion.span>
              </span>
            </Button>
            <motion.div
              key="accordion"
              initial="closed"
              animate={isConfigOpen ? 'open' : 'closed'}
              variants={{
                open: { opacity: 1, height: 'auto', marginBottom: '1rem' },
                closed: { opacity: 0, height: 0, marginBottom: 0 },
              }}
              className="expanded"
            >
              <Config>
                <div className="row">
                  <span>
                    <MdOutlineFlag size={25} color={theme.font_primary} />
                  </span>
                  <div>
                    <h3>{t('ENABLED_BRANDS', 'Marcas Habilitadas')}</h3>
                    {user.store?.brands?.length ? (
                      <ul>
                        {user.store.brands
                          .filter(
                            store_brand =>
                              store_brand?.enabled &&
                              store_brand?.brand?.enabled,
                          )
                          .map(({ brand }) => (
                            <li key={brand.id}>
                              <Avatar
                                key={brand.code}
                                name={brand.name}
                                size={30}
                                noBackground
                                fixedColor={theme.font_primary}
                                src={brand.image_url}
                              />
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <span>
                        {t(
                          'STORE_WITHOUT_BRAND',
                          'Esta loja ainda não possui nenhuma marca habilitada.',
                        )}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <span>
                    <MdAttachMoney size={25} color={theme.font_primary} />
                  </span>
                  <div>
                    <h3>
                      {t('ENABLED_PAYMENT_TYPES', 'Pagamentos Habilitados')}
                    </h3>
                    {user.store?.payment_types?.length ? (
                      <ul>
                        {user.store.payment_types
                          .filter(
                            store_payment_type =>
                              store_payment_type?.enabled &&
                              store_payment_type?.payment_type?.enabled,
                          )
                          .map(({ payment_type: payment }) => {
                            // Get icon
                            let icon: React.ReactNode = (
                              <MdAttachMoney
                                size={50}
                                color={theme.font_primary}
                              />
                            );

                            switch (payment.code) {
                              case 'manual_physical_card':
                                icon = (
                                  <MdReceipt
                                    size={20}
                                    color={theme.font_primary}
                                  />
                                );
                                break;

                              case 'physical_card':
                                icon = (
                                  <MdWifi
                                    size={20}
                                    color={theme.font_primary}
                                  />
                                );
                                break;

                              case 'payment_link':
                                icon = (
                                  <MdOutlineLink
                                    size={20}
                                    color={theme.font_primary}
                                  />
                                );
                                break;

                              case 'pix':
                                icon = (
                                  <PixIcon
                                    width={20}
                                    height={20}
                                    fill={theme.font_primary}
                                    stroke={theme.font_primary}
                                  />
                                );
                                break;

                              default:
                                icon = (
                                  <MdAttachMoney
                                    size={20}
                                    color={theme.font_primary}
                                  />
                                );
                                break;
                            }

                            return (
                              <li key={payment.code}>
                                {icon}
                                <p>
                                  {payment.name ||
                                    t(
                                      'UNKNOWN_PAYMENT_TYPE_LABEL',
                                      'MÉTODO NÃO IDENTIFICADO',
                                    )}
                                </p>
                              </li>
                            );
                          })}
                      </ul>
                    ) : (
                      <span>
                        {t(
                          'STORE_WITHOUT_PAYMENT_TYPE',
                          'Esta loja ainda não possui nenhum método de pagamento habilitado.',
                        )}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <span>
                    <MdOutlineLocalShipping
                      size={25}
                      color={theme.font_primary}
                    />
                  </span>
                  <div>
                    <h3>{t('ENABLED_PACKAGE_TYPES', 'Pacotes Habilitados')}</h3>

                    {user.store?.package_types?.length ? (
                      <ul>
                        {user.store.package_types
                          .filter(
                            store_package_type =>
                              store_package_type?.enabled &&
                              store_package_type?.package_type?.enabled,
                          )
                          .map(({ package_type: packageType }) => {
                            if (packageType.code === 'DELIVERY')
                              return (
                                <li key={packageType.code}>
                                  <MdLocalShipping
                                    size={20}
                                    color={theme.font_primary}
                                  />
                                  <p>{packageType.name}</p>
                                </li>
                              );

                            return (
                              <li key={packageType.code}>
                                <MdStoreMallDirectory
                                  size={20}
                                  color={theme.font_primary}
                                />
                                <p>{packageType.name}</p>
                              </li>
                            );
                          })}
                      </ul>
                    ) : (
                      <span>
                        {t(
                          'STORE_WITHOUT_PACKAGE_TYPES',
                          'Esta loja ainda não possui nenhum tipo de pacote habilitado.',
                        )}
                      </span>
                    )}
                  </div>
                </div>
                {userIsManager && (
                  <button
                    className="row clickable"
                    type="button"
                    onClick={() => dispatch(setPrinterConfig(true))}
                  >
                    <div className="printerDiv">
                      <span>
                        <AiOutlinePrinter
                          size={25}
                          color={theme.font_primary}
                        />
                      </span>
                      <h3>
                        {t('CONFIGURE_PRINTERS', 'Impressoras configuradas')}
                      </h3>
                    </div>
                    <MdKeyboardArrowRight
                      size={25}
                      color={theme.font_primary}
                    />
                  </button>
                )}
              </Config>
            </motion.div>
            <Button
              startIcon={<MdExitToApp />}
              variant="text"
              color={theme.font_primary}
              onClick={async () => {
                if (modalCondition) {
                  await setSignOutCondition(true);
                  savedBagsModalRef.current?.show();
                } else {
                  dispatch(signOut());
                }
              }}
              style={navigationStyles}
            >
              {t('EXIT', 'Sair')}
            </Button>
          </section>
        </div>
      </ScrollBar>
      {modals}
    </Container>
  );
};

export { Sidebar };
