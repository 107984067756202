import React, { useRef, useCallback, forwardRef, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';
import { Button } from '@components/Button';
import { IModalRef, Modal } from '@components/Modal';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useForwardRef } from '@hooks/useForwardRef';
import { useOrder } from '@hooks/useOrder';

// Service import
import { api, getResponseError } from '@services/api';
import { Analytics } from '@services/analytics';

// Store import
import { setBagId } from '@store/modules/ui/actions';

// Style import
import { Container } from './styles';

// Interfaces
interface IRedoServiceModalRef {
  show: () => any;
  hide: () => any;
}

// Feature identification
const featureKey = '@order/RedoService';

const RedoService = forwardRef<IRedoServiceModalRef>((_, forwardedRef) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrder();
  const dispatch = useDispatch();
  const history = useHistory();

  // Local states
  const [loading, setLoading] = useState<boolean>();

  // Local refs
  const modalRef = useRef<IModalRef>(null);

  const show = useCallback(() => {
    modalRef.current?.show();
  }, []);
  const hide = useCallback(() => {
    modalRef.current?.hide();
  }, []);

  // Merge local ref with forward ref
  useForwardRef(forwardedRef, {
    show,
    hide,
  });

  const cloneAsCart = async () => {
    setLoading(true);

    try {
      // API call
      const response = await api.patch(
        `v1/order/${order.data?.order.id}/clone-as-cart`,
      );

      const new_response = response.data;

      dispatch(setBagId(new_response.cartId));
      history.push(`/order/new`);
      setLoading(false);
      if (typeof hide === 'function') hide();
    } catch (err: any) {
      const { message, error } = getResponseError(err, t);
      toast.error(message);
      Analytics.trackEvent({
        featureKey,
        category: 'GET_PRODUCT',
        action: message,
        label: error,
      });
      setLoading(false);
      if (typeof hide === 'function') hide();

      throw err;
    }
  };

  return (
    <Modal ref={modalRef} size="smaller">
      <Container>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <div className="closeRow">
              <button
                type="button"
                onClick={() => {
                  // Hide modal
                  if (typeof hide === 'function') hide();
                }}
              >
                <MdOutlineClose className="closeIcon" />
              </button>
            </div>
            <div className="titleRow">
              <p>{t('REDO_SERVICE', 'Refazer Atendimento')}</p>
            </div>
            <div className="textRow">
              <p>
                {t(
                  'REDO_SERVICE_NOTICE',
                  'Você vai iniciar um novo atendimento com os produtos e informações de cliente já preenchidos. Verifique os dados e escolha uma nova forma de pagamento.',
                )}
              </p>
            </div>

            <Button
              onClick={() => {
                cloneAsCart();
              }}
            >
              {t('CONFIRM', 'CONFIRMAR')}
            </Button>
            <span>
              <Button
                color="danger"
                variant="outlined"
                onClick={() => {
                  // Hide modal
                  if (typeof hide === 'function') hide();
                }}
              >
                {t('CANCEL', 'CANCELAR')}
              </Button>
            </span>
          </>
        )}
      </Container>
    </Modal>
  );
});

export type { IRedoServiceModalRef };
export { RedoService };
