import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  gap: 2.5rem;

  .icon {
  }

  p {
    font-size: 1.875rem;
    font-weight: 600;
    text-align: center;
    width: 25.875rem;
    max-width: 70%;

    &.orientation {
      font-size: 1.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.font_secondary};
    }
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21rem;
    max-width: 70%;
  }

  @media screen and (max-width: 375px) {
    p,
    .action {
      max-width: 95%;
    }
  }

  @media screen and (max-width: 425px) {
    padding-bottom: 5rem;

    .action {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;

      z-index: 2;

      display: grid;
      align-items: center;
      justify-content: center;

      width: 100%;
      max-width: 100%;

      border-top: 0.125rem solid ${({ theme }) => theme.background_secondary};
      background: ${({ theme }) => theme.background_low};
      box-shadow: 0rem 0rem 1.5rem 0.05rem
        ${({ theme }) => rgba(theme.background_low, 0.95)};
    }
  }
`;
