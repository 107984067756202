import { all, AllEffect, ForkEffect } from 'redux-saga/effects';

// Saga import
import { auth } from './auth/sagas';
import { ui } from './ui/sagas';

function* rootSaga(): Generator<
  AllEffect<AllEffect<ForkEffect<never>>>,
  any,
  unknown
> {
  return yield all([auth, ui]);
}

export { rootSaga };
