import React from 'react';
import Lottie from 'react-lottie';

// Asset import
import animation from '@assets/soon.json';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@statistics/INDEX';

const Statistics: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);

  return (
    <Container>
      <h2>{t('SOON', 'EM BREVE')}</h2>
      <p>{t('WORK_IN_PROGRESS', 'Trabalho em progresso.')}</p>
      <div className="animation">
        <Lottie
          options={{
            animationData: animation,
            loop: true,
            autoplay: true,
          }}
          style={{
            borderRadius: '50%',
          }}
        />
      </div>
    </Container>
  );
};

export { Statistics };
