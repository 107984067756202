import { DefaultTheme } from 'styled-components';

const lightTheme: DefaultTheme = {
  font_primary: process.env.REACT_APP_THEME_LIGHT_FONT_PRIMARY,
  font_secondary: process.env.REACT_APP_THEME_LIGHT_FONT_SECONDARY,
  font_success: process.env.REACT_APP_THEME_LIGHT_FONT_SUCCESS,
  font_danger: process.env.REACT_APP_THEME_LIGHT_FONT_DANGER,
  font_warning: process.env.REACT_APP_THEME_LIGHT_FONT_WARNING,
  font_error: process.env.REACT_APP_THEME_LIGHT_FONT_ERROR,
  font_info: process.env.REACT_APP_THEME_LIGHT_FONT_INFO,
  font_high: process.env.REACT_APP_THEME_LIGHT_FONT_HIGH,
  font_low: process.env.REACT_APP_THEME_LIGHT_FONT_LOW,
  font_disabled: process.env.REACT_APP_THEME_LIGHT_FONT_DISABLED,

  button_primary: process.env.REACT_APP_THEME_LIGHT_BUTTON_PRIMARY,
  button_hover: process.env.REACT_APP_THEME_LIGHT_BUTTON_HOVER,
  button_secondary: process.env.REACT_APP_THEME_LIGHT_BUTTON_SECONDARY,
  button_success: process.env.REACT_APP_THEME_LIGHT_BUTTON_SUCCESS,
  button_danger: process.env.REACT_APP_THEME_LIGHT_BUTTON_DANGER,
  button_warning: process.env.REACT_APP_THEME_LIGHT_BUTTON_WARNING,
  button_error: process.env.REACT_APP_THEME_LIGHT_BUTTON_ERROR,
  button_info: process.env.REACT_APP_THEME_LIGHT_BUTTON_INFO,
  button_high: process.env.REACT_APP_THEME_LIGHT_BUTTON_HIGH,
  button_low: process.env.REACT_APP_THEME_LIGHT_BUTTON_LOW,
  button_disabled: process.env.REACT_APP_THEME_LIGHT_BUTTON_DISABLED,

  background_primary: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_PRIMARY,
  background_secondary: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_SECONDARY,
  background_success: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_SUCCESS,
  background_danger: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_DANGER,
  background_warning: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_WARNING,
  background_error: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_ERROR,
  background_info: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_INFO,
  background_high: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_HIGH,
  background_low: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_LOW,
  background_disabled: process.env.REACT_APP_THEME_LIGHT_BACKGROUND_DISABLED,
};

export { lightTheme };
