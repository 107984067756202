import { AnyAction } from 'redux';
import { all, takeLatest } from 'redux-saga/effects';

// Service import
import { Analytics } from '@services/analytics';

// Interface import
import { UiActionTypes } from './types';

// Feature identification
const featureKey = '@ui';

function setActivePageEffect({ payload }: AnyAction) {
  if (payload.page)
    Analytics.trackEvent({
      featureKey,
      category: 'NAVIGATE',
      action: String(payload.page),
      label: 'SUCCESS',
    });
}

const ui = all([
  takeLatest(UiActionTypes.ACTIVE_PAGE_SET, setActivePageEffect),
]);

export { ui };
