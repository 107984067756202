import React, { useRef, useEffect } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

// Component import
import { Button } from '@components/Button';
import { Modal, IModalRef } from '@components/Modal';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

import { Container } from './styles';

// Feature identification
const featureKey = '@order/AWAIT_INVOICE_MODAL';

const DocumentAvailabilityWarning: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const location = useLocation();

  // Local refs
  const documentAvailabilityWarningRef = useRef<IModalRef>(null);

  useEffect(() => {
    const shouldShowQuery = location.pathname.includes('showDocumentWarning');

    if (shouldShowQuery) {
      documentAvailabilityWarningRef.current?.show();
    }
  }, [location.pathname]);

  return (
    <Modal ref={documentAvailabilityWarningRef} size="smaller">
      <Container>
        <div className="closeRow">
          <button
            type="button"
            onClick={() => {
              // Hide modal
              documentAvailabilityWarningRef.current?.hide();
            }}
          >
            <MdOutlineClose className="closeIcon" />
          </button>
        </div>
        <div className="titleRow">Importante:</div>
        <div className="textRow">
          <p>
            {t(
              'DOCUMENT_AVAILABILITY_TEXT',
              'Quando o pedido estiver finalizado, Cupom de Troca e Nota Fiscal estarão disponíveis dentro dos detalhes deste atendimento.',
            )}
          </p>
        </div>
        <div className="actionArea">
          <Button
            onClick={() => {
              documentAvailabilityWarningRef.current?.hide();
            }}
          >
            <p className="modalButton">{t('CONFIRM', 'ENTENDI')}</p>
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              localStorage.setItem(
                'dontShowDocumentAvailabilityWarning',
                'seen',
              );
              documentAvailabilityWarningRef.current?.hide();
            }}
          >
            <p className="modalButton">
              {t('DONT_SHOW_AGAIN', 'NÃO MOSTRAR NOVAMENTE')}
            </p>
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export { DocumentAvailabilityWarning };
