import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { MdOutlineClose } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api } from '@services/api';

// Action import
import { signOut } from '@store/modules/auth/actions';
// Store importa
import { setBagId } from '@store/modules/ui/actions';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  url: string | undefined;
  orderId: string;
  signOutCondition: boolean;
}

// Feature identification
const featureKey = '@saved_bags/SAVED_BAGS_MODAL';

const SavedBagsModal: React.FC<IProps> = ({
  hide,
  url,
  orderId,
  signOutCondition,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const history = useHistory();

  const dispatch = useDispatch();

  const deleteBag = useCallback(async () => {
    try {
      // API call
      await api.delete(`/v1/cart/${orderId}`);

      if (typeof hide === 'function') hide();
    } catch (err: any) {
      toast.error(t('DELETE_BAG_FAILURE', 'Erro ao deletar sacola'));
    }
  }, [hide, orderId, t]);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="textRow">
        <p> {t('SAVE_BAGS_ASK', 'Deseja salvar esta sacola antes de sair?')}</p>
      </div>
      <div className="actionArea">
        <Button
          variant="outlined"
          onClick={async () => {
            await deleteBag();
            dispatch(setBagId(''));
            if (signOutCondition) {
              sessionStorage.removeItem('savedOrderCode');
              dispatch(signOut());
            }
            if (url) {
              sessionStorage.removeItem('savedOrderCode');
              history.push(url);
            }

            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          {t('NO', 'NÃO')}
        </Button>
        <Button
          onClick={() => {
            dispatch(setBagId(''));
            if (signOutCondition) {
              sessionStorage.removeItem('savedOrderCode');
              dispatch(signOut());
            }
            if (url) {
              sessionStorage.removeItem('savedOrderCode');
              history.push(url);
            }
            if (typeof hide === 'function') hide();
          }}
        >
          {t('YES', 'SIM')}
        </Button>
      </div>
    </Container>
  );
};

export { SavedBagsModal };
