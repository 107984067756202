import React from 'react';
import { useDispatch } from 'react-redux';

// Component import
import { CartPricingSummary } from '@components/CartPricingSummary';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Action import
import { setOrderSummary } from '@store/modules/ui/actions';

// Feature identification
const featureKey = '@create_order/CART_DELIVERY_PRICING';

interface IProps {
  handleCouponClick: () => void;
}

const CartPricing: React.FC<IProps> = ({ handleCouponClick }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const dispatch = useDispatch();

  return (
    <div className="totalsSection">
      <div className="title">
        <button
          type="button"
          onClick={() => {
            dispatch(setOrderSummary(true));
          }}
        >
          <p>{t('ORDER_SUMMARY', 'Resumo do Pedido')}</p>
        </button>
      </div>
      <CartPricingSummary step={3} handleCouponClick={handleCouponClick} />
    </div>
  );
};

export { CartPricing };
