import React from 'react';
import { BsCircle, BsCircleFill } from 'react-icons/bs';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { StepperStore } from './styles';

interface IProps {
  step: number;
}

// Feature identification
const featureKey = '@create_order/CART_STEPPER';

const CartStepper: React.FC<IProps> = ({ step }) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <StepperStore>
      <div className="statusItem">
        <BsCircleFill />
        <p>{t('RESUME', 'Resumo')}</p>
      </div>
      <div className="divider" />
      <div className="statusItem">
        {step >= 2 ? <BsCircleFill /> : <BsCircle />}
        <p>{t('IDENTIFICATION', 'Identificação')}</p>
      </div>
      <div className="divider" />
      <div className="statusItem">
        {step >= 3 ? <BsCircleFill /> : <BsCircle />}
        <p>{t('DELIVERY', 'Entrega')}</p>
      </div>
      <div className="divider" />
      <div className="statusItem">
        {step === 4 ? <BsCircleFill /> : <BsCircle />}
        <p>{t('PAYMENT', 'Pagamento')}</p>
      </div>
    </StepperStore>
  );
};

export { CartStepper };
