import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  align-self: stretch;
  gap: 1rem;

  .loading {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 1rem;

    li {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        border-radius: 0.125rem;

        padding: 0.5rem;
        gap: 0.5rem;
        background: ${({ theme }) => theme.background_secondary};
        align-self: stretch;

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 0.25rem;
          overflow: hidden;
          color: black !important;

          .street {
            overflow: hidden;
            text-align: left;
          }

          .postalCode {
            font-size: 1.125rem;
            font-weight: 700;
            overflow: hidden;
          }

          .complement {
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  form.zipcode {
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: flex-start;
    justify-content: center;
    gap: 0.3rem;

    button {
      margin-top: 1rem !important;
      height: 2.5rem;
    }
  }

  form.address {
    > section {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
`;
