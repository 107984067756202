import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Asset import
import {
  VeryDissatisfiedIcon,
  DissatisfiedIcon,
  NeutralIcon,
  SatisfiedIcon,
  VerySatisfiedIcon,
} from '@assets/index';

// Component import
import { Button } from '@components/Button';
import { Input } from '@components/Input';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  selectedSentiment:
    | 'very_dissatisfied'
    | 'dissatisfied'
    | 'neutral'
    | 'satisfied'
    | 'very_satisfied'
    | 'none';
  setSelectedStep: React.Dispatch<
    React.SetStateAction<
      'salutation' | 'first_step' | 'second_step' | 'third_step' | 'farewell'
    >
  >;
  secondStepComment: string;
  setSecondStepComment(comment: string): void;
}

// Feature identification
const featureKey = '@satisfaction_survey/SECOND_STEP_MODAL';

const SecondStepModal: React.FC<IProps> = ({
  hide,
  selectedSentiment,
  setSelectedStep,
  secondStepComment,
  setSecondStepComment,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const [response, setResponse] = useState(secondStepComment);

  const getSentiment = () => {
    switch (selectedSentiment) {
      case 'very_dissatisfied':
        return (
          <div className="sentimentRow">
            <VeryDissatisfiedIcon />{' '}
            <p>{t('VERY_UNSATISFIED', 'Muito insatisfeito')}</p>
          </div>
        );
      case 'dissatisfied':
        return (
          <div className="sentimentRow">
            <DissatisfiedIcon /> <p>{t('UNSATISFIED', 'Insatisfeito')}</p>
          </div>
        );
      case 'neutral':
        return (
          <div className="sentimentRow">
            <NeutralIcon /> <p>{t('NEUTRAL', 'Neutro')}</p>
          </div>
        );
      case 'satisfied':
        return (
          <div className="sentimentRow">
            <SatisfiedIcon /> <p>{t('SATISFIED', 'Muito satisfeito')}</p>
          </div>
        );
      case 'very_satisfied':
        return (
          <div className="sentimentRow">
            <VerySatisfiedIcon />
            <p>{t('VERY_SATISFIED', 'Muito satisfeito')}</p>
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="titleRow">
        <p className="step">2/3</p>
        {getSentiment()}
        <p className="titleText">
          {t('TELL MORE', 'Nos conte mais sobre sua experiência:')}
        </p>
      </div>
      <div className="menuRow">
        <Input
          id="comment"
          color="primary"
          label="Seu comentário aqui"
          inputProps={{ maxLength: 250 }}
          multiline
          variant="outlined"
          fullWidth
          autoFocus
          style={{
            color: 'white',
            minHeight: '5.875rem',
            borderRadius: '0.5rem',
          }}
          value={response}
          onChange={(e: any) => setResponse(e)}
        />
      </div>
      <div className="actionArea">
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedStep('first_step');
            setSecondStepComment(response);
          }}
        >
          <p className="modalButton">{t('PREVIOUS', 'ANTERIOR')}</p>
        </Button>
        <Button
          onClick={() => {
            setSelectedStep('third_step');
            setSecondStepComment(response);
          }}
          disabled={selectedSentiment === 'none'}
        >
          <p className="modalButton">{t('NEXT', 'PRÓXIMO')}</p>
        </Button>
      </div>
    </Container>
  );
};

export { SecondStepModal };
