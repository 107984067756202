/* eslint-disable no-console */
import { Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

// Store import
import { create } from './createStore';
// Type import
import { IAuthState } from './modules/auth/types';
import { IUiState } from './modules/ui/types';
// Root import
import { rootReducer } from './modules/rootReducer';
import { rootSaga } from './modules/rootSaga';

// Setup saga
const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

// Handle saga
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
const middlewares = [sagaMiddleware];

// Application store
interface IApplicationState {
  auth: IAuthState;
  ui: IUiState;
}

// Create store
const store: Store<IApplicationState> = create(rootReducer, middlewares);

sagaMiddleware.run(rootSaga);

export type { IApplicationState };
export { store };
