import axios from 'axios';

// Hook import
import { TranslationFunction } from '@hooks/useTranslation';

// Create axios instance
const maisVendasApi = axios.create({
  baseURL: process.env.REACT_APP_MAISVENDAS_API_URL,
});

// Error helper
interface IResponseError {
  status: number;
  code: string;
  message: string;
  error: 'FATAL' | 'ERROR';
  err: any;
}
const getResponseError = (err: any, t: TranslationFunction): IResponseError => {
  return {
    status: err?.response?.status || 0,
    code: err?.response?.data?.code || '@general/UNKNOWN',
    message:
      err?.response?.data?.message ||
      t(
        '@general/CONNECTION_FAILURE_ERROR',
        'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
      ),
    error: err?.response?.data?.fatal ? 'FATAL' : 'ERROR',
    err,
  };
};

export { maisVendasApi, getResponseError };
