import React from 'react';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@create_order/STORE_CLOSED';

const StoreClosed: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);

  return (
    <Container>
      <h2>{t('HEADER', 'LOJA FECHADA')}</h2>
      <p>
        {t(
          'DESCRIPTION',
          'A loja não está autorizada a operar neste momento. A tela se atualizará automaticamente quando estiver liberada.',
        )}
      </p>
    </Container>
  );
};

export { StoreClosed };
