import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdArrowBack } from 'react-icons/md';
import SwipeableViews from 'react-swipeable-views';

// Component import
import { Tabs } from '@components/Tabs';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTheme } from '@hooks/useTheme';
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';

// Content import
import { AddByRefAndColorOrText } from './AddByRefAndColorOrText';
import { SelectBrand } from './SelectBrand';
import { SelectBrandScreen } from './SelectBrandScreen';
import { VariationsScreen } from './VariationsScreen';
import { AddByCamera } from './AddByCamera';
import { AddByAnalogReader } from './AddByAnalogReader';
import { ProductsList } from './ProductsList';

// Style import
import { Container, Header, Content } from './styles';

// Interfaces
interface IAddProductScreenRef {
  onFocus: () => void;
  onBlur: () => void;
  onSelect: () => void;
  onDeselect: () => void;
}

// Feature identification
const featureKey = '@create_order/PRODUCT_ADD';

const Product: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const order = useOrderCreation();
  const product = useAddProduct();

  // Local states
  const [activeTab, setActiveTab] = useState(0);

  // Local refs
  const screenRef = useRef<Array<IAddProductScreenRef | null>>([]);

  const tabLabels = useMemo(
    () => [
      {
        label: t('LABEL_ADD_BY_REFERENCE_AND_COLOR_OR_TEXT', 'Consulta'),
      },
      {
        label: t('LABEL_ADD_BY_CAMERA', 'Leitura por Câmera'),
      },
      {
        label: t('LABEL_ADD_BY_ANALOG_READER', 'Digitar código'),
      },
    ],
    [t],
  );

  const handleChangeIndex = (index: number) => {
    const numberOfTabs = tabLabels.length;
    if (index >= numberOfTabs) {
      setActiveTab(numberOfTabs - 1);
      return;
    }
    setActiveTab(index);
  };

  const handleTabChange = useCallback(() => {
    screenRef.current.forEach((tab, index) => {
      // Selected
      if (index === activeTab) {
        if (typeof screenRef.current[index]?.onFocus === 'function')
          screenRef.current[index]?.onFocus();
        return;
      }

      // Not selected
      if (typeof screenRef.current[index]?.onBlur === 'function')
        screenRef.current[index]?.onBlur();
    });
  }, [activeTab]);

  // Handle tab select (before animations)
  useEffect(() => {
    screenRef.current.forEach((tab, index) => {
      // Selected
      if (index === activeTab) {
        if (typeof screenRef.current[index]?.onSelect === 'function')
          screenRef.current[index]?.onSelect();
        return;
      }

      // Not selected
      if (typeof screenRef.current[index]?.onDeselect === 'function')
        screenRef.current[index]?.onDeselect();
    });
  }, [activeTab, tabLabels]);

  if (!product.selectedBrandCode) return <SelectBrandScreen />;

  if (product.variationSelectOptions.length) return <VariationsScreen />;

  if (product.productsList.length) return <ProductsList />;

  return (
    <Container>
      <Header>
        <button
          type="button"
          onClick={() => {
            order.setAddingProduct(false);
            product.setSearchedTerm({});
          }}
        >
          <MdArrowBack size={24} color={theme.font_primary} />
        </button>
      </Header>
      <Content>
        <div className="brand">
          <h2>{t('BRAND_SELECTOR_LABEL', 'Selecione a Marca')}</h2>
          <SelectBrand />
        </div>
        <div className="main">
          <h2>
            {t(
              'PRODUCT_SELECTOR_LABEL',
              'Escolha como deseja consultar o produto',
            )}
          </h2>
          <Tabs
            index={activeTab}
            className="tabs"
            onChange={setActiveTab}
            disabled={product.loading}
            tabs={tabLabels}
          />
          <SwipeableViews
            index={activeTab}
            onChangeIndex={handleChangeIndex}
            onTransitionEnd={handleTabChange}
            enableMouseEvents={!product.loading}
            className="content"
            slideStyle={{
              padding: '0 1rem',
              height: '100%',
            }}
          >
            <AddByRefAndColorOrText
              ref={ref => {
                screenRef.current[0] = ref;
              }}
            />
            <AddByCamera
              ref={ref => {
                screenRef.current[1] = ref;
              }}
            />
            <AddByAnalogReader
              ref={ref => {
                screenRef.current[2] = ref;
              }}
            />
          </SwipeableViews>
        </div>
      </Content>
    </Container>
  );
};

export type { IAddProductScreenRef };
export { Product };
