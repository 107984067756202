import produce from 'immer';
import { Reducer } from 'redux';

// Type import
import { IUiState, UiActionTypes } from './types';
import { AuthActionTypes } from '../auth/types';

const INITIAL_STATE: IUiState = {
  left_menu: !(
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <=
    768
  ),
  order_summary: false,
  bag_summary: false,
  printer_config: false,
  saved_bag_adding_product: false,
  active_page: '',
  bag_id: '',
  order_id: '',
  help_mode: false,
  language: undefined,
};

const ui: Reducer<IUiState> = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case UiActionTypes.LEFT_MENU_SET:
        draft.left_menu = action.payload.open;
        break;

      case UiActionTypes.ORDER_SUMMARY_SET:
        draft.order_summary = action.payload.summary;
        break;

      case UiActionTypes.BAG_SUMMARY_SET:
        draft.bag_summary = action.payload.summary;
        break;

      case UiActionTypes.PRINTER_CONFIG_SET:
        draft.printer_config = action.payload.summary;
        break;

      case UiActionTypes.SAVED_BAG_ADDING_PRODUCT_SET:
        draft.saved_bag_adding_product = action.payload.summary;
        break;

      case UiActionTypes.LANGUAGE_SET:
        draft.language = action.payload.language;
        break;

      case UiActionTypes.ACTIVE_PAGE_SET:
        draft.active_page = action.payload.page;
        break;

      case UiActionTypes.BAG_ID_SET:
        draft.bag_id = action.payload.bag_id;
        break;

      case UiActionTypes.ORDER_ID_SET:
        draft.order_id = action.payload.order_id;
        break;

      case UiActionTypes.HELP_MODE_SET:
        draft.help_mode = action.payload.mode;
        break;

      // Clear UI when session changes
      case AuthActionTypes.SIGN_FAILURE:
      case AuthActionTypes.SIGN_OUT:
        draft.left_menu = true;
        draft.help_mode = false;
        break;

      default:
    }
  });
};

export { ui };
