import React from 'react';
import { useSelector } from 'react-redux';

// Component import
import { Select } from '@components/Select';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

interface IProps {
  selectedPackageType: string;
  setPackageType: React.Dispatch<
    React.SetStateAction<'OWN_STOCK' | 'DELIVERY'>
  >;
  setSelectedPackageType: React.Dispatch<
    React.SetStateAction<'OWN_STOCK' | 'DELIVERY' | 'none'>
  >;
}

// Feature identification
const featureKey = '@create_order/PACKAGE_OPTIONS_MOBILE';

const MobileLayout: React.FC<IProps> = ({
  selectedPackageType,
  setPackageType,
  setSelectedPackageType,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const product = useAddProduct();

  // Global states
  const user = useSelector(state => state.auth);

  const availablePackageTypes = user.store?.package_types.filter(
    storePackageType =>
      storePackageType.enabled &&
      storePackageType.package_type.enabled &&
      !(storePackageType.package_type.code === 'return'),
  );

  const isOwnStockEnabled = !!availablePackageTypes?.filter(
    package_type => package_type.package_type.code === 'own_stock',
  ).length;

  const getPackageTypeFriendlyName = (packageTypeCode: string) => {
    switch (packageTypeCode) {
      case 'own_stock':
        return t('OWN_STOCK_ACTION', 'Levar Agora');
      case 'infinite_aisle':
        return t('INFINITE_AISLE_ACTION', 'Receber em Casa');
      default:
        return t('UNKNOWN_PACKAGE_TYPE', 'Opção de Entrega Não Mapeada');
    }
  };

  const getPackageValidName = (packageName: string) => {
    switch (packageName) {
      case 'own_stock':
        return 'OWN_STOCK';
      case 'infinite_aisle':
        return 'DELIVERY';
      default:
        return '';
    }
  };

  return (
    <Container>
      {(isOwnStockEnabled || !!product.selectedVariationId) && (
        <div className="selectPackageType">
          <Select
            label={t('SELECT_PACKAGE_TYPE', 'Selecione a opção de Entrega:')}
            value={selectedPackageType}
            onChange={value => {
              setPackageType(value);
              setSelectedPackageType(value);
            }}
            options={
              availablePackageTypes?.map(({ package_type }) => ({
                value: getPackageValidName(package_type.code),
                label: getPackageTypeFriendlyName(package_type.code),
              })) || []
            }
          />
        </div>
      )}
    </Container>
  );
};

export { MobileLayout };
