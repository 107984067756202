import React, { useEffect, useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api } from '@services/api';

// Store import
import { setPrinterConfig } from '@store/modules/ui/actions';

// Import module components
import { PrinterList } from './PrinterList';
import { EmptyListState } from './EmptyListState';
import { IPrinterList, ISelectedPrinters } from './models';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@component/PRINTER_CONFIG';

const PrinterConfiguration: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const dispatch = useDispatch();

  // Global states
  const user = useSelector(state => state.auth);

  // Local states
  const [printerList, setPrinterList] = useState<IPrinterList[]>();
  const [selectedPrinters, setSelectedPrinters] = useState<ISelectedPrinters>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getPrintersList = async () => {
      setLoading(true);
      try {
        const response = await api.get('/store/printers');

        setPrinterList(response.data.printers);
      } catch {
        toast.error(
          t('ERROR_GETTING_LIST', 'Erro obtendo lista de impressoras'),
        );
      }
      setLoading(false);
    };

    setSelectedPrinters(user.store?.printers);
    getPrintersList();
  }, [t, user.store?.printers]);

  return (
    <Container>
      <div className="configComponent">
        <div className="closeRow">
          <button
            type="button"
            onClick={() => {
              dispatch(setPrinterConfig(false));
            }}
          >
            <MdOutlineClose className="closeIcon" />
          </button>
        </div>
        <div className="moduleTitle">
          <p>{t('CONFIGURED_PRINTERS', 'Impressoras configuradas')}</p>
        </div>
        {!printerList?.length && !loading ? (
          <EmptyListState />
        ) : (
          <>
            <PrinterList
              printerType="regular"
              printerList={printerList}
              selectedPrinters={selectedPrinters}
              setSelectedPrinters={setSelectedPrinters}
              loading={loading}
            />
            <PrinterList
              printerType="thermal"
              printerList={printerList}
              selectedPrinters={selectedPrinters}
              setSelectedPrinters={setSelectedPrinters}
              loading={loading}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export { PrinterConfiguration };
