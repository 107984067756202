import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import {
  MdAdd,
  MdRemove,
  MdOutlineShoppingCart,
  MdArrowBack,
} from 'react-icons/md';
import { Locale, getDate, parseJSON, format } from 'date-fns';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Asset import
import { EditIcon } from '@assets/index';
// import DefaultProductImage from '@assets/defaultProductImage.png';
import DefaultProductImageBigger from '@assets/defaultProductImageBigger.png';

// Component import
import { Button } from '@components/Button';
import { Input } from '@components/Input';
// import { LoadingIndicator } from '@components/LoadingIndicator';
import { Modal, IModalRef } from '@components/Modal';
import { CheckboxHandmade } from '@components/CheckboxHandmade';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTheme } from '@hooks/useTheme';
import { useWindowSize } from '@hooks/useWindowSize';
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';

// Page import
import { ScheduleModal } from './ScheduleModal';

// Style import
import { Container, Header, Footer } from '../../styles';
import { Content, ProductItemDesktop } from './styles';

// Interfaces
interface IAttachments {
  name?: string;
  email?: string;
  date?: Date;
}

// Feature identification
const featureKey = '@create_order/VARIATIONS_SCREEN_GIFT_CARD';

const VariationsScreenGiftCard: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const order = useOrderCreation();
  const product = useAddProduct();
  const [width] = useWindowSize();

  const desktopLayout = width > 768;
  const mobileLayout = width <= 768;

  // Global states
  const user = useSelector(state => state.auth);
  const language = useSelector(state => state.ui.language);

  // Local states
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);
  const [selectedEmail, setSelectedEmail] = useState<boolean>(false);
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const [attachment, setAttachment] = useState<IAttachments>({
    name: '',
    email: '',
  });
  const [receiverName, setReceiverName] = useState<string>();
  const [receiverEmail, setReceiverEmail] = useState<string>();
  const [nameInputError, setNameInputError] = useState<string>();
  const [emailInputError, setEmailInputError] = useState<string>();

  // Local refs
  const scheduleModalRef = useRef<IModalRef>(null);

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  const selectedInformation = useMemo(() => {
    const information = {
      name: '',
      image_url: '',
      reference: '',
      color: '',
      size: '',
    };

    if (!product.variations.length) return information;

    information.name =
      product.variations.find(variation => !!variation.name)?.name || '';
    information.image_url =
      product.variations.find(variation => !!variation.image_url)?.image_url ||
      '';
    information.reference =
      product.variations.find(variation => !!variation.reference)?.reference ||
      '';
    information.color =
      product.variations.find(variation => !!variation.color)?.color || '';
    information.size =
      product.variations.find(variation => !!variation.size)?.size || '';

    if (product.selectedVariationId) {
      const selected = product.variations.find(
        variation =>
          String(variation.id) === String(product.selectedVariationId),
      );

      information.name = selected?.name || '';
      information.image_url = selected?.image_url || '';
      information.reference = selected?.reference || '';
      information.color = selected?.color || '';
      information.size = selected?.size || '';
    }

    return information;
  }, [product]);

  // Handle add
  const handleAdd = useCallback((quantity: number) => {
    setProductQuantity(quantity + 1);
  }, []);

  // Handle remove
  const handleRemove = useCallback((quantity: number) => {
    if (quantity === 1) {
      setProductQuantity(quantity);
    } else {
      setProductQuantity(quantity - 1);
    }
  }, []);

  const handleAttachments = useCallback(
    (selectedDate: any) => {
      if (!receiverName?.length) {
        setNameInputError('Este campo é obrigatório');
        return;
      }

      if (!receiverEmail?.length) {
        setEmailInputError('Este campo é obrigatório');
        return;
      }

      if (!(receiverEmail?.includes('@') && receiverEmail?.includes('.'))) {
        setEmailInputError('Insira um e-mail válido');
        return;
      }

      setEmailInputError('');
      setNameInputError('');

      setAttachment({
        name: receiverName,
        email: receiverEmail,
        date: selectedDate,
      });

      product.setSelectedVariationId(product.variations[0].id.toString());
    },
    [product, receiverEmail, receiverName],
  );

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal ref={scheduleModalRef} size="smaller">
          <ScheduleModal
            hide={() => scheduleModalRef.current?.hide()}
            handleAttachments={handleAttachments}
          />
        </Modal>
      </>
    ),
    [handleAttachments],
  );

  useEffect(() => {
    scheduleModalRef.current?.hide();
  }, [user]);

  return (
    <Container>
      <Header>
        <button type="button" onClick={() => product.resetVariations()}>
          <MdArrowBack size={24} color={theme.font_primary} />
        </button>
        <p>{t('NEW_ORDER', 'Novo Atendimento')}</p>
      </Header>
      <Content>
        <div className="giftCardProduct">
          <div className="giftCardProductTitle">
            <MdOutlineShoppingCart />
            <p>{t('RECEIVE_AT_HOME', 'Receber em Casa')}</p>
          </div>
          <div className="giftCardProductContent">
            <div className="giftCardComponent">
              <ProductItemDesktop>
                <div className="generalArea">
                  <div className="image">
                    {selectedInformation.image_url ? (
                      <img
                        src={selectedInformation.image_url}
                        alt={selectedInformation.name}
                      />
                    ) : (
                      <img
                        src={DefaultProductImageBigger}
                        alt={selectedInformation.name}
                      />
                    )}
                  </div>
                  <div className="info">
                    <div className="name">
                      <p>{selectedInformation.name}</p>
                    </div>
                    <div className="infoRow">
                      <p>{t('FARM_BRAND', 'Marca: FARM')}</p>
                      {desktopLayout && (
                        <p>
                          {t('REFERENCE', 'Ref.:')}{' '}
                          {selectedInformation.reference}
                        </p>
                      )}
                    </div>
                    {mobileLayout && (
                      <div className="infoRow">
                        <p>
                          {t('REFERENCE', 'Ref.:')}{' '}
                          {selectedInformation.reference}
                        </p>
                      </div>
                    )}
                    <div className="colorAndSizeRow">
                      <p>{t('UNIQUE_COLOR', 'Cor: Única')}</p>
                      {desktopLayout && (
                        <div className="sizeText">
                          <p>{t('UNIQUE_SIZE', 'Tamanho U')}</p>
                        </div>
                      )}
                    </div>
                    <div />

                    <div className="price">
                      <>
                        {product.variations[0].original_price !==
                          product.variations[0].current_price && (
                          <span className="original_price">
                            R${' '}
                            {(
                              product.variations[0].original_price / 100
                            ).toFixed(2)}
                          </span>
                        )}
                        <span className="current_price">
                          R${' '}
                          {(product.variations[0].current_price / 100).toFixed(
                            2,
                          )}
                        </span>
                      </>
                    </div>
                    <div />
                    <div />
                    {width > 768 && (
                      <div className="quantityRow">
                        <p>{t('QUANTITY_IN_BAG', 'Quantidade na sacola:')}</p>
                        <div className="quantity">
                          <Button noFullWidth noMargin disabled={order.loading}>
                            <MdRemove
                              color={theme.font_low}
                              size={20}
                              onClick={() => handleRemove(productQuantity)}
                            />
                          </Button>
                          <span className="number">{productQuantity}</span>
                          <Button noFullWidth noMargin disabled={order.loading}>
                            <MdAdd
                              color={theme.font_low}
                              size={20}
                              onClick={() => handleAdd(productQuantity)}
                            />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {mobileLayout && (
                  <div className="quantityMobileRow">
                    <p>{t('QUANTITY_IN_BAG', 'Quantidade a adicionar: ')}</p>
                    <div className="quantity">
                      <Button noFullWidth noMargin disabled={order.loading}>
                        <MdRemove
                          color={theme.font_low}
                          size={20}
                          onClick={() => handleRemove(productQuantity)}
                        />
                      </Button>
                      <span className="number">{productQuantity}</span>
                      <Button noFullWidth noMargin disabled={order.loading}>
                        <MdAdd
                          color={theme.font_low}
                          size={20}
                          onClick={() => handleAdd(productQuantity)}
                        />
                      </Button>
                    </div>
                  </div>
                )}
              </ProductItemDesktop>
            </div>

            <div className="giftCardInfo">
              {product.variations[0].shipping.available ? (
                <>
                  <div className="giftCardUserInfo">
                    <div className="giftCardUserInfoTitle">
                      {t('RECEIVER_INFO', 'Informações de quem vai receber')}
                    </div>
                    <div className="giftCardUserInfoContent">
                      {attachment.date ? (
                        <>
                          <div className="giftCardUserInfoEdit">
                            <div className="editText">
                              <div className="editTextTitle">
                                {t('WHO_IS_THE_RECEIVER', 'quem vai receber:')}
                              </div>
                              <div className="editTextContent">
                                {attachment.name}
                              </div>
                            </div>
                            <div className="editIcon">
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedEmail(false);
                                  setAttachment({
                                    name: attachment.name,
                                    email: attachment.email,
                                  });
                                }}
                              >
                                <EditIcon />
                              </button>
                            </div>
                          </div>
                          <div className="giftCardUserInfoEdit">
                            <div className="editText">
                              <div className="editTextTitle">
                                {t(
                                  'RECEIVER_EMAIL_INFO',
                                  'e-mail de quem vai receber:',
                                )}
                              </div>
                              <div className="editTextContent">
                                {attachment.email}
                              </div>
                            </div>
                            <div className="editIcon">
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedEmail(false);
                                  setAttachment({
                                    name: attachment.name,
                                    email: attachment.email,
                                  });
                                }}
                              >
                                <EditIcon />
                              </button>
                            </div>
                          </div>
                          <div className="checkEmail">
                            <CheckboxHandmade
                              checked={selectedEmail}
                              onChange={() => setSelectedEmail(!selectedEmail)}
                              size="small"
                            />
                            {t(
                              'CHECK_RECEIVER_EMAIL',
                              ' e-mail para envio do presenteado está correto',
                            )}
                          </div>
                        </>
                      ) : (
                        <form>
                          <Input
                            label={t(
                              'RECEIVER_NAME',
                              'quem vai receber o presente',
                            )}
                            disabled={order.loading}
                            onChange={(value: any) => {
                              setNameInputError('');
                              setReceiverName(value);
                            }}
                            value={receiverName}
                            error={nameInputError}
                          />
                          <Input
                            label={t(
                              'RECEIVER_EMAIL',
                              'e-mail de quem vai receber o presente',
                            )}
                            disabled={order.loading}
                            onChange={(value: any) => {
                              setEmailInputError('');
                              setReceiverEmail(value);
                            }}
                            value={receiverEmail}
                            error={emailInputError}
                          />
                        </form>
                      )}
                    </div>
                  </div>
                  <div className="divider" />
                  <div className="giftCardTimeInfo">
                    <div className="giftCardTimeInfoTitle">
                      {t('SEND_DATE', 'Quando você quer enviar o presente?')}
                    </div>
                    {attachment.date ? (
                      <div className="giftCardTimeInfoEdit">
                        <div className="editText">
                          <div className="editTextTitle">envio</div>
                          <div className="editTextContent">
                            {isToday(attachment.date) && <>hoje </>}
                            {format(
                              parseJSON(Date.parse(attachment.date.toString())),
                              'EEEE',
                              {
                                locale: dateLanguagePack,
                              },
                            ).slice(0, 3)}
                            , {getDate(parseJSON(attachment.date))} de{' '}
                            {format(
                              parseJSON(Date.parse(attachment.date.toString())),
                              'MMMM',
                              {
                                locale: dateLanguagePack,
                              },
                            )[0].toUpperCase() +
                              format(
                                parseJSON(
                                  Date.parse(attachment.date.toString()),
                                ),
                                'MMMM',
                                {
                                  locale: dateLanguagePack,
                                },
                              ).substring(1)}{' '}
                            {format(parseJSON(attachment.date), 'yyyy', {
                              locale: dateLanguagePack,
                            })}{' '}
                            {!isToday(attachment.date) && (
                              <>
                                às{' '}
                                {format(
                                  parseJSON(
                                    Date.parse(attachment.date.toString()),
                                  ),
                                  'HH',
                                  {
                                    locale: dateLanguagePack,
                                  },
                                )}
                                :
                                {format(
                                  parseJSON(
                                    Date.parse(attachment.date.toString()),
                                  ),
                                  'mm',
                                  {
                                    locale: dateLanguagePack,
                                  },
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="editIcon">
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedEmail(false);
                              setAttachment({
                                name: attachment.name,
                                email: attachment.email,
                              });
                            }}
                          >
                            <EditIcon />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="giftCardTimeInfoContent">
                        <button
                          type="button"
                          onClick={() => {
                            handleAttachments(new Date());
                          }}
                        >
                          <p>{t('NOW', 'AGORA')}</p>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            scheduleModalRef.current?.show();
                          }}
                        >
                          <p>{t('TO_SCHEDULE', 'AGENDAR')}</p>
                        </button>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className="unavailableProduct">
                  <h2>{t('UNAVAILABLE_PRODUCT', 'PRODUTO INDISPONÍVEL')}</h2>
                </div>
              )}
            </div>
          </div>
        </div>

        <Footer>
          <Button
            className="action"
            startIcon={<MdAdd className="addButton" />}
            onClick={() => {
              if (!selectedEmail) {
                toast.info(
                  t(
                    'EMAIL_CONFIRMATION',
                    'Confirme o e-mail de quem vai receber',
                  ),
                );
              } else {
                product.addToCart({
                  packageTypeCode: 'DELIVERY',
                  initialQuantity: productQuantity,
                  attachments: {
                    name: attachment.name ? attachment.name : '',
                    email: attachment.email ? attachment.email : '',
                    date: attachment.date ? attachment.date : new Date(),
                  },
                });
              }
            }}
            disabled={
              product.loading ||
              !product.selectedVariationId ||
              !attachment.date
            }
            color="success"
          >
            <p className="addButton">{t('ADD_PRODUCT', 'ADICIONAR PRODUTO')}</p>
          </Button>
        </Footer>
      </Content>
      {modals}
    </Container>
  );
};

export { VariationsScreenGiftCard };
