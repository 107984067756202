import React from 'react';
import { useParams } from 'react-router-dom';
import { MdPersonOutline } from 'react-icons/md';

// Hook import
import { useTheme } from '@hooks/useTheme';
import { useTranslation } from '@hooks/useTranslation';
import { useOrder } from '@hooks/useOrder';

// Util import
import { getPhoneFormat, getCPFFormat } from '@util/getDocumentFormat';

import { Container } from './styles';

// Feature identification
const featureKey = '@order/CUSTOMER_METADATA';

const CustomerInfo: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<{ id?: string }>();
  const order = useOrder(id);
  const { t } = useTranslation(featureKey);

  return (
    <>
      {!!order?.data?.order?.customer_document &&
        !!order?.data?.order?.customer_name &&
        !!order?.data?.order?.customer_email &&
        !!order?.data?.order?.customer_phone && (
          <Container>
            <div className="icon">
              <MdPersonOutline color={theme.font_secondary} size={25} />
            </div>
            <div>
              <span>{t('CLIENT', 'Cliente:')} </span>
              <span>
                {order?.data?.order?.customer_name}{' '}
                {order?.data?.order?.customer_surname}
              </span>
            </div>
            <div>
              <span>{t('DOCUMENT', 'CPF:')} </span>
              <span>{getCPFFormat(order?.data?.order?.customer_document)}</span>
            </div>
            <div>
              <span>{t('EMAIL', 'E-mail: ')}</span>
              <span>{order?.data?.order?.customer_email}</span>
            </div>
            <div>
              <span>{t('PHONE_NUMBER', 'Telefone: ')}</span>
              <span>{getPhoneFormat(order?.data?.order?.customer_phone)}</span>
            </div>
          </Container>
        )}
    </>
  );
};

export { CustomerInfo };
