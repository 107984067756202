import React from 'react';

// Component import
import { IModalRef } from '@components/Modal';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Util import
import { getCEPFormat } from '@util/getDocumentFormat';

// Feature identification
const featureKey = '@create_order/CART_DELIVERY';

interface IProps {
  addressSelectorModalRef: React.RefObject<IModalRef>;
}

const CartAddress: React.FC<IProps> = ({ addressSelectorModalRef }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();

  return (
    <div className="addressSection">
      <div className="addressTitle">
        <p>{t('DELIVERY_TO', 'Entrega para:')}</p>
        <div className="editButton">
          <button
            type="button"
            onClick={() => addressSelectorModalRef.current?.show()}
          >
            <p>{t('TO_EDIT', 'Editar')}</p>
          </button>
        </div>
      </div>
      <div className="addressContent">
        <div className="addressContentText">
          {order.address.street}, {order.address.number}
          {' - '}
          {order.address.complement}
          {' - '}
          {order.address.city}/{order.address.state}
          {' - '}
          {getCEPFormat(order.address.zipcode)}
        </div>
      </div>
      <div className="editButtonMobile">
        <button
          type="button"
          onClick={() => addressSelectorModalRef.current?.show()}
        >
          <p>{t('TO_EDIT', 'Editar')}</p>
        </button>
      </div>
    </div>
  );
};

export { CartAddress };
