import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';

// Setup reactotron
import './config/reactotron';

// Setup i18n
import './i18n';

// Config import
import { toastConfig } from './config/toast';
// Store import
import { store } from './store';
// Route import
import { Routes } from './routes';

// Theme import
import { lightTheme } from './styles/themes/light';
import { GlobalStyle } from './styles/global';

const App: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <ToastContainer {...toastConfig} />
      <GlobalStyle />
      <ThemeProvider theme={lightTheme}>
        <Routes />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);

export { App };
