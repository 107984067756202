import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-self: stretch;
  flex: 1;

  gap: 2rem;
  padding: 2rem;

  @media screen and (max-width: 425px) {
    padding: 1rem;
  }

  .header {
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    gap: 1.4rem;
    font-weight: 600;
    border: none;
    color: ${({ theme }) => theme.font_primary};

    p {
      font-size: 1rem;
    }
  }
`;

export const ProductListContainer = styled.ul<{ loading?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  align-self: stretch;
  gap: 1rem;
  margin-top: 1rem;
  opacity: ${({ loading }) => (loading ? 0.75 : 1)};

  li {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-self: stretch;

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      align-self: stretch;
      background: ${({ theme }) => theme.background_primary};
      border-radius: 0.5rem;
      transition: filter 0.5s;
      text-align: left;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.25;
      }

      &:hover {
        filter: brightness(1.5);
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        flex: 1;
        gap: 0.5rem;
        padding: 1rem 0rem 1rem 1rem;
        overflow: hidden;

        .top {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 0.25rem;

          .name {
            font-size: 1rem;
            color: ${({ theme }) => theme.font_primary};
            font-weight: 600;
          }

          .code {
            font-size: 0.75rem;
            color: ${({ theme }) => theme.font_secondary};
          }

          .price {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;

            font-size: 0.75rem;
            color: ${({ theme }) => theme.font_primary};

            .original_price {
              text-decoration: line-through;
            }
          }
        }
      }

      img {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }

      .image {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: stretch;
        align-self: stretch;
        height: 12rem;
        width: 9rem;
        /** 16:12 */
      }
    }
  }

  @media screen and (max-width: 1800px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    li {
      .image {
        max-width: 6rem;
      }
      img {
        max-width: 6rem;
      }
    }
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
