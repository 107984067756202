import React, { useState, useEffect } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { Locale, getDate, parseJSON, format } from 'date-fns';
import { useSelector } from 'react-redux';
import ptBR from 'date-fns/esm/locale/pt-BR/index.js';

// Asset import
import DefaultProductImage from '@assets/defaultProductImage.png';

// Component import
import { Button } from '@components/Button';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTheme } from '@hooks/useTheme';
import { useWindowSize } from '@hooks/useWindowSize';
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { IItem } from '../../../../../hooks/useOrderCreation/models';

import { GiftCard } from './styles';

// Feature identification
const featureKey = '@create_order/GIFT_CART_ITEM';

interface IProps {
  handleAdd: (code: any, packageType: any) => void;
  handleRemove: (code: any, packageType: any) => void;
  item: IItem;
}

const GiftCardItem: React.FC<IProps> = ({ handleAdd, handleRemove, item }) => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const order = useOrderCreation();
  const [width] = useWindowSize();

  // Global states
  const language = useSelector(state => state.ui.language);

  // Local states
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);

  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  return (
    <GiftCard key={item.code}>
      <div className="giftContent">
        <div className="image">
          {item.image_url ? (
            <img src={item.image_url} alt={item.name} />
          ) : (
            <img src={DefaultProductImage} alt={item.name} />
          )}
        </div>
        <div className="info">
          <div className="name">
            <p>{item.name}</p>
          </div>
          {!item.available || !item.shipping.available ? (
            <div className="unavailablePill">
              <p>{t('UNAVAILABLE', 'Indisponível')}</p>
            </div>
          ) : (
            <>
              <div className="price">
                <span className="current_price">
                  R$ {(item.total_current_price / 100).toFixed(2)}
                </span>

                {width > 768 && (
                  <div className="quantityContent">
                    <p>{t('QUANTITY', 'Quantidade:')}</p>
                    <div className="quantity">
                      <Button noFullWidth noMargin disabled={order.loading}>
                        <MdRemove
                          color={theme.font_low}
                          size={20}
                          onClick={() =>
                            handleRemove(item.code, item.shipping.selected_type)
                          }
                        />
                      </Button>
                      <span className="number">{item.quantity}</span>
                      <Button noFullWidth noMargin disabled={order.loading}>
                        <MdAdd
                          color={theme.font_low}
                          size={20}
                          onClick={() =>
                            handleAdd(item.code, item.shipping.selected_type)
                          }
                        />
                      </Button>
                    </div>
                  </div>
                )}
              </div>

              <div className="giftInfoRow">
                <div className="giftInfoTitle">
                  {t('RECEIVER_EMAIL', 'e-mail de quem vai receber:')}
                </div>
                <div className="giftInfoContent">{item.attachments?.email}</div>
              </div>
              <div className="giftInfoRow">
                <div className="giftInfoTitle">
                  {t('SHIPPING_TYPE', 'tipo de envio')}
                </div>
                <div className="giftInfoContent">
                  {item.attachments && item.attachments.date && (
                    <>
                      {format(
                        parseJSON(Date.parse(item.attachments.date.toString())),
                        'EEEE',
                        {
                          locale: dateLanguagePack,
                        },
                      ).slice(0, 3)}
                      , {getDate(parseJSON(item.attachments.date))} de{' '}
                      {format(
                        parseJSON(Date.parse(item.attachments.date.toString())),
                        'MMMM',
                        {
                          locale: dateLanguagePack,
                        },
                      )[0].toUpperCase() +
                        format(
                          parseJSON(
                            Date.parse(item.attachments.date.toString()),
                          ),
                          'MMMM',
                          {
                            locale: dateLanguagePack,
                          },
                        ).substring(1)}{' '}
                      {format(parseJSON(item.attachments.date), 'yyyy', {
                        locale: dateLanguagePack,
                      })}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {width <= 768 && (
        <div className="mobileQuantityRow">
          <p>{t('QUANTITY_IN_BAG', 'Quantidade na sacola:')}</p>
          <div className="quantity">
            <Button noFullWidth noMargin disabled={order.loading}>
              <MdRemove
                color={theme.font_low}
                size={20}
                onClick={() =>
                  handleRemove(item.code, item.shipping.selected_type)
                }
              />
            </Button>
            <span className="number">{item.quantity}</span>
            <Button noFullWidth noMargin disabled={order.loading}>
              <MdAdd
                color={theme.font_low}
                size={20}
                onClick={() =>
                  handleAdd(item.code, item.shipping.selected_type)
                }
              />
            </Button>
          </div>
        </div>
      )}
    </GiftCard>
  );
};

export { GiftCardItem };
