import React, { useRef, useEffect } from 'react';

function useForwardRef<T>(forwardRef: React.ForwardedRef<T>, content: T) {
  // Local refs
  const ref = useRef<T>(null);

  // Assign items to forwardRef
  useEffect(() => {
    if (forwardRef) {
      if (typeof forwardRef === 'function') forwardRef(content);
      else forwardRef.current = content;
    }
  }, [content, forwardRef]);

  return ref;
}

export { useForwardRef };
