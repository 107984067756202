import React from 'react';

// Component import
import { CartPricingSummary } from '@components/CartPricingSummary';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Feature identification
const featureKey = '@create_order/CART_PAYMENT';

interface IProps {
  handleCouponClick: () => void;
}

const PaymentInfo: React.FC<IProps> = ({ handleCouponClick }) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <div className="paymentInfoSection">
      <div className="paymentInfoTitle">
        <p>{t('ORDER_TOTAL', 'Total do Pedido:')}</p>
      </div>
      <div className="onboardingCartPricingSummary">
        <CartPricingSummary step={4} handleCouponClick={handleCouponClick} />
      </div>
    </div>
  );
};

export { PaymentInfo };
