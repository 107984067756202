import React, { useCallback } from 'react';
import {
  MdAdd,
  MdRemove,
  MdOutlineModeEdit,
  MdOutlineShoppingCart,
  MdOutlineShoppingBag,
} from 'react-icons/md';
import { toast } from 'react-toastify';

// Asset import
import DefaultProductImage from '@assets/defaultProductImage.png';

// Component import
import { Button } from '@components/Button';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTheme } from '@hooks/useTheme';
import { useWindowSize } from '@hooks/useWindowSize';
import { useAddProduct } from '@hooks/useAddProduct';
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Util import
import { giftCardReferences } from '@util/giftCardReferences';

import { GiftCardItem } from './GiftCardItem';
// Model import
import { IItem } from '../../../../hooks/useOrderCreation/models';

// Style import
import {
  Container,
  ProductItemDesktop,
  ProductItemMobile,
  ProductSectionDesktop,
  ProductSectionMobile,
} from './styles';

// Feature identification
const featureKey = '@create_order/CART_LIST';

const CartList: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const product = useAddProduct();
  const order = useOrderCreation();
  const [width] = useWindowSize();

  // Handle add
  const handleAdd = useCallback(
    (code, packageType) => {
      // Register temp in memory
      const { items } = order;

      // Find item in order
      const find = items.findIndex(
        item =>
          item.code === code && item.shipping.selected_type === packageType,
      );

      // If item not found
      if (find < 0) {
        const error = t('PRODUCT_NOT_FOUND', 'Produto não encontrado.');
        toast.error(error);
        return;
      }

      // Mount
      items[find].quantity += 1;

      // Update
      order.setItems(items).catch(() => null);
    },
    [order, t],
  );

  const handleEditingPackageType = (item: IItem) => {
    if (item.shipping.selected_type === 'DELIVERY') return 'DELIVERY';
    return 'OWN_STOCK';
  };

  // Handle remove
  const handleRemove = useCallback(
    (code, packageType) => {
      // Register temp in memory
      const { items } = order;

      // Find item in order
      const find = items.findIndex(
        item =>
          item.code === code && item.shipping.selected_type === packageType,
      );

      // If item not found
      if (find < 0) {
        const error = t('PRODUCT_NOT_FOUND', 'Produto não encontrado.');
        toast.error(error);
        return;
      }

      // Mount
      items[find].quantity -= 1;

      // Check
      if (items[find].quantity <= 0) {
        items.splice(find, 1);
      }

      // Update
      order.setItems(items).catch(() => null);
    },
    [order, t],
  );

  const editProduct = useCallback(
    async (item: IItem) => {
      // Clear product variations
      product.resetVariations();

      // Get reference, brandcode and color from data
      const { reference, brand_code, color, ean } = item;

      // Mount reference
      const code = `${reference?.trim()}_${color?.trim()}`;

      // Request product information to backend
      try {
        await product.getProductToEdit({
          code,
          brand_code,
          ean,
        });

        const editingPackageInfo = handleEditingPackageType(item);
        product.setCurrentEditingPackageType(editingPackageInfo);
      } catch (err) {}

      order.setEditingProduct(true);
    },
    [order, product],
  );

  const ownStockItems = order.items.filter(
    item => item.shipping.selected_type === 'OWN_STOCK',
  );

  const deliveryItems = order.items.filter(
    item => item.shipping.selected_type === 'DELIVERY',
  );

  const findDeliveryOptionsInItem = (item: IItem) => {
    const findDeliveryOption = item.shipping.options.filter(
      _option => _option.type === item.shipping.selected_type,
    );
    return findDeliveryOption.length;
  };

  return (
    <Container>
      {width > 768 ? (
        <>
          {ownStockItems.length !== 0 && (
            <ProductSectionDesktop>
              <div className="productSectionTitle">
                <MdOutlineShoppingBag />
                <p>{t('OWN_STOCK', 'Levar Agora')}</p>
              </div>

              <div className="productItemRow">
                {ownStockItems.map((item, index) => (
                  <ProductItemDesktop
                    key={item.code}
                    className={index === 0 ? 'onboardItem' : ' '}
                  >
                    <div className="image">
                      {item.image_url ? (
                        <img src={item.image_url} alt={item.name} />
                      ) : (
                        <img src={DefaultProductImage} alt={item.name} />
                      )}
                    </div>
                    <div className="info">
                      <div className="name">
                        <p>{item.name}</p>
                      </div>
                      <div className="infoRow">
                        <p>
                          {t('BRAND', 'Marca:')} {item.brand?.name}
                        </p>
                        <p>
                          {t('REFERENCE', 'Ref.:')} {item.reference}
                        </p>
                      </div>

                      <div className="colorAndSizeRow">
                        <p>
                          {t('COLOR', 'Cor:')} {item.color}
                        </p>
                        <button
                          type="button"
                          onClick={() => {
                            editProduct(item);
                          }}
                        >
                          <p className="changeButtonText">
                            {t('SIZE', 'Tamanho')} {item.size}
                          </p>

                          <MdOutlineModeEdit color={theme.font_primary} />
                        </button>
                      </div>
                      <div className="price">
                        {item.total_original_price !==
                          item.total_current_price && (
                          <span className="original_price">
                            R$ {(item.total_original_price / 100).toFixed(2)}
                          </span>
                        )}
                        <span className="current_price">
                          R$ {(item.total_current_price / 100).toFixed(2)}
                        </span>
                      </div>

                      {item.shipping.available === false ? (
                        <div className="unavailablePill">
                          <p>{t('UNAVAILABLE', 'Indisponível')}</p>
                        </div>
                      ) : (
                        <>
                          <div className="editDelivery">
                            <button
                              type="button"
                              onClick={() => editProduct(item)}
                            >
                              <p>{t('CHANGE_DELIVERY', 'Alterar entrega')}</p>
                            </button>
                          </div>

                          <div className="quantityRow">
                            <p>Quantidade na sacola:</p>
                            <div className="quantity">
                              <Button
                                noFullWidth
                                noMargin
                                disabled={order.loading}
                              >
                                <MdRemove
                                  color={theme.font_low}
                                  size={20}
                                  onClick={() =>
                                    handleRemove(
                                      item.code,
                                      item.shipping.selected_type,
                                    )
                                  }
                                />
                              </Button>
                              <span className="number">{item.quantity}</span>
                              <Button
                                noFullWidth
                                noMargin
                                disabled={order.loading}
                              >
                                <MdAdd
                                  color={theme.font_low}
                                  size={20}
                                  onClick={() =>
                                    handleAdd(
                                      item.code,
                                      item.shipping.selected_type,
                                    )
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </ProductItemDesktop>
                ))}
              </div>

              <div className="divider" />
            </ProductSectionDesktop>
          )}
          {deliveryItems.length !== 0 && (
            <ProductSectionDesktop>
              <div className="productSectionTitle">
                <MdOutlineShoppingCart />
                <p>{t('INFINITE_AISLE', 'Receber em Casa')}</p>
              </div>

              <div className="productItemRow">
                {deliveryItems.map(item => (
                  <>
                    {item.reference &&
                    giftCardReferences.includes(item.reference) ? (
                      <GiftCardItem
                        item={item}
                        handleAdd={handleAdd}
                        handleRemove={handleRemove}
                      />
                    ) : (
                      <ProductItemDesktop key={item.code}>
                        <div className="image">
                          {item.image_url ? (
                            <img src={item.image_url} alt={item.name} />
                          ) : (
                            <img src={DefaultProductImage} alt={item.name} />
                          )}
                        </div>
                        <div className="info">
                          <div className="name">
                            <p>{item.name}</p>
                          </div>
                          <div className="infoRow">
                            <p>
                              {t('BRAND', 'Marca:')} {item.brand?.name}
                            </p>
                            <p>
                              {t('REFERENCE', 'Ref.:')} {item.reference}
                            </p>
                          </div>

                          <div className="colorAndSizeRow">
                            <p>
                              {t('COLOR', 'Cor:')} {item.color}
                            </p>
                            <button
                              type="button"
                              onClick={() => {
                                editProduct(item);
                              }}
                            >
                              <p className="changeButtonText">
                                {t('SIZE', 'Tamanho')} {item.size}
                              </p>

                              <MdOutlineModeEdit color={theme.font_primary} />
                            </button>
                          </div>
                          <div className="price">
                            {item.total_original_price !==
                              item.total_current_price && (
                              <span className="original_price">
                                R${' '}
                                {(item.total_original_price / 100).toFixed(2)}
                              </span>
                            )}
                            <span className="current_price">
                              R$ {(item.total_current_price / 100).toFixed(2)}
                            </span>
                          </div>

                          {item.shipping.available === false ? (
                            <div className="unavailablePill">
                              <p>{t('UNAVAILABLE', 'Indisponível')}</p>
                            </div>
                          ) : (
                            <>
                              <div className="editDelivery">
                                <button
                                  type="button"
                                  onClick={() => editProduct(item)}
                                >
                                  <p>
                                    {t('CHANGE_DELIVERY', 'Alterar entrega')}
                                  </p>
                                </button>
                              </div>

                              <div className="quantityRow">
                                <p>
                                  {t(
                                    'QUANTITY_IN_BAG',
                                    'Quantidade na sacola:',
                                  )}
                                </p>
                                <div className="quantity">
                                  <Button
                                    noFullWidth
                                    noMargin
                                    disabled={order.loading}
                                  >
                                    <MdRemove
                                      color={theme.font_low}
                                      size={20}
                                      onClick={() =>
                                        handleRemove(
                                          item.code,
                                          item.shipping.selected_type,
                                        )
                                      }
                                    />
                                  </Button>
                                  <span className="number">
                                    {item.quantity}
                                  </span>
                                  <Button
                                    noFullWidth
                                    noMargin
                                    disabled={order.loading}
                                  >
                                    <MdAdd
                                      color={theme.font_low}
                                      size={20}
                                      onClick={() =>
                                        handleAdd(
                                          item.code,
                                          item.shipping.selected_type,
                                        )
                                      }
                                    />
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </ProductItemDesktop>
                    )}
                  </>
                ))}
              </div>

              <div className="divider" />
            </ProductSectionDesktop>
          )}
        </>
      ) : (
        <>
          {ownStockItems.length !== 0 && (
            <ProductSectionMobile>
              <div className="productSectionTitle">
                <MdOutlineShoppingBag />
                <p>{t('OWN_STOCK', 'Levar Agora')}</p>
              </div>

              <div className="productItemRow">
                {ownStockItems.map((item, index) => (
                  <ProductItemMobile
                    key={item.code}
                    className={index === 0 ? 'onboardItem' : ' '}
                  >
                    <div className="topRow">
                      <div className="image">
                        {item.image_url ? (
                          <img src={item.image_url} alt={item.name} />
                        ) : (
                          <img src={DefaultProductImage} alt={item.name} />
                        )}
                      </div>
                      <div className="info">
                        <div className="name">
                          <p>{item.name}</p>
                        </div>
                        <div className="infoRow">
                          <p>
                            {t('BRAND', 'Marca:')} {item.brand?.name}
                          </p>
                        </div>

                        <div className="colorAndSizeRow">
                          <p>
                            {t('REFERENCE', 'Ref.:')} {item.reference}
                          </p>
                          <p>
                            {t('COLOR', 'Cor:')} {item.color}
                          </p>
                        </div>

                        <div className="editDelivery">
                          <button
                            type="button"
                            onClick={() => {
                              editProduct(item);
                            }}
                          >
                            <p className="changeButtonText">
                              {t('SIZE', 'Tam')} {item.size}
                            </p>
                          </button>
                          <button
                            type="button"
                            onClick={() => editProduct(item)}
                          >
                            <p>{t('CHANGE_DELIVERY', 'Alterar entrega')}</p>
                          </button>
                        </div>
                        <div className="price">
                          {item.total_original_price !==
                            item.total_current_price && (
                            <span className="original_price">
                              R$ {(item.total_original_price / 100).toFixed(2)}
                            </span>
                          )}
                          <span className="current_price">
                            R$ {(item.total_current_price / 100).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="bottomRow">
                      {item.shipping.available === false ? (
                        <div className="unavailablePill">
                          <p>{t('UNAVAILABLE', 'Indisponível')}</p>
                        </div>
                      ) : (
                        <>
                          <div className="quantityRow">
                            <p>Quantidade na sacola:</p>
                            <div className="quantity">
                              <Button
                                noFullWidth
                                noMargin
                                disabled={order.loading}
                              >
                                <MdRemove
                                  color={theme.font_low}
                                  size={20}
                                  onClick={() =>
                                    handleRemove(
                                      item.code,
                                      item.shipping.selected_type,
                                    )
                                  }
                                />
                              </Button>
                              <span className="number">{item.quantity}</span>
                              <Button
                                noFullWidth
                                noMargin
                                disabled={order.loading}
                              >
                                <MdAdd
                                  color={theme.font_low}
                                  size={20}
                                  onClick={() =>
                                    handleAdd(
                                      item.code,
                                      item.shipping.selected_type,
                                    )
                                  }
                                />
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </ProductItemMobile>
                ))}
              </div>

              <div className="divider" />
            </ProductSectionMobile>
          )}
          {deliveryItems.length !== 0 && (
            <ProductSectionMobile>
              <div className="productSectionTitle">
                <MdOutlineShoppingCart />
                <p>{t('INFINITE_AISLE', 'Receber em Casa')}</p>
              </div>

              <div className="productItemRow">
                {deliveryItems.map(item => (
                  <>
                    {item.reference &&
                    giftCardReferences.includes(item.reference) ? (
                      <GiftCardItem
                        item={item}
                        handleAdd={handleAdd}
                        handleRemove={handleRemove}
                      />
                    ) : (
                      <ProductItemMobile key={item.code}>
                        <div className="topRow">
                          <div className="image">
                            {item.image_url ? (
                              <img src={item.image_url} alt={item.name} />
                            ) : (
                              <img src={DefaultProductImage} alt={item.name} />
                            )}
                          </div>
                          <div className="info">
                            <div className="name">
                              <p>{item.name}</p>
                            </div>
                            <div className="infoRow">
                              <p>
                                {t('BRAND', 'Marca:')} {item.brand?.name}
                              </p>
                            </div>

                            <div className="colorAndSizeRow">
                              <p>
                                {t('REFERENCE', 'Ref.:')} {item.reference}
                              </p>
                              <p>
                                {t('COLOR', 'Cor:')} {item.color}
                              </p>
                            </div>

                            <div className="editDelivery">
                              <button
                                type="button"
                                onClick={() => {
                                  editProduct(item);
                                }}
                              >
                                <p className="changeButtonText">
                                  {t('SIZE', 'Tam')} {item.size}
                                </p>
                              </button>
                              <button
                                type="button"
                                onClick={() => editProduct(item)}
                              >
                                <p>{t('CHANGE_DELIVERY', 'Alterar entrega')}</p>
                              </button>
                            </div>
                            <div className="price">
                              {item.total_original_price !==
                                item.total_current_price && (
                                <span className="original_price">
                                  R${' '}
                                  {(item.total_original_price / 100).toFixed(2)}
                                </span>
                              )}
                              <span className="current_price">
                                R$ {(item.total_current_price / 100).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="bottomRow">
                          {!item.available ||
                          !findDeliveryOptionsInItem(item) ? (
                            <div className="unavailablePill">
                              <p>{t('UNAVAILABLE', 'Indisponível')}</p>
                            </div>
                          ) : (
                            <>
                              <div className="quantityRow">
                                <p>
                                  {t(
                                    'QUANTITY_IN_BAG',
                                    'Quantidade na sacola:',
                                  )}
                                </p>
                                <div className="quantity">
                                  <Button
                                    noFullWidth
                                    noMargin
                                    disabled={order.loading}
                                  >
                                    <MdRemove
                                      color={theme.font_low}
                                      size={20}
                                      onClick={() =>
                                        handleRemove(
                                          item.code,
                                          item.shipping.selected_type,
                                        )
                                      }
                                    />
                                  </Button>
                                  <span className="number">
                                    {item.quantity}
                                  </span>
                                  <Button
                                    noFullWidth
                                    noMargin
                                    disabled={order.loading}
                                  >
                                    <MdAdd
                                      color={theme.font_low}
                                      size={20}
                                      onClick={() =>
                                        handleAdd(
                                          item.code,
                                          item.shipping.selected_type,
                                        )
                                      }
                                    />
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </ProductItemMobile>
                    )}
                  </>
                ))}
              </div>

              <div className="divider" />
            </ProductSectionMobile>
          )}
        </>
      )}
    </Container>
  );
};

export { CartList };
