// Action types
export const AuthActionTypes = {
  RESUME_SESSION_REQUEST: '@auth/RESUME_SESSION_REQUEST',
  SESSION_REFRESH_REQUEST: '@auth/SESSION_REFRESH_REQUEST',
  SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  SIGN_OUT: '@auth/SIGN_OUT',
  SIGN_FAILURE: '@auth/SIGN_FAILURE',
};

export interface IOrderBrandProviderOrderPackageDeliveryItem {
  id: string;
  order_brand_provider_order_package_id: string;
  code: string;
  quantity: number;
  ean: string;
  reference: string;
  color: string;
  size: string;
  name: string;
  image_url?: string;
  shipping_selected_id?: string;
  shipping_selected_name?: string;
  shipping_original_price?: number;
  shipping_current_price?: number;
  shipping_business_days_to_delivery?: number;
  unit_original_price: number;
  unit_current_price: number;
  total_original_price: number;
  total_current_price: number;
  payload: any;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderBrandProviderOrderPackage {
  id: string;
  order_brand_provider_order_id: string;
  items: IOrderBrandProviderOrderPackageDeliveryItem[];
  code: string;
  total_original_price: number;
  total_current_price: number;
  shipping_original_price: number;
  shipping_current_price: number;
  total: number;
  payload: any;
  canceled_at?: Date;
  completed_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderBrandProviderPackage {
  id: string;
  order_id: string;
  package_type_id: string;
  package_type: IOrderBrandProviderPackagePackageType;
  brand_id: string;
  brand: IBrand;
  brand_provider_id: string;
  brand_provider: IOrderBrandProviderPackageBrandProvider;
  items: IOrderBrandProviderPackageItems[];
  code: string;
  sale_user_result: string;
  total_original_price: number;
  total_current_price: number;
  shipping_selected_type: string;
  shipping_selected_id: string;
  shipping_selected_name: string;
  shipping_business_days_to_delivery: number;
  shipping_original_price: number;
  shipping_current_price: number;
  shipping_payload: any;
  total: number;
  payload: any;
  canceled_at?: Date;
  invoices?: IInvoices[];
  completed_at?: Date;
  invoiced_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderBrandProviderPackagePackageType {
  id: string;
  code: string;
  name: string;
  enabled: boolean;
}

export interface IOrderBrandProviderPackageBrandProvider {
  id: string;
  code: number;
  name: string;
  enabled: boolean;
  image_url: string;
  payload: IOrderBrandProviderPackageBrandProviderPayload;
  providers: IOrderBrandProviderPackageBrandProviderProviders[];
}

export interface IOrderBrandProviderPackageBrandProviderPayload {
  product_code_pattern: string;
}

export interface IOrderBrandProviderPackageBrandProviderProviders {
  id: string;
  brand_id: string;
  name: string;
  code: string;
  enabled: boolean;
}

export interface IOrderBrandProviderPackageItems {
  id: string;
  order_package_id: string;
  code: string;
  quantity: number;
  name: string;
  ean: string;
  reference: string;
  color: string;
  size: string;
  image_url: string;
  unit_original_price: number;
  unit_current_price: number;
  total_original_price: number;
  total_current_price: number;
  payload: IOrderBrandProviderPackageItemsPayload;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderBrandProviderPackageItemsPayload {
  ref_id: string;
  product_id: number;
  unique_code: string;
}

export interface IOrderBrandProviderOwnStockItem {
  id: string;
  order_id: string;
  brand_id: string;
  brand: IBrand;
  code: string;
  quantity: number;
  ean: string;
  reference: string;
  color: string;
  size: string;
  name: string;
  image_url?: string;
  unit_original_price: number;
  unit_current_price: number;
  total_original_price: number;
  total_current_price: number;
  payload: any;
  canceled_at?: Date;
  completed_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderPaymentCharge {
  id: string;
  order_payment_id: string;
  code: string;
  amount: number;
  paid_at: Date;
  payload: any;
  canceled_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IOrderPayment {
  id: string;
  order_id: string;
  payment_type_id: string;
  payment_type: {
    id: string;
    code: string;
    name: string;
    created_at: Date;
    updated_at: Date;
  };
  charges: IOrderPaymentCharge[];
  amount: number;
  code: string;
  transaction_id: string;
  payload: any;
  try_until: Date;
  canceled_at?: Date;
  completed_at?: Date;
  created_at: Date;
  updated_at: Date;
}

export interface IInvoices {
  canceled_at?: string;
  completed_at?: string;
  id?: string;
  key?: string;
  order_id?: string;
  payload?: {
    response?: {
      [key: string]: any;
    };
  };
}

export interface IOrder {
  order: {
    id: string;
    coupon?: string;
    invoices?: IInvoices[];
    store_id: string;
    store: IStore;
    payments: IOrderPayment[];
    packages: IOrderBrandProviderPackage[];
    own_stock_items: IOrderBrandProviderOwnStockItem[];
    sale_user_name: string;
    sale_user_code: string;
    customer_document: string;
    customer_name: string;
    customer_surname: string;
    customer_email: string;
    customer_phone: string;
    shipping_country: string;
    shipping_state: string;
    shipping_city: string;
    shipping_street: string;
    shipping_receiver: string;
    shipping_neighborhood: string;
    shipping_zipcode: string;
    shipping_number?: string;
    shipping_complement?: string;
    status?: 'WAIT_ADJUST' | 'CANCELED' | 'COMPLETED' | 'PENDING';
    total_original_price: number;
    total_current_price: number;
    shipping_original_price: number;
    shipping_current_price: number;
    total: number;
    created_at: Date;
    updated_at: Date;
  };
  status: {
    has_error?: boolean;
    is_paid: boolean;
    is_canceled: boolean;
    paid_amount: number;
    status: string;
  };
}

export interface INewFormatOrder {
  id: string;
  coupon?: string;
  store_id: string;
  status: 'WAIT_ADJUST' | 'CANCELED' | 'COMPLETED' | 'PENDING';
  user_id: string;
  user_provider_id: string;
  packages: IOrderBrandProviderPackage[];
  origin_code: string;
  sale_user_name: string;
  sale_user_code: string;
  customer_document: string;
  customer_name: string;
  customer_surname: string;
  customer_email: string;
  customer_phone: string;
  shipping_country: string;
  shipping_state: string;
  shipping_city: string;
  shipping_street: string;
  shipping_receiver: string;
  shipping_neighborhood: string;
  shipping_zipcode: string;
  shipping_number: string;
  shipping_complement: string;
  total_original_price: number;
  total_current_price: number;
  shipping_original_price: number;
  shipping_current_price: number;
  total: number;
  created_at: Date;
  updated_at: Date;
  payload?: any;
}

export interface IPaymentType {
  id: string;
  enabled: boolean;
  code: string;
  name: string;
}

export interface IPackageType {
  id: string;
  enabled: boolean;
  code: string;
  name: string;
}

export interface IProductProviders {
  brand_id: string;
  code: string;
  enabled: boolean;
  id: string;
}

export interface IBrand {
  id: string;
  enabled: boolean;
  code: string;
  name: string;
  payload: any;
  product_providers: IProductProviders[];
  image_url?: string;
}

export interface IStoreBrand {
  id: string;
  enabled: boolean;
  store_id: string;
  brand_id: string;
  brand: IBrand;
}

export interface IStorePackageType {
  id: string;
  enabled: boolean;
  store_id: string;
  package_type_id: string;
  package_type: IPackageType;
  modality?: IStorePackageTypeModality;
}

export interface IStorePackageTypeModality {
  delivery?: boolean;
  in_hands?: boolean;
}

export interface IStorePaymentType {
  id: string;
  enabled: boolean;
  store_id: string;
  payment_type_id: string;
  payment_type: IPaymentType;
}

export interface IStoreOpeningPeriod {
  id: string;
  store_id: string;
  description: string;
  enabled: boolean;
  /** start time of a date to be specific rule */
  specific_day?: Date | null;
  /** 0 | 1 | 2 | 3 | 4 | 5 | 6	-> the day of week, 0 represents Sunday */
  weekday?: number | null;
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
  timezone: string;
}

export interface IPrinters {
  regular?: string;
  thermal?: string;
}

export interface IStore {
  id: string;
  enabled: boolean;
  code: string;
  name: string;
  codename: string;
  zipcode: string;
  image_url?: string;
  brands: IStoreBrand[];
  package_types: IStorePackageType[];
  payment_types: IStorePaymentType[];
  opening_periods: IStoreOpeningPeriod[];
  printers: IPrinters;
}

export interface IUserSaleCodes {
  code: string;
  name?: string;
}

export interface ISessionData {
  login?: string;

  name?: string;
  email?: string;
  avatar_url?: string;
  type_profile?: any;
  profile_type?: string;

  store?: IStore;
  store_is_open?: boolean;

  sale_codes?: IUserSaleCodes[];

  token?: string;
  persist?: boolean;
}

export interface IAuthState extends ISessionData {
  updated: Date;
  signed: boolean;
  loading: boolean;
}
