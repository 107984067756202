import React from 'react';
import Radio from '@material-ui/core/Radio';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

// Model import
import {
  IPrinterList,
  ISelectedPrinters,
} from '@modules/PrinterConfiguration/models';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api } from '@services/api';

// Feature identification
const featureKey = '@component/PRINTER_CONFIG_LIST';

interface IProps {
  printerType: string;
  printerList?: IPrinterList[];
  selectedPrinters?: ISelectedPrinters;
  setSelectedPrinters: (printer: ISelectedPrinters) => void;
  menuOpen: boolean;
}

const ListComponent: React.FC<IProps> = ({
  printerType,
  printerList,
  selectedPrinters,
  setSelectedPrinters,
  menuOpen,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  // Global states
  const user = useSelector(state => state.auth);

  const areConfiguredPrintersUnavailable = () => {
    const userConfiguredPrinters = user.store?.printers;

    const isThermalPrinterInPrintersList = printerList?.find(
      item => item.name === userConfiguredPrinters?.thermal,
    );
    const isRegularPrinterInPrintersList = printerList?.find(
      item => item.name === userConfiguredPrinters?.regular,
    );

    const newUnavailableList = [];

    if (!isThermalPrinterInPrintersList && userConfiguredPrinters?.thermal) {
      newUnavailableList?.push({ name: userConfiguredPrinters?.thermal });
    }
    if (
      !isRegularPrinterInPrintersList &&
      userConfiguredPrinters?.regular &&
      userConfiguredPrinters?.regular !== userConfiguredPrinters?.thermal
    ) {
      newUnavailableList?.push({ name: userConfiguredPrinters?.regular });
    }
    return newUnavailableList;
  };
  const unavailablePrinters = areConfiguredPrintersUnavailable();

  const isItemSelected = (printerName: string) => {
    const currentSelectedPrinterInType =
      printerType === 'regular'
        ? selectedPrinters?.regular
        : selectedPrinters?.thermal;

    return printerName === currentSelectedPrinterInType;
  };

  const handleSelectPrinter = async (printerName: string) => {
    try {
      const requestParams = {
        ...selectedPrinters,
        [printerType]: printerName,
      };

      await api.patch('/store/printer', {
        printers: requestParams,
      });

      setSelectedPrinters({
        ...selectedPrinters,
        [printerType]: printerName,
      });

      toast.success(
        t('SUCCESS_PRINTER_CONFIG', 'Nova impressora habilitada com sucesso'),
      );
    } catch {
      toast.error(
        t('ERROR_PRINTER_CONFIG', 'Erro na configuração de impressora'),
      );
    }
  };

  return (
    <motion.div
      initial="closed"
      animate={menuOpen ? 'open' : 'closed'}
      variants={{
        open: { opacity: 1 },
        closed: { opacity: 0 },
      }}
    >
      <div className="listComponent">
        {printerList?.map(printer => (
          <li>
            <div className="itemButton">
              <Radio
                color="primary"
                checked={isItemSelected(printer.name)}
                onClick={() => handleSelectPrinter(printer.name)}
              />
            </div>
            <p className="printerName">{printer.name}</p>
          </li>
        ))}
        {unavailablePrinters.map(printer => (
          <li className="unavailableList">
            <div className="listItemInfo">
              <div className="itemButton">
                <Radio color="primary" disabled />
              </div>
              <p className="printerName">{printer.name}</p>
            </div>
            <div className="unavailableSubtitle">
              {t(
                'UNAVAILABLE_PRINTER',
                'Não foi possível estabelecer conexão com esta impressora',
              )}
            </div>
          </li>
        ))}
      </div>
    </motion.div>
  );
};

export { ListComponent };
