import React, {
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useState,
  SyntheticEvent,
} from 'react';
import {
  MdOutlineClose,
  MdWifi,
  MdOutlineLink,
  MdAttachMoney,
  MdOutlineAccountBalanceWallet,
} from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';

import { PixIcon } from '@assets/index';

// Component import
import { Button } from '@components/Button';
import { Modal, IModalRef } from '@components/Modal';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useForwardRef } from '@hooks/useForwardRef';
import { useOrder } from '@hooks/useOrder';
import { IPayment } from '@hooks/useOrderCreation/models';

import {
  MultiplePaymentContainer,
  UniquePaymentContainer,
  Container,
} from './styles';

// Interfaces
interface IPaymentDataModalRef {
  show: () => any;
  hide: () => any;
}

// Feature identification
const featureKey = '@order/PAYMENT_DATA_MODAL';

const PaymentDataModal = forwardRef<IPaymentDataModalRef>((_, forwardedRef) => {
  // const PaymentDataModal: React.FC = () => {]
  // Hooks
  const { t } = useTranslation(featureKey);
  const location = useLocation();
  const order = useOrder();

  const [expanded, setExpanded] = useState<string | false>('0');

  // Local refs
  const modalRef = useRef<IModalRef>(null);

  const show = useCallback(() => {
    modalRef.current?.show();
  }, []);
  const hide = useCallback(() => {
    modalRef.current?.hide();
  }, []);

  // Merge local ref with forward ref
  useForwardRef(forwardedRef, {
    show,
    hide,
  });

  const currentPayments = order.data?.order.payments.sort((a, b) => {
    if (a.payment_type.code === 'physical_card') return 1;
    if (b.payment_type.code === 'physical_card') return -1;
    return 0;
  });

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const filteredCurrentPayment = currentPayments?.filter(
    item => item.payment_type.code !== 'store_credit',
  )[0];

  // Local refs
  // const paymentDataModalRef = useRef<IModalRef>(null);

  useEffect(() => {
    const shouldShowQuery = location.pathname.includes('showPaymentData');

    if (shouldShowQuery) {
      modalRef.current?.show();
    }
  }, [location.pathname]);

  // const getPaymentTimeLimit = (paymentType: IPayment) => {
  //   const givenPaymentLimit = paymentType?.time_limit;

  //   if (givenPaymentLimit?.unit === 'minute') {
  //     if (givenPaymentLimit?.value === 1) {
  //       return `${givenPaymentLimit?.value} minuto`;
  //     }
  //     return `${givenPaymentLimit?.value} minutos`;
  //   }
  //   if (givenPaymentLimit?.unit === 'day') {
  //     if (givenPaymentLimit?.value === 1) {
  //       return `${givenPaymentLimit?.value} dia`;
  //     }
  //     return `${givenPaymentLimit?.value} dias`;
  //   }
  //   if (givenPaymentLimit?.value === 1) {
  //     return `${givenPaymentLimit?.value} hora`;
  //   }
  //   return `${givenPaymentLimit?.value} horas`;
  // };

  const getPaymentIcon = (paymentType: IPayment) => {
    switch (paymentType?.code) {
      case 'physical_card':
        return <MdWifi size={42} />;

      case 'payment_link':
        return <MdOutlineLink size={42} />;

      case 'store_credit':
        return <MdOutlineAccountBalanceWallet size={42} />;

      case 'pix':
        return <PixIcon width={42} height={42} />;

      default:
        return <MdAttachMoney size={42} />;
    }
  };

  const getPaymentTitle = (paymentType: IPayment) => {
    switch (paymentType.code) {
      case 'physical_card':
        return `${t('PHYSICAL_CARD_TITLE', 'POS')}`;

      case 'payment_link':
        return `${t('PAYMENT_LINK_TITLE', 'Link')}`;

      case 'store_credit':
        return `${t('STORE_CREDIT_TITLE', 'Saldo de créditos')}`;

      case 'pix':
        return `${t('PIX_TITLE', 'PIX')}`;
      default:
        return '';
    }
  };

  // const getPaymentText = (paymentType: IPayment) => {
  //   switch (paymentType?.code) {
  //     case 'physical_card':
  //       return `${t(
  //         'PHYSICAL_CARD_NOTICE',
  //         `Atenção!\nApós a confirmação, continue o processo clicando na aba Pagamentos na máquina de POS. Você terá ${getPaymentTimeLimit(
  //           paymentType,
  //         )} para concluir a operação de pagamento.`,
  //       )}`;

  //     case 'payment_link':
  //       return `${t(
  //         'PAYMENT_LINK_NOTICE',
  //         `Atenção! Após a confirmação, envie o Link de Pagamento para sua cliente. Ele é válido por ${getPaymentTimeLimit(
  //           paymentType,
  //         )}.`,
  //       )}`;
  //     case 'store_credit':
  //       return '';
  //     case 'pix':
  //       return `${t(
  //         'PIX_NOTICE',
  //         `Atenção! Após a confirmação, será gerado o código para pagamento com prazo de expiração de ${getPaymentTimeLimit(
  //           paymentType,
  //         )}.`,
  //       )}`;
  //     default:
  //       return `${t(
  //         'PHYSICAL_CARD_NOTICE',
  //         `Atenção!\nApós a confirmação, continue o processo clicando na aba Pagamentos na máquina de POS. Você terá ${getPaymentTimeLimit(
  //           paymentType,
  //         )} para concluir a operação de pagamento.`,
  //       )}`;
  //   }
  // };

  return (
    <Modal ref={modalRef} size="small">
      <Container>
        {order.loading ? (
          <div className="loadingContent">
            <LoadingIndicator />
          </div>
        ) : (
          <>
            {currentPayments &&
            currentPayments.length &&
            !currentPayments.some(
              item => item.payment_type.code === 'store_credit',
            ) &&
            currentPayments.length > 1 ? (
              <MultiplePaymentContainer>
                <div className="closeRow">
                  <button
                    type="button"
                    onClick={() => {
                      // Hide modal
                      modalRef.current?.hide();
                    }}
                  >
                    <MdOutlineClose className="closeIcon" />
                  </button>
                </div>

                {currentPayments.map((paymentType, index) => (
                  <Accordion
                    expanded={expanded === index.toString()}
                    onChange={handleChange(index.toString())}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ fontSize: '2rem' }} />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <div className="accordionTitle">
                        {getPaymentIcon(paymentType.payment_type)}
                        <div className="titleText">
                          {getPaymentTitle(paymentType.payment_type)}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="paymentContent">
                        <div className="titleRow">Aguardando Pagamento</div>
                        {paymentType.payment_type.code === 'pix' && (
                          <>
                            <div className="infoText">
                              Peça para sua cliente escanear o QR code abaixo
                              ou, em caso de venda remota, copie e compartilhe o
                              link do código com ela.
                            </div>
                            <div className="qrArea">
                              {paymentType.payload?.pix_qr_code_url ? (
                                <img
                                  src={paymentType.payload?.pix_qr_code_url}
                                  alt={t('QRCODE_ALT', 'QR Code')}
                                />
                              ) : (
                                <p>
                                  {t(
                                    'QRCODE_NOT_AVAILABLE',
                                    'QR Code indisponível',
                                  )}
                                </p>
                              )}
                            </div>
                            <div className="actionArea">
                              <div className="buttonsSection">
                                <div className="code">
                                  <div className="text">
                                    <input
                                      value={paymentType.payload?.pix_code}
                                    />
                                  </div>
                                  <div className="button">
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        try {
                                          navigator.clipboard.writeText(
                                            paymentType.payload?.pix_code,
                                          );
                                          toast.success(
                                            t(
                                              'PIX_COPY_SUCCESS',
                                              'Pix copiado para a área de transferência.',
                                            ),
                                          );
                                        } catch (err) {
                                          toast.error(
                                            t(
                                              'PIX_CODE_COPY_FAILURE',
                                              'Não foi possível copiar! Copie o código manualmente.',
                                            ),
                                          );
                                        }
                                      }}
                                    >
                                      {t('PIX_COPY', 'COPIAR')}
                                    </Button>
                                  </div>
                                </div>
                                <div className="share">
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      if (navigator.share) {
                                        navigator.share({
                                          title: t(
                                            'SHARE_PIX_CODE',
                                            'Compartilhar código PIX',
                                          ),
                                          text: paymentType.payload?.pix_code,
                                        });
                                      } else {
                                        toast.error(
                                          t(
                                            'PIX_CODE_SHARE_FAILURE',
                                            'Não foi possível compartilhar! Compartilhe o código manualmente.',
                                          ),
                                        );
                                      }
                                    }}
                                  >
                                    {t('PIX_SHARE', 'COMPARTILHAR PIX')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {paymentType.payment_type.code === 'payment_link' && (
                          <>
                            <div className="infoText">
                              Copie o Link para enviar a seu ciente ou
                              compartilhe diretamente:
                            </div>
                            <div className="actionArea">
                              <div className="buttonsSection">
                                <div className="code">
                                  <div className="text">
                                    <input
                                      value={paymentType.payload?.checkout_link}
                                    />
                                  </div>
                                  <div className="button">
                                    <Button
                                      color="primary"
                                      onClick={() => {
                                        try {
                                          navigator.clipboard.writeText(
                                            paymentType.payload?.checkout_link,
                                          );
                                          toast.success(
                                            t(
                                              'LINK_COPY_SUCCESS',
                                              'Link copiado para a área de transferência.',
                                            ),
                                          );
                                        } catch (err) {
                                          toast.error(
                                            t(
                                              'LINK_CODE_COPY_FAILURE',
                                              'Não foi possível copiar! Copie o código manualmente.',
                                            ),
                                          );
                                        }
                                      }}
                                    >
                                      {t('LINK_COPY', 'COPIAR')}
                                    </Button>
                                  </div>
                                </div>
                                <div className="share">
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      if (navigator.share) {
                                        navigator.share({
                                          title: t(
                                            'SHARE_PAYMENT_LINK',
                                            'Compartilhar Link',
                                          ),
                                          text: paymentType.payload
                                            ?.checkout_link,
                                        });
                                      } else {
                                        toast.error(
                                          t(
                                            'LINK_CODE_SHARE_FAILURE',
                                            'Não foi possível compartilhar! Compartilhe o código manualmente.',
                                          ),
                                        );
                                      }
                                    }}
                                  >
                                    {t('LINK_SHARE', 'COMPARTILHAR LINK')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {paymentType.payment_type.code === 'physical_card' && (
                          <>
                            <div className="infoText">
                              Selecione a comanda da(o) cliente na máquina de
                              POS para receber o pagamento.
                            </div>
                          </>
                        )}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </MultiplePaymentContainer>
            ) : (
              <>
                {filteredCurrentPayment && (
                  <UniquePaymentContainer>
                    <div className="closeRow">
                      <button
                        type="button"
                        onClick={() => {
                          // Hide modal
                          modalRef.current?.hide();
                        }}
                      >
                        <MdOutlineClose className="closeIcon" />
                      </button>
                    </div>
                    <div className="accordionTitle">
                      {getPaymentIcon(filteredCurrentPayment.payment_type)}
                      <div className="titleText">
                        {getPaymentTitle(filteredCurrentPayment.payment_type)}
                      </div>
                    </div>
                    <div className="paymentContent">
                      <div className="titleRow">Aguardando Pagamento</div>
                      {filteredCurrentPayment.payment_type.code === 'pix' && (
                        <>
                          <div className="infoText">
                            Peça para sua cliente escanear o QR code abaixo ou,
                            em caso de venda remota, copie e compartilhe o link
                            do código com ela.
                          </div>
                          <div className="qrArea">
                            {filteredCurrentPayment.payload?.pix_qr_code_url ? (
                              <img
                                src={
                                  filteredCurrentPayment.payload
                                    ?.pix_qr_code_url
                                }
                                alt={t('QRCODE_ALT', 'QR Code')}
                              />
                            ) : (
                              <p>
                                {t(
                                  'QRCODE_NOT_AVAILABLE',
                                  'QR Code indisponível',
                                )}
                              </p>
                            )}
                          </div>
                          <div className="actionArea">
                            <div className="buttonsSection">
                              <div className="code">
                                <div className="text">
                                  <input
                                    value={
                                      filteredCurrentPayment.payload?.pix_code
                                    }
                                  />
                                </div>
                                <div className="button">
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      try {
                                        navigator.clipboard.writeText(
                                          filteredCurrentPayment.payload
                                            ?.pix_code,
                                        );
                                        toast.success(
                                          t(
                                            'PIX_COPY_SUCCESS',
                                            'Pix copiado para a área de transferência.',
                                          ),
                                        );
                                      } catch (err) {
                                        toast.error(
                                          t(
                                            'PIX_CODE_COPY_FAILURE',
                                            'Não foi possível copiar! Copie o código manualmente.',
                                          ),
                                        );
                                      }
                                    }}
                                  >
                                    {t('PIX_COPY', 'COPIAR')}
                                  </Button>
                                </div>
                              </div>
                              <div className="share">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (navigator.share) {
                                      navigator.share({
                                        title: t(
                                          'SHARE_PIX_CODE',
                                          'Compartilhar código PIX',
                                        ),
                                        text: filteredCurrentPayment.payload
                                          ?.pix_code,
                                      });
                                    } else {
                                      toast.error(
                                        t(
                                          'PIX_CODE_SHARE_FAILURE',
                                          'Não foi possível compartilhar! Compartilhe o código manualmente.',
                                        ),
                                      );
                                    }
                                  }}
                                >
                                  {t('PIX_SHARE', 'COMPARTILHAR PIX')}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {filteredCurrentPayment.payment_type.code ===
                        'payment_link' && (
                        <>
                          <div className="infoText">
                            Copie o Link para enviar a seu ciente ou compartilhe
                            diretamente:
                          </div>
                          <div className="actionArea">
                            <div className="buttonsSection">
                              <div className="code">
                                <div className="text">
                                  <input
                                    value={
                                      filteredCurrentPayment.payload
                                        ?.checkout_link
                                    }
                                  />
                                </div>
                                <div className="button">
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      try {
                                        navigator.clipboard.writeText(
                                          filteredCurrentPayment.payload
                                            ?.checkout_link,
                                        );
                                        toast.success(
                                          t(
                                            'LINK_COPY_SUCCESS',
                                            'Link copiado para a área de transferência.',
                                          ),
                                        );
                                      } catch (err) {
                                        toast.error(
                                          t(
                                            'LINK_CODE_COPY_FAILURE',
                                            'Não foi possível copiar! Copie o código manualmente.',
                                          ),
                                        );
                                      }
                                    }}
                                  >
                                    {t('LINK_COPY', 'COPIAR')}
                                  </Button>
                                </div>
                              </div>
                              <div className="share">
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    if (navigator.share) {
                                      navigator.share({
                                        title: t(
                                          'SHARE_PAYMENT_LINK',
                                          'Compartilhar Link',
                                        ),
                                        text: filteredCurrentPayment.payload
                                          ?.checkout_link,
                                      });
                                    } else {
                                      toast.error(
                                        t(
                                          'LINK_CODE_SHARE_FAILURE',
                                          'Não foi possível compartilhar! Compartilhe o código manualmente.',
                                        ),
                                      );
                                    }
                                  }}
                                >
                                  {t('LINK_SHARE', 'COMPARTILHAR LINK')}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {filteredCurrentPayment.payment_type.code ===
                        'physical_card' && (
                        <>
                          <div className="infoText">
                            Selecione a comanda da(o) cliente na máquina de POS
                            para receber o pagamento.
                          </div>
                        </>
                      )}
                    </div>
                  </UniquePaymentContainer>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
});

export type { IPaymentDataModalRef };
export { PaymentDataModal };
