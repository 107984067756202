import React from 'react';

// Asset import
import DefaultProductImage from '@assets/defaultProductImage.png';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useOrderCreation } from '@hooks/useOrderCreation';

// Feature identification
const featureKey = '@order_summary/INDEX';

interface IShippingTypes {
  title: string;
  method: string;
  enabled: boolean;
  selected: boolean;
  tagCode: string;
  data: any;
}

interface IPackageItem {
  brandcode: number;
  code: string;
  image_url: string;
  name: string;
}

interface IPackage {
  business_days_to_delivery: number;
  items: IPackageItem[];
  selected_id: string;
  selected_type: string;
}

const PackageSummary: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();

  const filterItems = (package_code: string) => {
    const findPackageCodeInItems = order?.items?.filter(
      (item: any) => item.code === package_code,
    );
    return findPackageCodeInItems;
  };

  const getItemData = (package_item: IPackageItem) => {
    const getFilteredItem = filterItems(package_item.code)[0];
    const itemData = {
      name: getFilteredItem?.name,
      size: getFilteredItem?.size,
      quantity: getFilteredItem?.quantity,
      image: getFilteredItem?.image_url,
    };

    return itemData;
  };

  const getItemPrice = (package_item: IPackageItem) => {
    const getFilteredItem = filterItems(package_item.code);
    const foundItem = getFilteredItem[0];

    const itemHasBeenDiscounted =
      foundItem?.total_original_price !== foundItem?.total_current_price;

    if (itemHasBeenDiscounted) {
      return (
        <>
          <span className="original_price">
            R$ {(foundItem?.total_original_price / 100).toFixed(2)}
          </span>
          <span className="current_price">
            R$ {(foundItem?.total_current_price / 100).toFixed(2)}
          </span>
        </>
      );
    }
    return (
      <span className="current_price">
        R$ {(foundItem?.total_current_price / 100).toFixed(2)}
      </span>
    );
  };

  const getSelectedShipping = (list: IShippingTypes[]) => {
    const filterSelected = list.filter((item: any) => item?.selected);
    return filterSelected[0];
  };
  const selectedShipping: IShippingTypes = getSelectedShipping(
    order?.shippingTypes,
  );

  const getPackageText = (packages: IPackage) => {
    if (packages.selected_id === 'own_stock') {
      return <p>Levar agora</p>;
    }
    return <p>Em até {packages.business_days_to_delivery} dias úteis</p>;
  };

  return (
    <div className="packages">
      {selectedShipping?.data?.packages?.map(
        (packages: IPackage, index: number) => (
          <div className="package">
            <div className="packageTitle">
              <p>{t(`PACKAGE_${index + 1}`, `Pacote ${index + 1}:`)}</p>
            </div>
            <div className="packageContent">{getPackageText(packages)}</div>
            {packages?.items?.map((item: any) => (
              <div className="productCard" key={item.code}>
                <div className="image">
                  {item.image_url ? (
                    <img
                      src={getItemData(item).image}
                      alt={getItemData(item).name}
                    />
                  ) : (
                    <img
                      src={DefaultProductImage}
                      alt={getItemData(item).name}
                    />
                  )}
                </div>

                <div className="info">
                  <div className="name">
                    <p>{getItemData(item).name}</p>
                  </div>

                  <div className="sizeAndQuantityRow">
                    <div className="size">
                      <p>Tamanho {getItemData(item).size}</p>
                    </div>
                    <div className="quantity">
                      <p>{getItemData(item).quantity} unidade(s)</p>
                    </div>
                  </div>

                  <div className="price">{getItemPrice(item)}</div>
                </div>
              </div>
            ))}
          </div>
        ),
      )}
    </div>
  );
};

export { PackageSummary };
