import { CircularProgress } from '@material-ui/core';
import React from 'react';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'high'
    | 'low'
    | string;
  size?: number;
}

const LoadingIndicator: React.FC<IProps> = ({ color, size }) => {
  return (
    <Container size={`${size}rem`} color={color}>
      <CircularProgress size={`${size}rem`} />
    </Container>
  );
};

LoadingIndicator.defaultProps = {
  color: 'primary',
  size: 2.5,
};

export { LoadingIndicator };
