import React from 'react';

// Style import
import * as Style from './styles';

interface IProps {
  handleToggleChange(): void;
  toggleIsSwitched?: boolean;
}

const ToggleSwitch: React.FC<IProps> = ({
  handleToggleChange,
  toggleIsSwitched,
}) => {
  return (
    <Style.Container>
      <Style.Input
        type="checkbox"
        onChange={handleToggleChange}
        checked={toggleIsSwitched}
      />
      <Style.SwitchBase />
    </Style.Container>
  );
};

export { ToggleSwitch };
