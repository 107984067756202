import React from 'react';

import { Container } from './styles';

interface IProps {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  size?: 'small' | 'normal';
}

const CheckboxHandmade: React.FC<IProps> = ({
  checked,
  onChange,
  disabled,
  size,
}) => {
  return (
    <Container size={size}>
      <input
        type="checkbox"
        checked={checked}
        onChange={e => onChange(e)}
        disabled={disabled}
      />
      <span className="checkboxContainer" />
    </Container>
  );
};

export { CheckboxHandmade };
