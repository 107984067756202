import React, { useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Component import
import { Modal, IModalRef } from '@components/Modal';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';
import { IProductVariation } from '@hooks/useAddProduct/models';

// Import modal
import { OwnStockConfirmationModal } from './OwnStockConfirmationModal';

// Style import
import { Container } from './styles';

interface IProps {
  selectedPackageType: string;
  page: string;
}

// Feature identification
const featureKey = '@create_order/SIZE_SELECTOR';

const SizeSelector: React.FC<IProps> = ({ selectedPackageType, page }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const product = useAddProduct();

  // Local refs
  const confirmationModalRef = useRef<IModalRef>(null);

  // Global states
  const user = useSelector(state => state.auth);

  // Which page is it
  const getListToRender =
    page === 'variation' ? product.variations : product.variationsToEdit;

  const availablePackageTypes = user.store?.package_types.filter(
    storePackageType =>
      storePackageType.enabled && storePackageType.package_type.enabled,
  );

  const isOwnStockEnabled = !!availablePackageTypes?.filter(
    package_type => package_type.package_type.code === 'own_stock',
  ).length;

  const areVariationButtonsVisible =
    !isOwnStockEnabled || (isOwnStockEnabled && selectedPackageType !== 'none');

  const setVariationSectionClass = areVariationButtonsVisible
    ? ''
    : 'invisible';

  const setVariationButtonClass = (
    shippingAvailable: boolean,
    itemId: string,
  ) => {
    const isProductAvailable = () => {
      if (selectedPackageType === 'OWN_STOCK') return true;
      if (shippingAvailable) return true;
      return false;
    };
    const productIsAvailable = isProductAvailable();
    if (productIsAvailable && itemId === product.selectedVariationId) {
      return 'selectedSize';
    }
    if (productIsAvailable && itemId !== product.selectedVariationId) {
      return 'size';
    }
    return 'unavailableSize';
  };

  const handleButton = (item: IProductVariation) => {
    product.setSelectedVariationId(item.id.toString());
    const hasWarningModalBeenShown = localStorage.getItem('ownStockWarning');
    if (
      isOwnStockEnabled &&
      !hasWarningModalBeenShown &&
      selectedPackageType === 'OWN_STOCK'
    ) {
      confirmationModalRef.current?.show();
    }
  };

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal ref={confirmationModalRef} size="small-1">
          <OwnStockConfirmationModal
            hide={() => confirmationModalRef.current?.hide()}
          />
        </Modal>
      </>
    ),
    [],
  );

  return (
    <>
      {modals}
      <Container className={setVariationSectionClass}>
        <div className="title">
          {t('AVAILABLE_SIZES', 'Tamanhos disponíveis')}
        </div>
        <div className="availableSizes">
          {getListToRender.map(item => (
            <>
              <button
                type="button"
                className={`${setVariationButtonClass(
                  item.shipping.available,
                  item.id.toString(),
                )}`}
                onClick={() => handleButton(item)}
              >
                <p>{item.size}</p>
                <div />
              </button>
            </>
          ))}
        </div>
      </Container>
    </>
  );
};

export { SizeSelector };
