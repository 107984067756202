const supportedCardBrands = [
  { label: 'Visa', value: 'visa' },
  { label: 'Mastercard', value: 'mastercard' },
  { label: 'American Express', value: 'american-express' },
  { label: 'Diners Club', value: 'diners-club' },
  { label: 'Discover', value: 'discover' },
  { label: 'JCB', value: 'jcb' },
  { label: 'Unionpay', value: 'unionpay' },
  { label: 'Maestro', value: 'maestro' },
  { label: 'Mir', value: 'mir' },
  { label: 'Elo', value: 'elo' },
  { label: 'Hipercard', value: 'hipercard' },
];

export { supportedCardBrands };
