import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import { MdOutlineAccountBalanceWallet, MdOutlinePerson } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

// Component import
import { Tabs } from '@components/Tabs';
import { Modal, IModalRef } from '@components/Modal';
import { Button } from '@components/Button';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';
import { useWindowSize } from '@hooks/useWindowSize';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { IClientData } from '@util/CartModels';

import { Customer } from './Customer';
import { AddByQrCode } from './AddByQrCode';
import { AddByDocument } from './AddByDocument';

import { Container, Footer } from './styles';

// Interfaces
interface IAddProductScreenRef {
  onFocus: () => void;
  onBlur: () => void;
  onSelect: () => void;
  onDeselect: () => void;
}

interface ICustomerStoreCredit {
  code: string;
  name: string;
  total: number;
}

// Feature identification
const featureKey = '@client/INDEX';

const Client: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const [width] = useWindowSize();
  const user = useSelector(state => state.auth);
  const history = useHistory();

  // Local states
  const [loading, setLoading] = useState(false);
  const [selectedClientInfo, setSelectedClientInfo] = useState<
    IClientData | undefined
  >();
  const [clientList, setClientList] = useState<IClientData[]>([]);
  const [initialClientDocument, setInitialClientDocument] = useState('');
  const [found, setFound] = useState<boolean | undefined>(undefined);
  const [, setCloseAllModals] = useState<boolean>(false);
  const [searchDocumentNumber, setSearchDocumentNumber] = useState<string>('');
  const [activeTab, setActiveTab] = useState(0);
  const [creditBalance, setCreditBalance] = useState<ICustomerStoreCredit>();

  // Local refs
  const customerSelectorModalRef = useRef<IModalRef>(null);

  const tabLabels = useMemo(() => {
    if (user.store?.brands.some(item => Number(item.brand.code) === 16)) {
      return [
        {
          label: t('LABEL_ADD_BY_CAMERA', 'QRcode'),
        },
        {
          label: t('LABEL_ADD_BY_DOCUMENT', 'CPF'),
        },
      ];
    }
    return [
      {
        label: t('LABEL_ADD_BY_DOCUMENT', 'CPF'),
      },
    ];
  }, [t, user.store?.brands]);

  const searchCreditBalance = useCallback(async () => {
    try {
      setLoading(true);

      // API call
      const response = await api.get('/customer/store_credit', {
        params: {
          document: searchDocumentNumber,
          email: selectedClientInfo?.email,
        },
      });

      setCreditBalance(response.data);

      setLoading(false);
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);
      setLoading(false);
    }
  }, [searchDocumentNumber, selectedClientInfo?.email, t]);

  useEffect(() => {
    if (selectedClientInfo?.email) {
      searchCreditBalance();
    }
  }, [searchCreditBalance, selectedClientInfo?.email]);

  const handleChangeIndex = (index: number) => {
    const numberOfTabs = tabLabels.length;
    if (index >= numberOfTabs) {
      setActiveTab(numberOfTabs - 1);
      return;
    }
    setActiveTab(index);
  };

  // Local refs
  const screenRef = useRef<Array<IAddProductScreenRef | null>>([]);

  const handleTabChange = useCallback(() => {
    screenRef.current.forEach((tab, index) => {
      // Selected
      if (index === activeTab) {
        if (typeof screenRef.current[index]?.onFocus === 'function')
          screenRef.current[index]?.onFocus();
        return;
      }

      // Not selected
      if (typeof screenRef.current[index]?.onBlur === 'function')
        screenRef.current[index]?.onBlur();
    });
  }, [activeTab]);

  const saveClientInfo = () => {
    if (selectedClientInfo) {
      localStorage.setItem(
        'savedClientInfo',
        JSON.stringify(selectedClientInfo),
      );
    }
  };

  const formatDateString = (dateString: string): string => {
    // Verifica se a string está no formato esperado "YYYY-MM-DD"
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      // throw new Error("Formato de data inválido. Esperado 'YYYY-MM-DD'.");
      return dateString;
    }

    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const modals = useMemo(
    () => (
      <>
        <Modal ref={customerSelectorModalRef} size="small">
          <Customer
            hide={() => customerSelectorModalRef.current?.hide()}
            selectedClientInfo={selectedClientInfo}
            clientList={clientList}
            initialClientDocument={initialClientDocument}
            found={found}
            setSelectedClientInfo={setSelectedClientInfo}
            setClientList={setClientList}
            setInitialClientDocument={setInitialClientDocument}
            setFound={setFound}
            setCloseAllModals={setCloseAllModals}
            searchDocumentNumber={searchDocumentNumber}
          />
        </Modal>
      </>
    ),
    [
      clientList,
      found,
      initialClientDocument,
      searchDocumentNumber,
      selectedClientInfo,
    ],
  );

  return (
    <Container>
      {selectedClientInfo && found ? (
        <div className="selectedClient">
          <div className="clientCard">
            {user.store?.brands.some(item => Number(item.brand.code) === 16) &&
              creditBalance && (
                <>
                  <div className="creditBalanceSection">
                    <div className="creditBalanceTitleRow">
                      <MdOutlineAccountBalanceWallet />
                      <div className="creditBalanceTitleIconText">
                        Saldo Disponível
                      </div>
                    </div>
                    <div className="divider" />
                    <div className="valueRow">
                      R$ {(creditBalance.total / 100).toFixed(2)}
                    </div>
                  </div>
                  <div className="verticalDivider" />
                </>
              )}

            <div className="identificationClientSection">
              <div className="clientTitleRow">
                <MdOutlinePerson />
                <div className="clientInfoTitle">Identificação Cliente</div>
              </div>
              <div className="divider" />
              <div className="generalInfo">
                <div className="generalRow">
                  <div className="infoTitle">Cliente: </div>
                  <div className="infoContent">
                    {selectedClientInfo.name} {selectedClientInfo.surname}
                  </div>
                </div>
                <div className="generalRow">
                  <div className="infoTitle">E-mail: </div>
                  <div className="infoContent">{selectedClientInfo.email}</div>
                </div>
                <div className="generalRow">
                  <div className="infoTitle">Aniversário: </div>
                  {selectedClientInfo.birthdate && (
                    <div className="infoContent">
                      {formatDateString(selectedClientInfo.birthdate)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {width > 768 ? (
            <Footer>
              <Button
                className="action"
                onClick={() => {
                  saveClientInfo();
                  history.push(`/order/new`);
                }}
                color="success"
              >
                <p>{t('NEW_SERVICE', 'Novo Atendimento')}</p>
              </Button>
            </Footer>
          ) : (
            <div className="newServiceButton">
              <button type="button">Novo Atendimento</button>
              <Button
                className="action"
                onClick={() => {
                  saveClientInfo();
                  history.push(`/order/new`);
                }}
                color="primary"
              >
                <p>{t('NEW_SERVICE', 'Novo Atendimento')}</p>
              </Button>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="clientTitle">
            Escolha como deseja identificar cliente
          </div>
          <Tabs
            index={activeTab}
            className="tabs"
            onChange={setActiveTab}
            disabled={loading}
            tabs={tabLabels}
          />
          {user.store?.brands.some(item => Number(item.brand.code) === 16) ? (
            <SwipeableViews
              index={activeTab}
              onChangeIndex={handleChangeIndex}
              onTransitionEnd={handleTabChange}
              enableMouseEvents={loading}
              className="content"
              slideStyle={{
                padding: '0 1rem',
                height: '100%',
              }}
            >
              <AddByQrCode
                customerSelectorModalRef={customerSelectorModalRef}
                setInitialClientDocument={setInitialClientDocument}
                searchDocumentNumber={searchDocumentNumber}
                setClientList={setClientList}
                setSelectedClientInfo={setSelectedClientInfo}
                setFound={setFound}
                setSearchDocumentNumber={setSearchDocumentNumber}
              />
              <AddByDocument
                customerSelectorModalRef={customerSelectorModalRef}
                searchDocumentNumber={searchDocumentNumber}
                setFound={setFound}
                setSelectedClientInfo={setSelectedClientInfo}
                setClientList={setClientList}
                setInitialClientDocument={setInitialClientDocument}
                setSearchDocumentNumber={setSearchDocumentNumber}
              />
            </SwipeableViews>
          ) : (
            <SwipeableViews
              index={activeTab}
              onChangeIndex={handleChangeIndex}
              onTransitionEnd={handleTabChange}
              enableMouseEvents={!loading}
              className="content"
              slideStyle={{
                padding: '0 1rem',
                height: '100%',
              }}
            >
              <AddByDocument
                customerSelectorModalRef={customerSelectorModalRef}
                searchDocumentNumber={searchDocumentNumber}
                setFound={setFound}
                setSelectedClientInfo={setSelectedClientInfo}
                setClientList={setClientList}
                setInitialClientDocument={setInitialClientDocument}
                setSearchDocumentNumber={setSearchDocumentNumber}
              />
            </SwipeableViews>
          )}
        </>
      )}
      {modals}
    </Container>
  );
};

export type { IAddProductScreenRef };
export { Client };
