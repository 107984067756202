import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MdChevronRight } from 'react-icons/md';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

import { CustomerInfo } from '../CustomerInfo';
import { AddressInfo } from '../AddressInfo';

import { Container } from './styles';

// Feature identification
const featureKey = '@order/MOBILE_METADATA';

const MobileMetadata: React.FC = () => {
  const { t } = useTranslation(featureKey);

  const [dataIsOpen, setDataIsOpen] = useState(false);

  const arrowClassName = dataIsOpen ? 'open' : '';

  return (
    <Container>
      <button
        className="title"
        type="button"
        onClick={() => setDataIsOpen(prev => !prev)}
      >
        <p>{t('CLIENT_ID', 'Identificação do cliente')}</p>
        <div className={`arrow ${arrowClassName}`}>
          <MdChevronRight />
        </div>
      </button>
      <motion.div
        initial="closed"
        animate={dataIsOpen ? 'open' : 'closed'}
        variants={{
          open: { opacity: 1, height: 'auto', marginBottom: '1rem' },
          closed: { opacity: 0, height: 0, marginBottom: 0 },
        }}
      >
        <div className={`expandedData ${arrowClassName}`}>
          <CustomerInfo />
          <div className="divider" />
          <AddressInfo />
        </div>
      </motion.div>
    </Container>
  );
};

export { MobileMetadata };
