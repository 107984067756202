import { offset } from '@floating-ui/dom';

export const identificationSteps = [
  {
    id: 'shepherd1',
    attachTo: { element: '.onboardingId', on: 'top' as const },
    title: '1/2',
    text: [
      'Verifique as informações de cadastro de cliente. Você pode editá-las caso necessário.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Sair',
        type: 'cancel',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'PRÓXIMO',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
  {
    id: 'shepherd2',
    attachTo: { element: '.onboardingAddress', on: 'top' as const },
    title: '2/2',
    text: [
      'Clique em editar endereço caso necessário para selecionar outro endereço ou cadastrar um novo.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Finalizar',
        type: 'complete',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
];

export const emailModalSteps = [
  {
    id: 'shepherd1',
    attachTo: { element: '.onboardModalStep', on: 'bottom-start' as const },
    text: [
      'Para concluir um atendimento, é necessário selecionar ou criar um novo cadastro para o(a) cliente com endereço, mesmo que a sacola só contenha produtos de Levar Agora.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: '',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'OK',
        type: 'complete',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 32 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
];

export const newCLientModalSteps = [
  {
    id: 'shepherd1',
    attachTo: { element: '.customerNotFound', on: 'bottom' as const },
    text: [
      'Para concluir um atendimento, é necessário selecionar ou criar um novo cadastro para o(a) cliente com endereço, mesmo que a sacola só contenha produtos de Levar Agora.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: '',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'OK',
        type: 'complete',
      },
    ],
    modalOverlayOpeningPadding: 22,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 32 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
];
