import { combineReducers } from 'redux';

// Reducer import
import { auth } from './auth/reducer';
import { ui } from './ui/reducer';

const rootReducer = combineReducers({
  auth,
  ui,
});

export { rootReducer };
