import styled from 'styled-components';

export const StepperStore = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 760px) {
    margin-bottom: 4rem;
  }

  .divider {
    border-top: solid ${({ theme }) => theme.font_secondary} 1px;
    width: 5.5rem;
    margin-top: 0.5rem;
    border-radius: 0.125rem;

    @media screen and (max-width: 760px) {
      width: 2.5rem;
    }
  }

  .statusItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 0.5rem;

      @media screen and (max-width: 760px) {
        font-size: 0.75rem;
      }
    }
  }
`;
