/* eslint-disable import/no-duplicates */
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { Locale, getDate, parseJSON, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  MdOutlineClose,
  MdAdd,
  MdOutlineShoppingBag,
  MdOutlineShoppingCart,
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Asset import
import { ReactComponent as DeleteIcon } from '@assets/delete.svg';
import DefaultProductImage from '@assets/defaultProductImage.png';

// Component import
import { Modal, IModalRef } from '@components/Modal';
import { Button } from '@components/Button';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useOrderCreation } from '@hooks/useOrderCreation';

// Service import
import { api } from '@services/api';

// Store import
import {
  setBagSummary,
  setBagId,
  setSavedBagAddingProduct,
} from '@store/modules/ui/actions';

// Util import
import { IBag, IBagItems } from '@util/SavedBagModels';

// Page import
import { DeleteUniqueBagModal } from './DeleteUniqueBagModal';
import { RemoveProductModal } from './RemoveProductModal';

// Style import
import { Container, Footer } from './styles';

// Feature identification
const featureKey = '@bag_summary/INDEX';

const BagSummary: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const dispatch = useDispatch();
  const history = useHistory();

  // Local states
  const [loading, setLoading] = useState<boolean>();
  const [selectedBag, setSelectedBag] = useState<IBag>();
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);
  const [selectedItemCode, setSelectedItemCode] = useState<string>('');
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [loadingUptaded, setLoadingUptaded] = useState<boolean>(false);

  // Global states
  const language = useSelector(state => state.ui.language);
  const user = useSelector(state => state.auth);

  // Local refs
  const deleteUniqueBagModalRef = useRef<IModalRef>(null);
  const removeProductModalRef = useRef<IModalRef>(null);

  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  const getBagDetails = useCallback(async () => {
    setLoading(true);

    try {
      // API call
      const response = await api.get(`/v1/cart/${order.orderCode}`);

      const new_response = response.data;

      const formatted_items = new_response.items.map((item: IBagItems) => {
        return {
          ...item,
          brand_code: item.brand.code,
        };
      });
      if (order.orderCode === selectedBag?.code) {
        order.setCoupon({ name: new_response.coupon });
        order.setAddress(new_response.address);
        order.setCustomer(new_response.customer);
      }

      setSelectedBag({ ...new_response, items: formatted_items });
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      throw err;
    }
  }, [order, selectedBag?.code]);

  const getBagDetailsUpdated = useCallback(async () => {
    setLoadingUptaded(true);

    try {
      // API call
      const response = await api.get(`/v1/cart/${order.orderCode}/updated`);

      const new_response = response.data;

      const formatted_items = new_response.items.map((item: IBagItems) => {
        if (item.payload.attachments && item.payload.attachments.length) {
          return {
            ...item,
            brand_code: item.brand.code,
            attachments: {
              name: item.payload.attachments[0].content.GiftCardName,
              email: item.payload.attachments[0].content.GiftCardEmail,
              date: item.payload.attachments[0].content.GiftCardSchedule,
            },
          };
        }
        return {
          ...item,
          brand_code: item.brand.code,
        };
      });
      if (order.orderCode === selectedBag?.code) {
        order.setCoupon({ name: new_response.coupon });
        order.setAddress(new_response.address);
        order.setCustomer(new_response.customer);
      }

      setSelectedBag(prevState => {
        if (prevState) {
          return {
            ...new_response,
            items: formatted_items,
            created_at: prevState.created_at,
            can_user_delete: prevState.can_user_delete,
          };
        }
        return {
          ...new_response,
          items: formatted_items,
        };
      });

      setLoadingUptaded(false);
    } catch (err: any) {
      setLoadingUptaded(false);
      throw err;
    }
  }, [order, selectedBag?.code]);

  // Initial load
  useEffect(() => {
    getBagDetails();
    getBagDetailsUpdated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deliveryItems = selectedBag?.items?.filter(
    item => item.shipping.selected_type === 'DELIVERY',
  );

  const ownStockItems = selectedBag?.items?.filter(
    item => item.shipping.selected_type === 'OWN_STOCK',
  );

  const getSummaryPackages = () => {
    if (deliveryItems?.length && ownStockItems?.length) {
      return (
        <div className="packages">
          <div className="package">
            <div className="packageTitle">
              <MdOutlineShoppingBag />
              <p>{t('TAKE_NOW', 'Levar Agora')}</p>
            </div>

            {ownStockItems?.map(item => (
              <div className="productCard" key={item.code}>
                <div className="image">
                  {item.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <img src={DefaultProductImage} alt={item.name} />
                  )}
                </div>

                <div className="info">
                  {item.shipping.available === false && (
                    <div className="outOfStock">
                      <p>{t('OUT_OF_STOCK', 'Sem estoque')}</p>
                    </div>
                  )}
                  <div className="name">
                    <p>{item.name}</p>
                  </div>

                  <div className="sizeAndQuantityRow">
                    <div className="quantity">
                      <p>
                        {item.quantity} unidade(s) - Tam. {item.size}
                      </p>
                    </div>
                  </div>

                  <div className="price">
                    {item.total_original_price !== item.total_current_price && (
                      <span className="original_price">
                        R$ {(item.total_original_price / 100).toFixed(2)}
                      </span>
                    )}
                    <span className="current_price">
                      R$ {(item.total_current_price / 100).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="action">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedItemCode(item.code);
                      removeProductModalRef.current?.show();
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="package">
            <div className="packageTitle">
              <MdOutlineShoppingCart />
              <p>{t('RECEIVE_AT_HOME', 'Receber em casa')}</p>
            </div>

            {deliveryItems?.map(item => (
              <div className="productCard" key={item.code}>
                <div className="image">
                  {item.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <img src={DefaultProductImage} alt={item.name} />
                  )}
                </div>
                <div className="info">
                  {item.shipping.available === false && (
                    <div className="outOfStock">
                      <p>{t('OUT_OF_STOCK', 'Sem estoque')}</p>
                    </div>
                  )}
                  <div className="name">
                    <p>{item.name}</p>
                  </div>

                  <div className="sizeAndQuantityRow">
                    <div className="quantity">
                      <p>
                        {item.quantity} unidade(s) - Tam. {item.size}
                      </p>
                    </div>
                  </div>

                  <div className="price">
                    {item.total_original_price !== item.total_current_price && (
                      <span className="original_price">
                        R$ {(item.total_original_price / 100).toFixed(2)}
                      </span>
                    )}
                    <span className="current_price">
                      R$ {(item.total_current_price / 100).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="action">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedItemCode(item.code);
                      removeProductModalRef.current?.show();
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    if (deliveryItems?.length) {
      return (
        <div className="packages">
          <div className="package">
            <div className="packageTitle">
              <MdOutlineShoppingCart />
              <p>{t('RECEIVE_AT_HOME', 'Receber em casa')}</p>
            </div>
            {deliveryItems?.map(item => (
              <div className="productCard" key={item.code}>
                <div className="image">
                  {item.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <img src={DefaultProductImage} alt={item.name} />
                  )}
                </div>
                <div className="info">
                  {item.shipping.available === false && (
                    <div className="outOfStock">
                      <p>{t('OUT_OF_STOCK', 'Sem estoque')}</p>
                    </div>
                  )}
                  <div className="name">
                    <p>{item.name}</p>
                  </div>

                  <div className="sizeAndQuantityRow">
                    <div className="quantity">
                      <p>
                        {item.quantity} unidade(s) - Tam. {item.size}
                      </p>
                    </div>
                  </div>

                  <div className="price">
                    {item.total_original_price !== item.total_current_price && (
                      <span className="original_price">
                        R$ {(item.total_original_price / 100).toFixed(2)}
                      </span>
                    )}
                    <span className="current_price">
                      R$ {(item.total_current_price / 100).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="action">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedItemCode(item.code);
                      removeProductModalRef.current?.show();
                      // setActiveModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <>
        {ownStockItems && ownStockItems?.length > 0 && (
          <div className="packages">
            <div className="package">
              <div className="packageTitle">
                <MdOutlineShoppingBag />
                <p>{t('TAKE_NOW', 'Levar Agora')}</p>
              </div>
              {ownStockItems?.map(item => (
                <div className="productCard" key={item.code}>
                  <div className="image">
                    {item.image_url ? (
                      <img src={item.image_url} alt={item.name} />
                    ) : (
                      <img src={DefaultProductImage} alt={item.name} />
                    )}
                  </div>
                  <div className="info">
                    {item.shipping.available === false && (
                      <div className="outOfStock">
                        <p>{t('OUT_OF_STOCK', 'Sem estoque')}</p>
                      </div>
                    )}
                    <div className="name">
                      <p>{item.name}</p>
                    </div>

                    <div className="sizeAndQuantityRow">
                      <div className="quantity">
                        <p>
                          {item.quantity} unidade(s) - Tam. {item.size}
                        </p>
                      </div>
                    </div>

                    <div className="price">
                      {item.total_original_price !==
                        item.total_current_price && (
                        <span className="original_price">
                          R$ {(item.total_original_price / 100).toFixed(2)}
                        </span>
                      )}
                      <span className="current_price">
                        R$ {(item.total_current_price / 100).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="action">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedItemCode(item.code);
                        removeProductModalRef.current?.show();
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  // Modals;
  const modals = useMemo(
    () => (
      <>
        <Modal
          ref={deleteUniqueBagModalRef}
          size="small-2"
          onShow={() => setActiveModal(true)}
          onHide={() => setActiveModal(false)}
        >
          <DeleteUniqueBagModal
            cartId={order.orderCode}
            hide={() => {
              deleteUniqueBagModalRef.current?.hide();
            }}
            setActiveModal={setActiveModal}
          />
        </Modal>
        <Modal
          ref={removeProductModalRef}
          size="small-2"
          onShow={() => setActiveModal(true)}
          onHide={() => {
            setActiveModal(false);
            getBagDetails();
            getBagDetailsUpdated();
          }}
        >
          <RemoveProductModal
            hide={() => removeProductModalRef.current?.hide()}
            item_code={selectedItemCode}
            setActiveModal={setActiveModal}
            selectedBag={selectedBag}
          />
        </Modal>
        ;
      </>
    ),
    [
      getBagDetails,
      getBagDetailsUpdated,
      order.orderCode,
      selectedBag,
      selectedItemCode,
    ],
  );
  const removeItems = async () => {
    const available_product = selectedBag?.items.filter(
      order_item => order_item.shipping.available === true,
    );

    if (available_product && available_product.length > 0) {
      await order.setItems(available_product);
      getBagDetailsUpdated();
    } else {
      deleteUniqueBagModalRef.current?.show();
    }
  };

  return (
    <>
      {/* This component doesn't work well in Web Safari. It doesn't cover all height screen */}

      <Container color={activeModal ? '99' : '101'}>
        <button
          className="disableSummary"
          type="button"
          onClick={() => {
            dispatch(setBagSummary(false));
            dispatch(setBagId('update'));
          }}
        >
          <div />
        </button>

        <div className="summaryArea">
          {loading || order.loading ? (
            <div className="loading">
              <LoadingIndicator />
            </div>
          ) : (
            <>
              <div className="orderSummaryComponent">
                <div className="orderSummaryHeader">
                  <div className="backButton">
                    {selectedBag?.can_user_delete ? (
                      <button
                        type="button"
                        onClick={() => {
                          deleteUniqueBagModalRef.current?.show();
                        }}
                      >
                        <p> {t('DELETE_BAG', 'Excluir Sacola')}</p>
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="closeButton">
                    <button
                      type="button"
                      onClick={() => {
                        order.setItems([]);
                        dispatch(setBagSummary(false));
                        dispatch(setBagId('update'));
                      }}
                    >
                      <MdOutlineClose />
                    </button>
                  </div>
                </div>
                <div className="titleRow">
                  <div className="title">
                    {t('SAVED_BAG_TITLE', 'Sacola Salva')}
                  </div>
                  {user.sale_codes && (
                    <>
                      {user.sale_codes.length > 1 && (
                        <div className="salespersonRow">
                          {t('SALESPERSON', '      Vendedor:')}{' '}
                          {selectedBag?.sale_user_code}
                          {' - '}
                          {selectedBag?.sale_user_name}
                        </div>
                      )}
                    </>
                  )}
                  <div className="info">
                    {selectedBag && selectedBag.created_at && (
                      <div>
                        <p>
                          {(
                            format(
                              parseJSON(Date.parse(selectedBag.created_at)),
                              'EEEE',
                              {
                                locale: dateLanguagePack,
                              },
                            )[0].toUpperCase() +
                            format(
                              parseJSON(Date.parse(selectedBag.created_at)),
                              'EEEE',
                              {
                                locale: dateLanguagePack,
                              },
                            ).substring(1)
                          ).slice(0, -6)}{' '}
                          {getDate(
                            parseJSON(Date.parse(selectedBag.created_at)),
                          )}{' '}
                          {format(
                            parseJSON(Date.parse(selectedBag.created_at)),
                            'LLL',
                            {
                              locale: dateLanguagePack,
                            },
                          )[0].toUpperCase() +
                            format(
                              parseJSON(Date.parse(selectedBag.created_at)),
                              'LLL',
                              {
                                locale: dateLanguagePack,
                              },
                            ).substring(1)}{' '}
                          {format(
                            parseJSON(Date.parse(selectedBag.created_at)),
                            'HH',
                            {
                              locale: dateLanguagePack,
                            },
                          )}
                          :
                          {format(
                            parseJSON(Date.parse(selectedBag.created_at)),
                            'mm',
                            {
                              locale: dateLanguagePack,
                            },
                          )}
                        </p>
                      </div>
                    )}

                    <div>
                      <p>
                        {selectedBag?.customer.name ||
                        selectedBag?.customer.surname ? (
                          <>
                            Cliente: {selectedBag?.customer.name}{' '}
                            {selectedBag?.customer.surname}
                          </>
                        ) : (
                          <>Cliente: -</>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                {selectedBag?.items.some(
                  order_item => order_item.shipping.available === false,
                ) && (
                  <div className="outOfStockRow">
                    <div className="text">
                      <p>
                        {t(
                          'PRODUCTS_OUT_OF_STOCK',
                          'Um ou mais produtos da sacola estão indisponíveis.',
                        )}
                      </p>
                    </div>
                    <div className="outOfStockButton">
                      <Button
                        color="danger"
                        onClick={() => {
                          removeItems();
                        }}
                      >
                        <p>{t('TO_REMOVE', 'REMOVER')}</p>
                      </Button>
                    </div>
                  </div>
                )}
                <div className="orderSummaryContent">
                  {loadingUptaded ? (
                    <div className="loading">
                      <LoadingIndicator />
                    </div>
                  ) : (
                    <>{getSummaryPackages()}</>
                  )}
                </div>
              </div>
              <Footer>
                <div className="info">
                  <div className="infoRow">
                    <p className="firstInfo">
                      {order && (
                        <>
                          {order?.items
                            .map(product => product.quantity)
                            .reduce((total, current) => total + current, 0) >
                          1 ? (
                            <>
                              {order?.items
                                .map(product => product.quantity)
                                .reduce(
                                  (total, current) => total + current,
                                  0,
                                )}{' '}
                              {t('PRODUCTS', 'produtos')}
                            </>
                          ) : (
                            <>
                              {order?.items
                                .map(product => product.quantity)
                                .reduce(
                                  (total, current) => total + current,
                                  0,
                                )}{' '}
                              {t('PRODUCT', 'produto')}
                            </>
                          )}
                        </>
                      )}
                    </p>
                    <p className="firstInfo">
                      R${' '}
                      {selectedBag && (
                        <>
                          {(selectedBag?.total_original_price / 100).toFixed(2)}
                        </>
                      )}
                    </p>
                  </div>
                  <div className="infoRow">
                    <p>{t('DELIVERY', 'Entrega')}</p>
                    <p>
                      {selectedBag ? (
                        <>
                          {(selectedBag?.shipping_current_price / 100).toFixed(
                            2,
                          )}
                        </>
                      ) : (
                        <>{t('TO_CALCULATE', 'a calcular')}</>
                      )}
                    </p>
                  </div>
                  {selectedBag && (
                    <>
                      {selectedBag?.coupon && (
                        <>
                          <div className="infoRow">
                            <p className="coupon">{selectedBag.coupon}</p>
                            <p className="discountValue">
                              -R${' '}
                              {(
                                (selectedBag.total_original_price -
                                  selectedBag.total_current_price) /
                                100
                              ).toFixed(2)}
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="total">
                  <p>{t('TOTAL', 'Total')}</p>
                  <p>
                    R${' '}
                    {selectedBag && (
                      <>{(selectedBag?.total / 100).toFixed(2)}</>
                    )}
                  </p>
                </div>
                {selectedBag && (
                  <Button
                    className="action"
                    color="primary"
                    onClick={() => {
                      dispatch(setBagId(selectedBag.code));
                      dispatch(setBagSummary(false));
                      history.push(`/order/new`);
                    }}
                    disabled={
                      selectedBag.items.some(
                        order_item => order_item.shipping.available === false,
                      ) || loadingUptaded
                    }
                  >
                    <p>{t('FINISH_SERVICE', 'FINALIZAR ATENDIMENTO')}</p>
                  </Button>
                )}
                <Button
                  className="action"
                  startIcon={<MdAdd />}
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    dispatch(setBagId(order.orderCode));
                    dispatch(setBagSummary(false));
                    dispatch(setSavedBagAddingProduct(true));
                    history.push(`/order/new`);
                  }}
                >
                  <p>{t('ADD_PRODUCT', 'ADICIONAR PRODUTO')}</p>
                </Button>
              </Footer>
            </>
          )}
        </div>
        {modals}
      </Container>
    </>
  );
};

export { BagSummary };
