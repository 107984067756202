import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { useDebounce } from 'use-lodash-debounce';
import { MdClose } from 'react-icons/md';

// Component import
import { Input } from '@components/Input';
import { IModalRef } from '@components/Modal';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { IClientData } from '@util/CartModels';
import { validateCpf } from '@util/validateCpf';
import { getFormErrors } from '@util/getFormErrors';

// Style import
import { Container } from './styles';

interface IProps {
  customerSelectorModalRef: React.RefObject<IModalRef>;
  searchDocumentNumber: string;
  setFound: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setSelectedClientInfo: React.Dispatch<
    React.SetStateAction<IClientData | undefined>
  >;
  setClientList: React.Dispatch<React.SetStateAction<IClientData[]>>;
  setInitialClientDocument: React.Dispatch<React.SetStateAction<string>>;
  setSearchDocumentNumber: React.Dispatch<React.SetStateAction<string>>;
}

// Feature identification
const featureKey = '@client/CLIENT_ADD_BY_DOCUMENT';

const AddByDocument: React.FC<IProps> = ({
  customerSelectorModalRef,
  searchDocumentNumber,
  setFound,
  setSelectedClientInfo,
  setClientList,
  setInitialClientDocument,
  setSearchDocumentNumber,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const product = useAddProduct();

  // Local refs
  const formRef = useRef<FormHandles>(null);

  // Local states
  const [loading, setLoading] = useState(false);
  const [, setTypedCode] = useState('');
  const [bagCustomerDocument, setBagCustomerDocument] = useState<string>('');
  const [documentError, setDocumentError] = useState<string>('');

  const searchParam = useDebounce(searchDocumentNumber, 1000);

  const formatDocument = (e: any) => {
    const finalString = e?.match(/\d/g)?.join('');

    return finalString;
  };

  const handleSubmit: SubmitHandler<{
    code: string;
  }> = useCallback(
    async data => {
      // Clear form errors
      if (formRef.current) formRef.current.setErrors({});

      // Clear product variations
      product.resetVariations();

      // Setup a schema to be validated
      const schema = Yup.object()
        .shape({
          code: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CODE_TYPE',
                'O código deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(t('REQUIRED_CODE', 'Escaneie ou digite o código.')),
        })
        .noUnknown(true, t('INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        getFormErrors(err, ({ form, errors }) => {
          formRef.current?.setErrors(form);
          toast.error(errors[0]);
        });
        return;
      }

      // Get reference and color from data
      const { code } = data;

      // Request product information to backend
      try {
        product.getProduct({
          code,
        });
      } catch (err: any) {}
    },
    [product, t],
  );

  const searchDocument = useCallback(async () => {
    try {
      setLoading(true);

      // API call
      const response = await api.get('/customer', {
        params: {
          document: searchDocumentNumber,
        },
      });

      // Check if is valid
      if (!Array.isArray(response.data)) throw new Error();

      setLoading(false);

      // Set selected document
      setInitialClientDocument(searchDocumentNumber);

      // Check if found
      if (!response.data.length) {
        setClientList([]);
        setSelectedClientInfo({
          document: searchDocumentNumber,

          email: '',
          name: '',
          phone: '',
          surname: '',
          birthdate: '',
        });
        setFound(false);
        customerSelectorModalRef.current?.show();
        return;
      }

      // Assign
      setFound(true);
      setClientList(
        response.data.map(client => ({
          document: client.document,
          name: client.name,
          surname: client.surname,
          email: client.email,
          phone: client.phone,
          brandcode: client.brand.code,
          code: client.code,
          birthdate: client.birthdate,
        })),
      );

      // Check if has one registry
      if (response.data.length === 1) {
        let selectedInfo = {
          document: response.data[0].document,
          name: response.data[0].name,
          surname: response.data[0].surname,
          email: response.data[0].email,
          phone: response.data[0].phone,
          brandcode: response.data[0].brand.code,
          code: response.data[0].code,
          birthdate: response.data[0].birthdate,
        };

        if (selectedInfo.birthdate) {
          const splitBirthdate = selectedInfo.birthdate.slice(0, 10).split('-');

          const formattedBirthDate = `${splitBirthdate[2]}/${splitBirthdate[1]}/${splitBirthdate[0]}`;

          selectedInfo = {
            ...selectedInfo,
            birthdate: formattedBirthDate,
          };
        }

        setSelectedClientInfo(selectedInfo);
      } else {
        setSelectedClientInfo(undefined);
      }
      customerSelectorModalRef.current?.show();
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);
      setLoading(false);
    }
  }, [
    customerSelectorModalRef,
    searchDocumentNumber,
    setClientList,
    setFound,
    setInitialClientDocument,
    setSelectedClientInfo,
    t,
  ]);

  // Clear typed when brand changes
  useEffect(() => {
    setTypedCode('');
  }, [product.selectedBrandCode]);

  useEffect(() => {
    setDocumentError('');
    if (searchParam && searchDocumentNumber?.length === 11) {
      // Document validation
      if (!validateCpf({ code: searchDocumentNumber })) {
        const error = t('CHECK_DOCUMENT_INVALID_CPF', 'CPF inválido.');
        setDocumentError(error);
        return;
      }

      searchDocument();
    }
  }, [
    searchDocument,
    searchParam,
    searchDocumentNumber,
    searchDocumentNumber?.length,
    t,
  ]);

  const documentInputEndAdornment =
    searchDocumentNumber || bagCustomerDocument ? (
      <button
        type="button"
        className="deleteButton"
        onClick={() => {
          setSearchDocumentNumber('');
          setBagCustomerDocument('');
        }}
      >
        <MdClose />
      </button>
    ) : (
      <div className="endAdornmentSpace" />
    );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
        <Input
          label={t('DOCUMENT', 'CPF')}
          disabled={loading}
          error={documentError}
          onChange={(value: any) => {
            setSearchDocumentNumber(formatDocument(String(value).trim()));
            setBagCustomerDocument('');
          }}
          value={searchDocumentNumber || bagCustomerDocument || ' '}
          mask="999.999.999-99"
          type="tel"
          inputMode="numeric"
          pattern="[0-9]*"
          endAdornment={documentInputEndAdornment}
        />
      </Form>
    </Container>
  );
};

export { AddByDocument };
