import { Form } from '@unform/web';
import { MdConfirmationNumber, MdRemove } from 'react-icons/md';
import React, { useRef, useCallback } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';

// Component import
import { Button } from '@components/Button';
import { Input } from '@components/Input';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
}

// Feature identification
const featureKey = '@create_order/COUPON';

const Coupon: React.FC<IProps> = ({ hide }) => {
  // Hooks
  const order = useOrderCreation();
  const { t } = useTranslation(featureKey);

  // Local refs
  const formRef = useRef<FormHandles>(null);

  // Handle submit
  const handleSubmit: SubmitHandler<{
    coupon: string;
  }> = useCallback(
    data => {
      // Update coupon
      order.setCoupon({
        name: data.coupon,
      });

      // Close modal
      if (typeof hide === 'function') hide();
    },
    [order, hide],
  );

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
        <Input
          name="coupon"
          label={t('COUPON', 'Cupom')}
          value={order.coupon?.name}
          disabled={order.loading}
          onEnterKey={() => formRef.current?.submitForm()}
        />
      </Form>
      <Button
        disabled={order.loading}
        onClick={() => {
          formRef.current?.submitForm();
        }}
        startIcon={<MdConfirmationNumber />}
        color="success"
      >
        {t('SAVE_COUPON', 'SALVAR')}
      </Button>
      {order.coupon && (
        <Button
          disabled={order.loading}
          onClick={() => {
            order.setCoupon({});
            if (typeof hide === 'function') hide();
          }}
          startIcon={<MdRemove />}
          color="danger"
          variant="outlined"
        >
          {t('REMOVE_COUPON', 'REMOVER CUPOM')}
        </Button>
      )}
    </Container>
  );
};

export { Coupon };
