import React from 'react';
import { useParams } from 'react-router-dom';
import { MdOutlinePlace } from 'react-icons/md';

// Hook import
import { useTheme } from '@hooks/useTheme';
import { useTranslation } from '@hooks/useTranslation';
import { useOrder } from '@hooks/useOrder';

// Util import
import { getCEPFormat } from '@util/getDocumentFormat';

import { Container } from './styles';

// Feature identification
const featureKey = '@order/ADDRESS_METADATA';

const AddressInfo: React.FC = () => {
  const theme = useTheme();
  const { id } = useParams<{ id?: string }>();
  const order = useOrder(id);
  const { t } = useTranslation(featureKey);

  return (
    <>
      {!!order?.data?.order?.shipping_zipcode &&
        !!order?.data?.order?.shipping_city && (
          <Container>
            <div className="icon">
              <MdOutlinePlace color={theme.font_secondary} size={25} />
            </div>
            <div>
              <span>{order?.data?.order?.shipping_street}</span>
            </div>
            <div>
              <span className="number">
                {t('ADDRESS_NUMBER_PRE', 'No')}{' '}
                {order?.data?.order?.shipping_number}
                {order?.data?.order?.shipping_complement
                  ? ` - ${order?.data?.order?.shipping_complement}`
                  : ''}
              </span>
            </div>
            <div>
              <span>
                {order?.data?.order?.shipping_neighborhood} -{' '}
                {order?.data?.order?.shipping_city}/
                {order?.data?.order?.shipping_state}
              </span>
            </div>
            <div>
              <span>{t('POSTAL_CODE', 'CEP: ')}</span>
              <span>{getCEPFormat(order?.data?.order?.shipping_zipcode)}</span>
            </div>
          </Container>
        )}
    </>
  );
};

export { AddressInfo };
