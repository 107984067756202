import React from 'react';

import { DesktopMetadata } from './DesktopMetadata';
import { MobileMetadata } from './MobileMetadata';

// Style import
import { Container } from './styles';

const ClientMetadata: React.FC = () => {
  return (
    <Container>
      <div className="desktopMetadata">
        <DesktopMetadata />
      </div>
      <div className="mobileMetadata">
        <MobileMetadata />
      </div>
    </Container>
  );
};

export { ClientMetadata };
