import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;
  height: fit-content;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .infoCard {
    height: fit-content;
    width: 100%;
    padding: 1.5rem;
    border: none;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.background_primary};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .cardTitle {
      display: flex;
      width: 100%;
      align-self: stretch;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }) => theme.font_secondary};

      .titleText {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;

        p:first-of-type {
          font-size: 1.5rem;
        }
      }

      .editButton {
        color: ${({ theme }) => theme.font_secondary};
        line-height: 1.5rem;
        text-decoration: underline;
      }
    }

    .infoSection {
      display: flex;
      flex-direction: column;
      color: ${({ theme }) => theme.font_primary};
      gap: 0.5rem;
      margin-top: 1.3rem;
    }
  }
`;
