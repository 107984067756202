import React from 'react';

// Hook import
import { useWindowSize } from '@hooks/useWindowSize';

// Components import
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';

interface IProps {
  handleSelectPackageType(type: string): void;
  selectedPackageType: string;
  setPackageType: React.Dispatch<
    React.SetStateAction<'OWN_STOCK' | 'DELIVERY'>
  >;
  setSelectedPackageType: React.Dispatch<
    React.SetStateAction<'OWN_STOCK' | 'DELIVERY' | 'none'>
  >;
  page: string;
}

const PackageOptions: React.FC<IProps> = ({
  handleSelectPackageType,
  selectedPackageType,
  setPackageType,
  setSelectedPackageType,
  page,
}) => {
  // Hooks
  const [width] = useWindowSize();

  return (
    <>
      {width > 768 ? (
        <DesktopLayout
          handleSelectPackageType={handleSelectPackageType}
          selectedPackageType={selectedPackageType}
          page={page}
        />
      ) : (
        <MobileLayout
          selectedPackageType={selectedPackageType}
          setPackageType={setPackageType}
          setSelectedPackageType={setSelectedPackageType}
        />
      )}
    </>
  );
};

export { PackageOptions };
