import React, { useMemo, useRef } from 'react';
import Radio from '@material-ui/core/Radio';

// Asset import
import { EditIcon } from '@assets/index';

// Component import
import { Modal, IModalRef } from '@components/Modal';
import { CheckboxHandmade } from '@components/CheckboxHandmade';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';
import { IPayment } from '@hooks/useOrderCreation/models';

import { IPaymentTypes } from '@util/CartModels';

// Modal import
import { EditPaymentAmountModal } from './EditPaymentAmountModal';

// Style import
import { Container } from './styles';

interface IDefaultButtonProps {
  toggleIsSelected?: boolean;
  icon?: any;
  payment?: any;
  availablePaymentTypes: IPaymentTypes[];
}

const DefaultButton: React.FC<IDefaultButtonProps> = ({
  toggleIsSelected,
  icon,
  payment,
  availablePaymentTypes,
}) => {
  // Feature identification
  const featureKey = '@create_order/PAYMENT';

  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const isMethodSelected = order?.payments.some(
    item => item.code === payment.code,
  );

  // Local refs
  const editPaymentAmountModalRef = useRef<IModalRef>(null);

  const currentPaymentAmount =
    order?.payments.filter(item => item.code === payment.code)[0] &&
    order?.payments.filter(item => item.code === payment.code)[0].amount;

  const currentPaymentQuantity = order?.payments.length;

  const storeCreditPaymentInfo = availablePaymentTypes.find(
    item => item.code === 'store_credit',
  );

  const setSelectedClass = isMethodSelected ? 'selected' : '';

  const hasStoreCreditBeenSelected: IPayment[] = order.payments.filter(
    item => item.code === 'store_credit',
  );
  const storeCreditTotalAmount = hasStoreCreditBeenSelected?.[0]?.amount || 0;

  const isOnlyStoreCreditAmount = storeCreditTotalAmount === order.totals.total;

  const handleClick = () => {
    const currentPaymentData = order.payments;
    const currentPaymentDataLength = order.payments.length;

    const isCurrentPaymentSelected = currentPaymentData.some(
      item => item.code === payment?.code,
    );

    if (toggleIsSelected) {
      if (isCurrentPaymentSelected) {
        const newPaymentData = currentPaymentData.filter(
          item => item.code !== payment?.code,
        );
        if (newPaymentData.length > 0) {
          if (
            newPaymentData[0].code === 'store_credit' &&
            storeCreditPaymentInfo
          ) {
            newPaymentData[0].amount = storeCreditPaymentInfo.payload.total;
          } else {
            newPaymentData[0].amount = order.totals.total;
          }
          order.setPayments(newPaymentData);
          return;
        }
        order.setPayments(newPaymentData);
        return;
      }

      let newTotal;
      switch (currentPaymentDataLength) {
        case 0:
          currentPaymentData.push({
            code: payment?.code,
            payload: {},
            amount: order.totals.total,
            time_limit: payment?.time_limit,
          });
          break;
        case 1:
          if (currentPaymentData[0].amount) {
            if (!(currentPaymentData[0].code === 'store_credit')) {
              currentPaymentData[0].amount = Math.floor(order.totals.total / 2);
            }
            newTotal = order.totals.total - currentPaymentData[0].amount;
            currentPaymentData.push({
              code: payment?.code,
              payload: {},
              amount: newTotal,
              time_limit: payment?.time_limit,
            });
          }
          break;

        case 2:
          currentPaymentData.pop();
          if (currentPaymentData[0].amount) {
            if (
              currentPaymentData[0].code === 'store_credit' &&
              storeCreditPaymentInfo
            ) {
              currentPaymentData[0].amount =
                storeCreditPaymentInfo.payload.total;
            } else {
              currentPaymentData[0].amount = Math.floor(order.totals.total / 2);
            }
            if (currentPaymentData[0].amount) {
              newTotal = order.totals.total - currentPaymentData[0].amount;
            }
            currentPaymentData.push({
              code: payment?.code,
              payload: {},
              amount: newTotal,
              time_limit: payment?.time_limit,
            });
          }
          break;

        default:
          break;
      }

      order.setPayments(currentPaymentData);
      return;
    }

    if (isCurrentPaymentSelected) {
      const newPaymentData = currentPaymentData.filter(
        item => item.code !== payment?.code,
      );
      order.setPayments(newPaymentData);
      return;
    }
    if (currentPaymentDataLength > 0) {
      currentPaymentData.pop();
    }
    currentPaymentData.push({
      code: payment?.code,
      payload: {},
      amount: order.totals.total,
      time_limit: payment?.time_limit,
    });
    order.setPayments(currentPaymentData);
  };

  // Modals
  const modals = useMemo(
    () => (
      <Modal ref={editPaymentAmountModalRef} size="small-2">
        <EditPaymentAmountModal
          hide={() => {
            editPaymentAmountModalRef.current?.hide();
          }}
          payment={payment}
          currentPaymentAmount={currentPaymentAmount}
        />
      </Modal>
    ),
    [currentPaymentAmount, payment],
  );

  return (
    <Container
      // type="button"
      disabled={order?.loading}
      // onClick={() => handleClick()}
      className={setSelectedClass}
    >
      <div className="paymentContent">
        <div className="contentColumn">
          <div className="paymentTitleRow">
            {icon}
            <span>
              {payment?.name ||
                t('UNKNOWN_PAYMENT_METHOD_LABEL', 'MÉTODO NÃO IDENTIFICADO')}
            </span>
          </div>
          {currentPaymentAmount &&
            currentPaymentQuantity > 1 &&
            !order?.loading &&
            toggleIsSelected && (
              <div className="valueRow">
                <p>{t('AMOUNT_TO_PAY', 'Valor a Pagar: ')}</p>
                <p className="value">
                  R$ {(currentPaymentAmount / 100).toFixed(2)}
                </p>
                <button
                  type="button"
                  className="editRow"
                  onClick={() => editPaymentAmountModalRef.current?.show()}
                >
                  <div className="icon">
                    <EditIcon />
                  </div>
                </button>
              </div>
            )}
        </div>
        <div className="actionColumn">
          {toggleIsSelected ? (
            <div className="itemCheckbox">
              <CheckboxHandmade
                checked={isMethodSelected}
                onChange={() => handleClick()}
                size="normal"
                disabled={isOnlyStoreCreditAmount}
              />
            </div>
          ) : (
            <div className="itemButton">
              <Radio
                checked={isMethodSelected}
                color="primary"
                onClick={() => handleClick()}
              />
            </div>
          )}
        </div>
      </div>
      {modals}
    </Container>
  );
};

export { DefaultButton };
