import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { useHistory, useLocation } from 'react-router-dom';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';

// Action import
import { sessionRefreshRequest } from '@store/modules/auth/actions';

// Page import
import { Cart } from './Cart';
import { Salecode } from './Salecode';
import { StoreClosed } from './StoreClosed';

const Content: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const order = useOrderCreation();
  const location = useLocation();
  const history = useHistory();
  const bag_id = useSelector(state => state.ui.bag_id);

  // Global states
  const { store_is_open } = useSelector(state => state.auth);

  // Interval to handle opening period
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(sessionRefreshRequest());
    }, 600000);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('addProduct')) {
      queryParams.delete('addProduct');
      order.setAddingProduct(true);
      history.replace({
        search: queryParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Store closed
  if (!store_is_open) return <StoreClosed />;

  // Sale code selection
  if (!order.saleCode.code && !order.saleCode.name && !bag_id) {
    return <Salecode />;
  }

  return (
    <AnimatePresence initial={false}>
      <Cart key="cart" />
    </AnimatePresence>
  );
};

// Consumer
const CreateOrder: React.FC = () => {
  return (
    <AnimatePresence initial={false}>
      <Content key="create-order" />
    </AnimatePresence>
  );
};

export { CreateOrder };
