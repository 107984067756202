import React, { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

// Component import
import { DDIList } from './DDIList';

// Style import
import { Container } from './styles';

interface IProps {
  customerCountryCode?: string;
  setCustomerCountryCode(code: string): void;
}

const CountryCodeSelect: React.FC<IProps> = ({
  customerCountryCode,
  setCustomerCountryCode,
}) => {
  const [isListVisible, setIsListVisible] = useState(false);

  return (
    <Container>
      <div className="titleRow">DDI</div>
      <button
        className="inputBox"
        type="button"
        onClick={() => setIsListVisible(prev => !prev)}
      >
        <p>{customerCountryCode}</p>
        <MdKeyboardArrowDown />
      </button>
      <DDIList
        visible={isListVisible}
        setVisibility={setIsListVisible}
        customerCountryCode={customerCountryCode}
        setCustomerCountryCode={setCustomerCountryCode}
      />
    </Container>
  );
};

export { CountryCodeSelect };
