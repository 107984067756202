import React, { useCallback, useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { toast } from 'react-toastify';

// Asset import
import DefaultProductImage from '@assets/defaultProductImage.png';

// Component import
import { ImageCarousel } from '@components/ImageCarousel';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';
import { useTheme } from '@hooks/useTheme';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { preloadImages } from '@util/preloadImages';

// Style import
import { Container, ProductListContainer } from './styles';

// Feature identification
const featureKey = '@create_order/PRODUCT_LIST';

const ProductsList: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const product = useAddProduct();
  const theme = useTheme();

  // Local states
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsList, setProductsList] = useState(product.productsList);

  const headerText =
    product.searchedTerm?.type === 'ref'
      ? `de Referência`
      : `de Nome de Produto `;

  // Open action
  const handleProductOpen = useCallback(
    (reference_and_color: string) => {
      // Request product information to backend
      try {
        product.getProduct({
          code: reference_and_color,
        });
      } catch (err) {}
    },
    [product],
  );

  useEffect(() => {
    document
      ?.getElementById('productListHeader')
      ?.scrollIntoView({ behavior: 'smooth' });

    preloadImages(
      Array.from(product.productsList).flatMap((item: any) =>
        Array.from(item.image_urls),
      ),
    );
  }, [product.productsList]);

  const searchProductList = useCallback(async () => {
    setLoading(true);

    try {
      const params = {
        brandcode: product.selectedBrandCode,
        text: product.searchedTerm?.search,
        page: currentPage,
        pageSize: 10,
      };
      // API call
      const response = await api.get('/v1/product/list', {
        params,
      });

      // Validation
      if (!Array.isArray(response.data.data)) throw new Error();

      // Result
      const new_result = [...productsList, ...response.data.data];

      setProductsList(new_result);

      setLoading(false);

      if (currentPage < 3) {
        setCurrentPage(currentPage + 1);
      }
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [
    product.selectedBrandCode,
    product.searchedTerm?.search,
    currentPage,
    productsList,
    t,
  ]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setCurrentPage(currentPageInsideState => currentPageInsideState + 1);
      }
    });
    const sentinelDiv = document.querySelector('#sentinel');

    if (sentinelDiv) {
      intersectionObserver.observe(sentinelDiv);
    }
    return () => intersectionObserver.disconnect();
  }, []);

  useEffect(() => {
    setReady(true);
  }, []);

  // Initial load
  useEffect(() => {
    if (!ready) return;
    searchProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <Container>
      <button
        className="header"
        type="button"
        onClick={() => product.setProductsList([])}
        id="productListHeader"
      >
        <MdArrowBack />
        <p>
          {t(
            'SEARCH_RESULTS',
            `Resultados ${headerText} "${product?.searchedTerm?.search}"`,
          )}
        </p>
      </button>
      <ProductListContainer>
        {productsList.map(item => (
          <li key={item.id}>
            <button
              type="button"
              disabled={product.loading}
              onClick={() => handleProductOpen(item.reference_and_color)}
            >
              <div className="info">
                <div className="top">
                  <span className="name">{item.name}</span>
                  <span className="code">{item.reference_and_color}</span>
                </div>
              </div>
              {item.image_urls && item.image_urls?.length ? (
                <div className="image">
                  <ImageCarousel images={item.image_urls} />
                </div>
              ) : (
                <img
                  src={DefaultProductImage}
                  alt={t(
                    'ADD_BY_REF_COLOR_AND_TEXT_DEFAULT_IMAGE',
                    'Imagem Ilustrativa do Produto',
                  )}
                />
              )}
            </button>
          </li>
        ))}
        <div id="sentinel" />
        {loading && (
          <div style={{ justifySelf: 'center' }}>
            <LoadingIndicator color={theme.font_secondary} size={3} />
          </div>
        )}
      </ProductListContainer>
    </Container>
  );
};

export { ProductsList };
