import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Component import
import { ISelectOptions } from '@components/Select';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useOrderCreation } from '@hooks/useOrderCreation';

// Service import
import { api, getResponseError } from '@services/api';

// Model import
import {
  IGetProduct,
  IGetProductToEdit,
  IProductVariation,
  IAddProductParams,
  IEditProductParams,
  IListProductItemDTO,
  ISearchedTerm,
} from './models';

// Feature identification
const featureKey = '@create_order/ADD_PRODUCT_CONTEXT';

export interface IAddProductContextData {
  // Brand selector
  selectedBrandCode: string;
  setSelectedBrandCode(selectedBrandCode: string): void;

  // TODO Product identifier

  // Product variation identifier
  variations: IProductVariation[];
  setVariations(variations: IProductVariation[]): void;

  // Product variation identifier to edit
  variationsToEdit: IProductVariation[];
  setVariationsToEdit(variations: IProductVariation[]): void;

  variationSelectOptions: ISelectOptions[];
  editionSelectOptions: ISelectOptions[];

  selectedVariationId: string;
  setSelectedVariationId(variationId: string): void;

  productsList: IListProductItemDTO[];
  setProductsList(products: IListProductItemDTO[]): void;

  searchedTerm?: ISearchedTerm;
  setSearchedTerm(searchedTerm: ISearchedTerm): void;

  // Loading states
  adding: boolean;
  setAdding(adding: boolean): void;

  editing: boolean;
  setEditing(editing: boolean): void;

  currentEditingEan: string;
  currentEditingPackageType: 'OWN_STOCK' | 'DELIVERY';
  setCurrentEditingPackageType(packageType: 'OWN_STOCK' | 'DELIVERY'): void;

  loading: boolean;

  // Actions
  resetVariations(): void;
  addToCart(params: IAddProductParams): Promise<void>;
  replaceToCart(params: IEditProductParams): Promise<void>;
  getProduct(params: IGetProduct): Promise<void>;
  getProductToEdit(params: IGetProductToEdit): Promise<void>;
}

// Context
export const AddProductContext = createContext<IAddProductContextData>(
  {} as IAddProductContextData,
);

// Provider
export const AddProductProvider: React.FC = ({ children }) => {
  // Hooks
  const order = useOrderCreation();
  const { t } = useTranslation(featureKey);

  // Global states
  const user = useSelector(state => state.auth);

  // Brand selector
  const validBrands = user.store?.brands?.filter(
    storeBrand => storeBrand.enabled && storeBrand.brand.enabled,
  );
  const [selectedBrandCode, setSelectedBrandCode] = useState(
    validBrands?.length === 1 ? validBrands[0].brand.code : '',
  );

  // Product variation identifier
  const [variations, setVariations] = useState<IProductVariation[]>([]);
  const [variationsToEdit, setVariationsToEdit] = useState<IProductVariation[]>(
    [],
  );
  const [variationSelectOptions, setProductVariationSelectOptions] = useState<
    ISelectOptions[]
  >([]);
  const [editionSelectOptions, setProductEditionSelectOptions] = useState<
    ISelectOptions[]
  >([]);
  const [selectedVariationId, setSelectedVariationId] = useState('');
  const [productsList, setProductsList] = useState<IListProductItemDTO[]>([]);
  const [searchedTerm, setSearchedTerm] = useState<ISearchedTerm>();
  const [currentEditingEan, setCurrentEditingEan] = useState<string>('');
  const [currentEditingPackageType, setCurrentEditingPackageType] = useState<
    'OWN_STOCK' | 'DELIVERY'
  >('OWN_STOCK');

  // Loading states
  const [getLoading, setGetLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);

  // General loading
  const loading = order.loading || adding || getLoading;
  // When variations changes
  useEffect(() => {
    // Clear selected variation
    setSelectedVariationId('');

    // Build variation options
    setProductVariationSelectOptions(
      variations.map(variation => ({
        label: `${variation.size} (${
          user.store?.package_types.some(
            store_package_type =>
              store_package_type.enabled &&
              store_package_type.package_type.enabled &&
              store_package_type.package_type.code === 'own_stock',
          )
            ? user.store?.package_types.some(
                store_package_type =>
                  store_package_type.enabled &&
                  store_package_type.package_type.enabled &&
                  store_package_type.package_type.code === 'infinite_aisle',
              )
              ? variation.shipping?.available
                ? t('OWN_STOCK_AND_INFINITE_AISLE', 'Levar ou Receber')
                : t('OWN_STOCK', 'Levar')
              : t('OWN_STOCK', 'Levar')
            : user.store?.package_types.some(
                store_package_type =>
                  store_package_type.enabled &&
                  store_package_type.package_type.enabled &&
                  store_package_type.package_type.code === 'infinite_aisle',
              ) && variation.shipping?.available
            ? t('INFINITE_AISLE', 'Receber')
            : t('NOT_AVAILABLE', 'Não disponível')
        })`,
        value: variation.id,
      })),
    );

    setProductEditionSelectOptions(
      variationsToEdit.map(variation => ({
        label: `${variation.size} (${
          user.store?.package_types.some(
            store_package_type =>
              store_package_type.enabled &&
              store_package_type.package_type.enabled &&
              store_package_type.package_type.code === 'own_stock',
          )
            ? user.store?.package_types.some(
                store_package_type =>
                  store_package_type.enabled &&
                  store_package_type.package_type.enabled &&
                  store_package_type.package_type.code === 'infinite_aisle',
              )
              ? variation.shipping?.available
                ? t('OWN_STOCK_AND_INFINITE_AISLE', 'Levar ou Receber')
                : t('OWN_STOCK', 'Levar')
              : t('OWN_STOCK', 'Levar')
            : user.store?.package_types.some(
                store_package_type =>
                  store_package_type.enabled &&
                  store_package_type.package_type.enabled &&
                  store_package_type.package_type.code === 'infinite_aisle',
              ) && variation.shipping?.available
            ? t('INFINITE_AISLE', 'Receber')
            : t('NOT_AVAILABLE', 'Não disponível')
        })`,
        value: variation.id,
      })),
    );
  }, [t, variations, user.store, variationsToEdit]);

  // Actions
  const resetVariations = useCallback(() => {
    setVariations([]);
    setVariationsToEdit([]);
    setProductVariationSelectOptions([]);
    setProductEditionSelectOptions([]);
    setSelectedVariationId('');
    // setSelectedEditionId('');
  }, []);

  // Get product action
  const getProduct = useCallback(
    async ({ code }: IGetProduct) => {
      if (loading || !code) return;

      setGetLoading(true);

      // Check if result is reference or ean
      let reference = '';
      const refAndColorCheck = String(code).split('_');
      if (refAndColorCheck.length > 1)
        reference = `${refAndColorCheck[0]}_${refAndColorCheck[1]}`;

      // Request product information to backend
      try {
        // API call
        const response = await api.get('/product', {
          params: {
            salecode: order.saleCode.code,
            brandcode: Number(selectedBrandCode),
            ...(reference
              ? { ref: reference }
              : {
                  ean: code,
                }),
            ...(order.coupon && {
              coupon: order.coupon,
            }),
            ...(order.address?.zipcode && {
              zipcode: order.address.zipcode,
            }),
          },
        });

        // Check if there are variations
        if (
          !Array.isArray(response.data.size_variations) ||
          !response.data.size_variations?.length ||
          !response.data.reference ||
          !response.data.color ||
          !response.data.name
        ) {
          const error = t(
            'MISSING_VARIATIONS_ON_RESPONSE',
            'Não foram encontrados os tamanhos deste produto.',
          );
          toast.error(error);
          setGetLoading(false);
          return;
        }

        // Get size from ean
        const size = String(code).match(/([A-Z]+)$/g);

        // Set variations
        setVariations(
          response.data.size_variations.map((variation: any) => ({
            ...variation,
            name: response.data.name,
            reference: response.data.reference,
            color: response.data.color,
          })),
        );

        // Open select
        if (
          response.data.size_variations.some(
            (variation: any) => variation.size === size?.[0],
          )
        )
          setSelectedVariationId(
            response.data.size_variations.find(
              (variation: any) => variation.size === size?.[0],
            )?.id || '',
          );

        setGetLoading(false);
      } catch (err: any) {
        const { message } = getResponseError(err, t);
        toast.error(message);
        setGetLoading(false);
        throw err;
      }
    },
    [
      loading,
      order.address.zipcode,
      order.coupon,
      order.saleCode.code,
      selectedBrandCode,
      t,
    ],
  );

  // Get product action to edit
  const getProductToEdit = useCallback(
    async ({ code, brand_code, ean }: IGetProductToEdit) => {
      if (loading || !code) return;
      setGetLoading(true);

      // Check if result is reference or ean
      let reference = '';
      const refAndColorCheck = String(code).split('_');
      if (refAndColorCheck.length > 1)
        reference = `${refAndColorCheck[0]}_${refAndColorCheck[1]}`;

      // Request product information to backend
      try {
        // API call
        const response = await api.get('/product', {
          params: {
            salecode: order.saleCode.code,
            brandcode: brand_code,
            ...(reference
              ? { ref: reference }
              : {
                  ean: code,
                }),
            ...(order.coupon && {
              coupon: order.coupon,
            }),
            ...(order.address?.zipcode && {
              zipcode: order.address.zipcode,
            }),
          },
        });

        // Check if there are variations
        if (
          !Array.isArray(response.data.size_variations) ||
          !response.data.size_variations?.length ||
          !response.data.reference ||
          !response.data.color ||
          !response.data.name
        ) {
          const error = t(
            'MISSING_VARIATIONS_ON_RESPONSE',
            'Não foram encontrados os tamanhos deste produto.',
          );
          toast.error(error);
          setGetLoading(false);
          return;
        }

        // Get size from ean
        const size = String(code).match(/([A-Z]+)$/g);

        // Set variations
        setVariationsToEdit(
          response.data.size_variations.map((variation: any) => ({
            ...variation,
            name: response.data.name,
            reference: response.data.reference,
            color: response.data.color,
          })),
        );

        // Open select
        if (
          response.data.size_variations.some(
            (variation: any) => variation.size === size?.[0],
          )
        )
          setSelectedVariationId(
            response.data.size_variations.find(
              (variation: any) => variation.size === size?.[0],
            )?.id || '',
          );
        setCurrentEditingEan(ean);
        setGetLoading(false);
      } catch (err: any) {
        const { message } = getResponseError(err, t);
        toast.error(message);
        setGetLoading(false);
        throw err;
      }
    },
    [loading, order.address.zipcode, order.coupon, order.saleCode.code, t],
  );

  // Add item to cart
  const addToCart = useCallback(
    async (params: IAddProductParams) => {
      // Try to get selected item
      const selected_item = variations.find(
        variation => String(variation.id) === String(selectedVariationId),
      );

      // If item not set
      if (!selected_item) {
        const error = t(
          '@order_product/SELECTED_ITEM_NOT_FOUND',
          'Item selecionado não encontrado.',
        );
        toast.error(error);
        return;
      }

      // Check infinite aisle permission
      if (
        params.packageTypeCode === 'DELIVERY' &&
        !user.store?.package_types.some(
          store_package_type =>
            store_package_type.enabled &&
            store_package_type.package_type.enabled &&
            store_package_type.package_type.code === 'infinite_aisle',
        )
      ) {
        const error = t(
          'STORE_WITHOUT_INIFNITE_AISLE_PERMISSION',
          'Esta loja ainda não pode vender prateleira infinita pelo Soma Store.',
        );
        toast.error(error);
        return;
      }

      // Check own stock permission
      if (
        params.packageTypeCode === 'OWN_STOCK' &&
        !user.store?.package_types.some(
          store_package_type =>
            store_package_type.enabled &&
            store_package_type.package_type.enabled &&
            store_package_type.package_type.code === 'own_stock',
        )
      ) {
        const error = t(
          'STORE_WITHOUT_OWN_STOCK_PERMISSION',
          'Esta loja ainda não pode vender estoque próprio pelo Soma Store.',
        );
        toast.error(error);
        return;
      }

      // Check if is infinite aisle but item not available
      if (
        params.packageTypeCode === 'DELIVERY' &&
        (!selected_item.shipping?.available ||
          !selected_item.shipping?.options?.length)
      ) {
        const error = t(
          'ITEM_NOT_AVAILABLE_FOR_SHIPPING',
          'Este item não está disponível para entrega.',
        );
        toast.error(error);
        return;
      }

      // Get items
      const items = Array.from(order.items);

      // Get item index
      const existingIndex = items.findIndex(
        item =>
          String(item.ean) === String(selected_item.ean) &&
          String(item.shipping.selected_type) === params.packageTypeCode &&
          !item.attachments,
      );

      // Get existing item
      const existingItem =
        existingIndex >= 0 && items.splice(existingIndex, 1)[0];

      // Add product to cart
      order
        .setItems([
          {
            code: selected_item.id.toString(),
            quantity: existingItem
              ? existingItem.quantity + 1
              : params.initialQuantity,
            brand_code: Number(selectedBrandCode),
            name: selected_item.name,
            ean: selected_item.ean,
            image_url: selected_item.image_url,
            size: selected_item.size,
            reference: selected_item.reference,
            color: selected_item.color,
            shipping: {
              selected_type: params.packageTypeCode,
              selected_id:
                params.packageTypeCode === 'DELIVERY'
                  ? selected_item.shipping.options.find(
                      option => option.id === 'Normal',
                    )?.id ||
                    selected_item.shipping.options[0]?.id ||
                    ''
                  : 'own_stock',
              available: selected_item.shipping.available,
              available_quantity: 1,
              options: selected_item.shipping.options.map(option => ({
                id: option.id,
                name: option.name,
                original_price: option.original_price,
                current_price: option.current_price,
                business_days_to_delivery: option.business_days_to_delivery,
                type: option.type,
              })),
            },
            attachments: {
              name: params.attachments?.name || '',
              email: params.attachments?.email || '',
              date: params.attachments?.date || new Date(),
            },

            unit_original_price: selected_item.original_price,
            unit_current_price: selected_item.current_price,
            total_original_price:
              selected_item.shipping.options.find(
                option => option.id === 'Normal',
              )?.original_price ||
              selected_item.shipping.options[0]?.original_price ||
              0,
            total_current_price:
              selected_item.shipping.options.find(
                option => option.id === 'Normal',
              )?.current_price ||
              selected_item.shipping.options[0]?.current_price ||
              0,
          },
          ...items,
        ])
        .then(() => {
          order.setAddingProduct(false);
          setProductsList([]);
          setSearchedTerm({});
          setProductVariationSelectOptions([]);
        })
        .catch(() => null);
    },
    [
      variations,
      user.store?.package_types,
      order,
      selectedBrandCode,
      selectedVariationId,
      t,
    ],
  );

  // Replace item to cart
  const replaceToCart = useCallback(
    async (params: IEditProductParams) => {
      // Try to get selected item
      const selected_item = variationsToEdit.find(
        variation => String(variation.id) === String(selectedVariationId),
      );

      // If item not set
      if (!selected_item) {
        const error = t(
          '@order_product/SELECTED_ITEM_NOT_FOUND',
          'Item selecionado não encontrado.',
        );
        toast.error(error);
        return;
      }

      // Check infinite aisle permission
      if (
        params.packageTypeCode === 'DELIVERY' &&
        !user.store?.package_types.some(
          store_package_type =>
            store_package_type.enabled &&
            store_package_type.package_type.enabled &&
            store_package_type.package_type.code === 'infinite_aisle',
        )
      ) {
        const error = t(
          'STORE_WITHOUT_INIFNITE_AISLE_PERMISSION',
          'Esta loja ainda não pode vender prateleira infinita pelo Soma Store.',
        );
        toast.error(error);
        return;
      }

      // Check own stock permission
      if (
        params.packageTypeCode === 'OWN_STOCK' &&
        !user.store?.package_types.some(
          store_package_type =>
            store_package_type.enabled &&
            store_package_type.package_type.enabled &&
            store_package_type.package_type.code === 'own_stock',
        )
      ) {
        const error = t(
          'STORE_WITHOUT_OWN_STOCK_PERMISSION',
          'Esta loja ainda não pode vender estoque próprio pelo Soma Store.',
        );
        toast.error(error);
        return;
      }

      // Check if is infinite aisle but item not available
      if (
        params.packageTypeCode === 'DELIVERY' &&
        (!selected_item.shipping?.available ||
          !selected_item.shipping?.options?.length)
      ) {
        const error = t(
          'ITEM_NOT_AVAILABLE_FOR_SHIPPING',
          'Este item não está disponível para entrega.',
        );
        toast.error(error);
        return;
      }

      // Get items
      const items = Array.from(order.items);

      // First, we filter all items that aren't currently being edited in the list
      const firstFilteredList = items.filter(
        item =>
          item.ean !== currentEditingEan ||
          (item.ean === currentEditingEan &&
            item.shipping.selected_type !== currentEditingPackageType),
      );

      // Then, we filter items that aren't the same as the one we are now adding to the bag
      const finalFilteredList = firstFilteredList.filter(
        item =>
          item.ean !== selected_item.ean ||
          (item.ean === selected_item.ean &&
            item.shipping.selected_type !== params.packageTypeCode),
      );

      // Get item index
      const existingItemInPackage = items.filter(
        item =>
          String(item.ean) === String(selected_item.ean) &&
          String(item.shipping.selected_type) === params.packageTypeCode,
      );

      // Get existing item
      const thereIsExistingItemInPackage = existingItemInPackage.length === 1;

      // Add product to cart
      order
        .setItems([
          {
            code: selected_item.id.toString(),
            quantity: thereIsExistingItemInPackage
              ? params.initialQuantity + existingItemInPackage[0].quantity
              : params.initialQuantity,
            brand_code: params.brandcode,
            ean: selected_item.ean,
            name: selected_item.name,
            image_url: selected_item.image_url,
            size: selected_item.size,
            reference: selected_item.reference,
            color: selected_item.color,
            shipping: {
              selected_type: params.packageTypeCode,
              selected_id:
                params.packageTypeCode === 'DELIVERY'
                  ? selected_item.shipping.options.find(
                      option => option.id === 'Normal',
                    )?.id ||
                    selected_item.shipping.options[0]?.id ||
                    ''
                  : 'own_stock',
              available: selected_item.shipping.available,
              available_quantity: 1,
              options: selected_item.shipping.options.map(option => ({
                id: option.id,
                name: option.name,
                original_price: option.original_price,
                current_price: option.current_price,
                business_days_to_delivery: option.business_days_to_delivery,
                type: option.type,
              })),
            },
            unit_original_price: selected_item.original_price,
            unit_current_price: selected_item.current_price,
            total_original_price:
              selected_item.shipping.options.find(
                option => option.id === 'Normal',
              )?.original_price ||
              selected_item.shipping.options[0]?.original_price ||
              0,
            total_current_price:
              selected_item.shipping.options.find(
                option => option.id === 'Normal',
              )?.current_price ||
              selected_item.shipping.options[0]?.current_price ||
              0,
          },
          ...finalFilteredList,
        ])
        .then(() => {
          order.setEditingProduct(false);
          // Display message to user
          toast.success(
            t('EDITED_PRODUCT_SUCCESS', 'Produto editado com sucesso.'),
          );
        })
        .catch(() => null);
    },
    [
      variationsToEdit,
      user.store?.package_types,
      order,
      selectedVariationId,
      t,
      currentEditingEan,
      currentEditingPackageType,
    ],
  );

  return (
    <AddProductContext.Provider
      value={{
        // Brand selector
        selectedBrandCode,
        setSelectedBrandCode,

        // Product variation identifier
        variations,
        setVariations,

        variationsToEdit,
        setVariationsToEdit,

        variationSelectOptions,

        editionSelectOptions,

        selectedVariationId,
        setSelectedVariationId,

        productsList,
        setProductsList,

        searchedTerm,
        setSearchedTerm,

        currentEditingEan,
        currentEditingPackageType,
        setCurrentEditingPackageType,

        // Loading states
        adding,
        setAdding,

        editing,
        setEditing,

        loading,

        // Actions
        resetVariations,
        addToCart,
        replaceToCart,
        getProduct,
        getProductToEdit,
      }}
    >
      {children}
    </AddProductContext.Provider>
  );
};

// Hook
export function useAddProduct(): IAddProductContextData {
  // Get data from context
  const context = useContext(AddProductContext);

  // If user is not using context provider (DEV purposes only)
  if (!context)
    throw new Error('useAddProduct must be used within a AddProductProvider');

  return context;
}
