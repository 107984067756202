const removeAccents = (str: string, removeSymbols = true): string => {
  let result = str;

  if (!result) return '';

  const accents = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g,
  };

  Object.entries(accents).forEach(([char, reg]) => {
    result = result.replace(reg, char);
  });

  if (removeSymbols) result = result.replace(/[^a-zA-Z0-9]+/g, '');

  return result;
};

export { removeAccents };
