import React, { useState } from 'react';
import {
  MdWifi,
  MdOutlineLink,
  MdAttachMoney,
  MdReceipt,
  MdOutlineAccountBalanceWallet,
} from 'react-icons/md';

// Asset import
import { PixIcon } from '@assets/index';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';
import { PaymentTypeButton } from '@components/PaymentTypeButton';
import { ToggleSwitch } from '@components/ToggleSwitch';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTheme } from '@hooks/useTheme';
import { useTranslation } from '@hooks/useTranslation';

// Util import
import { IPaymentTypes } from '@util/CartModels';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@create_order/CART_PAYMENT_SELECTOR';

interface IProps {
  availablePaymentTypes: IPaymentTypes[];
  loading: boolean;
}

const PaymentTypeSelector: React.FC<IProps> = ({
  availablePaymentTypes,
  loading,
}) => {
  // Hooks
  const theme = useTheme();
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();

  // Local states
  const [toggleIsSelected, setToggleIsSelected] = useState(false);

  const isStoreCreditAvailable = availablePaymentTypes.some(
    item => item.code === 'store_credit',
  );

  const storeCreditIcon = <MdOutlineAccountBalanceWallet size={30} />;

  const handleToggleChange = () => {
    const currentPaymentDataLength = order.payments.length;

    if (!!toggleIsSelected && currentPaymentDataLength > 1) {
      order.setPayments([]);
    }

    setToggleIsSelected(!toggleIsSelected);
  };

  return (
    <Container>
      <div className="onboardingPaymentTitle">
        <div className="paymentTypeSectionTitle">
          <p>{t('SET_PAYMENT', 'Selecione o meio de pagamento desejado: ')}</p>
        </div>

        <div className="multiplePaymentsSwitchSection">
          <div className="title">
            <p>{t('COMBINE_PAYMENT_METHODS', 'Combinar meios de pagamento')}</p>
          </div>
          <div className="multiplePaymentsSwitch">
            <ToggleSwitch
              handleToggleChange={handleToggleChange}
              toggleIsSwitched={toggleIsSelected}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LoadingIndicator color={theme.font_secondary} size={3} />
        </div>
      ) : (
        <>
          <ul className="onboardingMultiplePaymentTitle">
            {isStoreCreditAvailable && (
              <li
                key={
                  availablePaymentTypes?.find(
                    payment => payment.code === 'store_credit',
                  )?.code
                }
              >
                <PaymentTypeButton
                  availablePaymentTypes={availablePaymentTypes}
                  setToggleIsSelected={setToggleIsSelected}
                  toggleIsSelected={toggleIsSelected}
                  icon={storeCreditIcon}
                  payment={availablePaymentTypes.find(
                    payment => payment.code === 'store_credit',
                  )}
                />
              </li>
            )}
            <div className="onboardingOtherPayments">
              {availablePaymentTypes
                .filter(item => item.code !== 'store_credit')
                .map(payment => {
                  // Get icon
                  let icon: React.ReactNode = <MdAttachMoney size={30} />;

                  switch (payment.code) {
                    case 'manual_physical_card':
                      icon = <MdReceipt size={30} />;
                      break;

                    case 'physical_card':
                      icon = <MdWifi size={30} />;
                      break;

                    case 'store_credit':
                      icon = <MdOutlineAccountBalanceWallet size={30} />;
                      break;

                    case 'payment_link':
                      icon = <MdOutlineLink size={30} />;
                      break;

                    case 'pix':
                      icon = <PixIcon width={30} height={30} />;
                      break;

                    default:
                      icon = <MdAttachMoney size={30} />;
                      break;
                  }

                  return (
                    <li key={payment.code}>
                      <PaymentTypeButton
                        availablePaymentTypes={availablePaymentTypes}
                        setToggleIsSelected={setToggleIsSelected}
                        icon={icon}
                        payment={payment}
                        toggleIsSelected={toggleIsSelected}
                      />
                    </li>
                  );
                })}
            </div>
          </ul>
        </>
      )}
    </Container>
  );
};

export { PaymentTypeSelector };
