import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .regularRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (min-width: 780px) {
      font-size: 1rem;
    }

    .cupom {
      button {
        p {
          font-size: 0.875rem;
          text-decoration: underline;
          color: white;

          @media screen and (min-width: 780px) {
            font-size: 1rem;
          }
        }
      }
    }
    .discountValue {
      color: ${({ theme }) => theme.font_success};
    }
  }

  .totalRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      font-weight: 600;
      font-size: 1.25rem;
      color: ${({ theme }) => theme.font_secondary};
    }
  }
`;
