import React from 'react';

// Component import
import { IModalRef } from '@components/Modal';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';

// Util import
import { StringMask } from '@util/stringMask';

import { InfoCard } from '../InfoCard';

import { Container } from './styles';

interface IProps {
  customerModalRef: React.RefObject<IModalRef>;
  addressmodalRef: React.RefObject<IModalRef>;
}

const ClientInfo: React.FC<IProps> = ({
  customerModalRef,
  addressmodalRef,
}) => {
  // Hooks
  const order = useOrderCreation();

  const getCPFFormat = (cpf: string) => {
    const formattedCPF = `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(
      6,
      9,
    )}-${cpf.slice(9, 11)}`;
    return formattedCPF;
  };

  const getPhoneFormat = (phone: string) => {
    const brazilPhonePattern = '00 (00) 0 0000-0000';
    const internationalPhonePattern = '000-0000-#';
    let maskInstance;
    if (phone.includes('+55')) {
      maskInstance = new StringMask(brazilPhonePattern, {});
    } else {
      maskInstance = new StringMask(internationalPhonePattern, {});
    }
    const adjustedPhoneString = phone.slice(1);
    const formattedPhone = maskInstance.proccess(adjustedPhoneString).result;
    return `+${formattedPhone}`;
  };

  const getCEPFormat = (cep: string) => {
    const formattedCEP = `${cep.slice(0, 5)}-${cep.slice(5, 8)} `;
    return formattedCEP;
  };

  return (
    <Container>
      <InfoCard
        type="identification"
        handleEditClick={() => customerModalRef.current?.show()}
      >
        <div className="infoSection">
          <p>
            {order.customer.name} {order.customer.surname}
          </p>
          <p>{getCPFFormat(order.customer.document)}</p>
          <p>{order.customer.email}</p>
          <p>{getPhoneFormat(order.customer.phone)}</p>
        </div>
      </InfoCard>
      {order.address.street && (
        <InfoCard
          type="address"
          handleEditClick={() => addressmodalRef.current?.show()}
        >
          <div className="infoSection">
            <p>{order.address.street}</p>
            <p>
              {order.address.number}
              {' - '}
              {order.address.complement}{' '}
            </p>
            <p>
              {order.address.city}/{order.address.state}
            </p>
            <p>{getCEPFormat(order.address.zipcode)}</p>
          </div>
        </InfoCard>
      )}
    </Container>
  );
};

export { ClientInfo };
