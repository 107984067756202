import React from 'react';
import { MdOutlineClose, MdWarning } from 'react-icons/md';
import { toast } from 'react-toastify';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  setEmptySavedBags(emptySavedBags: boolean): void;
}

// Feature identification
const featureKey = '@saved_bags/SAVED_BAGS_MODAL';

const DeleteAllBagsModal: React.FC<IProps> = ({ hide, setEmptySavedBags }) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  const handleDeleteAllBags = async () => {
    try {
      // API call
      await api.delete('/v1/cart');

      // Set page as empty
      setEmptySavedBags(true);
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);
    }
  };

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="iconRow">
        <MdWarning />
      </div>
      <div className="textRow">
        <p className="text">
          {' '}
          {t('DELETE_BAGS_ASK', 'Deseja excluir suas sacolas salvas?')}
        </p>
        <p className="addtionalTextRow">
          {t('ADDITIONAL_INFO', 'Esta ação não poderá ser desfeita.')}
        </p>
      </div>

      <div className="actionArea">
        <Button
          variant="outlined"
          color="danger"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          {t('NO', 'NÃO')}
        </Button>
        <Button
          onClick={() => {
            handleDeleteAllBags();
            if (typeof hide === 'function') hide();
          }}
        >
          {t('YES', 'SIM')}
        </Button>
      </div>
    </Container>
  );
};

export { DeleteAllBagsModal };
