import styled from 'styled-components';

export const UniquePaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .closeIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.75rem;
    }
  }

  .iconRow {
    color: ${({ theme }) => theme.font_secondary};
    font-size: 2.75rem;
  }
  .titleRow {
    p {
      font-weight: 600;
      font-size: 1.125rem;
      margin-top: 1.75rem;
      margin-bottom: 1.5rem;
      white-space: pre-line;
    }
  }
  .textRow {
    p {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 2rem;
      white-space: pre-line;
    }
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 1.125rem;
  }
  span:last-child {
    margin-top: 1rem;
    width: 100%;
  }
`;

export const MultiplePaymentContainer = styled.div`
  display: flex;
  flex-direction: column;

  .MuiPaper-root {
    color: ${({ theme }) => theme.font_primary};
    background-color: ${({ theme }) => theme.background_primary};
  }

  .accordionTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .titleText {
      font-weight: 700;
      font-size: 1.125rem;
    }
  }

  svg {
    color: ${({ theme }) => theme.button_high};
  }

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .closeIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.75rem;
    }
  }

  .accordionContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .accordionText {
      padding-left: 3.625rem;
      padding-right: 3.625rem;

      font-weight: 700;
      font-size: 0.875rem;
    }
  }

  .actionArea {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    gap: 1rem;

    .loadingContent {
      align-self: center;
    }
  }
`;
