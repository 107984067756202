import React from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@component/FEATURE_ALERT';

interface IProps {
  hide?: () => any;
  title?: string;
  icon?: React.ReactNode;
  text?: string;
  buttonText?: string;
  buttonFunction?: () => any;
}

const FeatureAlert: React.FC<IProps> = ({
  hide,
  title,
  icon,
  text,
  buttonText,
  buttonFunction,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="titleRow">
        <p>{t('SUPER_NEWS', 'Super Novidade!')}</p>
      </div>
      <div className="iconRow">
        {!!icon && (
          <div className="iconImage">
            <p>{icon}</p>
          </div>
        )}
        {!!title && <div className="iconText">{title}</div>}
      </div>
      {!!text && (
        <div className="textRow">
          <p>{text}</p>
        </div>
      )}
      <div className="actionArea">
        <Button
          onClick={() => {
            if (typeof hide === 'function') hide();
            if (typeof buttonFunction === 'function') buttonFunction();
          }}
        >
          <p className="modalButton">{buttonText || t('CONFIRM', 'ENTENDI')}</p>
        </Button>
      </div>
    </Container>
  );
};

export { FeatureAlert };
