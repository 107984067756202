import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
  align-self: stretch;

  gap: 2rem;
  padding: 2rem 2rem 2rem 3rem;

  &.error {
    width: 30rem;
    max-width: 100%;
    align-self: center;
  }

  h2 {
    color: ${({ theme }) => theme.font_primary};
    font-size: 1.75rem;

    align-self: stretch;
    flex-shrink: 1;
    text-align: center;
  }

  ul {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    flex-wrap: wrap;

    width: 50rem;
    max-width: 90%;
    gap: 2rem;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      span {
        color: ${({ theme }) => theme.font_primary};
        font-size: 1rem;
      }
    }
  }
`;
