import { offset } from '@floating-ui/dom';

export const bagSteps = [
  {
    id: 'shepherd1',
    attachTo: { element: '.productSectionTitle', on: 'bottom-start' as const },
    title: '1/4',
    text: [
      'Os produtos adicionados estarão agrupados por modo de entrega. Confira o modo de entrega acima da lista de produtos.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Sair',
        type: 'cancel',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'PRÓXIMO',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 0,
    modalOverlayOpeningRadius: 8,
    canClickTarget: false,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },
  {
    id: 'shepherd2',
    attachTo: { element: '.onboardItem', on: 'bottom' as const },
    title: '2/4',
    text: [
      'Você poderá alterar modo de entrega, tamanho e quantidade na área de produto.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Próximo',
        type: 'next',
      },
    ],
    canClickTarget: false,
    modalOverlayOpeningPadding: 0,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },
  {
    id: 'shepherd3',
    attachTo: { element: '.totalsSection', on: 'top-end' as const },
    title: '3/4',
    text: [
      'Aqui você pode consultar o valor total da venda, entre produtos, descontos e entrega. Cupons que não precisem de identificação para serem validados podem ser adicionados neste momento.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Próximo',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
  {
    id: 'shepherd4',
    attachTo: { element: '.shepherdFooter', on: 'top' as const },
    title: '4/4',
    text: [
      'Nesta etapa, você ainda poderá adicionar produtos ou seguir para a etapa de identificação de cliente.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Finalizar',
        type: 'complete',
      },
    ],
    modalOverlayOpeningRadius: 8,
    canClickTarget: false,
    scrollTo: true,
  },
];
