import React from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Component import
import { PackageSummary } from '@components/PackagesSummary';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { MobileOrderSummary } from './styles';

// Feature identification
const featureKey = '@create_order/MOBILE_SUMMARY';

interface IProps {
  mobileOrderSummary: boolean;
  setMobileOrderSummary: (value: boolean) => void;
}

const MobileSummary: React.FC<IProps> = ({
  mobileOrderSummary,
  setMobileOrderSummary,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <>
      {mobileOrderSummary && (
        <MobileOrderSummary>
          <div className="orderSummaryHeader">
            <div className="backButton">
              <button
                type="button"
                onClick={() => setMobileOrderSummary(false)}
              >
                <p> {t('BACK_TO_BAG', 'Voltar para Sacola')}</p>
              </button>
            </div>
            <div className="closeButton">
              <button
                type="button"
                onClick={() => setMobileOrderSummary(false)}
              >
                <MdOutlineClose />
              </button>
            </div>
          </div>
          <div className="orderSummaryContent">
            <PackageSummary />
          </div>
        </MobileOrderSummary>
      )}
    </>
  );
};

export { MobileSummary };
