import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdChevronRight } from 'react-icons/md';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTheme } from '@hooks/useTheme';
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';

// Action import
import { setBagId } from '@store/modules/ui/actions';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@create_order/SALE_CODE';

const CartSaleCode: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const theme = useTheme();
  const dispatch = useDispatch();

  // Global states
  const user = useSelector(state => state.auth);

  return (
    <Container
      type="button"
      className="seller"
      disabled={order.loading || user?.sale_codes?.length === 1}
      onClick={() => {
        order.setSaleCode({
          code: '',
          name: '',
        });
        dispatch(setBagId(''));
      }}
    >
      <div className="information">
        <span className="code">{order.saleCode.code}</span>
        <span className="name">
          {order.saleCode.name || t('NAME_NOT_PROVIDED', 'Nome não informado')}
        </span>
      </div>
      {user.sale_codes?.length !== 1 && (
        <MdChevronRight color={theme.font_primary} size={40} />
      )}
    </Container>
  );
};

export { CartSaleCode };
