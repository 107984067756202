import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  .title {
    color: ${({ theme }) => theme.font_secondary};
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: none;
    background-color: transparent;

    .arrow {
      transform: rotate(90deg);
      transition: 300ms;
      font-size: 1.5rem;

      &.open {
        transform: rotate(270deg);
      }
    }
  }

  .expandedData {
    display: none;

    .divider {
      width: 100%;
      border-top: 0.125rem solid ${({ theme }) => theme.font_secondary};
    }

    &.open {
      display: flex;
      flex-direction: column;
      margin: 1rem 0 0;
      gap: 1rem;
    }
  }
`;
