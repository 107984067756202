import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';

// Component import
import { Input } from '@components/Input';
import { Button } from '@components/Button';

// Util import
import { getFormErrors } from '@util/getFormErrors';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTheme } from '@hooks/useTheme';
import { useForwardRef } from '@hooks/useForwardRef';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { AddContainer, Footer } from '../styles';

// Interface import
import { IAddProductScreenRef } from '..';

// Feature identification
const featureKey = '@create_order/PRODUCT_ADD_BY_ANALOG_READER';

const AddByAnalogReader = forwardRef<IAddProductScreenRef>((_, ref) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const product = useAddProduct();

  // Local refs
  const codeInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);

  // Local states
  const [typedCode, setTypedCode] = useState('');

  // Focus action
  const onFocus = useCallback(() => {
    setTypedCode('');
    codeInputRef.current?.focus();
  }, []);

  // Blur action
  const onBlur = useCallback(() => {
    setTypedCode('');
  }, []);

  // Assign method to ref
  useForwardRef(ref, {
    onFocus,
    onBlur,
    onSelect: () => codeInputRef.current?.focus(),
    onDeselect: () => null,
  });

  const handleSubmit: SubmitHandler<{
    code: string;
  }> = useCallback(
    async data => {
      // Clear form errors
      if (formRef.current) formRef.current.setErrors({});

      // Clear product variations
      product.resetVariations();

      // Setup a schema to be validated
      const schema = Yup.object()
        .shape({
          code: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CODE_TYPE',
                'O código deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(t('REQUIRED_CODE', 'Escaneie ou digite o código.')),
        })
        .noUnknown(true, t('INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        getFormErrors(err, ({ form, errors }) => {
          formRef.current?.setErrors(form);
          toast.error(errors[0]);
        });
        return;
      }

      // Get reference and color from data
      const { code } = data;

      // Request product information to backend
      try {
        product.getProduct({
          code,
        });
      } catch (err: any) {}
    },
    [product, t],
  );

  // Clear typed when brand changes
  useEffect(() => {
    setTypedCode('');
  }, [product.selectedBrandCode]);

  return (
    <AddContainer>
      <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
        <h3>{t('HEADER', 'Escaneie o código com o leitor analógico')}</h3>
        <Input
          ref={codeInputRef}
          name="code"
          onEnterKey={() => formRef.current?.submitForm()}
          label={t('INPUT_LABEL', 'Código')}
          value={typedCode}
          onChange={(value: any) => {
            setTypedCode(String(value).trim());
          }}
          disabled={product.loading}
        />
      </Form>
      <Footer>
        <Button
          color={theme.background_success}
          className="action"
          loading={product.loading}
          disabled={!typedCode}
          onClick={() => formRef.current?.submitForm()}
        >
          {t('ACTION', 'CONSULTAR PRODUTO')}
        </Button>
      </Footer>
    </AddContainer>
  );
});

export { AddByAnalogReader };
