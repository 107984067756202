import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// Store import
import { store } from '@store/index';
// Action import
import { setLanguage } from '@store/modules/ui/actions';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    /**
     * LOGGING
     */

    // If true, will throw logs
    debug: false,

    /**
     * LANGUAGES, NAMESPACES AND RESOURCES
     */

    // Pre-load disabled, using backend instead
    resources: undefined,

    // Translation files paths
    backend: {
      loadPath: './locales/{{lng}}/{{ns}}.json',
      addPath: './locales/{{lng}}/{{ns}}.missing.json',
      parse: data => {
        return JSON.parse(data);
      },
    },
    partialBundledLanguages: false,

    // Override all translations
    lng: 'pt',

    // If not found language, switches to this
    fallbackLng: 'pt',

    // Supported languages
    supportedLngs: ['pt'],
    nonExplicitSupportedLngs: false,

    // Preload languages
    load: 'all',
    preload: ['pt'],

    // Locale lowercase
    lowerCaseLng: false,
    cleanCode: false,

    // Namespaces to load
    ns: 'translation',
    defaultNS: 'translation',
    fallbackNS: false,

    /**
     * MISSING KEYS
     */

    // Save missing to missing json
    saveMissing: false,
    updateMissing: false,
    saveMissingTo: 'all',
    parseMissingKeyHandler: () => {
      return 'Carregando...';
    },

    // Missing key handler function(lng, ns, key, fallbackValue) { }
    missingKeyHandler: false,
    // parseMissingKeyHandler: undefined,
    appendNamespaceToMissingKey: false,
    // missingInterpolationHandler: undefined,

    /**
     * DETECTION
     */

    // Language detector options
    detection: {
      // Order and from where user language should be detected
      order: [/* 'path', 'session', */ 'querystring', 'cookie', 'header'],

      // Keys or params to lookup language from
      lookupQuerystring: 'lng',
      lookupCookie: 'i18n',
      lookupHeader: 'accept-language',
      lookupSession: 'lng',
      lookupPath: 'lng',
      lookupFromPathIndex: 0,
      lookupLocalStorage: null,

      // Cache user language
      caches: false, // ['cookie']

      // Optional expire and domain for set cookie
      cookieExpirationDate: new Date(),
      cookieDomain: 'somastore-lng',
      cookieSecure: true, // if need secure cookie
    },

    // Missing key function
    // missingKeyHandler: translationMissing,

    // Testing env
    ...(process.env.NODE_ENV === 'test'
      ? {
          lng: 'cimode',
          initImmediate: true,
        }
      : {}),

    // React configuration
    react: {
      useSuspense: false,
    },
  });

i18n.on('languageChanged', lng => {
  store.dispatch(setLanguage(lng));
});

export const t = i18n.t.bind(i18n);

export { i18n };
