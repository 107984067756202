import React, { useState, SyntheticEvent } from 'react';
import {
  MdOutlineClose,
  MdWifi,
  MdOutlineLink,
  MdAttachMoney,
  MdOutlineAccountBalanceWallet,
} from 'react-icons/md';
import { useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PixIcon } from '@assets/index';

// Component import
import { Button } from '@components/Button';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { IPayment } from '@hooks/useOrderCreation/models';
import { useOrderCreation } from '@hooks/useOrderCreation';

// Store import
import { setBagId } from '@store/modules/ui/actions';

// Style import
import { UniquePaymentContainer, MultiplePaymentContainer } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
}

// Feature identification
const featureKey = '@create_order/PAYMENT_CONFIRMATION_MODAL';

const PaymentModal: React.FC<IProps> = ({ hide }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState<string | false>('0');

  const order = useOrderCreation();

  const getPaymentTypes = () => {
    return order.payments.filter(item => item.code !== 'store_credit');
  };

  const chosenPaymentTypes = getPaymentTypes();

  const getPaymentTimeLimit = (paymentType: IPayment) => {
    const givenPaymentLimit = paymentType?.time_limit;

    if (givenPaymentLimit?.unit === 'minute') {
      if (givenPaymentLimit?.value === 1) {
        return `${givenPaymentLimit?.value} minuto`;
      }
      return `${givenPaymentLimit?.value} minutos`;
    }
    if (givenPaymentLimit?.unit === 'day') {
      if (givenPaymentLimit?.value === 1) {
        return `${givenPaymentLimit?.value} dia`;
      }
      return `${givenPaymentLimit?.value} dias`;
    }
    if (givenPaymentLimit?.value === 1) {
      return `${givenPaymentLimit?.value} hora`;
    }
    return `${givenPaymentLimit?.value} horas`;
  };

  const getPaymentIcon = (paymentType: IPayment) => {
    switch (paymentType?.code) {
      case 'physical_card':
        return <MdWifi size={42} />;

      case 'payment_link':
        return <MdOutlineLink size={42} />;

      case 'store_credit':
        return <MdOutlineAccountBalanceWallet size={42} />;

      case 'pix':
        return <PixIcon width={42} height={42} />;

      default:
        return <MdAttachMoney size={42} />;
    }
  };

  const getPaymentTitle = (paymentType: IPayment) => {
    switch (paymentType?.code) {
      case 'physical_card':
        return `${t('PHYSICAL_CARD_TITLE', 'Pagamento Escolhido: POS')}`;

      case 'payment_link':
        return `${t('PAYMENT_LINK_TITLE', 'Pagamento Escolhido: Link')}`;

      case 'store_credit':
        return `${t(
          'STORE_CREDIT_TITLE',
          'Pagamento Escolhido:\nSaldo de créditos',
        )}`;

      case 'pix':
        return `${t('PIX_TITLE', 'Pagamento Escolhido: PIX')}`;
      default:
        return '';
    }
  };

  const getPaymentText = (paymentType: IPayment) => {
    switch (paymentType?.code) {
      case 'physical_card':
        return `${t(
          'PHYSICAL_CARD_NOTICE',
          `Atenção!\nApós a confirmação, continue o processo clicando na aba Pagamentos na máquina de POS. Você terá ${getPaymentTimeLimit(
            paymentType,
          )} para concluir a operação de pagamento.`,
        )}`;

      case 'payment_link':
        return `${t(
          'PAYMENT_LINK_NOTICE',
          `Atenção! Após a confirmação, envie o Link de Pagamento para sua cliente. Ele é válido por ${getPaymentTimeLimit(
            paymentType,
          )}.`,
        )}`;
      case 'store_credit':
        return '';
      case 'pix':
        return `${t(
          'PIX_NOTICE',
          `Atenção! Após a confirmação, será gerado o código para pagamento com prazo de expiração de ${getPaymentTimeLimit(
            paymentType,
          )}.`,
        )}`;
      default:
        return `${t(
          'PHYSICAL_CARD_NOTICE',
          `Atenção!\nApós a confirmação, continue o processo clicando na aba Pagamentos na máquina de POS. Você terá ${getPaymentTimeLimit(
            paymentType,
          )} para concluir a operação de pagamento.`,
        )}`;
    }
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {chosenPaymentTypes &&
      chosenPaymentTypes.length &&
      chosenPaymentTypes.length > 1 ? (
        <MultiplePaymentContainer>
          <div className="closeRow">
            <button
              type="button"
              disabled={order.loading}
              onClick={() => {
                // Hide modal
                if (typeof hide === 'function') hide();
              }}
            >
              <MdOutlineClose className="closeIcon" />
            </button>
          </div>
          {chosenPaymentTypes.map((paymentType, index) => (
            <Accordion
              expanded={expanded === index.toString()}
              onChange={handleChange(index.toString())}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ fontSize: '2rem' }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="accordionTitle">
                  {getPaymentIcon(paymentType)}
                  <div className="titleText">
                    {getPaymentTitle(paymentType)}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="accordionContent">
                  <div className="accordionText">
                    {getPaymentText(paymentType)}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
          <div className="actionArea">
            {order.creatingOrder ? (
              <div className="loadingContent">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                <Button
                  onClick={async () => {
                    dispatch(setBagId(''));
                    sessionStorage.removeItem('savedOrderCode');
                    order.confirmOrder();
                    // if (typeof hide === 'function') hide();
                  }}
                  disabled={order.loading}
                >
                  {t('CONFIRM', 'CONFIRMAR')}
                </Button>
                <span>
                  <Button
                    color="danger"
                    variant="outlined"
                    disabled={order.loading}
                    onClick={() => {
                      // Hide modal
                      if (typeof hide === 'function') hide();
                    }}
                  >
                    {t('CANCEL', 'CANCELAR')}
                  </Button>
                </span>
              </>
            )}
          </div>
        </MultiplePaymentContainer>
      ) : (
        <UniquePaymentContainer>
          <div className="closeRow">
            <button
              type="button"
              disabled={order.loading}
              onClick={() => {
                // Hide modal
                if (typeof hide === 'function') hide();
              }}
            >
              <MdOutlineClose className="closeIcon" />
            </button>
          </div>
          <div className="iconRow">{getPaymentIcon(chosenPaymentTypes[0])}</div>
          <div className="titleRow">
            <p>{getPaymentTitle(chosenPaymentTypes[0])}</p>
          </div>
          <div className="textRow">
            {order.creatingOrder ? (
              <LoadingIndicator />
            ) : (
              <p>{getPaymentText(chosenPaymentTypes[0])}</p>
            )}
          </div>

          <Button
            onClick={async () => {
              dispatch(setBagId(''));
              sessionStorage.removeItem('savedOrderCode');
              order.confirmOrder();
              // if (typeof hide === 'function') hide();
            }}
            disabled={order.loading}
          >
            {t('CONFIRM', 'CONFIRMAR')}
          </Button>
          <span>
            <Button
              color="danger"
              variant="outlined"
              disabled={order.loading}
              onClick={() => {
                // Hide modal
                if (typeof hide === 'function') hide();
              }}
            >
              {t('CANCEL', 'CANCELAR')}
            </Button>
          </span>
        </UniquePaymentContainer>
      )}
    </>
  );
};

export { PaymentModal };
