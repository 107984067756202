import React, { useMemo, useRef } from 'react';
import Radio from '@material-ui/core/Radio';
import { toast } from 'react-toastify';

// Asset import
import { EditIcon } from '@assets/index';

// Component import
import { Modal, IModalRef } from '@components/Modal';
import { CheckboxHandmade } from '@components/CheckboxHandmade';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Import modal
import { ValueModal } from './ValueModal';

// Style import
import { Container, ButtonTitleContainer, CreditDataContainer } from './styles';

interface IStoreCreditButtonProps {
  toggleIsSelected?: boolean;
  icon?: any;
  payment?: any;
  setToggleIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const StoreCreditButton: React.FC<IStoreCreditButtonProps> = ({
  toggleIsSelected,
  icon,
  payment,
  setToggleIsSelected,
}) => {
  // Local refs
  const valueModalRef = useRef<IModalRef>(null);

  const featureKey = '@create_order/PAYMENT';

  // Hooks
  const order = useOrderCreation();
  const { t } = useTranslation(featureKey);

  // Local states
  const availableStoreCreditTotal = payment?.payload?.total;
  const isMethodSelected = !!order?.payments.filter(
    item => item.code === payment.code,
  ).length;

  const setSelectedClass = isMethodSelected ? 'selected' : '';
  const formattedStoreCredit = () => {
    if (availableStoreCreditTotal) {
      if (availableStoreCreditTotal === 0) return '0';

      const hasStoreCreditBeenSelected = order.payments.filter(
        item => item.code === 'store_credit',
      );

      const storeCreditTotalAmount =
        hasStoreCreditBeenSelected?.[0]?.amount || 0;

      if (storeCreditTotalAmount) {
        return (
          (availableStoreCreditTotal - storeCreditTotalAmount) /
          100
        ).toFixed(2);
      }

      return (availableStoreCreditTotal / 100).toFixed(2);
    }
    return '0';
  };

  const handleEditButton = () => {
    if (payment?.payload?.total === 0) {
      return;
    }
    valueModalRef.current?.show();
  };

  const handleClick = () => {
    const currentPayments = order.payments;

    const hasStoreCreditBeenSelected = currentPayments.some(
      item => item.code === payment?.code,
    );
    const newCurrentPayments = currentPayments.filter(
      item => item.code !== 'store_credit',
    );

    if (hasStoreCreditBeenSelected) {
      order.setPayments(newCurrentPayments);
      return;
    }

    if (order.totals.total > availableStoreCreditTotal && !toggleIsSelected) {
      order.setPayments([
        {
          code: payment?.code,
          payload: {},
          amount: availableStoreCreditTotal,
          time_limit: payment?.time_limit,
        },
      ]);
      setToggleIsSelected(true);
      const error = t(
        'INSUFFICIENT_CREDIT_BALANCE',
        'Seu limite no Saldo de Crédito é insuficiente. Combine-o com outro meio de pagamento.',
      );
      toast.error(error);
      return;
    }

    handleEditButton();
  };

  const modals = useMemo(
    () => (
      <>
        <Modal ref={valueModalRef} size="small-1">
          <ValueModal
            hide={() => valueModalRef.current?.hide()}
            payment={payment}
          />
        </Modal>
      </>
    ),
    [payment],
  );

  return (
    <>
      <Container className={setSelectedClass} disabled={!order?.loading}>
        <div className="dataColumn">
          <ButtonTitleContainer>
            {icon}
            <span>{t('CREDIT_BALANCE', 'Saldo de Créditos')}</span>
          </ButtonTitleContainer>
          <CreditDataContainer>
            <p>
              {t('AVAILABLE', 'Disponível: ')}
              <b>R$ {formattedStoreCredit()}</b>
            </p>
            <button type="button" onClick={() => handleEditButton()}>
              <EditIcon />
            </button>
          </CreditDataContainer>
        </div>
        {toggleIsSelected ? (
          <div className="itemButton">
            <CheckboxHandmade
              checked={isMethodSelected}
              onChange={() => handleClick()}
              disabled={availableStoreCreditTotal === 0}
              size="normal"
            />
          </div>
        ) : (
          <div className="itemButton">
            <Radio
              checked={isMethodSelected}
              color="primary"
              onClick={() => handleClick()}
              disabled={availableStoreCreditTotal === 0}
            />
          </div>
        )}
      </Container>
      {modals}
    </>
  );
};

export { StoreCreditButton };
