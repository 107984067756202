import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MdOutlineClose } from 'react-icons/md';
import { ptBR } from '@mui/x-date-pickers/locales';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import { ptBR as coreptBR } from '@mui/material/locale';
import { ptBR as datefnsLocaleptBR } from 'date-fns/locale';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

import { ThemeProvider, createTheme } from '@mui/material/styles';
// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;

  handleAttachments: (selectedDate: any) => void;
}

const theme = createTheme(
  {
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            border: '2px solid',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: '#DCC6B7',
            '&.Mui-focused': {
              borderColor: '#DCC6B7 !important', // Cor de fundo para o dia selecionado
              // backgroundColor: '#212121',
            },
          },
        },
      },
      MuiPickersLayout: {
        styleOverrides: {
          root: {
            // color: '#DCC6B7',
            backgroundColor: '#212121',
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#DCC6B7 !important', // Cor de fundo para o dia selecionado
              color: '#212121 !important',
              '&:hover': {
                backgroundColor: '#DCC6B7', // Cor de fundo ao passar o mouse sobre o dia selecionado
                color: '#212121 !important',
              },
            },
          },
        },
      },
      MuiClock: {
        styleOverrides: {
          pin: {
            backgroundColor: '#DCC6B7 !important', // Cor do pin do relógio
          },
        },
      },
      MuiClockNumber: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#212121', // Cor do número selecionado no relógio
              backgroundColor: '#DCC6B7', // Cor do pin do relógio
            },
            color: '#DCC6B7',
          },
        },
      },
      MuiClockPointer: {
        styleOverrides: {
          root: {
            backgroundColor: '#DCC6B7', // Cor do pin do relógio
          },
          thumb: {
            border: '16px solid #DCC6B7 !important', // Cor do pin do relógio
            backgroundColor: '#212121 !important', // Cor do pin do relógio
            opacity: '0.65',
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          header: {
            color: '#DCC6B7 !important', // Cor do pin do relógio
          },
          weekDayLabel: {
            color: '#DCC6B7 !important', // Cor do pin do relógio
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            '&.Mui-selected': {
              backgroundColor: '#DCC6B7 !important', // Cor de fundo para o dia selecionado
              color: '#212121 !important',
              '&:hover': {
                backgroundColor: '#DCC6B7', // Cor de fundo ao passar o mouse sobre o dia selecionado
                color: '#212121 !important',
              },
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: '#DCC6B7 !important', // Cor do pin do relógio
          },
        },
      },

      MuiDateTimePickerToolbar: {
        styleOverrides: {
          ampmLabel: {
            '&.Mui-selected': {
              backgroundColor: '#DCC6B7 !important', // Cor de fundo para o dia selecionado
              color: '#212121 !important',
              borderRadius: '1rem',
              padding: '0.25rem',
            },
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#DCC6B7',
      },
      error: { main: '#DCC6B7' },
    },
  },
  ptBR,
  coreptBR,
  datefnsLocaleptBR,
);

// Feature identification
const featureKey = '@create_order/VARIATIONS_SCREEN_SCHEDULE_MODAL';

const ScheduleModal: React.FC<IProps> = ({ hide, handleAttachments }) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  const [value, setValue] = useState<Dayjs | null>(dayjs());
  const [currentDate, setCurrentDate] = useState<string>();

  useEffect(() => {
    const currentValue = value?.toString();
    setCurrentDate(currentValue);
  }, [value]);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>

      <p className="textRow">
        {t('SELECT_DATE_AND_TIME', 'Selecione uma data e hora')}
      </p>
      <div className="dateRow">
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={
              ptBR.components.MuiLocalizationProvider.defaultProps.localeText
            }
            // locale={ptBR}
            // renderInput={(params: any) => <TextField {...params} />}
          >
            <div className="pickerContent">
              <MobileDateTimePicker
                value={value}
                onChange={newValue => setValue(newValue)}
                referenceDate={dayjs(new Date())}
                format="DD / MM / YYYY HH:mm"
                disablePast
                slotProps={{
                  layout: {
                    sx: {
                      [`.${pickersLayoutClasses.actionBar}`]: {
                        button: {
                          color: '#DCC6B7 ',
                        },
                      },
                      [`.${pickersLayoutClasses.tabs}`]: {
                        button: {
                          color: '#DCC6B7',
                        },
                      },
                      [`.${pickersLayoutClasses.contentWrapper}`]: {
                        color: '#DCC6B7',
                        button: {
                          color: '#DCC6B7',
                        },
                      },
                      [`.${pickersLayoutClasses.toolbar}`]: {
                        color: '#DCC6B7 ',
                        span: {
                          color: '#DCC6B7 ',
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </LocalizationProvider>
        </ThemeProvider>
      </div>
      {currentDate && (
        <Button
          onClick={() => {
            handleAttachments(new Date(currentDate));
            if (typeof hide === 'function') hide();
          }}
        >
          {t('SCHEDULE_EMAIL', 'AGENDAR E-MAIL')}
        </Button>
      )}
      <span>
        <Button
          variant="outlined"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          {t('CANCEL', 'CANCELAR')}
        </Button>
      </span>
    </Container>
  );
};

export { ScheduleModal };
