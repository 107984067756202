import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  gap: 2rem;
  padding: 2rem;
  flex: 1;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 2rem;

    .search {
      width: 80%;
      max-width: 20rem;
    }

    .error {
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.font_danger};
    }

    h2 {
      color: ${({ theme }) => theme.font_secondary};
      font-size: 1.3rem;
      font-weight: 600;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(5, minmax(18.75rem, 1fr));
      gap: 1rem;
      align-items: center;
      justify-content: center;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    width: 18.75rem;
    max-width: 90vw;
  }

  @media screen and (max-width: 2000px) {
    .top {
      ul {
        grid-template-columns: repeat(3, minmax(18.75rem, 1fr));
      }
    }
  }

  @media screen and (max-width: 1800px) {
    .top {
      ul {
        grid-template-columns: repeat(2, minmax(18.75rem, 1fr));
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .top {
      ul {
        grid-template-columns: repeat(1, minmax(18.75rem, 1fr));
      }
    }
  }

  @media screen and (max-width: 425px) {
    .top {
      padding-bottom: 5rem;
    }

    .bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;

      z-index: 2;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: center;

      width: 100%;
      max-width: 100%;

      border-top: 0.125rem solid ${({ theme }) => theme.background_secondary};
      background: ${({ theme }) => theme.background_low};
      box-shadow: 0rem 0rem 1.5rem 0.05rem
        ${({ theme }) => rgba(theme.background_low, 0.95)};
    }
  }
`;

export const ListItem = styled(motion.button)`
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    padding: 1rem;
    gap: 0.5rem;
    max-width: 18.75rem;
    border-radius: 0.25rem;
    background: ${({ theme }) => theme.background_secondary};
    transition: 0.25s;

    &.selected {
      box-shadow: inset 0 0 0 0.188rem ${({ theme }) => theme.font_success};
    }

    .information {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      color: ${({ theme }) => theme.font_low};
      font-size: 1rem;

      .code {
        font-weight: bold;
      }
    }
  }
`;
