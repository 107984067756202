import React, { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

// Module import
import { OrderList } from '@modules/OrderList';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';
import { useTheme } from '@hooks/useTheme';
import { useWindowSize } from '@hooks/useWindowSize';

// Service import
import { api, getResponseError } from '@services/api';

// Store import
import { INewFormatOrder } from '@store/modules/auth/types';

// Style import
import { DesktopContainer, MobileContainer } from './styles';

// Feature identification
const featureKey = '@list_orders/INDEX';

const ListOrders: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const [width] = useWindowSize();

  // Local states
  const [orders, setOrders] = useState<INewFormatOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setCurrentPage(currentPageInsideState => currentPageInsideState + 1);
      }
    });
    const sentinelDiv = document.querySelector('#sentinel');

    if (sentinelDiv) {
      intersectionObserver.observe(sentinelDiv);
    }
    return () => intersectionObserver.disconnect();
  }, []);

  // Get orders
  const getOrders = useCallback(async () => {
    setLoading(true);

    try {
      // API call
      const response = await api.get('/v1/order', {
        params: {
          page: currentPage,
        },
      });

      // Validation
      if (!Array.isArray(response.data.data)) throw new Error();

      // Result
      const new_orders = [...orders, ...response.data.data];

      // Iterate to remove repeated items
      const result = new_orders.reduce<INewFormatOrder[]>((total, current) => {
        if (!total.some(order => order.id === current.id)) total.push(current);
        return total;
      }, []);

      setOrders(result);
      setLoading(false);
      if (currentPage < 3) {
        setCurrentPage(currentPage + 1);
      }
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);

      setLoading(false);
      throw err;
    }
  }, [currentPage, orders, t]);

  useEffect(() => {
    setReady(true);
  }, []);

  // Initial load
  useEffect(() => {
    if (!ready) return;
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      {width > 768 ? (
        <DesktopContainer>
          <div className="scrollArea">
            {!!orders.length && <OrderList orders={orders} />}

            <div id="sentinel" />

            {loading && (
              <LoadingIndicator color={theme.font_secondary} size={3} />
            )}
          </div>
        </DesktopContainer>
      ) : (
        <MobileContainer>
          <div className="scrollArea">
            {!!orders.length && <OrderList orders={orders} />}

            <div id="sentinel" />

            {loading && (
              <LoadingIndicator color={theme.font_secondary} size={3} />
            )}
          </div>
        </MobileContainer>
      )}
    </>
  );
};

export { ListOrders };
