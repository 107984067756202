import { IOrderTotals, IPayment } from '@hooks/useOrderCreation/models';

const formatValueToString = (value: number) => {
  const handleInitialValue = (value / 100).toFixed(2);
  const numberToString = handleInitialValue.toString();
  const formatToBrString = numberToString.replace('.', ',');
  return formatToBrString;
};

const getInitialStoreCreditValue = (
  orderTotals: IOrderTotals,
  orderPayments: IPayment[],
  payment: IPayment,
) => {
  const hasStoreCreditAlreadyBeenSelected = orderPayments.filter(
    item => item.code === 'store_credit',
  ).length;
  const creditValueIsHigherThanOrderValue =
    payment?.payload?.total > orderTotals?.total;

  if (hasStoreCreditAlreadyBeenSelected) {
    const storeCreditAmount = orderPayments?.[0]?.amount || 0;
    return storeCreditAmount;
  }
  if (creditValueIsHigherThanOrderValue) {
    return orderTotals?.total;
  }
  return payment?.payload?.total;
};

const hasStoreCreditBeenSelected = (payments: IPayment[]) => {
  const filterPayments = payments.filter(
    item => item.code === 'store_credit',
  ).length;

  return !!filterPayments;
};

export {
  formatValueToString,
  getInitialStoreCreditValue,
  hasStoreCreditBeenSelected,
};
