import React from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Asset import
import {
  VeryDissatisfiedOutlinedIcon,
  DissatisfiedOutlinedIcon,
  NeutralOutlinedIcon,
  SatisfiedOutlinedIcon,
  VerySatisfiedOutlinedIcon,
  VeryDissatisfiedIcon,
  DissatisfiedIcon,
  NeutralIcon,
  SatisfiedIcon,
  VerySatisfiedIcon,
} from '@assets/index';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  showSecondStep?: any;
  selectedSentiment:
    | 'very_dissatisfied'
    | 'dissatisfied'
    | 'neutral'
    | 'satisfied'
    | 'very_satisfied'
    | 'none';
  setSelectedSentiment: React.Dispatch<
    React.SetStateAction<
      | 'very_dissatisfied'
      | 'dissatisfied'
      | 'neutral'
      | 'satisfied'
      | 'very_satisfied'
      | 'none'
    >
  >;
  setSelectedStep: React.Dispatch<
    React.SetStateAction<
      'salutation' | 'first_step' | 'second_step' | 'third_step' | 'farewell'
    >
  >;
}

// Feature identification
const featureKey = '@satisfaction_survey/FIRST_STEP_MODAL';

const FirstStepModal: React.FC<IProps> = ({
  hide,
  selectedSentiment,
  setSelectedSentiment,
  setSelectedStep,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="titleRow">
        <p className="step">1/3</p>
        <p className="titleText">
          {t(
            'HOW_SATISFIED_ARE_YOU',
            'Baseada nas suas últimas experiências com o Store, quão satisfeito(a) você está com o produto?',
          )}
        </p>
      </div>
      <div className="menuRow">
        <button
          className="option"
          type="button"
          onClick={() => setSelectedSentiment('very_dissatisfied')}
        >
          {selectedSentiment === 'very_dissatisfied' ? (
            <VeryDissatisfiedIcon />
          ) : (
            <VeryDissatisfiedOutlinedIcon />
          )}
          <p>
            {t('VERY', 'Muito')}
            <br />
            {t('DISSATISFIED', 'insatisfeito')}
          </p>
        </button>
        <button
          className="option"
          type="button"
          onClick={() => setSelectedSentiment('dissatisfied')}
        >
          {selectedSentiment === 'dissatisfied' ? (
            <DissatisfiedIcon />
          ) : (
            <DissatisfiedOutlinedIcon />
          )}
          <p>{t('DISSATISFIED', 'Insatisfeito')}</p>
        </button>
        <button
          className="option"
          type="button"
          onClick={() => setSelectedSentiment('neutral')}
        >
          {selectedSentiment === 'neutral' ? (
            <NeutralIcon />
          ) : (
            <NeutralOutlinedIcon />
          )}
          <p>{t('NEUTRAL', 'Neutro')}</p>
        </button>
        <button
          className="option"
          type="button"
          onClick={() => setSelectedSentiment('satisfied')}
        >
          {selectedSentiment === 'satisfied' ? (
            <SatisfiedIcon />
          ) : (
            <SatisfiedOutlinedIcon />
          )}
          <p> {t('SATISFIED', 'Satisfeito')}</p>
        </button>
        <button
          className="option"
          type="button"
          onClick={() => setSelectedSentiment('very_satisfied')}
        >
          {selectedSentiment === 'very_satisfied' ? (
            <VerySatisfiedIcon />
          ) : (
            <VerySatisfiedOutlinedIcon />
          )}
          <p>
            {t('VERY', 'Muito')}
            <br />
            {t('SATISFIED', 'satisfeito')}
          </p>
        </button>
      </div>
      <div className="actionArea">
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedStep('salutation');
          }}
        >
          <p className="modalButton">{t('PREVIOUS', 'ANTERIOR')}</p>
        </Button>
        <Button
          onClick={() => {
            setSelectedStep('second_step');
          }}
          disabled={selectedSentiment === 'none'}
        >
          <p className="modalButton">{t('NEXT', 'PRÓXIMO')}</p>
        </Button>
      </div>
    </Container>
  );
};

export { FirstStepModal };
