import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;

  .title {
    font-size: 1.25rem;
    color: ${({ theme }) => theme.font_secondary};
    font-weight: 600;
    margin: 1.5rem 0 0.5rem;
  }

  .subtitle {
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme }) => theme.font_primary};
    max-width: 60%;
    text-align: center;
    line-height: 1rem;
  }
`;
