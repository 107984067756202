import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  .packages {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    .package {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding-bottom: 1rem;
      border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};
      .packageTitle {
        p {
          font-weight: 600;
          font-size: 1rem;
        }
      }
      .packageContent {
        p {
          font-weight: 500;
          font-size: 0.875rem;
          color: ${({ theme }) => theme.font_secondary};
        }
      }
    }

    .package:last-child {
      border-bottom: none;
    }
  }
`;

export const ShippingDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .productShippingQuantity {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.font_secondary};
    min-width: fit-content;

    span {
      color: white;
    }
  }

  .shippingPrice {
    font-weight: 500;
    font-size: 0.875rem;
    color: white;
  }
`;
