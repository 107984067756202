// Action types
export const UiActionTypes = {
  LEFT_MENU_SET: '@ui/LEFT_MENU_SET',
  ORDER_SUMMARY_SET: '@ui/ORDER_SUMMARY_SET',
  BAG_SUMMARY_SET: '@ui/BAG_SUMMARY_SET',
  PRINTER_CONFIG_SET: '@ui/PRINTER_CONFIG_SET',
  SAVED_BAG_ADDING_PRODUCT_SET: '@ui/SAVED_BAG_ADDING_PRODUCT_SET',
  LANGUAGE_SET: '@ui/LANGUAGE_SET',
  ACTIVE_PAGE_SET: '@ui/ACTIVE_PAGE_SET',
  BAG_ID_SET: '@ui/BAG_ID_SET',
  ORDER_ID_SET: '@ui/ORDER_ID_SET',
  HELP_MODE_SET: '@ui/HELP_MODE_SET',
};

// State
export interface IUiState {
  left_menu: boolean;
  order_summary: boolean;
  bag_summary: boolean;
  printer_config: boolean;
  saved_bag_adding_product: boolean;
  bag_id: string;
  order_id: string;
  active_page: string;
  help_mode: boolean;
  language?: string;
}
