import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { MdArrowBack, MdAdd, MdRemove } from 'react-icons/md';
import { useSelector } from 'react-redux';

// Asset import
import DefaultProductImage from '@assets/defaultProductImage.png';
import DefaultProductImageBigger from '@assets/defaultProductImageBigger.png';

// Component import
import { Button } from '@components/Button';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { Modal, IModalRef } from '@components/Modal';
import { SizeSelector } from '@components/SizeSelector';
import { PackageOptions } from '@components/PackageOptions';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTheme } from '@hooks/useTheme';
import { useWindowSize } from '@hooks/useWindowSize';
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';

// Page import
import { giftCardReferences } from '@util/giftCardReferences';

import { ConfirmationModal } from './ConfirmationModal';
import { VariationsScreenGiftCard } from './VariationsScreenGiftCard';

// Style import
import { Container, Header, Footer } from '../styles';
import { Content, ProductCardDesktop, ProductCardMobile } from './styles';

// Feature identification
const featureKey = '@create_order/VARIATIONS_SCREEN';

const VariationsScreen: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const order = useOrderCreation();
  const product = useAddProduct();
  const [width] = useWindowSize();

  // Global states
  const user = useSelector(state => state.auth);

  // Local states
  const [packageType, setPackageType] = useState<'OWN_STOCK' | 'DELIVERY'>(
    'OWN_STOCK',
  );
  const [selectedPackageType, setSelectedPackageType] = useState<
    'OWN_STOCK' | 'DELIVERY' | 'none'
  >('none');
  const [productQuantity, setProductQuantity] = useState<number>(1);

  // Local refs
  const confirmationModalRef = useRef<IModalRef>(null);

  const availablePackageTypes = user.store?.package_types.filter(
    storePackageType =>
      storePackageType.enabled && storePackageType.package_type.enabled,
  );

  const isOwnStockEnabled = !!availablePackageTypes?.filter(
    package_type => package_type.package_type.code === 'own_stock',
  ).length;

  const handleSelectPackageType = (type: string) => {
    switch (type) {
      case 'own_stock':
        setPackageType('OWN_STOCK');
        setSelectedPackageType('OWN_STOCK');
        break;
      case 'infinite_aisle':
        setPackageType('DELIVERY');
        setSelectedPackageType('DELIVERY');
        break;
      default:
        break;
    }
  };

  const selectedInformation = useMemo(() => {
    const information = {
      name: '',
      image_url: '',
      reference: '',
      color: '',
      size: '',
    };

    if (!product.variations.length) return information;

    information.name =
      product.variations.find(variation => !!variation.name)?.name || '';
    information.image_url =
      product.variations.find(variation => !!variation.image_url)?.image_url ||
      '';
    information.reference =
      product.variations.find(variation => !!variation.reference)?.reference ||
      '';
    information.color =
      product.variations.find(variation => !!variation.color)?.color || '';
    information.size =
      product.variations.find(variation => !!variation.size)?.size || '';

    if (product.selectedVariationId) {
      const selected = product.variations.find(
        variation =>
          String(variation.id) === String(product.selectedVariationId),
      );

      information.name = selected?.name || '';
      information.image_url = selected?.image_url || '';
      information.reference = selected?.reference || '';
      information.color = selected?.color || '';
      information.size = selected?.size || '';
    }

    return information;
  }, [product]);

  const selectedVariation = useMemo(
    () =>
      product.variations.find(
        variation =>
          String(variation.id) === String(product.selectedVariationId),
      ),
    [product],
  );

  // Handle add
  const handleAdd = useCallback((quantity: number) => {
    setProductQuantity(quantity + 1);
  }, []);

  // Handle remove
  const handleRemove = useCallback((quantity: number) => {
    if (quantity === 1) {
      setProductQuantity(quantity);
    } else {
      setProductQuantity(quantity - 1);
    }
  }, []);

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal ref={confirmationModalRef} size="smaller">
          <ConfirmationModal
            hide={() => confirmationModalRef.current?.hide()}
            productQuantity={productQuantity}
          />
        </Modal>
      </>
    ),
    [productQuantity],
  );

  useEffect(() => {
    confirmationModalRef.current?.hide();
  }, [user]);

  const giftProductCondition = product.variations.some(item =>
    giftCardReferences.includes(item.reference),
  );

  if (giftProductCondition) return <VariationsScreenGiftCard />;

  return (
    <Container>
      <Header>
        <button type="button" onClick={() => product.resetVariations()}>
          <MdArrowBack size={24} color={theme.font_primary} />
        </button>
        <p>{t('NEW_ORDER', 'Novo Atendimento')}</p>
      </Header>
      <Content>
        {width > 768 ? (
          <ProductCardDesktop>
            {product.loading ? (
              <div className="loadingSection">
                <LoadingIndicator size={2.5} />
              </div>
            ) : (
              <>
                {selectedInformation.image_url ? (
                  <div className="image">
                    <img
                      src={selectedInformation.image_url}
                      alt={t(
                        'VARIATIONS_SELECT_SIZE_IMAGE_ALT',
                        'Imagem Ilustrativa do Produto',
                      )}
                    />
                  </div>
                ) : (
                  <div className="defaultImage">
                    <img
                      src={DefaultProductImageBigger}
                      alt={t(
                        'VARIATIONS_SELECT_SIZE_IMAGE_ALT',
                        'Imagem Ilustrativa do Produto',
                      )}
                    />
                  </div>
                )}
                <div className="info">
                  <div className="top">
                    <span className="name">{selectedInformation.name}</span>
                    <div className="code">
                      <span className="reference">
                        {t('VARIATIONS_SELECT_SIZE_REFERENCE', 'Ref.:')}{' '}
                        {selectedInformation.reference}
                      </span>
                      <span className="reference">
                        {t('VARIATIONS_SELECT_SIZE_COLOR', 'Cor:')}{' '}
                        {selectedInformation.color}
                      </span>
                    </div>

                    <div className="price">
                      {selectedVariation ? (
                        <>
                          {selectedVariation.original_price !==
                            selectedVariation.current_price && (
                            <span className="originalPrice">
                              R${' '}
                              {(selectedVariation.original_price / 100).toFixed(
                                2,
                              )}
                            </span>
                          )}
                          <span className="current_price">
                            R${' '}
                            {(selectedVariation.current_price / 100).toFixed(2)}
                          </span>
                        </>
                      ) : (
                        <>
                          {product.variations[0].original_price !==
                            product.variations[0].current_price && (
                            <span className="originalPrice">
                              R${' '}
                              {(
                                product.variations[0].original_price / 100
                              ).toFixed(2)}
                            </span>
                          )}
                          <span className="current_price">
                            R${' '}
                            {(
                              product.variations[0].current_price / 100
                            ).toFixed(2)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <SizeSelector
                    selectedPackageType={selectedPackageType}
                    page="variation"
                  />

                  <div className="bottom">
                    {(isOwnStockEnabled || !!product.selectedVariationId) && (
                      <PackageOptions
                        handleSelectPackageType={handleSelectPackageType}
                        selectedPackageType={selectedPackageType}
                        setPackageType={setPackageType}
                        setSelectedPackageType={setSelectedPackageType}
                        page="variation"
                      />
                    )}
                    {selectedPackageType !== 'none' &&
                    !!product.selectedVariationId ? (
                      <div className="quantitySection">
                        <div className="quantityTitle">
                          <p>
                            {' '}
                            {t('QUANTITY_TO_ADD', 'Quantidade a adicionar:')}
                          </p>
                        </div>
                        <div className="quantity">
                          <Button noFullWidth noMargin disabled={order.loading}>
                            <MdRemove
                              color={theme.font_low}
                              size={20}
                              onClick={() => handleRemove(productQuantity)}
                            />
                          </Button>
                          <span className="number">{productQuantity}</span>
                          <Button noFullWidth noMargin disabled={order.loading}>
                            <MdAdd
                              color={theme.font_low}
                              size={20}
                              onClick={() => handleAdd(productQuantity)}
                            />
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="quantitySection" style={{ opacity: 0 }}>
                        <div className="quantityTitle">
                          <p>
                            {' '}
                            {t('QUANTITY_TO_ADD', 'Quantidade a adicionar:')}
                          </p>
                        </div>
                        <div className="quantity">
                          <Button noFullWidth noMargin disabled={order.loading}>
                            <MdRemove
                              color={theme.font_low}
                              size={20}
                              onClick={() => handleRemove(productQuantity)}
                            />
                          </Button>
                          <span className="number">{productQuantity}</span>
                          <Button noFullWidth noMargin disabled={order.loading}>
                            <MdAdd
                              color={theme.font_low}
                              size={20}
                              onClick={() => handleAdd(productQuantity)}
                            />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </ProductCardDesktop>
        ) : (
          <ProductCardMobile>
            {product.loading ? (
              <div className="loadingSection">
                <LoadingIndicator size={2.5} />
              </div>
            ) : (
              <>
                <div className="imageAndInfoSection">
                  <div className="image">
                    {selectedInformation.image_url ? (
                      <img
                        src={selectedInformation.image_url}
                        alt={t(
                          'VARIATIONS_SELECT_SIZE_IMAGE_ALT',
                          'Imagem Ilustrativa do Produto',
                        )}
                      />
                    ) : (
                      <img
                        src={DefaultProductImage}
                        alt={t(
                          'VARIATIONS_SELECT_SIZE_IMAGE_ALT',
                          'Imagem Ilustrativa do Produto',
                        )}
                      />
                    )}
                  </div>
                  <div className="info">
                    <div className="top">
                      <span className="name">{selectedInformation.name}</span>
                      <div className="code">
                        <span className="reference">
                          {t('VARIATIONS_SELECT_SIZE_REFERENCE', 'Ref.:')}{' '}
                          {selectedInformation.reference}
                        </span>
                        <span className="reference">
                          {t('VARIATIONS_SELECT_SIZE_COLOR', 'Cor:')}{' '}
                          {selectedInformation.color}
                        </span>
                      </div>

                      <div className="price">
                        {selectedVariation ? (
                          <>
                            {selectedVariation.original_price !==
                              selectedVariation.current_price && (
                              <span className="originalPrice">
                                R${' '}
                                {(
                                  selectedVariation.original_price / 100
                                ).toFixed(2)}
                              </span>
                            )}
                            <span className="current_price">
                              R${' '}
                              {(selectedVariation.current_price / 100).toFixed(
                                2,
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            {product.variations[0].original_price !==
                              product.variations[0].current_price && (
                              <span className="originalPrice">
                                R${' '}
                                {(
                                  product.variations[0].original_price / 100
                                ).toFixed(2)}
                              </span>
                            )}
                            <span className="current_price">
                              R${' '}
                              {(
                                product.variations[0].current_price / 100
                              ).toFixed(2)}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="varitationsSection">
                  <SizeSelector
                    selectedPackageType={selectedPackageType}
                    page="variation"
                  />

                  {(isOwnStockEnabled || !!product.selectedVariationId) && (
                    <PackageOptions
                      handleSelectPackageType={handleSelectPackageType}
                      selectedPackageType={selectedPackageType}
                      setPackageType={setPackageType}
                      setSelectedPackageType={setSelectedPackageType}
                      page="variation"
                    />
                  )}
                  <div className="quantityRow">
                    <div className="quantityTitle">
                      <p> {t('QUANTITY_TO_ADD', 'Quantidade a adicionar:')}</p>
                    </div>
                    <div className="quantity">
                      <Button noFullWidth noMargin disabled={order.loading}>
                        <MdRemove
                          color={theme.font_low}
                          size={20}
                          onClick={() => handleRemove(productQuantity)}
                        />
                      </Button>
                      <span className="number">{productQuantity}</span>
                      <Button noFullWidth noMargin disabled={order.loading}>
                        <MdAdd
                          color={theme.font_low}
                          size={20}
                          onClick={() => handleAdd(productQuantity)}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </ProductCardMobile>
        )}

        <Footer>
          {selectedPackageType === 'none' ? (
            <Button
              className="action"
              onClick={() => {
                product.resetVariations();
                product.setProductsList([]);
                product.setSearchedTerm({});
              }}
              color="primary"
            >
              <p>{t('NEW_SEARCH', 'NOVA CONSULTA')}</p>
            </Button>
          ) : (
            <>
              {packageType === 'OWN_STOCK' ? (
                <Button
                  className="action"
                  startIcon={<MdAdd className="addButton" />}
                  onClick={() => {
                    confirmationModalRef.current?.show();
                  }}
                  disabled={product.loading || !product.selectedVariationId}
                  color="success"
                >
                  <p className="addButton">
                    {t('ADD_PRODUCT', 'ADICIONAR PRODUTO')}
                  </p>
                </Button>
              ) : (
                <Button
                  className="action"
                  startIcon={<MdAdd className="addButton" />}
                  onClick={() => {
                    product.addToCart({
                      packageTypeCode: packageType,
                      initialQuantity: productQuantity,
                    });
                  }}
                  disabled={product.loading || !product.selectedVariationId}
                  color="success"
                >
                  <p className="addButton">
                    {t('ADD_PRODUCT', 'ADICIONAR PRODUTO')}
                  </p>
                </Button>
              )}
            </>
          )}
        </Footer>
      </Content>
      {modals}
    </Container>
  );
};

export { VariationsScreen };
