import styled from 'styled-components';

// Util import
import { getColor } from '@util/getColor';

interface IContainerProps {
  color?: string;
  size?: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${props => (props.size ? props.size : '2.5rem')};
  height: ${props => (props.size ? props.size : '2.5rem')};

  svg {
    color: ${props =>
      getColor({
        theme: props.theme,
        color: props.color,
        disabled: false,
        error: undefined,
        readOnly: false,
      })};
  }
`;
