import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

// Component import
import { BarcodeScanner } from '@components/BarcodeScanner';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { IModalRef } from '@components/Modal';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { IClientData } from '@util/CartModels';

// Style import
import { Container } from './styles';

interface IProps {
  customerSelectorModalRef: React.RefObject<IModalRef>;
  setInitialClientDocument: React.Dispatch<React.SetStateAction<string>>;
  searchDocumentNumber: string;
  setClientList: React.Dispatch<React.SetStateAction<IClientData[]>>;
  setSelectedClientInfo: React.Dispatch<
    React.SetStateAction<IClientData | undefined>
  >;
  setFound: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setSearchDocumentNumber: React.Dispatch<React.SetStateAction<string>>;
}

// Feature identification
const featureKey = '@client/CLIENT_ADD_BY_QR_CODE';

// const AddByQrCode = forwardRef<IAddProductScreenRef>((_, ref) => {
const AddByQrCode: React.FC<IProps> = ({
  customerSelectorModalRef,
  setInitialClientDocument,
  searchDocumentNumber,
  setClientList,
  setSelectedClientInfo,
  setFound,
  setSearchDocumentNumber,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  // Local states
  const [barcodeResult, setBarcodeResult] = useState<any>('Not Found');
  const [loading, setLoading] = useState(false);

  // Local refs
  const isBarcodeProcessing = useRef<boolean>(false);

  const searchClient = useCallback(
    async (clientDocument, clientEmail) => {
      try {
        setLoading(true);

        const response = await api.get('/customer', {
          params: {
            document: clientDocument,
            email: clientEmail,
          },
        });

        setLoading(false);

        // Set selected document
        setInitialClientDocument(searchDocumentNumber);
        // Check if found
        if (!response.data.length) {
          setClientList([]);
          setSelectedClientInfo({
            document: searchDocumentNumber,

            email: '',
            name: '',
            phone: '',
            surname: '',
            birthdate: '',
          });
          setFound(false);
          customerSelectorModalRef.current?.show();
          return;
        }

        // Assign
        setFound(true);

        let selectedInfo = {
          document: response.data[0].document,
          name: response.data[0].name,
          surname: response.data[0].surname,
          email: response.data[0].email,
          phone: response.data[0].phone,
          brandcode: response.data[0].brand.code,
          code: response.data[0].code,
          birthdate: response.data[0].birthdate,
        };

        if (selectedInfo.birthdate) {
          const splitBirthdate = selectedInfo.birthdate.slice(0, 10).split('-');

          const formattedBirthDate = `${splitBirthdate[2]}/${splitBirthdate[1]}/${splitBirthdate[0]}`;

          selectedInfo = {
            ...selectedInfo,
            birthdate: formattedBirthDate,
          };
        }

        setSelectedClientInfo(selectedInfo);

        customerSelectorModalRef.current?.show();
      } catch (err: any) {
        const { message } = getResponseError(err, t);
        toast.error(message);
        setLoading(false);
      }
    },
    [
      customerSelectorModalRef,
      searchDocumentNumber,
      setClientList,
      setFound,
      setInitialClientDocument,
      setSelectedClientInfo,
      t,
    ],
  );

  const correctJsonString = (jsonString: string): string => {
    // Coloca aspas duplas em torno das chaves
    jsonString = jsonString.replace(/([{,]\s*)([a-zA-Z0-9_]+)\s*:/g, '$1"$2":');
    // Coloca aspas duplas em torno dos valores de string
    jsonString = jsonString.replace(/:\s*([^"\s]+)\s*([,}])/g, ': "$1"$2');
    return jsonString;
  };

  useEffect(() => {
    if (barcodeResult !== 'Not Found') {
      const correctedString = correctJsonString(barcodeResult);
      const QrCodeObject = JSON.parse(correctedString);

      const clientDocument = QrCodeObject.person_document_cpf;
      const clientEmail = QrCodeObject.person_email;
      setSearchDocumentNumber(clientDocument);
      try {
        searchClient(clientDocument, clientEmail);

        setTimeout(() => {
          isBarcodeProcessing.current = false;
        }, 2000);
      } catch (err: any) {
        isBarcodeProcessing.current = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barcodeResult]);

  // Camera scanner instance
  const cameraScanner = useMemo(
    () => (
      <div className="camera">
        <BarcodeScanner
          barcodeResult={barcodeResult}
          setBarcodeResult={setBarcodeResult}
        />
      </div>
    ),
    [barcodeResult],
  );

  return (
    <Container>
      {loading ? (
        <div className="loading">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <h3>{t('HEADER', 'Aponte a câmera para o QR code')}</h3>
          {cameraScanner}
        </>
      )}
    </Container>
  );
};

export { AddByQrCode };
