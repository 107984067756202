import React from 'react';
import stc from 'string-to-color';

// Util import
import { GetUserStr } from '../../util/stringToColor';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  name: string;
  src?: string;
  size: number;
  fixedColor?: string;
  noBackground?: boolean;
}

const Avatar: React.FC<IProps> = ({
  name,
  src,
  size,
  fixedColor,
  noBackground,
}) => {
  const validImage = src?.match(/\.(jpeg|jpg|gif|png)$/) !== null;

  return (
    <Container
      fixedColor={fixedColor}
      color={stc(name)}
      size={size}
      src={validImage ? src : undefined}
      noBackground={noBackground}
    >
      {validImage ? (
        <img src={src} alt={src} />
      ) : (
        <span>{GetUserStr(name)}</span>
      )}
    </Container>
  );
};

Avatar.defaultProps = {
  src: '',
};

export { Avatar };
