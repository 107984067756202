import React from 'react';
import { MdOutlinePlace, MdPersonOutline } from 'react-icons/md';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Feature identification
const featureKey = '@create_order/BAG_STAGE_CLIENT_INFO';

interface IProps {
  children: React.ReactNode;
  type: string;
  handleEditClick: () => void;
}

const InfoCard: React.FC<IProps> = ({ children, type, handleEditClick }) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  const cardOnboardingClass =
    type === 'identification' ? 'onboardingId' : 'onboardingAddress';

  const renderCardTitle = () => {
    if (type === 'identification') {
      return (
        <div className="titleText">
          <p>
            <MdPersonOutline />
          </p>
          <p>Identificação</p>
        </div>
      );
    }
    return (
      <div className="titleText">
        <p>
          <MdOutlinePlace />
        </p>
        <p>Endereço</p>
      </div>
    );
  };

  return (
    <div className={`infoCard ${cardOnboardingClass}`}>
      <div className="cardTitle">
        {renderCardTitle()}
        <button
          type="button"
          className="editButton"
          onClick={() => handleEditClick()}
        >
          {t('TO_EDIT', 'editar')}
        </button>
      </div>
      {children}
    </div>
  );
};

export { InfoCard };
