import styled from 'styled-components';

export const Container = styled.div`
  min-height: 12rem;
  .loadingContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
  }
`;

export const UniquePaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .closeIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.75rem;
    }
  }

  .accordionTitle {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .titleText {
      font-weight: 700;
      font-size: 1.125rem;
    }
  }

  .paymentContent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .titleRow {
      font-weight: 600;
      font-size: 1.5rem;
    }

    .infoText {
    }

    .qrArea {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
    }

    .actionArea {
      display: flex;
      flex-direction: column;

      .buttonsSection {
        .code {
          align-self: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 2rem 0 2rem;

          .text {
            border: solid 0.063rem;
            min-height: 3.5rem;
            display: flex;
            align-items: center;
            border-radius: 0.25rem 0 0 0.25rem;
            width: 50%;

            input {
              background: transparent;
              border: 0;
              font-size: 1rem;
              padding: 0 1rem 0 1rem;
              color: ${({ theme }) => theme.font_primary};
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .button {
            width: 50%;

            button {
              border-radius: 0 0.25rem 0.25rem 0;
            }
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        .share {
          align-self: center;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
        @media screen and (max-width: 768px) {
          margin-top: 0rem;
        }
      }
    }
  }

  .iconRow {
    color: ${({ theme }) => theme.font_secondary};
    font-size: 2.75rem;
  }
  .titleRow {
    p {
      font-weight: 600;
      font-size: 1.125rem;
      margin-top: 1.75rem;
      margin-bottom: 1.5rem;
      white-space: pre-line;
    }
  }
  .textRow {
    p {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: 2rem;
      white-space: pre-line;
    }
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 1.125rem;
  }
  span:last-child {
    margin-top: 1rem;
    width: 100%;
  }
`;

export const MultiplePaymentContainer = styled.div`
  display: flex;
  flex-direction: column;

  .MuiPaper-root {
    color: ${({ theme }) => theme.font_primary};
    background-color: ${({ theme }) => theme.background_primary};
  }

  svg {
    color: ${({ theme }) => theme.button_high};
  }

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .closeIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.75rem;
    }
  }

  .accordionTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .titleText {
      font-weight: 700;
      font-size: 1.125rem;
    }
  }

  .paymentContent {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .titleRow {
      font-weight: 600;
      font-size: 1.5rem;
    }

    .infoText {
    }

    .qrArea {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;
    }

    .actionArea {
      display: flex;
      flex-direction: column;

      .buttonsSection {
        .code {
          align-self: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 2rem 0 2rem;

          .text {
            border: solid 0.063rem;
            min-height: 3.5rem;
            display: flex;
            align-items: center;
            border-radius: 0.25rem 0 0 0.25rem;
            width: 50%;

            input {
              background: transparent;
              border: 0;
              font-size: 1rem;
              padding: 0 1rem 0 1rem;
              color: ${({ theme }) => theme.font_primary};
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .button {
            width: 50%;

            button {
              border-radius: 0 0.25rem 0.25rem 0;
            }
          }

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }

        .share {
          align-self: center;

          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
        @media screen and (max-width: 768px) {
          margin-top: 0rem;
        }
      }
    }
  }
`;
