import React from 'react';

import { MenuItem } from './MenuItem';

import { MenuContainer } from './style';

interface IMenuItems {
  title: string;
  enabled: boolean;
  selected: boolean;
  subTitle?: string;
  id?: string;
}

interface IProps {
  menuList: IMenuItems[];
  handleClick(title: string): void;
}

const RadioButtonMenu: React.FC<IProps> = ({ menuList, handleClick }) => {
  return (
    <MenuContainer>
      <div className="items">
        {menuList?.map(item => (
          <div className={item.id}>
            <MenuItem
              item={item}
              clickFunction={(title: string) => handleClick(title)}
            />
          </div>
        ))}
      </div>
      <div className="divider" />
    </MenuContainer>
  );
};

export { RadioButtonMenu };
