import styled from 'styled-components';
import { motion } from 'framer-motion';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  display: flex;
  flex-direction: column;

  align-self: stretch;
  flex: 1;

  gap: 2rem;
  padding: 2rem;

  @media screen and (max-width: 425px) {
    padding: 1rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  align-self: stretch;
  gap: 1rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    font-weight: 600;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.font_primary};
    flex: 1;
  }

  p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 1.25rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  align-self: stretch;

  margin-top: 1rem;
  border-top: 0.125rem solid ${({ theme }) => theme.font_secondary};
  padding: 1rem 0;

  gap: 1rem;

  .action {
    width: 21rem;
    max-width: 100%;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .action {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-self: stretch;
  flex: 1;

  gap: 2rem;

  padding-left: 3rem;

  .brand {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    align-self: stretch;

    h2 {
      color: ${({ theme }) => theme.font_primary};
      font-size: 1.25rem;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    align-self: stretch;
    flex: 1;

    h2 {
      color: ${({ theme }) => theme.font_primary};
      font-size: 1.25rem;
    }

    .tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      align-self: stretch;

      border-bottom: 0.125rem solid ${({ theme }) => theme.font_secondary};
    }

    .content {
      align-self: stretch;
      flex: 1;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    align-self: stretch;

    border-top: 0.125rem solid ${({ theme }) => theme.font_secondary};
  }

  @media screen and (max-width: 425px) {
    padding-left: 0;
  }
`;

export const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;

  margin-top: 1rem;

  h3 {
    font-weight: bold;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.font_primary};
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;

    flex: 1;

    min-width: 30%;
  }
`;
