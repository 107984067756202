const preloadImages = (urls: string[]) =>
  urls.map(url => {
    try {
      if (!url) return undefined;
      const image = new Image();
      image.src = url;
      return image;
    } catch {
      return undefined;
    }
  });

export { preloadImages };
