import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { useField } from '@unform/core';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

// Style import
import { Container } from './styles';

// Interfaces
type IRef = HTMLInputElement;

interface IProps {
  name?: string;
  standalone?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  hide?: boolean;
  className?: string;

  disabled?: boolean;
  readOnly?: boolean;
  error?: string;

  label?: string;
  helpLabel?: string;
  size?: 'small' | 'medium';
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'high'
    | 'low'
    | string;
  margin?: 'normal' | 'dense' | 'none';
  variant?: 'filled' | 'outlined' | 'standard';
  noFullWidth?: boolean;

  rippleColor?: string;
  disableRipple?: boolean;

  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;

  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
}

const Checkbox = forwardRef<IRef, IProps>(
  (
    {
      name,
      standalone,
      checked,
      defaultChecked,
      hide,

      disabled,
      readOnly,
      error,
      className,

      label,
      helpLabel,
      size,
      color,
      margin,
      variant,
      noFullWidth,

      rippleColor,
      disableRipple,

      icon,
      checkedIcon,

      onChange,
      onFocus,

      ...rest
    },
    ref,
  ) => {
    // Register field name
    const {
      fieldName,
      defaultValue,
      registerField,
      error: formError,
      clearError,
    } = name && !standalone // eslint-disable-next-line react-hooks/rules-of-hooks
      ? useField(name)
      : ({} as ReturnType<typeof useField>);

    // Local states
    const [value, setValue] = useState<boolean>(
      checked || defaultChecked || defaultValue,
    );

    // Local refs
    const inputRef = useRef(null);

    // Merge external ref with local ref
    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === 'function') ref(inputRef.current);
        else ref.current = inputRef.current;
      }
    }, [inputRef, ref]);

    // Register field on unform
    useEffect(() => {
      if (name && !standalone)
        registerField({
          name: fieldName,
          getValue: () => value,
          setValue: (_: any, newValue: any) => setValue(newValue),
          clearValue: () => setValue(false),
        });
    }, [registerField, name, standalone, fieldName, value]);

    // Set local value when external value changes
    useEffect(() => {
      if (typeof checked === 'boolean') setValue(!!checked);
    }, [checked]);

    // Handle input change
    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.checked);
        if (typeof onChange === 'function')
          onChange(event, event.target.checked);
      },
      [onChange],
    );

    // Handle input focus
    const handleFocus = useCallback(
      (event: React.FocusEvent<HTMLButtonElement>) => {
        if (typeof clearError === 'function') clearError();
        if (typeof onFocus === 'function') onFocus(event);
      },
      [clearError, onFocus],
    );

    const checkbox = (
      <MaterialCheckbox
        {...rest}
        inputRef={inputRef}
        name={name}
        checked={value}
        disabled={disabled}
        readOnly={readOnly}
        size={size}
        disableRipple={disableRipple}
        icon={icon}
        checkedIcon={checkedIcon}
        onChange={handleChange}
        onFocus={handleFocus}
      />
    );

    return (
      <Container
        className={className}
        hide={!!hide}
        color={color}
        rippleColor={rippleColor}
        fullWidth={!noFullWidth}
        disabled={!!disabled}
        readOnly={!!readOnly}
        error={!!error || !!formError}
      >
        <FormControl
          disabled={disabled}
          error={!!error}
          variant={variant}
          size={size}
          margin={margin}
        >
          {label ? (
            <FormControlLabel label={label} control={checkbox} />
          ) : (
            checkbox
          )}
          {(error || formError || helpLabel) && (
            <FormHelperText>{error || formError || helpLabel}</FormHelperText>
          )}
        </FormControl>
      </Container>
    );
  },
);

Checkbox.defaultProps = {
  name: undefined,
  standalone: false,
  checked: undefined,
  defaultChecked: false,
  hide: false,

  disabled: false,
  readOnly: false,
  error: undefined,

  label: undefined,
  helpLabel: undefined,
  size: 'medium',
  color: undefined,
  margin: 'none',
  variant: 'standard',
  noFullWidth: false,

  rippleColor: undefined,
  disableRipple: false,

  icon: undefined,
  checkedIcon: undefined,

  onChange: undefined,
  onFocus: undefined,
};

export { Checkbox };
