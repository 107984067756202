import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 270px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 92%;
  position: absolute;
  background-color: ${({ theme }) => theme.background_primary};
  margin-top: 54px;
  border: 1px solid ${({ theme }) => theme.button_primary};
  border-radius: 8px;

  @media screen and (max-width: 630px) {
    width: 91%;
  }

  @media screen and (max-width: 550px) {
    width: 90%;
  }

  @media screen and (max-width: 480px) {
    width: 89%;
  }

  @media screen and (max-width: 450px) {
    width: 87%;
  }

  @media screen and (max-width: 380px) {
    width: 85%;
  }

  .inputRow {
    padding: 0 15px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  &.invisible {
    display: none;
  }

  .codesList {
    li {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid ${({ theme }) => theme.button_primary};

      .itemText {
        font-weight: 400;
        font-size: 1rem;
      }

      .itemButton {
        height: fit-content;
        .MuiRadio-colorPrimary {
          color: ${({ theme }) => theme.button_primary};
        }
        .MuiButtonBase-root {
          padding: 0;
        }

        .Mui-checked {
          color: white;
        }
      }
    }

    li.selected {
      background-color: ${({ theme }) => theme.button_primary};
      color: ${({ theme }) => theme.background_primary};
    }

    li.selected:first-child {
      padding: 15px;
    }

    li:first-child {
      padding: 0 15px 15px;
    }

    li:last-child {
      border-bottom: none;
    }
  }
`;
