import { offset } from '@floating-ui/dom';

export const deliverySteps = [
  {
    id: 'shepherd1',
    attachTo: { element: '.tabMenu', on: 'bottom' as const },
    title: '1/5',
    text: ['Utilize as abas para navegar entre as opções de entrega.'],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Sair',
        type: 'cancel',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'PRÓXIMO',
        type: 'next',
      },
    ],
    canClickTarget: false,
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },
  {
    id: 'shepherd2',
    attachTo: { element: '.onboardTabMenu', on: 'top' as const },
    title: '2/5',
    text: [
      'Na aba Levar Agora você precisa selecionar como o pacote Levar Agora será entregue o(a) cliente.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Próximo',
        type: 'next',
      },
    ],
    canClickTarget: false,
    modalOverlayOpeningPadding: 4,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
    when: {},
  },
  {
    id: 'shepherd3',
    attachTo: { element: '.in_hands', on: 'bottom-start' as const },
    title: '3/5',
    text: [
      'Selecione a opção de Entrega Imediata quando a(o) cliente sairá da loja com os pedidos em mãos. Esta opção estará sempre selecionada como padrão.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Próximo',
        type: 'next',
      },
    ],
    canClickTarget: false,
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },
  {
    id: 'shepherd4',
    attachTo: { element: '.delivery', on: 'bottom-start' as const },
    title: '4/5',
    text: [
      'Selecione a opção de Delivery/Portador quando o produto será entregue por terceiros.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Próximo',
        type: 'next',
      },
    ],
    canClickTarget: false,
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },

  {
    id: 'shepherd5',
    attachTo: { element: '.items', on: 'bottom' as const },
    title: '5/5',
    text: [
      'Clique para alterar a preferência de entrega dentre as disponíveis.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Finalizar',
        type: 'complete',
      },
    ],
    canClickTarget: false,
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },
];

export const onlyOwnStockDeliverySteps = [
  {
    id: 'shepherd1',
    attachTo: { element: '.in_hands', on: 'top' as const },
    title: '1/2',
    text: [
      'Selecione a opção de Entrega Imediata quando a(o) cliente sairá da loja com os pedidos em mãos. Esta opção estará sempre selecionada como padrão.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Sair',
        type: 'cancel',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'PRÓXIMO',
        type: 'next',
      },
    ],
    canClickTarget: false,
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },
  {
    id: 'shepherd2',
    attachTo: { element: '.delivery', on: 'top' as const },
    title: '2/2',
    text: [
      'Selecione a opção de Delivery/Portador quando o produto será entregue por terceiros.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Finalizar',
        type: 'complete',
      },
    ],
    canClickTarget: false,
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },
];
