import styled, { css } from 'styled-components';
import { lighten, transparentize } from 'polished';

// Util import
import { getColor } from '@util/getColor';

// Interfaces
interface IContainerProps {
  color?: string;
  rippleColor?: string;
  hide: boolean;
  fullWidth: boolean;
  disabled: boolean;
  readOnly: boolean;
  error: boolean;
  children: React.ReactNode;
}

export const Container = styled.div<IContainerProps>`
  display: ${props => (props.hide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};

  ${props =>
    (props.disabled || props.readOnly) &&
    css`
      * {
        cursor: not-allowed !important;
      }
    `}

  .MuiFormControl-root {
    .MuiFormControlLabel-root {
      cursor: ${props =>
        props.disabled || props.readOnly ? 'not-allowed' : 'pointer'};

      .MuiCheckbox-root {
        color: ${props =>
          getColor({
            theme: props.theme,
            color: props.color,
            error: props.error,
            disabled: props.disabled,
            readOnly: props.readOnly,
          })} !important;

        &:hover {
          background-color: ${props =>
            transparentize(
              0.7,
              lighten(
                0.5,
                getColor({
                  theme: props.theme,
                  color: props.color,
                  error: props.error,
                  disabled: props.disabled,
                  readOnly: props.readOnly,
                }),
              ),
            )} !important;
        }

        .MuiTouchRipple-root {
          .MuiTouchRipple-child {
            background-color: ${props =>
              getColor({
                theme: props.theme,
                color: props.color,
                error: props.error,
                disabled: props.disabled,
                readOnly: props.readOnly,
              })} !important;
          }
        }
      }
    }

    .MuiTypography-root,
    .MuiFormControlLabel-label {
      font-size: 1rem;
      color: ${props =>
        getColor({
          theme: props.theme,
          color: props.color,
          error: props.error,
          disabled: props.disabled,
          readOnly: props.readOnly,
        })} !important;
    }

    .MuiFormHelperText-root {
      margin-top: -0.625rem;
      font-size: 0.8rem;
      color: ${props =>
        getColor({
          theme: props.theme,
          color: props.color,
          error: props.error,
          disabled: props.disabled,
          readOnly: props.readOnly,
        })} !important;
    }
  }
`;
