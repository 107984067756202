import React from 'react';

// Component import
import { IPaymentTypes } from '@util/CartModels';

import { DefaultButton } from './DefaultButton';
import { StoreCreditButton } from './StoreCreditButton';

interface IPaymentTypeButtonProps {
  toggleIsSelected?: boolean;
  icon?: any;
  payment?: any;
  setToggleIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  availablePaymentTypes: IPaymentTypes[];
}

const PaymentTypeButton: React.FC<IPaymentTypeButtonProps> = ({
  toggleIsSelected,
  icon,
  payment,
  setToggleIsSelected,
  availablePaymentTypes,
}) => {
  const RenderComponent = () => {
    if (payment?.code === 'store_credit')
      return (
        <div className="onboardingStoreCredit" style={{ width: '100%' }}>
          <StoreCreditButton
            icon={icon}
            payment={payment}
            toggleIsSelected={toggleIsSelected}
            setToggleIsSelected={setToggleIsSelected}
          />
        </div>
      );
    return (
      <DefaultButton
        icon={icon}
        payment={payment}
        toggleIsSelected={toggleIsSelected}
        availablePaymentTypes={availablePaymentTypes}
      />
    );
  };

  return <>{RenderComponent()}</>;
};

export { PaymentTypeButton };
