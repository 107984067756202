import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  align-self: stretch;
  gap: 1rem;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    > .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      span.count {
        color: ${({ theme }) => theme.font_secondary};
        margin-bottom: 0.125rem;
      }

      span.type {
        color: ${({ theme }) => theme.font_primary};
        font-size: 1.25rem;
        font-weight: bold;
      }

      span.status {
        font-size: 1rem;

        &.paid {
          color: ${({ theme }) => theme.font_success};
        }

        &.canceled {
          color: ${({ theme }) => theme.font_danger};
        }

        &.pending {
          color: ${({ theme }) => theme.font_warning};
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      .total,
      .paid,
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        span.label {
          font-size: 1rem;
          color: ${({ theme }) => theme.font_secondary};
          font-weight: bold;
        }

        span.value {
          font-size: 1rem;
          color: ${({ theme }) => theme.font_primary};
        }
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 1rem;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem 1rem;
      border-radius: 0.2rem;
      background: ${({ theme }) => theme.background_secondary};

      span.count {
        color: ${({ theme }) => theme.font_low};
        font-size: 1rem;
        font-weight: bold;
      }

      .info {
        flex: 1;

        .value {
          font-size: 1rem;
          color: ${({ theme }) => theme.font_low};

          &.canceled {
            text-decoration: line-through;
          }
        }
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    .actions {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
`;
