import styled from 'styled-components';

export const Metadata = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 2.5rem;

  .customerInfo {
    display: flex;
    flex-direction: column;

    width: 50%;
    gap: 0.25rem;
    div {
      span {
        font-size: 1rem;
      }
    }

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }

  .divider {
    width: 0.125rem;
    border-right: solid ${({ theme }) => theme.font_secondary};
    margin-right: 4rem;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    min-width: 100vw;
    border-radius: 0;

    > div {
      border-radius: 0;
    }
  }
`;
