import axios from 'axios';

// Hook import
import { TranslationFunction } from '@hooks/useTranslation';

// Store import
import { store } from '../store';
import { signOut } from '../store/modules/auth/actions';

// Create axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': 'https://somastore.somalabs.com.br',
    Referer: 'https://somastore.somalabs.com.br',
  },
});

// Handle 201 status
api.interceptors.response.use(
  successResponse => successResponse,
  errorResponse => {
    // When 401 error, sign out
    if (errorResponse?.response?.status === 401) store.dispatch(signOut());
    return Promise.reject(errorResponse);
  },
);

// Error helper
interface IResponseError {
  status: number;
  code: string;
  message: string;
  error: 'FATAL' | 'ERROR';
  err: any;
}
const getResponseError = (err: any, t: TranslationFunction): IResponseError => {
  return {
    status: err?.response?.status || 0,
    code: err?.response?.data?.code || '@general/UNKNOWN',
    message:
      err?.response?.data?.message ||
      t(
        '@general/CONNECTION_FAILURE_ERROR',
        'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
      ),
    error: err?.response?.data?.fatal ? 'FATAL' : 'ERROR',
    err,
  };
};

export { api, getResponseError };
