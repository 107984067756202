import React from 'react';
import { IoMdWarning } from 'react-icons/io';

// Style import
import { Container } from './styles';

const MigrationWarning: React.FC = () => {
  return (
    <Container>
      <p className="warningIcon">
        <IoMdWarning />
      </p>
      <div className="warningText">
        <p className="warningTitle">Atenção - Migração de CNPJ!</p>
        <p className="warningSubtitle">
          Atualize sua máquina Stone{' '}
          <span>
            com o StoneCode enviado pelo financeiro seguindo{' '}
            <a
              href="https://drive.google.com/file/d/1F_Rv6RkKBxA6WIuGKJGpwp-APt6P50nF/view?t=280"
              target="_blank"
              rel="noreferrer"
            >
              este passo a passo.
            </a>
          </span>
        </p>
      </div>
    </Container>
  );
};

export { MigrationWarning };
