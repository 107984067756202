import styled from 'styled-components';

export const OnboardingTourButtonContent = styled.button`
  color: ${({ theme }) => theme.button_primary};
  font-size: 1rem;
  border: 2px solid;
  border-radius: 2rem;
  padding: 0.5rem;
  position: absolute;
  z-index: -2;
  opacity: 0;
`;
