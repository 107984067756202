import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .closeIcon {
      color: ${({ theme }) => theme.button_high};
      font-size: 1.75rem;
    }
  }

  .titleRow {
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
  }

  .textRow {
    max-width: 350px;
    display: flex;
    flex-direction: column;
  }

  .noRepeatRow {
    text-align: center;
    font-weight: 600;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.font_secondary};
    margin-bottom: 1.5;

    button {
      border: none;
    }
  }

  .actionArea {
    width: 18rem;
    margin-bottom: 2rem;
  }

  p {
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    white-space: pre-line;
  }
  span:last-child {
    margin-top: 1rem;
    width: 100%;
  }
`;
