import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 50;
  height: fit-content;
  background-color: ${({ theme }) => theme.background_primary};

  .titleRow {
    position: relative;
    width: fit-content;
    background-color: ${({ theme }) => theme.background_primary};
    font-size: 0.75rem;
    color: ${({ theme }) => theme.font_secondary};
    z-index: 100;
    top: 8px;
    left: 12px;
    padding: 0 5px;
  }

  .inputBox {
    width: 126px;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.font_secondary};
    border-radius: 6px;
    padding: 0 12px;
    font-size: 1rem;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    @media screen and (max-width: 726px) {
      width: 75px;
    }
  }
`;
