import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useSelector } from 'react-redux';

// Asset import
import { PrintDisabledIcon } from '@assets/index';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
}

// Feature identification
const featureKey = '@order/PRINT_FILE_MODAL';

const DisconnectedPrinterModal: React.FC<IProps> = ({ hide }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const user = useSelector(state => state.auth);

  const userIsManager = user.profile_type === 'store';
  const modalSecondParagraph = userIsManager
    ? t(
        'CONTACT_MANAGER',
        'Para continuar imprimindo documentos, habilite outra impressora da lista em Configurações no Menu e tente novamente. ',
      )
    : t(
        'CONTACT_MANAGER',
        'Peça para gerente ou ADM de sua loja selecionar outra impressora dentre as disponíveis para continuar imprimindo documentos. ',
      );

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="titleRow">
        {t('LOST_CONNECTION', 'PERDA DE CONEXÃO COM IMPRESSORA')}
      </div>
      <div className="iconRow">
        <PrintDisabledIcon width={80} />
      </div>
      <div className="textRow">
        <div className="paragraph">
          {t(
            'NO_CONNECTION_AVAILABLE',
            'Não conseguimos estabelecer conexão com a impressora selecionada para sua loja.',
          )}
        </div>
        <div className="paragraph">{modalSecondParagraph}</div>
      </div>
      <div className="actionArea">
        <Button
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          {t('CLOSE_MODAL', 'ENTENDI')}
        </Button>
      </div>
    </Container>
  );
};

export { DisconnectedPrinterModal };
