import { AnyAction } from 'redux';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { api } from '@services/api';
import { maisVendasApi } from '@services/maisVendasApi';
import { invoiceApi } from '@services/invoiceApi';

// Action import
import { signInSuccess, signOut } from './actions';
// Type import
import { AuthActionTypes } from './types';

function signInSuccessEffect({ payload }: AnyAction) {
  // Get token from payload
  const { token } = payload.data;
  const { persist } = payload;

  // Setup token on axios
  api.defaults.headers.Authorization = `Bearer ${token}`;
  maisVendasApi.defaults.headers.Authorization = `Bearer ${token}`;
  invoiceApi.defaults.headers.Authorization = `JWT ${process.env.REACT_APP_INVOICE_TOKEN}`;

  // Set storage
  if (persist)
    localStorage.setItem(process.env.REACT_APP_SESSION_PERSIST_KEY, token);
}

function signOutEffect() {
  // Clear axios token
  api.defaults.headers.Authorization = undefined;
  maisVendasApi.defaults.headers.Authorization = undefined;
  invoiceApi.defaults.headers.Authorization = undefined;

  // Clear storage
  localStorage.removeItem(process.env.REACT_APP_SESSION_PERSIST_KEY);
}

function* refreshSessionEffect() {
  // Get token from store
  const { token, persist, store, store_is_open } = yield select(
    state => state.auth,
  );

  // Check if token is defined
  if (token) {
    // Setup token on axios
    api.defaults.headers.Authorization = `Bearer ${token}`;
    maisVendasApi.defaults.headers.Authorization = `Bearer ${token}`;
    invoiceApi.defaults.headers.Authorization = `JWT ${process.env.REACT_APP_INVOICE_TOKEN}`;

    try {
      // API call
      const response: AxiosResponse = yield call(api.get, '/session', {
        timeout: 15000,
      });

      if (
        response.data.store_is_open !== store_is_open ||
        JSON.stringify(response.data.store) !== JSON.stringify(store)
      ) {
        // Handle data
        yield put(signInSuccess(response.data, !!persist));
      }
    } catch (err: any) {
      // Logout
      yield put(signOut());
    }
  } else {
    // Logout
    yield put(signOut());
  }
}

function* resumeSessionRequestEffect({ payload }: AnyAction) {
  if (payload.token) {
    try {
      // API call
      const response: AxiosResponse = yield call(api.get, '/session', {
        headers: {
          Authorization: `Bearer ${payload.token}`,
        },
        timeout: 15000,
      });

      // Setup axios
      api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      maisVendasApi.defaults.headers.Authorization = `Bearer ${response.data.token}`;
      invoiceApi.defaults.headers.Authorization = `JWT ${process.env.REACT_APP_INVOICE_TOKEN}`;

      // Handle success
      yield put(signInSuccess(response.data, true));
    } catch (err: any) {
      // Logout
      yield put(signOut());
    }
    // Logout
  } else {
    // Logout
    yield put(signOut());
  }
}

const auth = all([
  takeLatest(
    AuthActionTypes.RESUME_SESSION_REQUEST,
    resumeSessionRequestEffect,
  ),
  takeLatest(AuthActionTypes.SIGN_IN_SUCCESS, signInSuccessEffect),
  takeLatest(AuthActionTypes.SESSION_REFRESH_REQUEST, refreshSessionEffect),
  takeLatest(AuthActionTypes.SIGN_OUT, signOutEffect),
]);

export { auth };
