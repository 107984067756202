import { offset } from '@floating-ui/dom';

export const paymentSteps = [
  {
    id: 'shepherd1',
    attachTo: { element: '.onboardingPaymentTitle', on: 'bottom' as const },
    title: '1/4',
    text: [
      'Caso a cliente deseje, você pode selecionar duas formas de pagamento ativando o toggle.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Sair',
        type: 'cancel',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'PRÓXIMO',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
  {
    id: 'shepherd2',
    attachTo: { element: '.onboardingStoreCredit', on: 'bottom' as const },
    title: '2/4',
    text: [
      'Caso a cliente possua saldo, clique aqui e indique a quantidade de saldo que deseja utilizar. Caso o saldo seja insuficiente, será pedido que você selecione mais uma forma de pagamento para completar o valor.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Próximo',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
  {
    id: 'shepherd3',
    attachTo: { element: '.onboardingOtherPayments', on: 'bottom' as const },
    title: '3/4',
    text: [
      'Clique para escolher a forma de pagamento dentre as disponíveis para finalizar a venda.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Próximo',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
  {
    id: 'shepherd4',
    attachTo: {
      element: '.onboardingCartPricingSummary',
      on: 'bottom' as const,
    },
    title: '4/4',
    text: [
      'Veja aqui o total da compra e clique para adicionar um cupom. Nesta etapa, cupons que precisam de identificação de cliente também estarão ativos.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Finalizar',
        type: 'complete',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
    canClickTarget: false,
  },
];

export const multiplePaymentSteps = [
  {
    id: 'shepherd5',
    attachTo: {
      element: '.onboardingMultiplePaymentTitle',
      on: 'bottom-start' as const,
    },
    text: [
      'Clicando no valor, é possível editar o quanto deseja pagar em cada forma de pagamento, que por padrão vem divido igualmente.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: '',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'OK',
        type: 'complete',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 32 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
];

export const storeCreditSteps = [
  {
    id: 'shepherd6',
    attachTo: {
      element: '.onboardingCartPricingSummary',
      on: 'bottom' as const,
    },
    text: [
      'O valor de desconto de seu Saldo de Créditos aparecerá no resumo da compra.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: '',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'OK',
        type: 'complete',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 32 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
];

export const paymentWithoutStoreCreditSteps = [
  {
    id: 'shepherd7',
    attachTo: { element: '.onboardingPaymentTitle', on: 'bottom' as const },
    title: '1/3',
    text: [
      'Caso a cliente deseje, você pode selecionar duas formas de pagamento ativando o toggle.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Sair',
        type: 'cancel',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'PRÓXIMO',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
  {
    id: 'shepherd8',
    attachTo: { element: '.onboardingOtherPayments', on: 'bottom' as const },
    title: '2/3',
    text: [
      'Clique para escolher a forma de pagamento dentre as disponíveis para finalizar a venda.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Próximo',
        type: 'next',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    canClickTarget: false,
    scrollTo: true,
  },
  {
    id: 'shepherd9',
    attachTo: {
      element: '.onboardingCartPricingSummary',
      on: 'bottom' as const,
    },
    title: '3/3',
    text: [
      'Veja aqui o total da compra e clique para adicionar um cupom. Nesta etapa, cupons que precisam de identificação de cliente também estarão ativos.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: 'Voltar',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary',
        text: 'Finalizar',
        type: 'complete',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
    canClickTarget: false,
  },
];
