import styled from 'styled-components';

export const AddByReferenceAndColorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  form {
    display: grid;
    align-self: stretch;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.375rem;

    .inputWrap {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 425px) {
    form {
      grid-template-columns: 1fr;
      gap: 0rem;
      margin-bottom: 0.5rem;
    }
  }
`;

export const AddByTextSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;

  form {
    display: grid;
    align-self: stretch;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.375rem;
  }

  @media screen and (max-width: 425px) {
    border-top: 0.125rem solid ${({ theme }) => theme.font_secondary};

    form {
      grid-template-columns: 1fr;
      gap: 0rem;
      padding-bottom: 1rem;
    }
  }
`;

export const ChangeMethodModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  max-width: 23.0625rem;

  p {
    color: ${({ theme }) => theme.font_secondary};
    font-size: 1rem;
    font-weight: 600;
  }

  .actions {
    margin-top: 2.3125rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  align-self: stretch;

  margin-top: 1rem;
  border-top: 0.03rem solid ${({ theme }) => theme.font_secondary};
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.background_low};
  gap: 1rem;

  .action {
    width: 21rem;
    max-width: 100%;
  }

  @media screen and (max-width: 425px) {
    border: none;
    margin-top: 0;

    .action {
      width: 100%;
    }
  }
`;
