interface IShipping {
  title: string;
  method: string;
  enabled: boolean;
  selected: boolean;
  tagCode: string;
  data: {
    [key: string]: any;
  };
}

const AddDataToShippingList = (
  responseData: any,
  shippingList: IShipping[],
) => {
  const moreThanOneMethodAvailable = responseData.length > 1;

  shippingList.forEach((item: any) => {
    const itemMethod = item.method;
    const filterResponseData = responseData.filter(
      (dataItem: any) => itemMethod === dataItem.method,
    )[0];

    item.selected = false;
    item.enabled = false;
    item.data = filterResponseData;

    //  Quando tipos diferentes de entrega forem disponibilizados, o código abaixo poderá ser aplicado
    const isShippingTypeAvailable = item.data;
    if (isShippingTypeAvailable) {
      item.enabled = true;
    } else {
      item.enabled = false;
    }

    if (moreThanOneMethodAvailable && itemMethod === 'cheapest') {
      item.selected = true;
    }
    if (!moreThanOneMethodAvailable && itemMethod === 'best') {
      item.selected = true;
    }
  });

  return shippingList;
};

const FilterPackageItems = (
  packages: any,
  code: string,
  item_selected_type: string,
) => {
  const filterPackageItems = packages.items.filter(
    (packageItem: any) =>
      packageItem.code === code &&
      packages.selected_type.toUpperCase() === item_selected_type.toUpperCase(),
  );
  const packageWasFound = filterPackageItems.length > 0;
  return packageWasFound;
};

const SetItemsShippingData = (items: any, selectedShippingType: IShipping) => {
  items.forEach((item: any) => {
    const itemCode = item.code;
    const itemSelectedType = item?.shipping?.selected_type;
    const selectedShippingTypeData = selectedShippingType.data;

    if (itemSelectedType === 'DELIVERY') {
      const findItemInPackages = selectedShippingTypeData.packages.filter(
        (packages: any) =>
          FilterPackageItems(packages, itemCode, itemSelectedType),
      )[0];

      item.shipping.selected_id = findItemInPackages?.selected_id;
      item.shipping.selected_type =
        findItemInPackages?.selected_type.toUpperCase();
    }
  });
  return items;
};

const compareShippingData = (
  prevList: IShipping[],
  currentList: IShipping[],
) => {
  const comparisonData = {
    hasShippingDataChanged: false,
    hasValueChanged: false,
  };

  currentList.forEach((item: IShipping, index: number) => {
    const prevItemData = prevList[index];

    const isSamePrice =
      prevItemData?.data?.current_price === item?.data?.current_price;

    const isSameStatus = prevItemData.enabled === item.enabled;

    const isSamePackageData =
      JSON.stringify(item?.data?.packages) ===
      JSON.stringify(prevItemData?.data?.packages);

    if (isSamePrice && isSameStatus && isSamePackageData) {
      comparisonData.hasShippingDataChanged = false;
      return;
    }
    comparisonData.hasShippingDataChanged = true;

    if (isSameStatus && !isSamePrice) {
      comparisonData.hasValueChanged = true;
    }
  });
  return comparisonData;
};

export { AddDataToShippingList, SetItemsShippingData, compareShippingData };
