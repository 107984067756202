import React, { useMemo } from 'react';
import { MdAdd, MdOutlineShoppingBag } from 'react-icons/md';

// Component import
import { Button } from '@components/Button';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTheme } from '@hooks/useTheme';
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@create_order/CART_EMPTY';

const CartEmpty: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const order = useOrderCreation();

  const userName = useMemo(() => {
    if (!order.saleCode.name) return '';
    const name = order.saleCode.name?.split(' ')[0]?.toLowerCase();
    return `${name[0]?.toUpperCase()}${name.slice(1)}`;
  }, [order.saleCode.name]);

  return (
    <Container>
      <>
        {order.loading ? (
          <>
            <LoadingIndicator color={theme.font_secondary} size={3} />
          </>
        ) : (
          <>
            {!!userName && (
              <p>
                {t('HELLLO', 'Olá')} {String(userName).split(' ')[0]}!
              </p>
            )}

            <div className="icon">
              <MdOutlineShoppingBag color={theme.font_secondary} size={100} />
            </div>
            <p>{t('EMPTY_TITLE', 'Sua sacola está vazia.')}</p>
            <p className="orientation">
              {t(
                'ORIENTATION',
                'Clique abaixo em Adicionar produto para iniciar uma nova sacola.',
              )}
            </p>
            <div className="action">
              <Button
                onClick={() => {
                  order.setAddingProduct(true);
                }}
                startIcon={<MdAdd />}
                disabled={order.loading}
              >
                {t('ACTION_ADD_PRODUCT', 'ADICIONAR PRODUTO')}
              </Button>
            </div>
          </>
        )}
      </>
    </Container>
  );
};

export { CartEmpty };
