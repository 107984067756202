/**
 * Wraps a number when it breaches the defined range.
 * Example y = wrap(0,2,X)
 *
 * X 0 1 2 3 4 5 6 7 8 9
 * Y 0 1 0 1 0 1 0 1 0 1
 */
const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min;
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

export { wrap };
