import React from 'react';

// Asset impoort
import { ReactComponent as Logo } from '@assets/soma.svg';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useTheme } from '@hooks/useTheme';

// Style import
import { Container } from './styles';

const Loading: React.FC = () => {
  // Hooks
  const theme = useTheme();

  return (
    <Container>
      <div className="logo">
        <Logo fill={theme.button_low} />
      </div>
      <LoadingIndicator color={theme.button_low} />
    </Container>
  );
};

export { Loading };
