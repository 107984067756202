import styled from 'styled-components';

export const MenuContainer = styled.div`
  margin-bottom: 1rem;

  .items {
    display: flex;
    width: fit-content;
    flex-direction: row;
    justify-content: space-between;
    gap: 88px;
    margin-bottom: 18px;
    @media screen and (max-width: 800px) {
      width: 100%;
      flex-direction: column;
      gap: 18px;
      justify-content: flex-start;
    }
  }
  .divider {
    width: 100%;
    border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};
    border-radius: 0.125rem;
  }
`;
