import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* flex: 1; */
  /* align-self: stretch; */
  gap: 1rem;
  padding: 1rem;
  height: 100%;
  width: 100%;

  /* color: ${({ theme }) => theme.font_primary}; */

  .selectedClient {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .clientCard {
      width: 100%;
      display: flex;
      flex-direction: column;

      background-color: ${({ theme }) => theme.background_primary};
      border-radius: 0.5rem;
      padding: 1rem;
      gap: 4rem;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        gap: 6rem;

        .verticalDivider {
          height: 50%;
          border: 1px solid ${({ theme }) => theme.background_secondary};
          align-self: center;
        }
      }

      .creditBalanceSection {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .creditBalanceTitleRow {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;

          align-items: center;
          font-weight: 600;
          font-size: 1rem;
        }

        .divider {
          border: 1px solid ${({ theme }) => theme.background_secondary};
        }

        .valueRow {
          font-size: 1.5rem;
        }
      }

      .identificationClientSection {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .clientTitleRow {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;

          align-items: center;
          font-weight: 600;
          font-size: 1rem;
        }

        .divider {
          border: 1px solid ${({ theme }) => theme.background_secondary};
        }

        .generalInfo {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .generalRow {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
          }
        }
      }
    }
  }

  .clientTitle {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    align-self: stretch;

    border-bottom: 0.125rem solid ${({ theme }) => theme.font_secondary};
  }

  .content {
    align-self: stretch;
    flex: 1;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  align-self: stretch;

  margin-top: 1rem;
  border-top: 0.125rem solid ${({ theme }) => theme.font_secondary};
  padding: 1rem 0;

  gap: 1rem;

  p {
    color: ${({ theme }) => theme.font_primary};
  }

  .action {
    width: 21rem;
    max-width: 100%;
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .action {
      width: 100%;
    }
  }
`;
