import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  padding: 0.75rem;
  border-top: 1px solid ${({ theme }) => theme.font_secondary};
  border-bottom: 1px solid ${({ theme }) => theme.font_secondary};
  align-items: flex-start;
  gap: 0.3rem;

  p.warningIcon {
    font-size: 1rem;
    color: ${({ theme }) => theme.font_secondary};
    height: fit-content;
  }

  .warningText {
    display: flex;
    flex-direction: column;
    height: fit-content;

    p.warningTitle {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${({ theme }) => theme.font_secondary};
    }

    p.warningSubtitle {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5625rem;

      span {
        font-weight: 600;

        a {
          text-decoration: underline;
        }
      }
    }
  }
`;
