import styled from 'styled-components';

export const MobileOrderSummary = styled.div`
  height: 100vh;
  position: absolute;
  right: 0;
  width: 100%;
  min-height: -webkit-fill-available;
  background-color: ${({ theme }) => theme.background_primary};
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 1rem;
  .orderSummaryHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .backButton {
      button {
        p {
          font-weight: 500;
          text-decoration: underline;
          font-size: 0.75rem;
          color: ${({ theme }) => theme.font_secondary};
        }
      }
    }
    .closeButton {
      button {
        color: ${({ theme }) => theme.font_secondary};
        svg {
          font-size: 1.5rem;
        }
      }
    }
  }
  .orderSummaryContent {
    max-height: 100vh;
    .packages {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      height: 100%;
      max-height: inherit;
      width: 100%;
      overflow: hidden;
      overflow-y: scroll;
      padding-right: 0.5rem;

      /* width */
      ::-webkit-scrollbar {
        width: 0.25rem;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: none;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.background_secondary};
        border-radius: 0.25rem;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      .package {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-bottom: 1rem;
        border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};

        .packageTitle {
          p {
            font-weight: 600;
            font-size: 1rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
        .packageContent {
          display: flex;
          flex-direction: row;

          p {
            font-weight: 500;
            font-size: 0.875rem;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
        .productCard {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          align-self: stretch;
          background: ${({ theme }) => theme.background_primary};
          border-radius: 0.5rem;
          width: 100%;
          min-height: 5.5rem;
          margin-right: 0.5rem;

          .image {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: stretch;
            align-self: stretch;
            max-width: 3.75rem;

            img {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
              object-fit: cover;
              flex: 1;
              align-self: stretch;
              max-width: 3.75rem;
            }
          }

          .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            height: 100%;
            width: 100%;
            padding: 1rem;
            overflow: hidden;
            gap: 0.125rem;

            .name {
              font-size: 0.875rem;
              font-weight: 400;
              color: ${({ theme }) => theme.font_primary};
              width: -webkit-fill-available;
              margin-bottom: 0.25rem;
              P {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .sizeAndQuantityRow {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 2rem;
              .size {
                p {
                  font-weight: 400;
                  font-size: 0.75rem;
                }
              }
              .quantity {
                p {
                  font-weight: 400;
                  font-size: 0.75rem;
                }
              }
            }

            .price {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              gap: 1rem;

              font-size: 1rem;
              font-weight: 400;
              color: ${({ theme }) => theme.font_primary};

              .original_price {
                text-decoration: line-through;
                color: ${({ theme }) => theme.button_secondary};
              }
            }
          }
        }
      }
    }
  }
`;
