import styled from 'styled-components';

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 2rem;
  padding: 1rem;
  flex: 1;

  overflow: hidden;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.background_secondary};
    border-radius: 0.25rem;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scrollArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    gap: 1rem;

    overflow: hidden;
    overflow-y: scroll;
    padding: 1rem;
    width: 100%;

    /* width */
    ::-webkit-scrollbar {
      width: 0.25rem;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: none;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.background_secondary};
      border-radius: 0.25rem;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 2rem;
  padding: 0.25rem;
  flex: 1;

  overflow: hidden;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.background_secondary};
    border-radius: 0.25rem;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .scrollArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;

    overflow: hidden;
    overflow-y: scroll;
    padding: 0.25rem;
    width: 100%;
    gap: 1rem;

    /* width */
    ::-webkit-scrollbar {
      width: 0.25rem;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: none;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.background_secondary};
      border-radius: 0.25rem;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
