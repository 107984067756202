import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';
import { toast } from 'react-toastify';

// Component import
import { CartSaleCode } from '@components/CartSaleCode';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import {
  AddDataToShippingList,
  SetItemsShippingData,
  compareShippingData,
} from '@util/handleShippingData';
import { initialShippingData } from '@util/initialShippingData';
import { IShippingTypes } from '@util/CartModels';

// Feature identification
const featureKey = '@create_order/CART_PAYMENT_TITLE';

interface IProps {
  setStatusState: (
    value: 'bag' | 'identification' | 'delivery' | 'payments',
  ) => void;
  selectedShipping: IShippingTypes;
}

const SectionTitle: React.FC<IProps> = ({
  setStatusState,
  selectedShipping,
}) => {
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const user = useSelector(state => state.auth);

  const cartId = useDebounce(order?.orderCode, 1000);

  const getSelectedShipping = (list: IShippingTypes[]) => {
    const filterSelected = list.filter((item: any) => item.selected);
    return filterSelected[0];
  };

  const updateShippingData = async () => {
    try {
      // API call
      const response = await api.get(`/v1/cart/${cartId}/shipping`);

      const prevShippingData = order.shippingTypes;

      const updatedShippingData = AddDataToShippingList(
        response.data,
        initialShippingData,
      );

      const dataComparison = compareShippingData(
        prevShippingData,
        updatedShippingData,
      );

      if (dataComparison.hasShippingDataChanged) {
        selectedShipping = getSelectedShipping(updatedShippingData);

        const addShippingDataToItems = SetItemsShippingData(
          order?.items,
          selectedShipping,
        );
        order.setItems(addShippingDataToItems);

        if (dataComparison.hasValueChanged) {
          toast.warning('Seu valor de frete foi atualizado');
          return;
        }
        toast.warning('Suas opções de entrega foram atualizadas');
      }
    } catch (err) {
      const { message } = getResponseError(err, t);
      toast.error(message);
    }
  };

  return (
    <div className="titleRow">
      <button
        type="button"
        onClick={() => {
          setStatusState('delivery');
          updateShippingData();
        }}
      >
        <MdArrowBack />
      </button>
      <div className="title">
        <p>{t('PAYMENT', 'Pagamento')}</p>
        {user.sale_codes && (
          <>
            {order.saleCode.code &&
              order.saleCode.name &&
              user.sale_codes.length > 1 && <CartSaleCode />}
          </>
        )}
      </div>
    </div>
  );
};

export { SectionTitle };
