import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

// Component import
import { BarcodeScanner } from '@components/BarcodeScanner';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';
import { useForwardRef } from '@hooks/useForwardRef';

// Util import
import { validateUrl } from '@util/validateUrl';
import { getQueryStringValues } from '@util/getQueryStringValues';

// Style import
import { Container } from './styles';

// Interface import
import { IAddProductScreenRef } from '..';

// Feature identification
const featureKey = '@create_order/PRODUCT_ADD_BY_CAMERA';

const AddByCamera = forwardRef<IAddProductScreenRef>((_, ref) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const product = useAddProduct();

  // Local states
  const [barcodeResult, setBarcodeResult] = useState<any>('Not Found');

  // Local refs
  const isFocused = useRef<boolean>(false);
  const isBarcodeProcessing = useRef<boolean>(false);

  // Focus action
  const onSelect = useCallback(() => {
    isFocused.current = true;
  }, []);

  // Blur action
  const onDeselect = useCallback(() => {
    isFocused.current = false;
  }, []);

  // Assign method to ref
  useForwardRef(ref, {
    onFocus: () => null,
    onBlur: () => null,
    onSelect,
    onDeselect,
  });

  useEffect(() => {
    if (barcodeResult !== 'Not Found') {
      if (validateUrl(barcodeResult)) {
        // Get query params
        const query = getQueryStringValues(barcodeResult);

        // Check if exists
        if (query) {
          const validURLQueryParams = ['ref'];

          const find = Object.entries(query).find(([key]) =>
            validURLQueryParams.includes(key),
          );

          if (find) {
            const innerResult2 = find[1];
            try {
              product.getProduct({
                code: innerResult2,
              });

              setTimeout(() => {
                isBarcodeProcessing.current = false;
              }, 2000);
            } catch (err: any) {
              isBarcodeProcessing.current = false;
            }
          }
        }
      } else {
        try {
          product.getProduct({
            code: barcodeResult,
          });

          setTimeout(() => {
            isBarcodeProcessing.current = false;
          }, 2000);
        } catch (err: any) {
          isBarcodeProcessing.current = false;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barcodeResult]);

  // Camera scanner instance
  const cameraScanner = useMemo(
    () => (
      <div className="camera">
        <BarcodeScanner
          barcodeResult={barcodeResult}
          setBarcodeResult={setBarcodeResult}
        />
      </div>
    ),
    [barcodeResult],
  );

  return (
    <Container>
      {product.loading ? (
        <div className="loading">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <h3>
            {t('HEADER', 'Aponte a câmera para o código de barras ou QR code')}
          </h3>
          {cameraScanner}
        </>
      )}
    </Container>
  );
});

export { AddByCamera };
