import styled from 'styled-components';
import { motion } from 'framer-motion';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding: 2rem;
  flex: 1;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.background_primary};
    border-radius: 0.25rem;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .titleRow {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    width: 100%;

    @media screen and (min-width: 768px) {
      margin-bottom: 2rem;
    }

    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      button {
        margin-right: 0;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    button {
      margin-right: 1rem;
      color: ${({ theme }) => theme.button_high};

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    p {
      font-weight: 600;
      font-size: 1.25rem;
    }
  }

  .paymentSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 19rem;
    flex: 1;

    .infoContainer {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;

      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .paymentInfoSection {
      display: flex;
      flex-direction: column;
      width: 50%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      .paymentInfoTitle {
        p {
          font-weight: 600;
          font-size: 1.25rem;
        }
        margin-bottom: 2.5rem;
      }

      .totalsInfo {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .totalsRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .totalRegular {
            p {
              font-weight: 400;
              font-size: 1rem;
            }
            .discountValue {
              color: ${({ theme }) => theme.font_success};
            }
          }
          .cupomButton {
            button {
              p {
                font-weight: 400;
                font-size: 1rem;
                text-decoration: underline;
                color: ${({ theme }) => theme.font_primary};
              }
            }
          }
          .totalFinal {
            p {
              color: ${({ theme }) => theme.font_secondary};
              font-weight: 600;
              font-size: 1.25rem;
            }
          }
        }
      }
    }

    .divider {
      width: 1px;
      height: 22rem;
      border-right: solid ${({ theme }) => theme.font_secondary} 1px;
      margin: 0 3rem;
      border-radius: 0.125rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 0.063rem;
        margin: 0;
        border-right: none;
        border-bottom: solid ${({ theme }) => theme.font_secondary} 1px;
      }
    }
  }

  @media screen and (max-width: 1800px) {
    ul {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 1440px) {
    ul {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 768px) {
    gap: 2rem;
    padding: 1rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  align-self: stretch;

  margin: 1rem 2rem 0;

  border-top: 0.188rem solid ${({ theme }) => theme.font_secondary};
  border-radius: 0.125rem;

  padding: 1rem 0;

  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 1rem 0;
    border: none;
    border-radius: 0;
    padding: 0 0.5rem;
  }

  .action {
    width: 21rem;
    max-width: 100%;
  }

  .removeUnavailable {
    color: ${({ theme }) => theme.font_high};
  }
`;
