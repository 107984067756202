import { Form } from '@unform/web';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ShepherdTour } from 'react-shepherd';

// OnboardingButton
import { OnboardingTourButton } from '@components/OnboardingTourButton';
// Component import
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { Modal, IModalRef } from '@components/Modal';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTheme } from '@hooks/useTheme';
import { useForwardRef } from '@hooks/useForwardRef';
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { StringMask } from '@util/stringMask';
import { getFormErrors } from '@util/getFormErrors';

import { onboardingSteps } from './Onboarding/onboardingSteps';

// Style import
import { AddContainer } from '../styles';
import {
  AddByReferenceAndColorContainer,
  AddByTextSearchContainer,
  ChangeMethodModalContainer,
  Footer,
} from './styles';

// Interface import
import { IAddProductScreenRef } from '..';

// Feature identification
const featureKey = '@create_order/PRODUCT_ADD_BY_REF_AND_COLOR_OR_TEXT';

const AddByRefAndColorOrText = forwardRef<IAddProductScreenRef>((_, ref) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const product = useAddProduct();

  // Global states
  const user = useSelector(state => state.auth);

  // Local refs
  const changeMethodModalRef = useRef<IModalRef>(null);
  const referenceOnboardingRef = useRef<HTMLButtonElement>(null);

  // Reference and color refs
  const formRef = useRef<FormHandles>(null);
  const nameFormRef = useRef<FormHandles>(null);
  const referenceInputRef = useRef<HTMLInputElement>(null);
  const colorInputRef = useRef<HTMLInputElement>(null);

  // Text search refs
  const searchInputRef = useRef<HTMLInputElement>(null);

  // Local states
  const [methodDestination, setMethodDestination] = useState<
    'TEXT' | 'REFERENCE_AND_COLOR' | undefined
  >(undefined);

  // Reference and color states
  const [typedReference, setTypedReference] = useState('');
  const [typedColor, setTypedColor] = useState('');

  // Text search states
  const [typedSearch, setTypedSearch] = useState('');
  const [loading, setLoading] = useState(false);

  // Onboarding control
  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: false,
      },
    },
    useModalOverlay: true,
  };

  // Focus action
  const onFocus = useCallback(() => {
    // Reference and color
    setTypedReference('');
    setTypedColor('');

    // Text search
    setTypedSearch('');
    searchInputRef.current?.focus();
  }, []);

  // Blur action
  const onBlur = useCallback(() => {
    // Reference and color
    setTypedReference('');
    setTypedColor('');

    // Text search
    setTypedSearch('');
  }, []);

  // Assign method to ref
  useForwardRef(ref, {
    onFocus,
    onBlur,
    onSelect: () => null,
    onDeselect: () => null,
  });

  // Clear typed when brand changes
  useEffect(() => {
    // Reference and color
    setTypedReference('');
    setTypedColor('');

    // Text
    setTypedSearch('');
  }, [product.selectedBrandCode]);

  const searchProductList = useCallback(
    async (productRefOrName: string, type: string) => {
      setLoading(true);

      try {
        const params =
          type === 'ref'
            ? { brandcode: product.selectedBrandCode, ref: productRefOrName }
            : {
                brandcode: product.selectedBrandCode,
                text: productRefOrName,
                page: 1,
                pageSize: 10,
              };
        // API call
        const response = await api.get('/v1/product/list', {
          params,
        });

        product.setProductsList(response.data.data);
        product.setSearchedTerm({
          type,
          search: productRefOrName,
        });
      } catch (err: any) {
        const { message } = getResponseError(err, t);
        toast.error(message);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [t, product],
  );

  const searchSpecificProduct = useCallback(
    async (code: string) => {
      try {
        product.getProduct({
          code,
        });
        product.setSearchedTerm({
          type: 'ref',
          search: code,
        });
      } catch (err) {}
    },
    [product],
  );

  const handleReferenceSubmit: SubmitHandler<{
    reference: string;
    color: string;
  }> = useCallback(
    async data => {
      // Clear form errors
      if (formRef.current) formRef.current.setErrors({});

      // Clear product variations
      product.resetVariations();

      // Setup a schema to be validated
      const schema = Yup.object()
        .shape({
          reference: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_REFERENCE_TYPE',
                'A referência deve ser composta por caracteres alfanuméricos.',
              ),
            )
            .required(t('REQUIRED_REFERENCE', 'Informe a referência.')),
          color: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_COLOR_TYPE',
                'A cor deve ser composta por caracteres alfanuméricos.',
              ),
            ),
        })
        .noUnknown(true, t('INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        getFormErrors(err, ({ form, errors }) => {
          formRef.current?.setErrors(form);
          toast.error(errors[0]);
        });
        return;
      }

      // Get reference and color from data
      const { reference, color } = data;

      if (!color) {
        searchProductList(reference, 'ref');
        return;
      }

      // Mount reference
      const code = `${reference.trim()}_${color.trim()}`;

      // Request product information to backend
      searchSpecificProduct(code);
    },
    [t, product, searchProductList, searchSpecificProduct],
  );

  const handleNameSubmit: SubmitHandler<{
    productName: string;
  }> = useCallback(
    async data => {
      // Get name from data
      const { productName } = data;

      setLoading(true);
      const trimmedProductName = productName.trim();

      searchProductList(trimmedProductName, 'name');
    },
    [searchProductList],
  );

  // Selected brand code_pattern
  const code_pattern = product.selectedBrandCode
    ? user.store?.brands?.find(
        store_brand =>
          store_brand.brand.code === product.selectedBrandCode &&
          store_brand.enabled &&
          store_brand.brand.enabled,
      )?.brand?.payload?.product_code_pattern
    : undefined;

  // Selected brand color_pattern
  const color_pattern = product.selectedBrandCode
    ? user.store?.brands?.find(
        store_brand =>
          store_brand.brand.code === product.selectedBrandCode &&
          store_brand.enabled &&
          store_brand.brand.enabled,
      )?.brand?.payload?.product_color_pattern
    : undefined;

  // Initial focus
  useEffect(() => {
    if (typeof onFocus === 'function') onFocus();
  }, [onFocus]);

  // Change methods
  const handleMethodFocus = useCallback(
    (type: 'TEXT' | 'REFERENCE_AND_COLOR', e: FocusEvent) => {
      e.preventDefault();
      if (type === 'REFERENCE_AND_COLOR' && typedSearch) {
        changeMethodModalRef.current?.show();
        setMethodDestination(type);
      }

      if (type === 'TEXT' && (typedReference || typedColor)) {
        changeMethodModalRef.current?.show();
        setMethodDestination(type);
      }
    },
    [typedColor, typedReference, typedSearch],
  );

  const confirmMethodFocus = useCallback(() => {
    if (methodDestination === 'REFERENCE_AND_COLOR') {
      setTypedSearch('');
      referenceInputRef.current?.focus();
    }

    if (methodDestination === 'TEXT') {
      setTypedReference('');
      setTypedColor('');
      searchInputRef.current?.focus();
    }

    changeMethodModalRef.current?.hide();
    setMethodDestination(undefined);
  }, [methodDestination]);

  const cancelMethodFocus = useCallback(() => {
    if (methodDestination === 'REFERENCE_AND_COLOR')
      searchInputRef.current?.focus();

    if (methodDestination === 'TEXT') referenceInputRef.current?.focus();

    changeMethodModalRef.current?.hide();
    setMethodDestination(undefined);
  }, [methodDestination]);

  const handleRefInput = (value: any) => {
    setTypedReference(String(value).trim());

    const deleteInputCondition =
      !product.searchedTerm || product.searchedTerm.search !== value;

    if (typedColor && deleteInputCondition) {
      setTypedColor('');
    }
  };

  useEffect(() => {
    const shouldShowRefTour = !(
      localStorage.getItem('referenceOnboardingComplete') === 'seen'
    );
    if (shouldShowRefTour) {
      referenceOnboardingRef?.current?.click();
    }
  }, []);

  useEffect(() => {
    if (product?.searchedTerm) {
      if (product?.searchedTerm?.type === 'name') {
        setTypedSearch(product?.searchedTerm?.search || '');
        return;
      }

      referenceInputRef.current?.focus();

      const separateRefCode = product?.searchedTerm?.search?.split('_');
      setTypedReference(separateRefCode?.[0] || '');

      if (separateRefCode?.[1]) {
        setTypedColor(separateRefCode?.[1]);
      }
    }
  }, [product?.searchedTerm]);
  return (
    <>
      <ShepherdTour steps={onboardingSteps} tourOptions={tourOptions}>
        <OnboardingTourButton
          reference={referenceOnboardingRef}
          completeTourKey="referenceOnboardingComplete"
          cancelTourKey="referenceOnboardingComplete"
        />
      </ShepherdTour>
      <AddContainer>
        <AddByReferenceAndColorContainer className="referenceFormSection">
          <Form
            ref={formRef}
            onSubmit={handleReferenceSubmit}
            autoComplete="off"
          >
            <div className="inputWrap">
              <h3>{t('REFERENCE_HEADER', 'Referência')}</h3>
              <Input
                ref={referenceInputRef}
                name="reference"
                label={t('REFERENCE', 'Referência')}
                value={typedReference}
                onFocus={(e: FocusEvent) =>
                  handleMethodFocus('REFERENCE_AND_COLOR', e)
                }
                onChange={(value: any) => {
                  handleRefInput(value);
                }}
                disabled={product.loading}
                onEnterKey={() => colorInputRef.current?.focus()}
                onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                  // Clear whitespaces
                  e.currentTarget.value = e.currentTarget.value.trim();

                  // Non selected brand
                  if (!product.selectedBrandCode) return e;

                  // If not found
                  if (!code_pattern) return e;

                  // Get value
                  let { value } = e.currentTarget;

                  // Mask instance
                  const maskInstance = new StringMask(code_pattern, {});

                  // Process
                  value = maskInstance.proccess(String(value)).result;

                  e.currentTarget.value = `${value}`.trim();
                  return e;
                }}
                endAdornment={
                  loading &&
                  typedReference && (
                    <LoadingIndicator color={theme.font_secondary} size={1} />
                  )
                }
                {...(!!code_pattern &&
                  !['*', '+', 'A', 'S', 'U', 'L'].some(
                    item => String(code_pattern).includes(item), // Numeric keyboard ONLY on non-alphabet patterns
                  ) && {
                    type: 'tel',
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  })}
              />
            </div>
            <div className="inputWrap">
              <h3>{t('COLOR_HEADER', `Cor (opcional)`)}</h3>
              <Input
                ref={colorInputRef}
                onEnterKey={() => formRef.current?.submitForm()}
                name="color"
                label={t('REFERENCE', 'Cor')}
                value={typedColor}
                onFocus={(e: FocusEvent) =>
                  handleMethodFocus('REFERENCE_AND_COLOR', e)
                }
                onChange={(value: any) => {
                  setTypedColor(String(value).trim());
                }}
                disabled={product.loading}
                {...(!!code_pattern &&
                  !['*', '+', 'A', 'S', 'U', 'L'].some(
                    item => String(code_pattern).includes(item), // Numeric keyboard ONLY on non-alphabet patterns
                  ) && {
                    type: 'tel',
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  })}
                onKeyUp={(e: React.FormEvent<HTMLInputElement>) => {
                  // Clear whitespaces
                  e.currentTarget.value = e.currentTarget.value.trim();

                  // Non selected brand
                  if (!product.selectedBrandCode) return e;

                  // If not found
                  if (!color_pattern) return e;

                  let { value } = e.currentTarget;

                  // Mask instance
                  const maskInstance = new StringMask(color_pattern, {});

                  // Process
                  value = maskInstance.proccess(String(value)).result;

                  e.currentTarget.value = `${value}`.trim();
                  return e;
                }}
              />
            </div>
          </Form>
        </AddByReferenceAndColorContainer>
        <AddByTextSearchContainer>
          <h3>{t('TEXT_SEARCH_HEADER', 'Nome do Produto')}</h3>
          <Form
            ref={nameFormRef}
            onSubmit={handleNameSubmit}
            autoComplete="off"
          >
            <Input
              ref={searchInputRef}
              name="productName"
              label={t('TEXT_SEARCH_INPUT_LABEL', 'Nome do Produto')}
              value={typedSearch}
              onFocus={(e: FocusEvent) => handleMethodFocus('TEXT', e)}
              onChange={(value: any) => {
                setTypedSearch(String(value).trim());
              }}
              onEnterKey={() => nameFormRef.current?.submitForm()}
              disabled={product.loading}
              endAdornment={
                loading &&
                typedSearch && (
                  <LoadingIndicator color={theme.font_secondary} size={1} />
                )
              }
            />
          </Form>
          <Footer>
            <Button
              color={theme.background_success}
              className="action"
              loading={product.loading || loading}
              disabled={!typedReference && !typedSearch}
              onClick={() => {
                if (typedSearch) {
                  nameFormRef.current?.submitForm();
                } else {
                  formRef.current?.submitForm();
                }
              }}
            >
              {t('ACTION', 'CONSULTAR PRODUTO')}
            </Button>
          </Footer>
        </AddByTextSearchContainer>
      </AddContainer>
      <Modal ref={changeMethodModalRef} size="auto" autoWidth>
        <ChangeMethodModalContainer>
          <p>
            {t('CHANGING_METHODS', 'Você está trocando o modo de consulta.')}
          </p>
          <p>{t('CHANGING_METHODS_ASK', 'Deseja continuar?')}</p>
          <div className="actions">
            <Button
              color={theme.background_secondary}
              onClick={() => cancelMethodFocus()}
              variant="outlined"
            >
              {t('CHANGING_METHODS_CANCEL', 'CANCELAR')}
            </Button>
            <Button
              color={theme.background_secondary}
              onClick={() => confirmMethodFocus()}
            >
              {t('CHANGING_METHODS_CONFIRM', 'CONTINUAR')}
            </Button>
          </div>
        </ChangeMethodModalContainer>
      </Modal>
    </>
  );
});

export { AddByRefAndColorOrText };
