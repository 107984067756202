/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable class-methods-use-this */
class StringMask {
  private options: IStringMaskOptions = {};

  private pattern: string;

  private tokens: { [key: string]: IStringMaskOptions } = {
    '0': { pattern: /\d/, defaultValue: '0' },
    '9': { pattern: /\d/, optional: true },
    '*': { pattern: /[a-zA-Z0-9.]/, recursive: true },
    '#': { pattern: /\d/, optional: true, recursive: true },
    '+': { pattern: /[a-zA-Z]/, optional: true, recursive: true },
    A: { pattern: /[a-zA-Z0-9]/ },
    S: { pattern: /[a-zA-Z]/ },
    U: {
      pattern: /[a-zA-Z]/,
      transform(c) {
        return c.toLocaleUpperCase();
      },
    },
    L: {
      pattern: /[a-zA-Z]/,
      transform(c) {
        return c.toLocaleLowerCase();
      },
    },
    $: { escape: true },
  };

  constructor(pattern: string, options: IStringMaskOptions) {
    this.options = options || {};
    this.options = {
      reverse: this.options.reverse || false,
      usedefaults: this.options.usedefaults || this.options.reverse,
    };
    this.pattern = pattern;
  }

  public isEscaped(pattern: any, pos: any) {
    let count = 0;
    let i = pos - 1;
    let token: IStringMaskOptions = { escape: true };
    while (i >= 0 && token && token.escape) {
      token = this.tokens[pattern.charAt(i)];
      count += token && token.escape ? 1 : 0;
      i -= 1;
    }
    return count > 0 && count % 2 === 1;
  }

  calcOptionalNumbersToUse(pattern: string, value: string): number {
    const numbersInP = pattern.replace(/[^0]/g, '').length;
    const numbersInV = value.replace(/[^\d]/g, '').length;
    return numbersInV - numbersInP;
  }

  concatChar(
    text: string,
    character: string,
    options: IStringMaskOptions,
    token: IStringMaskOptions | null,
  ) {
    if (token && typeof token.transform === 'function') {
      character = token.transform(character);
    }
    if (options.reverse) {
      return character + text;
    }
    return text + character;
  }

  hasMoreTokens(pattern: string, pos: number, inc: number): boolean {
    const pc = pattern.charAt(pos);
    const token = this.tokens[pc];
    if (pc === '') {
      return false;
    }
    return token && !token.escape
      ? true
      : this.hasMoreTokens(pattern, pos + inc, inc);
  }

  hasMoreRecursiveTokens(pattern: string, pos: number, inc: number): boolean {
    const pc = pattern.charAt(pos);
    const token = this.tokens[pc];
    if (pc === '') {
      return false;
    }
    return token && token.recursive
      ? true
      : this.hasMoreRecursiveTokens(pattern, pos + inc, inc);
  }

  insertChar(text: string, char: string, position: number): string {
    const t = text.split('');
    t.splice(position, 0, char);
    return t.join('');
  }

  proccess(value: string): {
    result: string;
    valid: boolean;
  } {
    if (!value) {
      return { result: '', valid: false };
    }
    value += '';
    let pattern2 = this.pattern;
    let valid = true;
    let formatted = '';
    let valuePos = this.options.reverse ? value.length - 1 : 0;
    let patternPos = 0;
    let optionalNumbersToUse = this.calcOptionalNumbersToUse(pattern2, value);
    let escapeNext = false;
    const recursive: string[] = [];
    let inRecursiveMode = false;
    const instance = this;

    const steps = {
      start: this.options.reverse ? pattern2.length - 1 : 0,
      end: this.options.reverse ? -1 : pattern2.length,
      inc: this.options.reverse ? -1 : 1,
    };

    function continueCondition(options: IStringMaskOptions) {
      if (
        !inRecursiveMode &&
        !recursive.length &&
        instance.hasMoreTokens(pattern2, patternPos, steps.inc)
      ) {
        // continue in the normal iteration
        return true;
      }
      if (
        !inRecursiveMode &&
        recursive.length &&
        instance.hasMoreRecursiveTokens(pattern2, patternPos, steps.inc)
      ) {
        // continue looking for the recursive tokens
        // Note: all chars in the patterns after the recursive portion will be handled as static string
        return true;
      }
      if (!inRecursiveMode) {
        // start to handle the recursive portion of the pattern
        inRecursiveMode = recursive.length > 0;
      }

      if (inRecursiveMode) {
        const pc = recursive.shift();
        if (pc) {
          recursive.push(pc);
          if (options.reverse && valuePos >= 0) {
            patternPos += 1;
            pattern2 = instance.insertChar(pattern2, pc, patternPos);
            return true;
          }
          if (!options.reverse && valuePos < value.length) {
            pattern2 = instance.insertChar(pattern2, pc, patternPos);
            return true;
          }
        }
      }
      return patternPos < pattern2.length && patternPos >= 0;
    }

    /**
     * Iterate over the pattern's chars parsing/matching the input value chars
     * until the end of the pattern. If the pattern ends with recursive chars
     * the iteration will continue until the end of the input value.
     *
     * Note: The iteration must stop if an invalid char is found.
     */
    for (
      patternPos = steps.start;
      continueCondition(this.options);
      patternPos += steps.inc
    ) {
      // Value char
      const vc = value.charAt(valuePos);
      // Pattern char to match with the value char
      const pc = pattern2.charAt(patternPos);

      let token: IStringMaskOptions | null = this.tokens[pc];
      if (recursive.length && token && !token.recursive) {
        // In the recursive portion of the pattern: tokens not recursive must be seen as static chars
        token = null;
      }

      // 1. Handle escape tokens in pattern
      // go to next iteration: if the pattern char is a escape char or was escaped
      if (!inRecursiveMode || vc) {
        if (this.options.reverse && this.isEscaped(pattern2, patternPos)) {
          // pattern char is escaped, just add it and move on
          formatted = this.concatChar(formatted, pc, this.options, token);
          // skip escape token
          patternPos += steps.inc;
        } else if (!this.options.reverse && escapeNext) {
          // pattern char is escaped, just add it and move on
          formatted = this.concatChar(formatted, pc, this.options, token);
          escapeNext = false;
        } else if (!this.options.reverse && token && token.escape) {
          // mark to escape the next pattern char
          escapeNext = true;
        }
      }

      // 2. Handle recursive tokens in pattern
      // go to next iteration: if the value str is finished or
      //                       if there is a normal token in the recursive portion of the pattern
      if (!inRecursiveMode && token && token.recursive) {
        // save it to repeat in the end of the pattern and handle the value char now
        recursive.push(pc);
      } else if (inRecursiveMode && !vc) {
        // in recursive mode but value is finished. Add the pattern char if it is not a recursive token
        formatted = this.concatChar(formatted, pc, this.options, token);
      } else if (!inRecursiveMode && recursive.length > 0 && !vc) {
        // recursiveMode not started but already in the recursive portion of the pattern
      }

      // 3. Handle the value
      // break iterations: if value is invalid for the given pattern
      if (!token) {
        // add char of the pattern
        formatted = this.concatChar(formatted, pc, this.options, token);

        // static value
        if (vc === pc) valuePos += steps.inc;

        if (!inRecursiveMode && recursive.length) {
          // save it to repeat in the end of the pattern
          recursive.push(pc);
        }
      } else if (token.optional) {
        // if token is optional, only add the value char if it matchs the token pattern
        //                       if not, move on to the next pattern char
        if (token.pattern?.test(vc) && optionalNumbersToUse) {
          formatted = this.concatChar(formatted, vc, this.options, token);
          valuePos += steps.inc;
          optionalNumbersToUse -= 1;
        } else if (recursive.length > 0 && vc) {
          valid = false;
          break;
        }
      } else if (token.pattern?.test(vc)) {
        // if token isn't optional the value char must match the token pattern
        formatted = this.concatChar(formatted, vc, this.options, token);
        valuePos += steps.inc;
      } else if (!vc && token.defaultValue && this.options.usedefaults) {
        // if the token isn't optional and has a default value, use it if the value is finished
        formatted = this.concatChar(
          formatted,
          token.defaultValue,
          this.options,
          token,
        );
      } else {
        // the string value don't match the given pattern
        valid = false;
        break;
      }
    }

    return { result: formatted, valid };
  }
}

export interface IStringMaskOptions {
  pattern?: RegExp;
  defaultValue?: string;
  optional?: boolean;
  reverse?: boolean;
  recursive?: boolean;
  escape?: boolean;
  usedefaults?: boolean;
  transform?: (c: string) => string;
}

export { StringMask };
