// Type import
import { IAuthState } from '../../store/modules/auth/types';

const hasMultipleOwnStockOptions = (user: IAuthState) => {
  const userHasDeliveryOption = user?.store?.package_types.find(
    item => item.package_type.code === 'own_stock',
  )?.modality?.delivery;
  const userHasInHandsOption = user?.store?.package_types.find(
    item => item.package_type.code === 'own_stock',
  )?.modality?.in_hands;

  if (userHasDeliveryOption && userHasInHandsOption) {
    return true;
  }
  return false;
};

const onlyDeliveryOptionEnabled = (user: IAuthState) => {
  const userHasDeliveryEnabled =
    user.store?.package_types.find(
      package_type => package_type.package_type.code === 'own_stock',
    )?.modality?.delivery === true;
  const userHasInHandsDisabled =
    user.store?.package_types.find(
      package_type => package_type.package_type.code === 'own_stock',
    )?.modality?.in_hands === false;

  if (userHasDeliveryEnabled && userHasInHandsDisabled) {
    return true;
  }
  return false;
};

const onlyInHandsOptionEnabled = (user: IAuthState) => {
  const userHasDeliveryDisabled =
    user.store?.package_types.find(
      package_type => package_type.package_type.code === 'own_stock',
    )?.modality?.delivery === false;
  const userHasInHandsEnabled =
    user.store?.package_types.find(
      package_type => package_type.package_type.code === 'own_stock',
    )?.modality?.in_hands === true;

  if (userHasDeliveryDisabled && userHasInHandsEnabled) {
    return true;
  }
  return false;
};

const inHandsOptionIsEnabled = (user: IAuthState) => {
  const userHasInHandsEnabled =
    user.store?.package_types.find(
      package_type => package_type.package_type.code === 'own_stock',
    )?.modality?.in_hands === true;

  if (userHasInHandsEnabled) {
    return true;
  }
  return false;
};

const deliveryOptionIsEnabled = (user: IAuthState) => {
  const userHasDeliveryEnabled =
    user.store?.package_types.find(
      package_type => package_type.package_type.code === 'own_stock',
    )?.modality?.delivery === true;

  if (userHasDeliveryEnabled) {
    return true;
  }
  return false;
};

export {
  hasMultipleOwnStockOptions,
  onlyDeliveryOptionEnabled,
  onlyInHandsOptionEnabled,
  inHandsOptionIsEnabled,
  deliveryOptionIsEnabled,
};
