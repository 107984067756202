import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem;
  gap: 3rem;

  .modal_news {
    border-radius: 0.5rem;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
  }

  .orders {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: 1rem;
    flex: 1;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    align-self: stretch;
    border-top: 0.125rem solid ${({ theme }) => theme.background_secondary};
    background: ${({ theme }) => theme.background_low};
    padding-top: 0.3125rem;
    button {
      width: 21rem;
    }
  }

  @media screen and (max-width: 425px) {
    .orders {
      padding-bottom: 3rem;
    }

    .actions {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;

      box-shadow: 0rem 0rem 1.5rem 0.05rem
        ${({ theme }) => rgba(theme.background_low, 0.95)};
    }
  }
`;
