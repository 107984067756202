import { lighten, readableColor } from 'polished';
import styled, { css } from 'styled-components';

// Util import
import { getColor } from '@util/getColor';

// Interfaces
interface IContainerProps {
  color?: string;
  rippleColor?: string;
  hide: boolean;
  fullWidth: boolean;
  noMargin: boolean;
  children: React.ReactNode;
  disabled: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')} !important;
  user-select: none;

  button,
  a {
    font-weight: 700;
    font-size: 0.875rem;
    padding: 1rem;
    transition: 0.25s;

    ${props =>
      props.noMargin &&
      css`
        margin: 0 !important;
        padding: 0 !important;
      `}

    &.MuiButton-contained {
      background-color: ${props =>
        getColor({
          theme: props.theme,
          color: props.color,
          disabled: props.disabled,
        })};

      color: ${props =>
        readableColor(
          getColor({
            theme: props.theme,
            color: props.color,
            disabled: props.disabled,
          }),
          props.theme.font_low,
          props.theme.font_high,
          false,
        )};

      &:hover {
        background-color: ${props =>
          lighten(
            0.05,
            getColor({
              theme: props.theme,
              color: props.color,
              disabled: props.disabled,
            }),
          )};
      }
    }

    &.MuiButton-text {
      color: ${props =>
        getColor({
          theme: props.theme,
          color: props.color,
          disabled: props.disabled,
        })};
    }

    &.MuiButton-outlined {
      color: ${props =>
        getColor({
          theme: props.theme,
          color: props.color,
          disabled: props.disabled,
        })};

      border-color: ${props =>
        getColor({
          theme: props.theme,
          color: props.color,
          disabled: props.disabled,
        })};
    }

    ${props =>
      props.rippleColor &&
      css`
        .MuiTouchRipple-root {
          .MuiTouchRipple-child {
            background-color: ${getColor({
              theme: props.theme,
              color: props.rippleColor,
              disabled: props.disabled,
            })};
          }
        }
      `}
  }
`;
