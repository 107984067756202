import styled from 'styled-components';
import { motion } from 'framer-motion';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
