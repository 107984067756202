import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    max-height: 1rem;
    width: 100%;

    button {
      .closeIcon {
        color: ${({ theme }) => theme.button_high};
        font-size: 1.5rem;
      }
    }
  }

  .iconRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    @media screen and (max-width: 768px) {
    }
    svg {
      height: 10.25rem;
      width: 8.75rem;
    }

    p {
      font-weight: 600;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.font_secondary};
      text-align: center;
    }
  }

  .textRow {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.font_primary};
    text-align: center;
    @media screen and (max-width: 768px) {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }

  .actionArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 18rem;
    gap: 1.75rem;
    margin-bottom: 1rem;

    div {
      button {
        border-radius: 0.5rem;
      }
    }
  }
`;
