import React from 'react';
import { useSelector } from 'react-redux';
import {
  MdOutlineShoppingCart,
  MdOutlineShoppingBag,
  MdOutlineWarningAmber,
} from 'react-icons/md';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';
import { useOrderCreation } from '@hooks/useOrderCreation';

// Style import
import { Container } from './styles';

interface IProps {
  handleSelectPackageType(type: string): void;
  selectedPackageType: string;
  page: string;
}

// Feature identification
const featureKey = '@create_order/PACKAGE_OPTIONS_DESKTOP';

const DesktopLayout: React.FC<IProps> = ({
  handleSelectPackageType,
  selectedPackageType,
  page,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const product = useAddProduct();
  const order = useOrderCreation();

  // Global states
  const user = useSelector(state => state.auth);

  // Which page is it
  const getListToRender =
    page === 'variation' ? product.variations : product.variationsToEdit;

  const availablePackageTypes = user.store?.package_types.filter(
    storePackageType =>
      storePackageType.enabled &&
      storePackageType.package_type.enabled &&
      !(storePackageType.package_type.code === 'return'),
  );

  const isOwnStockEnabled = !!availablePackageTypes?.filter(
    package_type => package_type.package_type.code === 'own_stock',
  ).length;

  const setDisabledOptionsSection =
    product.loading ||
    order.loading ||
    (!isOwnStockEnabled &&
      (!product.selectedVariationId ||
        !getListToRender.find(
          variation =>
            String(variation.id) === String(product.selectedVariationId) &&
            !!variation.shipping?.available &&
            variation.shipping?.options?.length,
        )));

  const setPackageTypeSelectButtonClass = (type: string) => {
    if (type === 'own_stock' && selectedPackageType === 'OWN_STOCK')
      return 'selectedOption';
    if (type === 'infinite_aisle' && selectedPackageType === 'DELIVERY')
      return 'selectedOption';
    return 'option';
  };

  const getPackageTypeButtonText = (packageTypeCode: string) => {
    switch (packageTypeCode) {
      case 'own_stock':
        return (
          <>
            <MdOutlineShoppingBag className="optionIcon" />

            <div className="optionText">
              <p> {t('OWN_STOCK_ACTION', 'Levar Agora')}</p>
            </div>
          </>
        );
      case 'infinite_aisle':
        return (
          <>
            <MdOutlineShoppingCart className="optionIcon" />

            <div className="optionText">
              <p>{t('INFINITE_AISLE_ACTION', 'Receber em Casa')}</p>
            </div>
          </>
        );
      default:
        return (
          <>
            <MdOutlineWarningAmber className="optionIcon" />

            <div className="optionText">
              <p>{t('UNKNOWN_PACKAGE_TYPE', 'Opção de Entrega Não Mapeada')}</p>
            </div>
          </>
        );
    }
  };

  return (
    <Container>
      <legend>
        {t('SELECT_PACKAGE_OPTIONS', 'Selecione a opção de Pacote')}
      </legend>
      <div className="optionsSection">
        {availablePackageTypes?.map(availablePackageType => (
          <button
            type="button"
            disabled={setDisabledOptionsSection}
            onClick={() =>
              handleSelectPackageType(availablePackageType?.package_type?.code)
            }
          >
            <div
              className={`${setPackageTypeSelectButtonClass(
                availablePackageType?.package_type?.code,
              )}`}
            >
              {getPackageTypeButtonText(
                `${availablePackageType?.package_type?.code}`,
              )}
            </div>
          </button>
        ))}
      </div>
    </Container>
  );
};

export { DesktopLayout };
