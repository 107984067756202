import styled from 'styled-components';

export const GiftCard = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  background: ${({ theme }) => theme.background_primary};
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  min-height: 11.688rem;
  margin-right: 0.5rem;

  @media screen and (max-width: 768px) {
    margin-right: 0rem;
    margin-top: 0rem;
  }

  .mobileQuantityRow {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    p {
      font-size: 1rem;
      color: ${({ theme }) => theme.font_secondary};
      margin-right: 1rem;
    }

    .quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.background_high};
      border-radius: 0.25rem;

      button {
        min-width: auto;
        width: 1.875rem;
        height: 1.875rem;
      }

      .number {
        color: ${({ theme }) => theme.font_low};
        padding: 0 0.5rem;
      }
    }
  }

  .giftContent {
    display: flex;
    flex-direction: row;
    width: 100%;

    .image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      align-self: stretch;
      max-width: 8.625rem;

      img {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        object-fit: cover;
        flex: 1;
        align-self: stretch;
        max-width: 8.625rem;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      height: 100%;
      width: 100%;
      padding: 1rem;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        padding-bottom: 0rem;
        align-items: flex-start;
        height: auto;
      }

      .name {
        font-size: 1rem;
        font-weight: 400;
        color: ${({ theme }) => theme.font_primary};
        width: -webkit-fill-available;
        margin-bottom: 0.25rem;
        P {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        @media screen and (max-width: 768px) {
          font-weight: 600;
        }
      }

      .infoRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin-bottom: 0.25rem;

        color: ${({ theme }) => theme.font_secondary};
        font-size: 0.75rem;
        font-weight: 400;

        p:first-child {
          margin-right: 1rem;
        }
      }

      .giftInfoRow {
        display: flex;
        flex-direction: column;

        .giftInfoTitle {
          font-weight: 300;
          font-size: 1rem;
          line-height: 1.5rem;

          @media screen and (max-width: 768px) {
            font-size: 0.875rem;
          }
        }
        .giftInfoContent {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;

          @media screen and (max-width: 768px) {
            font-size: 0.875rem;
          }
        }
      }

      .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        font-size: 1rem;
        color: ${({ theme }) => theme.font_primary};

        @media screen and (max-width: 768px) {
          font-weight: 600;
        }

        .quantityContent {
          display: flex;
          p {
            font-size: 1rem;
            /* color: ${({ theme }) => theme.font_secondary}; */
            margin-right: 1rem;
          }

          .quantity {
            display: flex;
            align-items: center;
            justify-content: center;
            background: ${({ theme }) => theme.background_high};
            border-radius: 0.25rem;

            button {
              min-width: auto;
              width: 1.2rem;
              height: 1.2rem;
            }

            .number {
              color: ${({ theme }) => theme.font_low};
              padding: 0 0.5rem;
            }
          }
        }
      }
      .unavailablePill {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        p {
          background-color: ${({ theme }) => theme.background_danger};
          padding: 0.5rem 1.5rem;
          border-radius: 1.25rem;
          font-weight: 500;
        }
      }
    }
  }
`;
