import styled from 'styled-components';

export const NoOrders = styled.div`
  display: flex;

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.font_primary};
  }
`;

export const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: 1rem 2rem;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    .main {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      position: relative;

      border-radius: 0.5rem;
      background: ${({ theme }) => theme.background_primary};

      .errorSection {
        position: absolute;
        z-index: 2;
        top: 1rem;
        left: 18.25rem;

        button {
          padding: 0;
          color: ${({ theme }) => theme.button_error};
          font-size: 1.25rem;
        }

        .tooltip {
          position: absolute;
          left: -4.35rem;
        }
      }

      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        gap: 1rem;
        width: 100%;

        border-radius: 0.5rem;
        background: ${({ theme }) => theme.background_primary};
        padding: 1rem;

        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 1.25rem;
          font-weight: 500;
          color: ${({ theme }) => theme.background_primary};
          background: ${({ theme }) => theme.background_secondary};
          border-radius: 0.125rem;
          width: 3rem;
          height: 3rem;
          min-width: 3rem;
          min-height: 3rem;
        }

        .center {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 0.25rem;
          flex: 1;
          color: ${({ theme }) => theme.font_primary};

          .name {
            color: ${({ theme }) => theme.font_primary};
            font-weight: bold;
            font-size: 1rem;
            max-width: 13.875rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .statusRow {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            .status {
              font-size: 1rem;
              max-width: 13.875rem;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              &.error {
                color: ${({ theme }) => theme.font_error};
              }

              &.canceled {
                color: ${({ theme }) => theme.font_danger};
              }

              &.finished {
                color: ${({ theme }) => theme.font_success};
              }

              &.pending {
                color: #9e9e9e;
              }
            }
          }

          .salecode {
            color: ${({ theme }) => theme.font_primary};
            font-size: 1rem;
            max-width: 13.875rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .products {
            color: #e0e0e0;
            font-weight: 700;
            font-size: 0.875rem;
            max-width: 13.875rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
