import React from 'react';
import Radio from '@material-ui/core/Radio';

import { ListItemContainer } from './style';

interface IMenuItems {
  title: string;
  enabled: boolean;
  selected: boolean;
  subTitle?: string;
}

interface IProps {
  item: IMenuItems;
  clickFunction(title: string): void;
}

const MenuItem: React.FC<IProps> = ({ item, clickFunction }) => {
  const isItemDisabled = !item.enabled;
  const setDisabledClass = isItemDisabled ? 'disabled' : '';

  return (
    <ListItemContainer
      onClick={() => clickFunction(item.title)}
      className={setDisabledClass}
    >
      <div className="itemButton">
        <Radio
          checked={item.selected}
          color="primary"
          disabled={isItemDisabled}
        />
      </div>
      <div className="itemText">
        <p>{item?.title}</p>
        <p>{item?.subTitle}</p>
      </div>
    </ListItemContainer>
  );
};

export { MenuItem };
