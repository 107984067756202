import GoogleAnalytics from 'react-ga4';

interface ITrackEventProps {
  // Example: @session/LOGIN
  featureKey: string;

  // Main group of this event
  category:
    | 'GENERAL'
    | 'NAVIGATE'
    /**
     * SESSION
     */
    | 'LOGIN' // has time value
    | 'LOGOUT'
    | 'REFRESH_TOKEN' // has time value
    | 'RESUME_SESSION' // has time value
    /**
     * DASHBOARD
     */
    | 'GET_DASHBOARD_ORDERS'
    /**
     * CREATE ORDER
     */
    // Main
    | 'START_ORDER'
    | 'CREATE_ORDER' // has time value
    | 'UPDATE_ORDER' // has time value
    | 'FINISH_ORDER' // has time value
    // Customer
    | 'CUSTOMER_SELECTOR_MODAL_OPEN'
    | 'CUSTOMER_SELECTOR_MODAL_CLOSE'
    | 'IDENTIFY_CUSTOMER'
    | 'SET_CUSTOMER'
    | 'CHANGE_CUSTOMER'
    // Coupon
    | 'COUPON_SELECTOR_MODAL_OPEN'
    | 'COUPON_SELECTOR_MODAL_CLOSE'
    | 'SET_COUPON'
    | 'CHANGE_COUPON'
    // Payment
    | 'PAYMENT_SELECTOR_MODAL_OPEN'
    | 'PAYMENT_SELECTOR_MODAL_CLOSE'
    // Address
    | 'ADDRESS_SELECTOR_MODAL_OPEN'
    | 'ADDRESS_SELECTOR_MODAL_CLOSE'
    | 'GET_ZIPCODE'
    | 'GET_CLIENT_ADDRESS'
    | 'SET_ADDRESS'
    | 'CHANGE_ADDRESS'
    // Sale code
    | 'CHANGE_SALE_CODE'
    | 'SEARCH_SALE_CODE'
    // Brand
    | 'CHANGE_ADD_PRODUCT_BRAND' // has brand code value
    // Product
    | 'GET_PRODUCT' // has time value
    | 'ADD_PRODUCT_TO_CART'
    // Add product methods
    | 'CHANGE_ADD_PRODUCT_METHOD'
    | 'INPUT_ANALOG_READER'
    | 'INPUT_REF_AND_COLOR'
    | 'SEARCH_PRODUCT'
    | 'TOGGLE_BETWEEN_REF_COLOR_AND_TEXT_MODAL_OPEN'
    | 'TOGGLE_BETWEEN_REF_COLOR_AND_TEXT_MODAL_CLOSE'
    | 'CAMERA_JSON_FOUND'
    | 'CAMERA_URL_FOUND'
    | 'CAMERA_CODE_FOUND'
    // Shipping
    | 'CHANGE_SHIPPING_METHOD'
    | 'ADD_PRODUCT_QUANTITY'
    | 'REMOVE_PRODUCT_QUANTITY'
    | 'REMOVE_PRODUCT'
    // Navigation only
    | 'CART_EMPTY'
    | 'CART_LIST'
    | 'CART_SALE_CODE'
    | 'ORDER_LOADING'
    | 'SELECT_BRAND'
    | 'SHOW_PRODUCT_VARIATIONS'
    | 'CLOSED'
    /**
     * LIST ORDERS
     */
    | 'GET_ORDER_LIST'
    /**
     * SHOW ORDER */
    | 'GET_ORDER_DATA' // has time value
    | 'CANCEL_ORDER'
    // Manual physical card
    | 'MANUAL_PHYSICAL_CARD_ADD_PAYMENT'
    | 'MANUAL_PHYSICAL_CARD_REMOVE_PAYMENT'
    // Link
    | 'COPY_PAYMENT_LINK'
    | 'SHARE_PAYMENT_LINK'
    // Pix
    | 'COPY_PIX_CODE'
    | 'SHARE_PIX_CODE';

  // Event itself
  action: string;

  // Status
  label: 'SUCCESS' | 'ERROR' | 'FATAL';

  [key: string]: any;
}

const init = () => {
  GoogleAnalytics.set({ send_page_view: false });
};

const trackEvent = ({
  featureKey,
  category,
  action,
  label,
  ...rest
}: ITrackEventProps) => {
  const parsedCategory = String(category).toUpperCase().replace(' ', '_');
  const parsedKey = String(featureKey).toLowerCase().replace(' ', '_');
  const key = parsedKey.includes('/') ? `${parsedKey}_` : `${parsedKey}/`;

  GoogleAnalytics.event({
    category: `${key}${parsedCategory}`.replace('@', ''), // ga does not allow '@' on events
    action: action.replace('@', ''),
    label,
    ...(typeof rest.value === 'number' && {
      value: rest.value,
    }),
  });
};

const sendInfo = (data: { [key: string]: any }) => {
  GoogleAnalytics.set(data);
};

init();
const Analytics = { trackEvent, sendInfo };

export { Analytics };
