import React from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Asset import
import { InvoiceIcon, SwapHorizontalCircle } from '@assets/index';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  type?: string;
}

// Feature identification
const featureKey = '@order/AWAIT_INVOICE_MODAL';

const AwaitInvoiceModal: React.FC<IProps> = ({ hide, type }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const modalTextStart =
    type === 'exchangeCoupon' ? 'O Cupom de Troca' : 'A Nota Fiscal';
  const modalTitle =
    type === 'exchangeCoupon' ? 'Cupom de Troca' : 'Nota Fiscal';

  const renderIcon = () => {
    if (type === 'exchangeCoupon') return <SwapHorizontalCircle />;
    return <InvoiceIcon />;
  };

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="iconRow">
        <div className="iconImage">
          <p>{renderIcon()}</p>
        </div>
        <div className="iconText">{t('MODAL_TYPE', `${modalTitle}`)}</div>
      </div>
      <div className="textRow">
        <p>
          {t(
            'AWAIT_TEXT',
            `${modalTextStart} deste atendimento estará disponível em alguns minutos. Tente novamente mais tarde. `,
          )}
        </p>
      </div>
      <div className="actionArea">
        <Button
          onClick={() => {
            if (typeof hide === 'function') hide();
          }}
        >
          <p className="modalButton">{t('CONFIRM', 'ENTENDI')}</p>
        </Button>
      </div>
    </Container>
  );
};

export { AwaitInvoiceModal };
