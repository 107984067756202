/* eslint-disable import/no-duplicates */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Locale, getDate, parseJSON, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  MdAdd,
  MdKeyboardArrowRight,
  MdOutlineShoppingBag,
  MdOutlineClose,
  MdOutlineShoppingCart,
} from 'react-icons/md';
import Carousel from 'react-elastic-carousel';
import { useHistory } from 'react-router-dom';

// Asset impoort
import { ReactComponent as DeleteIcon } from '@assets/delete.svg';
import DefaultProductImage from '@assets/defaultProductImage.png';

// Component import
import { Button } from '@components/Button';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { Modal, IModalRef } from '@components/Modal';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';
import { useTheme } from '@hooks/useTheme';
import { useWindowSize } from '@hooks/useWindowSize';
import { useOrderCreation } from '@hooks/useOrderCreation';

// Service import
import { api, getResponseError } from '@services/api';

// Action import
import {
  setBagSummary,
  setBagId,
  setSavedBagAddingProduct,
} from '@store/modules/ui/actions';

// Util import
import { IBag, ICartData, IBagItems } from '@util/SavedBagModels';

// Page import
import { DeleteUniqueBagModal } from './DeleteUniqueBagModal';
import { RemoveProductModal } from './RemoveProductModal';
import { DeleteAllBagsModal } from './DeleteAllBagsModal';

// Style import
import {
  Container,
  BagContainer,
  Footer,
  CarouselContent,
  ImagesContent,
  EmptySavedBagsContainer,
  MobileBagSummary,
  MobileBagSummaryFooter,
} from './styles';

// Feature identification
const featureKey = '@saved_bags/INDEX';

const SavedBags: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [width] = useWindowSize();
  const history = useHistory();
  const order = useOrderCreation();

  // Global states
  const user = useSelector(state => state.auth);
  const language = useSelector(state => state.ui.language);
  const bag_id = useSelector(state => state.ui.bag_id);

  // Local states
  const [bags, setBags] = useState<[string, ICartData[]][]>();
  const [selectedBag, setSelectedBag] = useState<IBag>();
  const [loading, setLoading] = useState(false);
  const [emptySavedBags, setEmptySavedBags] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ready, setReady] = useState(false);
  const [selectedBagId, setSelectedBagId] = useState<string>('');
  const [selectedCartId, setSelectedCartId] = useState<string>('');
  const [mobileBagSummary, setMobileBagSummary] = useState<boolean>(false);
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);
  const [selectedItemCode, setSelectedItemCode] = useState<string>('');
  const [loadingUptaded, setLoadingUptaded] = useState<boolean>(false);

  // Local refs
  const deleteUniqueBagModalRef = useRef<IModalRef>(null);
  const removeProductModalRef = useRef<IModalRef>(null);
  const deleteAllBagsModalRef = useRef<IModalRef>(null);

  const userIsManager = user.profile_type === 'store';
  const userName = useMemo(() => {
    if (!user.name) return '';
    const name = user.name?.split(' ')[0]?.toLowerCase();
    return `${name[0]?.toUpperCase()}${name.slice(1)}`;
  }, [user.name]);

  const breakPoints = [{ width: 4000, itemsToShow: 4 }];
  // Get language module
  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        setCurrentPage(currentPageInsideState => currentPageInsideState + 1);
      }
    });
    const sentinelDiv = document.querySelector('#sentinel');

    if (sentinelDiv) {
      intersectionObserver.observe(sentinelDiv);
    }
    return () => intersectionObserver.disconnect();
  }, []);

  // Get bags
  const getOrders = useCallback(async () => {
    setLoading(true);

    if (currentPage === 0) return;

    try {
      // API call
      const response = await api.get('/v1/cart', {
        params: {
          page: currentPage,
          pageSize: 10,
        },
      });

      // Validation
      if (Array.isArray(response.data)) throw new Error();

      // Result
      const new_orders = response.data;

      if (currentPage === 1 && !new_orders.data.length) {
        setEmptySavedBags(true);
      }

      const formatted_cart: { [key: string]: ICartData[] } = {};

      new_orders?.data.forEach((new_order: ICartData) => {
        const date = new_order?.created_at?.split('T')[0];
        if (formatted_cart[date]) {
          formatted_cart[date].push(new_order);
        } else {
          formatted_cart[date] = [new_order];
        }
      });

      setBags(prevState => {
        if (prevState && prevState?.length && currentPage > 1) {
          return prevState.concat(Object.entries(formatted_cart));
        }
        return Object.entries(formatted_cart);
      });

      setLoading(false);
      if (currentPage < 3) {
        setCurrentPage(currentPage + 1);
      }
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);

      setLoading(false);
      throw err;
    }
  }, [currentPage, t]);

  useEffect(() => {
    setReady(true);
  }, []);

  // Initial load
  useEffect(() => {
    if (!ready) return;

    getOrders();
  }, [getOrders, ready]);

  useEffect(() => {
    if (bag_id === 'update') {
      setCurrentPage(1);
    }
  }, [bag_id]);

  const handleHideModal = useCallback(() => {
    if (currentPage === 1) {
      getOrders();
    } else {
      setCurrentPage(1);
    }
  }, [currentPage, getOrders, setCurrentPage]);

  const getCartDetails = useCallback(async () => {
    setLoading(true);

    try {
      // API call
      const response = await api.get(`/v1/cart/${selectedCartId}`);

      const new_response = response.data;

      const formatted_items = new_response.items?.map((item: IBagItems) => {
        return {
          ...item,
          brand_code: item.brand.code,
        };
      });

      if (order.orderCode !== selectedCartId) {
        order.setCoupon({ name: new_response.coupon });
        order.setAddress(new_response.address);
        order.setCustomer(new_response.customer);
      }

      setSelectedBag({ ...new_response, items: formatted_items });

      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      throw err;
    }
  }, [order, selectedCartId]);

  const getCartDetailsUpdated = useCallback(async () => {
    setLoadingUptaded(true);

    try {
      // API call
      const response = await api.get(`/v1/cart/${selectedCartId}/updated`);

      const new_response = response.data;

      const formatted_items = new_response.items?.map((item: IBagItems) => {
        if (item.payload.attachments && item.payload.attachments.length) {
          return {
            ...item,
            brand_code: item.brand.code,
            attachments: {
              name: item.payload.attachments[0].content.GiftCardName,
              email: item.payload.attachments[0].content.GiftCardEmail,
              date: item.payload.attachments[0].content.GiftCardSchedule,
            },
          };
        }
        return {
          ...item,
          brand_code: item.brand.code,
        };
      });

      if (order.orderCode !== selectedCartId) {
        order.setCoupon({ name: new_response.coupon });
        order.setAddress(new_response.address);
        order.setCustomer(new_response.customer);
      }

      setSelectedBag(prevState => {
        if (prevState) {
          return {
            ...new_response,
            items: formatted_items,
            created_at: prevState.created_at,
            can_user_delete: prevState.can_user_delete,
          };
        }
        return {
          ...new_response,
          items: formatted_items,
        };
      });

      setLoadingUptaded(false);
    } catch (err: any) {
      setLoadingUptaded(false);
      throw err;
    }
  }, [order, selectedCartId]);

  useEffect(() => {
    if (selectedCartId) {
      getCartDetails();
      getCartDetailsUpdated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCartId]);

  const deliveryItems = selectedBag?.items?.filter(
    item => item.shipping.selected_type === 'DELIVERY',
  );

  const ownStockItems = selectedBag?.items?.filter(
    item => item.shipping.selected_type === 'OWN_STOCK',
  );

  const getSummaryPackages = () => {
    if (deliveryItems?.length && ownStockItems?.length) {
      return (
        <div className="packages">
          <div className="package">
            <div className="packageTitle">
              <MdOutlineShoppingBag />
              <p>{t('TAKE_NOW', 'Levar Agora')}</p>
            </div>

            {ownStockItems?.map(item => (
              <div className="productCard" key={item.code}>
                <div className="image">
                  {item.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <img src={DefaultProductImage} alt={item.name} />
                  )}
                </div>

                <div className="info">
                  {!item.shipping.available && (
                    <div className="outOfStock">
                      <p>{t('OUT_OF_STOCK', 'Sem estoque')}</p>
                    </div>
                  )}
                  <div className="name">
                    <p>{item.name}</p>
                  </div>
                  <div className="sizeAndQuantityRow">
                    <div className="quantity">
                      <p>
                        {item.quantity} unidade(s) - Tam. {item.size}
                      </p>
                    </div>
                  </div>

                  <div className="priceRow">
                    <div className="price">
                      {item.total_original_price !==
                        item.total_current_price && (
                        <span className="original_price">
                          R$ {(item.total_original_price / 100).toFixed(2)}
                        </span>
                      )}
                      <span className="current_price">
                        R$ {(item.total_current_price / 100).toFixed(2)}
                      </span>
                    </div>
                    <div className="action">
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedItemCode(item.code);
                          removeProductModalRef.current?.show();
                        }}
                      >
                        <DeleteIcon className="icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="package">
            <div className="packageTitle">
              <MdOutlineShoppingCart />
              <p>{t('RECEIVE_AT_HOME', 'Receber em casa')}</p>
            </div>

            {deliveryItems?.map(item => (
              <div className="productCard" key={item.code}>
                <div className="image">
                  {item.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <img src={DefaultProductImage} alt={item.name} />
                  )}
                </div>
                <div className="info">
                  {!item.shipping.available && (
                    <div className="outOfStock">
                      <p>{t('OUT_OF_STOCK', 'Sem estoque')}</p>
                    </div>
                  )}
                  <div className="name">
                    <p>{item.name}</p>
                  </div>

                  <div className="sizeAndQuantityRow">
                    <div className="quantity">
                      <p>
                        {item.quantity} unidade(s) - Tam. {item.size}
                      </p>
                    </div>
                  </div>

                  <div className="priceRow">
                    <div className="price">
                      {item.total_original_price !==
                        item.total_current_price && (
                        <span className="original_price">
                          R$ {(item.total_original_price / 100).toFixed(2)}
                        </span>
                      )}
                      <span className="current_price">
                        R$ {(item.total_current_price / 100).toFixed(2)}
                      </span>
                    </div>
                    <div className="action">
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedItemCode(item.code);
                          removeProductModalRef.current?.show();
                        }}
                      >
                        <DeleteIcon className="icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    if (deliveryItems?.length) {
      return (
        <div className="packages">
          <div className="package">
            <div className="packageTitle">
              <MdOutlineShoppingCart />
              <p>{t('RECEIVE_AT_HOME', 'Receber em casa')}</p>
            </div>
            {deliveryItems?.map(item => (
              <div className="productCard" key={item.code}>
                <div className="image">
                  {item.image_url ? (
                    <img src={item.image_url} alt={item.name} />
                  ) : (
                    <img src={DefaultProductImage} alt={item.name} />
                  )}
                </div>
                <div className="info">
                  {!item.shipping.available && (
                    <div className="outOfStock">
                      <p>{t('OUT_OF_STOCK', 'Sem estoque')}</p>
                    </div>
                  )}
                  <div className="name">
                    <p>{item.name}</p>
                  </div>

                  <div className="sizeAndQuantityRow">
                    <div className="quantity">
                      <p>
                        {item.quantity} unidade(s) - Tam. {item.size}
                      </p>
                    </div>
                  </div>
                  <div className="priceRow">
                    <div className="price">
                      {item.total_original_price !==
                        item.total_current_price && (
                        <span className="original_price">
                          R$ {(item.total_original_price / 100).toFixed(2)}
                        </span>
                      )}
                      <span className="current_price">
                        R$ {(item.total_current_price / 100).toFixed(2)}
                      </span>
                    </div>
                    <div className="action">
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedItemCode(item.code);
                          removeProductModalRef.current?.show();
                        }}
                      >
                        <DeleteIcon className="icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
    return (
      <>
        {ownStockItems && ownStockItems?.length > 0 && (
          <div className="packages">
            <div className="package">
              <div className="packageTitle">
                <MdOutlineShoppingBag />
                <p>{t('TAKE_NOW', 'Levar Agora')}</p>
              </div>
              {ownStockItems?.map(item => (
                <div className="productCard" key={item.code}>
                  <div className="image">
                    {item.image_url ? (
                      <img src={item.image_url} alt={item.name} />
                    ) : (
                      <img src={DefaultProductImage} alt={item.name} />
                    )}
                  </div>
                  <div className="info">
                    {!item.shipping.available && (
                      <div className="outOfStock">
                        <p>{t('OUT_OF_STOCK', 'Sem estoque')}</p>
                      </div>
                    )}
                    <div className="name">
                      <p>{item.name}</p>
                    </div>

                    <div className="sizeAndQuantityRow">
                      <div className="quantity">
                        <p>
                          {item.quantity} unidade(s) - Tam. {item.size}
                        </p>
                      </div>
                    </div>

                    <div className="priceRow">
                      <div className="price">
                        {item.total_original_price !==
                          item.total_current_price && (
                          <span className="original_price">
                            R$ {(item.total_original_price / 100).toFixed(2)}
                          </span>
                        )}
                        <span className="current_price">
                          R$ {(item.total_current_price / 100).toFixed(2)}
                        </span>
                      </div>
                      <div className="action">
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedItemCode(item.code);
                            removeProductModalRef.current?.show();
                          }}
                        >
                          <DeleteIcon className="icon" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  // Modals
  const modals = useMemo(
    () => (
      <>
        <Modal
          ref={deleteUniqueBagModalRef}
          size="small-2"
          onHide={() => handleHideModal()}
        >
          <DeleteUniqueBagModal
            hide={() => {
              deleteUniqueBagModalRef.current?.hide();
            }}
            cartId={selectedBagId}
            setLoading={setLoading}
            setMobileBagSummary={setMobileBagSummary}
          />
        </Modal>
        <Modal
          ref={removeProductModalRef}
          size="small-2"
          onHide={() => {
            getCartDetails();
            getCartDetailsUpdated();
            handleHideModal();
          }}
        >
          <RemoveProductModal
            hide={() => {
              setSelectedItemCode('');
              removeProductModalRef.current?.hide();
            }}
            item_code={selectedItemCode}
            setMobileBagSummary={setMobileBagSummary}
            selectedBag={selectedBag}
          />
        </Modal>
        <Modal
          ref={deleteAllBagsModalRef}
          size="small-2"
          onHide={() => handleHideModal()}
        >
          <DeleteAllBagsModal
            setEmptySavedBags={setEmptySavedBags}
            hide={() => {
              deleteAllBagsModalRef.current?.hide();
            }}
          />
        </Modal>
      </>
    ),
    [
      selectedBagId,
      selectedItemCode,
      selectedBag,
      handleHideModal,
      getCartDetails,
      getCartDetailsUpdated,
    ],
  );

  const removeItems = async () => {
    const available_product = selectedBag?.items.filter(
      order_item => order_item.shipping.available === true,
    );

    if (available_product && available_product.length > 0) {
      await order.setItems(available_product);
      getCartDetailsUpdated();
    } else {
      deleteUniqueBagModalRef.current?.show();
    }
  };

  return (
    <>
      {mobileBagSummary && (
        <MobileBagSummary>
          <div className="summaryArea">
            {loading || order.loading ? (
              <div className="loading">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                {selectedBag && (
                  <div className="orderSummaryComponent">
                    <div className="orderSummaryHeader">
                      <div className="backButton">
                        {selectedBag.can_user_delete ? (
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedBagId(selectedBag.code);
                              deleteUniqueBagModalRef.current?.show();
                            }}
                          >
                            <p> {t('DELETE_BAG', 'Excluir Sacola')}</p>
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="closeButton">
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedCartId('');
                            order.setItems([]);
                            if (currentPage === 1) {
                              getOrders();
                            } else {
                              setCurrentPage(1);
                            }
                            setMobileBagSummary(false);
                          }}
                        >
                          <MdOutlineClose />
                        </button>
                      </div>
                    </div>
                    <div className="titleRow">
                      <div className="title">
                        {t('SAVED_BAG_TITLE', 'Sacola Salva')}
                      </div>
                      <div className="info">
                        {user.sale_codes && (
                          <>
                            {user.sale_codes.length > 1 && (
                              <div className="salespersonRow">
                                {t('SALESPERSON', '      Vendedor:')}{' '}
                                {selectedBag?.sale_user_code}
                                {' - '}
                                {selectedBag?.sale_user_name}
                              </div>
                            )}
                          </>
                        )}
                        {selectedBag && selectedBag.created_at && (
                          <div>
                            <p>
                              {(
                                format(
                                  parseJSON(Date.parse(selectedBag.created_at)),
                                  'EEEE',
                                  {
                                    locale: dateLanguagePack,
                                  },
                                )[0].toUpperCase() +
                                format(
                                  parseJSON(Date.parse(selectedBag.created_at)),
                                  'EEEE',
                                  {
                                    locale: dateLanguagePack,
                                  },
                                ).substring(1)
                              ).slice(0, -6)}{' '}
                              {getDate(
                                parseJSON(Date.parse(selectedBag.created_at)),
                              )}{' '}
                              {format(
                                parseJSON(Date.parse(selectedBag.created_at)),
                                'LLL',
                                {
                                  locale: dateLanguagePack,
                                },
                              )[0].toUpperCase() +
                                format(
                                  parseJSON(Date.parse(selectedBag.created_at)),
                                  'LLL',
                                  {
                                    locale: dateLanguagePack,
                                  },
                                ).substring(1)}{' '}
                              {format(
                                parseJSON(Date.parse(selectedBag.created_at)),
                                'HH',
                                {
                                  locale: dateLanguagePack,
                                },
                              )}
                              :
                              {format(
                                parseJSON(Date.parse(selectedBag.created_at)),
                                'mm',
                                {
                                  locale: dateLanguagePack,
                                },
                              )}
                            </p>
                          </div>
                        )}

                        <div>
                          <p>
                            {selectedBag?.customer.name ||
                            selectedBag?.customer.surname ? (
                              <>
                                Cliente: {selectedBag?.customer.name}{' '}
                                {selectedBag?.customer.surname}
                              </>
                            ) : (
                              <>Cliente: -</>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    {selectedBag.items.filter(
                      order_item => order_item.shipping.available === false,
                    ).length !== 0 && (
                      <div className="outOfStockRow">
                        <div className="text">
                          <p>
                            {t(
                              'PRODUCTS_OUT_OF_STOCK',
                              'Um ou mais produtos da sacola estão indisponíveis.',
                            )}
                          </p>
                        </div>
                        <div className="outOfStockButton">
                          <Button
                            color="danger"
                            onClick={() => {
                              setSelectedBagId(selectedBag.code);
                              removeItems();
                            }}
                          >
                            <p>{t('TO_REMOVE', 'REMOVER')}</p>
                          </Button>
                        </div>
                      </div>
                    )}
                    <div className="orderSummaryContent">
                      {loadingUptaded ? (
                        <div className="loading">
                          <LoadingIndicator />
                        </div>
                      ) : (
                        <>{getSummaryPackages()}</>
                      )}
                    </div>
                    <MobileBagSummaryFooter>
                      <div className="info">
                        <div className="infoRow">
                          <p className="firstInfo">
                            {order && (
                              <>
                                {order?.items
                                  .map(product => product.quantity)
                                  .reduce(
                                    (total, current) => total + current,
                                    0,
                                  ) > 1 ? (
                                  <>
                                    {selectedBag?.items
                                      .map(product => product.quantity)
                                      .reduce(
                                        (total, current) => total + current,
                                        0,
                                      )}{' '}
                                    {t('PRODUCTS', 'produtos')}
                                  </>
                                ) : (
                                  <>
                                    {selectedBag?.items
                                      .map(product => product.quantity)
                                      .reduce(
                                        (total, current) => total + current,
                                        0,
                                      )}{' '}
                                    {t('PRODUCT', 'produto')}
                                  </>
                                )}
                              </>
                            )}
                          </p>
                          <p className="firstInfo">
                            R${' '}
                            {selectedBag && (
                              <>
                                {(
                                  selectedBag?.total_original_price / 100
                                ).toFixed(2)}
                              </>
                            )}
                          </p>
                        </div>
                        <div className="infoRow">
                          <p>{t('DELIVERY', 'Entrega')}</p>
                          <p>
                            {selectedBag ? (
                              <>
                                {(
                                  selectedBag?.shipping_current_price / 100
                                ).toFixed(2)}
                              </>
                            ) : (
                              <>{t('TO_CALCULATE', 'a calcular')}</>
                            )}
                          </p>
                        </div>
                        {selectedBag && (
                          <>
                            {selectedBag.coupon && (
                              <>
                                <div className="infoRow">
                                  <p className="coupon">{selectedBag.coupon}</p>
                                  <p className="discountValue">
                                    -R${' '}
                                    {(
                                      (selectedBag.total_original_price -
                                        selectedBag.total_current_price) /
                                      100
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="total">
                        <p>{t('TOTAL', 'Total')}</p>
                        <p>
                          R${' '}
                          {selectedBag && (
                            <>{(selectedBag?.total / 100).toFixed(2)}</>
                          )}
                        </p>
                      </div>

                      <Button
                        className="action"
                        color="primary"
                        onClick={() => {
                          dispatch(setBagId(selectedCartId));
                          history.push(`/order/new`);
                        }}
                        disabled={
                          !!(
                            selectedBag.items.filter(
                              order_item =>
                                order_item.shipping.available === false,
                            ).length > 0
                          ) || loadingUptaded
                        }
                      >
                        <p>{t('FINISH_SERVICE', 'FINALIZAR ATENDIMENTO')}</p>
                      </Button>
                      <Button
                        className="action"
                        startIcon={<MdAdd />}
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          dispatch(setBagId(selectedCartId));
                          dispatch(setSavedBagAddingProduct(true));
                          history.push(`/order/new`);
                        }}
                      >
                        <p>{t('ADD_PRODUCT', 'ADICIONAR PRODUTO')}</p>
                      </Button>
                    </MobileBagSummaryFooter>
                  </div>
                )}
              </>
            )}
          </div>
          {modals}
        </MobileBagSummary>
      )}

      <>
        {!emptySavedBags ? (
          <>
            <Container>
              {width > 768 && (
                <div className="titleRow">
                  <p>{t('START', 'Início')}</p>
                </div>
              )}
              {width < 768 && !userIsManager && (
                <div className="deleteAllBags">
                  <button
                    type="button"
                    onClick={() => deleteAllBagsModalRef.current?.show()}
                  >
                    <p>Limpar tudo</p>
                  </button>
                </div>
              )}
              <div className="salutationRow">
                <p className="salutation">
                  Olá{!!userName && `, ${userName}`} !
                </p>
                <p className="text">
                  {t(
                    'YOU_HAVE_SAVED_BAGS',
                    'Você possui sacolas salvas na sua lista.',
                  )}
                </p>
              </div>

              <div className="bagsRow">
                {bags?.map(item => (
                  <div className="dayBreak">
                    <div className="dateRow">
                      <div className="date">
                        {(
                          format(
                            parseJSON(Date.parse(item[1][0].created_at)),
                            'EEEE',
                            {
                              locale: dateLanguagePack,
                            },
                          )[0].toUpperCase() +
                          format(
                            parseJSON(Date.parse(item[1][0].created_at)),
                            'EEEE',
                            {
                              locale: dateLanguagePack,
                            },
                          ).substring(1)
                        ).slice(0, -6)}{' '}
                        {getDate(parseJSON(Date.parse(item[1][0].created_at)))}{' '}
                        {format(
                          parseJSON(Date.parse(item[1][0].created_at)),
                          'LLL',
                          {
                            locale: dateLanguagePack,
                          },
                        )[0].toUpperCase() +
                          format(
                            parseJSON(Date.parse(item[1][0].created_at)),
                            'LLL',
                            {
                              locale: dateLanguagePack,
                            },
                          ).substring(1)}
                      </div>
                    </div>
                    <div className="bagsSection">
                      {item[1].map(bag => (
                        <>
                          <BagContainer>
                            <div className="topRow">
                              <div className="quantity">
                                {bag.items
                                  .map(product => product.quantity)
                                  .reduce(
                                    (total, current) => total + current,
                                    0,
                                  ) > 1 ? (
                                  <>
                                    {bag.items
                                      .map(product => product.quantity)
                                      .reduce(
                                        (total, current) => total + current,
                                        0,
                                      )}{' '}
                                    {t('PRODUCTS', 'produtos')}
                                  </>
                                ) : (
                                  <>
                                    {bag.items
                                      .map(product => product.quantity)
                                      .reduce(
                                        (total, current) => total + current,
                                        0,
                                      )}{' '}
                                    {t('PRODUCT', 'produto')}
                                  </>
                                )}
                              </div>

                              {width > 768 ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    order.setOrderCode(bag.id);
                                    dispatch(setBagSummary(true));
                                    dispatch(setBagId(bag.id));
                                  }}
                                >
                                  <div className="details">
                                    <p>{t('SEE_DETAILS', 'ver detalhes')}</p>
                                    <MdKeyboardArrowRight />
                                  </div>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSelectedCartId(bag.id);
                                    order.setOrderCode(bag.id);
                                    setMobileBagSummary(true);
                                  }}
                                >
                                  <div className="details">
                                    <p>{t('SEE_DETAILS', 'ver detalhes')}</p>
                                    <MdKeyboardArrowRight />
                                  </div>
                                </button>
                              )}
                            </div>
                            <div className="middleRow">
                              {user.sale_codes && (
                                <>
                                  {user.sale_codes.length > 1 && (
                                    <p className="salespersonRow">
                                      {t('SALESPERSON', '      Vendedor:')}{' '}
                                      <b>{bag.sale_user_code}</b> {' - '}
                                      {bag.sale_user_name}
                                    </p>
                                  )}
                                </>
                              )}
                              <p>
                                {t('CLIENT', '   Cliente:')}{' '}
                                {bag.customer.name && bag.customer.surname ? (
                                  <b>
                                    {bag.customer.name} {bag.customer.surname}
                                  </b>
                                ) : (
                                  <b>
                                    {t('NOT_IDENTIFIED', 'não identificado')}
                                  </b>
                                )}
                              </p>
                              <p>
                                {t('TOTAL', '      Total:')}{' '}
                                <b>
                                  R${' '}
                                  {(bag.total_current_price / 100).toFixed(2)}
                                </b>
                              </p>
                            </div>
                            <div className="bottomRow">
                              {bag.items.length > 3 ? (
                                <CarouselContent>
                                  <Carousel
                                    isRTL={false}
                                    breakPoints={breakPoints}
                                    pagination={false}
                                  >
                                    {bag.items.map(bag_item => (
                                      <div className="carouselCard">
                                        <img
                                          src={bag_item.image_url}
                                          alt={DefaultProductImage}
                                        />
                                      </div>
                                    ))}
                                    <div className="carouselCard">
                                      <div />
                                    </div>
                                  </Carousel>
                                  <div className="background" />
                                </CarouselContent>
                              ) : (
                                <ImagesContent>
                                  {bag.items.map(bag_item => (
                                    <div className="carouselCard">
                                      <img
                                        src={bag_item.image_url}
                                        alt={DefaultProductImage}
                                      />
                                    </div>
                                  ))}
                                  <div className="background" />
                                </ImagesContent>
                              )}
                              {bag.can_user_delete && (
                                <button
                                  className="trash"
                                  type="button"
                                  onClick={() => {
                                    setSelectedBagId(bag.id);
                                    deleteUniqueBagModalRef.current?.show();
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              )}
                            </div>
                          </BagContainer>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div id="sentinel" />
              {loading && (
                <div className="loadingIndicator">
                  <LoadingIndicator color={theme.font_secondary} size={3} />
                </div>
              )}
              {width < 768 && (
                <Button
                  className="action"
                  startIcon={<MdAdd />}
                  color="primary"
                  onClick={() => {
                    history.push(`/order/new`);
                  }}
                >
                  <p>{t('NEW_BAG', 'NOVA SACOLA')}</p>
                </Button>
              )}
            </Container>
            {width > 768 && (
              <Footer>
                {!userIsManager && (
                  <Button
                    color="danger"
                    className="action"
                    variant="outlined"
                    onClick={() => {
                      deleteAllBagsModalRef.current?.show();
                    }}
                  >
                    <p>{t('CLEAR_ALL', 'LIMPAR TUDO')}</p>
                  </Button>
                )}
                <Button
                  className="action"
                  startIcon={<MdAdd />}
                  color="primary"
                  onClick={() => {
                    history.push(`/order/new`);
                  }}
                >
                  <p>{t('NEW_BAG', 'NOVA SACOLA')}</p>
                </Button>
              </Footer>
            )}
            {modals}
          </>
        ) : (
          <EmptySavedBagsContainer>
            {!!userName && (
              <p className="title">
                {t('HELLLO', 'Olá')} {String(userName).split(' ')[0]}!
              </p>
            )}

            <div className="icon">
              <MdOutlineShoppingBag color={theme.font_secondary} size={180} />
            </div>
            <p className="orientation">
              {t('ORIENTATION', 'Você não possui sacolas salvas.')}
            </p>
            <p className="text">
              Clique abaixo em{' '}
              <b>
                NOVA SACOLA para
                <br />
              </b>
              iniciar um novo atendimento.
            </p>
            <div className="action">
              <Button
                onClick={() => {
                  history.push(`/order/new`);
                }}
                startIcon={<MdAdd />}
              >
                {t('NEW_BAG', 'NOVA SACOLA')}
              </Button>
            </div>
          </EmptySavedBagsContainer>
        )}
      </>
    </>
  );
};

export { SavedBags };
