import styled from 'styled-components';
import { rgba } from 'polished';
import CustomScrollBar from 'react-custom-scrollbars';

const HEADER_SIZE = 3.75;
const SIDEBAR_SIZE = 18.75;

interface IContainerProps {
  open: boolean;
  mobileSize: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: fixed;
  left: ${props =>
    props.open ? 0 : props.mobileSize ? '-110%' : `-${SIDEBAR_SIZE + 1}rem`};
  top: ${HEADER_SIZE}rem;
  z-index: 99;

  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.background_primary};
  width: ${props => (props.mobileSize ? '100%' : `${SIDEBAR_SIZE}rem`)};
  height: calc(100vh - ${HEADER_SIZE}rem);
  transition: 0.2s ease-out;

  box-shadow: 0rem 0rem 0.313rem 0rem
    ${({ theme }) => rgba(theme.background_primary, 0.95)};

  .content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .margin {
      width: 100%;
      background: ${({ theme }) => theme.background_secondary};
      height: 2.5rem;
      box-shadow: 0rem 0rem 0.6rem 0rem
        ${({ theme }) => rgba(theme.background_low, 0.95)};
      z-index: 1;
    }

    section.head {
      background: ${({ theme }) => theme.background_secondary};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.75rem;
      padding: 1rem;

      .info {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.25rem;
        color: ${({ theme }) => theme.font_low};

        .name {
          font-weight: 600;
          font-size: 1rem;
        }

        .store {
          font-weight: 400;
          font-size: 0.75rem;
        }
      }
    }

    section.navigation {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.5rem;
      padding: 1rem;

      .new {
        background-color: ${({ theme }) => theme.background_secondary};
        border-radius: 1rem;
        padding: 0.1rem 0.625rem;
        margin-left: 0.5rem;

        p {
          color: ${({ theme }) => theme.background_primary};
          font-size: 0.875rem;
        }
      }
    }

    section.footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 1rem;

      span.divisor {
        width: 80%;
        background: ${({ theme }) => theme.background_secondary};
        border-radius: 0.125rem;
        height: 0.188rem;
      }

      .expanded {
        display: flex;
        flex-direction: column;
        align-self: stretch;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    position: fixed;
  }
`;

export const ScrollBar = styled(CustomScrollBar)``;

export const Config = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 1rem;
  padding: 0 0.7rem;

  > .row {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    gap: 0.3rem;

    &.clickable {
      cursor: pointer;
      justify-content: space-between;
    }

    .printerDiv {
      display: flex;
      flex-direction: row;
      gap: 0.3rem;

      span {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      h3 {
        font-weight: normal;
        color: ${({ theme }) => theme.font_primary};
        white-space: nowrap;
      }
    }

    > span {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    > div {
      display: flex;
      align-self: stretch;
      flex-direction: column;
      flex: 1;
      gap: 1rem;

      h3 {
        font-weight: normal;
        color: ${({ theme }) => theme.font_primary};
      }

      ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        align-self: stretch;

        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;

          p {
            color: ${({ theme }) => theme.font_primary};
            font-size: 0.625rem;
            text-align: center;
          }
        }
      }

      span {
      }
    }
  }
`;
