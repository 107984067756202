import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  .closeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    button {
      .closeIcon {
        color: ${({ theme }) => theme.font_secondary};
        font-size: 1.75rem;
      }
    }
  }

  .titleRow {
    font-size: 1.125rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.font_primary};
  }

  .textRow {
    font-weight: 500;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.font_secondary};
    text-align: center;
    margin-bottom: 1rem;
    padding: 0 1rem;

    @media screen and (min-width: 768px) {
      max-width: 21rem;
      align-self: center;
    }
  }

  .actionArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;

    div {
      button {
        border-radius: 0.5rem;
      }
    }
  }
`;
