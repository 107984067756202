import React from 'react';

import { CustomerInfo } from '../CustomerInfo';
import { AddressInfo } from '../AddressInfo';

// Style import
import { Metadata } from './styles';

const DesktopMetadata: React.FC = () => {
  return (
    <Metadata>
      <CustomerInfo />
      <div className="divider" />
      <AddressInfo />
    </Metadata>
  );
};

export { DesktopMetadata };
