import { i18n as Ii18n } from 'i18next';
import { useMemo } from 'react';
import {
  useTranslation as useI18nextTranslation,
  TFunction,
} from 'react-i18next';

type TranslationFunction = (
  subKey: string,
  defaultValue: string,
  ...rest: any[]
) => string;

interface ITranslation {
  t: TranslationFunction;
  i18nt: TFunction<'translation'>;
  i18n: Ii18n;
}

const useTranslation = (key: string): ITranslation => {
  // Hooks
  const { t, i18n } = useI18nextTranslation();

  const translationKey = useMemo(() => {
    return String(key).includes('/') ? `${key}_` : `${key}/`;
  }, [key]);
  const result = useMemo(() => {
    return {
      t: (subKey: string, defaultValue: string, ...rest: any[]) =>
        t(
          `${translationKey}${String(subKey.toUpperCase().replace(' ', '_'))}`,
          defaultValue,
          ...rest,
        ),
      i18nt: t,
      i18n,
    };
  }, [i18n, t, translationKey]);

  return result;
};

export type { TranslationFunction };
export { useTranslation };
