// import { rgba } from 'polished';
import styled from 'styled-components';
import { motion } from 'framer-motion';

// Config import
import { defaultTransition } from '@config/transition';

export const Container = styled(motion.div).attrs(defaultTransition)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding: 2rem 2rem 2rem 2rem;
  flex: 1;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;

  @media screen and (max-width: 768px) {
    gap: 2rem;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: none;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.background_primary};
    border-radius: 0.25rem;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .titleRow {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    width: 100%;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
    }

    .title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }

      button {
        margin-right: 0;
      }
    }

    button {
      margin-right: 1rem;
      color: ${({ theme }) => theme.button_high};

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    p {
      font-weight: 600;
      font-size: 1.25rem;
    }
  }

  .idInfoSection {
    align-self: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    flex: 1;

    .title {
      font-weight: 600;
      font-size: 1.25rem;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .cpfForm {
        align-self: flex-start;
        display: flex;
        gap: 2rem;
        align-items: center;

        @media screen and (max-width: 768px) {
          width: 85%;
        }

        .deleteButton {
          font-size: 1rem;
        }

        .endAdornmentSpace {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 1800px) {
    ul {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 1440px) {
    ul {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 1rem 1rem 1rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2rem;
  margin-left: 2rem;

  align-self: stretch;

  margin-top: 1rem;
  border-top: 0.188rem solid ${({ theme }) => theme.font_secondary};
  border-radius: 0.125rem;
  padding: 1rem 0;

  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 1rem;
    border-top: none;
    border-radius: 0;
    padding: 0 0.5rem;
  }

  .action {
    width: 21rem;
    max-width: 100%;
    @media screen and (max-width: 768px) {
      max-width: none;
    }
  }
`;
