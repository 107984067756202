import React from 'react';
import { toast } from 'react-toastify';
import { MdOutlineClose, MdWarning } from 'react-icons/md';
import { useDispatch } from 'react-redux';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useOrderCreation } from '@hooks/useOrderCreation';

// Service import
import { api } from '@services/api';

// Util import
import { setBagSummary, setBagId } from '@store/modules/ui/actions';

// Util import
import { IBag } from '@util/SavedBagModels';

// Style import
import { Container } from './styles';

interface IProps {
  hide?: () => any;
  item_code: string;
  setActiveModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedBag: IBag | undefined;
}

// Feature identification
const featureKey = '@saved_bags/SAVED_BAGS_MODAL';

const RemoveProductModal: React.FC<IProps> = ({
  hide,
  item_code,
  setActiveModal,
  selectedBag,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const dispatch = useDispatch();

  const removeItem = async () => {
    if (selectedBag && selectedBag.items.length === 1) {
      try {
        // API call
        await api.delete(`/v1/cart/${selectedBag?.code}`);
        dispatch(setBagSummary(false));
        dispatch(setBagId('update'));
      } catch (err: any) {
        toast.error(t('DELETE_ERROR', 'Erro ao remover produto'));
      }
    } else if (selectedBag) {
      await order.setItems(
        selectedBag.items.filter(item => item.code !== item_code),
      );
    }
  };

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="iconRow">
        <MdWarning />
      </div>
      <div className="textRow">
        <p className="text">
          {t('REMOVE_PRODUCT_ASK', 'Deseja remover o produto da sacola?')}
        </p>
      </div>

      <div className="actionArea">
        <Button
          variant="outlined"
          color="danger"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          {t('NO', 'NÃO')}
        </Button>
        <Button
          onClick={async () => {
            await removeItem();
            setActiveModal(false);
            if (typeof hide === 'function') hide();
          }}
        >
          {t('REMOVE', 'REMOVER')}
        </Button>
      </div>
    </Container>
  );
};

export { RemoveProductModal };
