const initialShippingData = [
  {
    title: 'Mais econômica',
    method: 'cheapest',
    enabled: false,
    selected: false,
    tagCode: 'entrega_economica',
    data: {},
  },
  {
    title: 'Mais rápida',
    method: 'fastest',
    enabled: false,
    selected: false,
    tagCode: 'entrega_rapida',
    data: {},
  },
  {
    title: 'Mais rápida e ecônomica',
    method: 'best',
    enabled: false,
    selected: false,
    tagCode: 'entrega_rapida_economica',
    data: {},
  },
];

export { initialShippingData };
