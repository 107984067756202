import React, { forwardRef, useLayoutEffect, useRef } from 'react';
import MaterialButton from '@material-ui/core/Button';
import { readableColor } from 'polished';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useTheme } from '@hooks/useTheme';

// Util import
import { getColor } from '@util/getColor';

// Style import
import { Container } from './styles';

// Interfaces
type IRef = HTMLButtonElement;

interface IProps {
  hide?: boolean;
  loading?: boolean;
  disabled?: boolean;
  className?: string;

  children?: React.ReactNode;

  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'high'
    | 'low'
    | string;
  rippleColor?: string;
  disableFocusRipple?: boolean;
  disableElevation?: boolean;
  noFullWidth?: boolean;
  noMargin?: boolean;

  href?: string;
  variant?: 'text' | 'outlined' | 'contained';

  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;

  onClick?: () => any;

  [key: string]: any;
}

const Button = forwardRef<IRef, IProps>(
  (
    {
      hide,
      loading,
      disabled,
      className,

      children,

      color,
      rippleColor,
      disableFocusRipple,
      disableElevation,
      noFullWidth,
      noMargin,

      href,
      variant,

      startIcon,
      endIcon,

      onClick,

      ...rest
    },
    ref,
  ) => {
    // Hooks
    const theme = useTheme();

    // Local refs
    const buttonRef = useRef(null);

    // Merge external ref with local ref
    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === 'function') ref(buttonRef.current);
        else ref.current = buttonRef.current;
      }
    }, [buttonRef, ref]);

    return (
      <Container
        className={className}
        color={color}
        rippleColor={rippleColor}
        hide={!!hide}
        fullWidth={!noFullWidth}
        disabled={!!disabled || !!loading}
        noMargin={!!noMargin}
      >
        <MaterialButton
          {...rest}
          className={className}
          ref={buttonRef}
          disabled={disabled || loading}
          disableElevation={disableElevation}
          disableFocusRipple={disableFocusRipple}
          fullWidth={!noFullWidth}
          endIcon={endIcon}
          href={href}
          startIcon={startIcon}
          variant={variant}
          onClick={onClick}
        >
          {loading ? (
            <LoadingIndicator
              size={1.5}
              color={readableColor(
                getColor({
                  theme,
                  color,
                  disabled: !!disabled || !!loading,
                }),
              )}
            />
          ) : (
            children
          )}
        </MaterialButton>
      </Container>
    );
  },
);

Button.defaultProps = {
  hide: false,
  loading: false,
  disabled: false,

  children: undefined,

  color: undefined,
  rippleColor: undefined,
  disableFocusRipple: false,
  disableElevation: false,
  noFullWidth: false,
  noMargin: false,

  href: undefined,
  variant: 'contained',

  endIcon: undefined,
  startIcon: undefined,

  onClick: undefined,
};

export { Button };
