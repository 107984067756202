import React from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Asset import
import { CompletedSurveyIcon } from '@assets/index';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
}

// Feature identification
const featureKey = '@satisfaction_survey/FAREWELL_MODAL';

const FarewellModal: React.FC<IProps> = ({ hide }) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="iconRow">
        <CompletedSurveyIcon />
        <p>{t('THANK_YOU', ' Obrigada!')}</p>
      </div>
      <div className="textRow">
        <p>{t('RECEIVED_ANSWERS', 'Recebemos as suas respostas.')}</p>
      </div>
      <div className="actionArea">
        <Button
          onClick={() => {
            if (typeof hide === 'function') hide();
          }}
        >
          <p className="modalButton">
            {t('GO_TO_SOMA_STORE', ' IR PARA O SOMA STORE')}
          </p>
        </Button>
      </div>
    </Container>
  );
};

export { FarewellModal };
