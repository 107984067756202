import { offset } from '@floating-ui/dom';

export const onboardingSteps = [
  {
    id: 'shepherd1',
    attachTo: { element: '.referenceFormSection', on: 'bottom' as const },
    text: [
      'Agora é possível consultar produtos apenas pela sua referência!\n Preencha a referência e clique no botão de consultar produto para ver todos os resultados com aquela referência.\nCaso prefira uma consulta direcionada, preencha o código da cor.',
    ],
    buttons: [
      {
        classes: 'shepherd-button-primary',
        text: '',
        type: 'back',
      },
      {
        classes: 'shepherd-button-secondary single-button-modal',
        text: 'OK',
        type: 'complete',
      },
    ],
    modalOverlayOpeningPadding: 16,
    modalOverlayOpeningRadius: 8,
    canClickTarget: false,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 16, crossAxis: 0 })],
    },
    scrollTo: true,
  },
];
