import React from 'react';
import { MdOutlineWarning, MdOutlineClose } from 'react-icons/md';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useAddProduct } from '@hooks/useAddProduct';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  productQuantity: number;
}

// Feature identification
const featureKey = '@create_order/VARIATIONS_SCREEN_CONFIRMATION_MODAL';

const ConfirmationModal: React.FC<IProps> = ({ hide, productQuantity }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const product = useAddProduct();

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="iconRow">
        <MdOutlineWarning />
      </div>
      <p className="textRow">
        {t(
          'AVAILABILITY_NOTICE',
          'Verifique se tem a peça em loja. Apenas com a peça em mãos confirme sua disponibilidade.',
        )}
      </p>
      <Button
        onClick={() => {
          product.addToCart({
            packageTypeCode: 'OWN_STOCK',
            initialQuantity: productQuantity,
          });
          if (typeof hide === 'function') hide();
        }}
      >
        {t('CONFIRM', 'CONFIRMAR')}
      </Button>
      <span>
        <Button
          color="danger"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          {t('CANCEL', 'CANCELAR')}
        </Button>
      </span>
    </Container>
  );
};

export { ConfirmationModal };
