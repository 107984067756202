import React, { useCallback, useState } from 'react';
import MaterialTab, { TabProps } from '@material-ui/core/Tab';

// Hook import
import { useNonInitialEffect } from '@hooks/useNonInitialEffect';

// Style import
import { Container } from './styles';

// Interfaces
interface ITab extends TabProps {
  label: string;
  disabled?: boolean;
  color?: string;
}

interface IProps {
  index?: number;
  onChange?: (index: number) => void;
  tabs: ITab[];
  disabled?: boolean;
  className?: string;
}

const Tabs: React.FC<IProps> = ({
  index,
  onChange,
  tabs,
  disabled,
  ...rest
}) => {
  // Local states
  const [activeTab, setActiveTab] = useState(index || 0);

  const handleChange = useCallback(
    (_, value) => {
      setActiveTab(Number(value));
      if (typeof onChange === 'function') onChange(Number(value));
    },
    [onChange],
  );

  useNonInitialEffect(() => {
    setActiveTab(index || 0);
  }, [index]);

  return (
    <Container
      value={activeTab}
      onChange={handleChange}
      style={{}}
      variant="scrollable"
      scrollButtons="auto"
      {...rest}
    >
      {tabs.map(({ label, disabled: tabDisabled, color, ...tabRest }, i) => (
        <MaterialTab
          key={String(i)}
          label={label}
          disabled={disabled || tabDisabled}
          color={color}
          {...tabRest}
        />
      ))}
    </Container>
  );
};

export { Tabs };
