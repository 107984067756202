import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 101;

  .configComponent {
    position: absolute;
    width: 32rem;
    height: 100vh;
    max-height: 100vh;
    right: 0;
    display: flex;
    flex-direction: column;
    z-index: 100;
    padding: 1.5rem;
    background-color: ${({ theme }) => theme.background_low};
    overflow-y: scroll;

    ::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    .closeRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding: 1.5rem 0;

      button {
        .closeIcon {
          color: ${({ theme }) => theme.button_high};
          font-size: 1.75rem;
        }
      }
    }

    .moduleTitle {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 1.5rem;
      border-bottom: 0.0625rem solid ${({ theme }) => theme.font_secondary};

      p {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.font_secondary};
        white-space: nowrap;
      }
    }

    .printerList {
      margin-top: 3rem;

      .listHeader {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-bottom: 1.1rem;
        border-bottom: 0.0625rem solid ${({ theme }) => theme.font_secondary};

        .title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          align-items: flex-start;

          p {
            font-size: 1.25rem;
            font-weight: 600;
            color: ${({ theme }) => theme.font_secondary};
            white-space: nowrap;

            &.icon {
              font-size: 1.5rem;
            }
          }
        }

        .subtitle {
          font-size: 0.75rem;
          font-weight: 400;
          color: ${({ theme }) => theme.font_primary};
        }
      }
    }
  }
`;
