import React from 'react';
import { useSelector } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';

// Component import
import { CartSaleCode } from '@components/CartSaleCode';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Feature identification
const featureKey = '@create_order/CART_DELIVERY_TITLE';

interface IProps {
  setStatusState: (
    value: 'bag' | 'identification' | 'delivery' | 'payments',
  ) => void;
}

const SectionTitle: React.FC<IProps> = ({ setStatusState }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const user = useSelector(state => state.auth);

  return (
    <div className="titleRow">
      <button
        type="button"
        onClick={() => {
          setStatusState('identification');
        }}
      >
        <MdArrowBack />
      </button>
      <div className="title">
        <p>{t('DELIVERY', 'Entrega')}</p>
        {user.sale_codes && (
          <>
            {order.saleCode.code &&
              order.saleCode.name &&
              user.sale_codes.length > 1 && <CartSaleCode />}
          </>
        )}
      </div>
    </div>
  );
};

export { SectionTitle };
