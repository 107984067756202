import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { MdCheck, MdOutlinePlace } from 'react-icons/md';
import { FormHandles, SubmitHandler } from '@unform/core';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

// Constant import
import { stateOptions } from '@constants/stateOptions';

// Component import
import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { Select, ISelectRef } from '@components/Select';
import { LoadingIndicator } from '@components/LoadingIndicator';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { IAddress } from '@hooks/useOrderCreation/models';
import { useTheme } from '@hooks/useTheme';
import { useTranslation } from '@hooks/useTranslation';
import { useWindowSize } from '@hooks/useWindowSize';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { getFormErrors } from '@util/getFormErrors';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  selectedClientInfo: IClientData | undefined;
  setSelectedClientInfo: React.Dispatch<
    React.SetStateAction<IClientData | undefined>
  >;
  closeAllModals: boolean;
  searchDocumentNumber: string;
  setCloseAllModals: React.Dispatch<React.SetStateAction<boolean>>;
  localStorageClientDocument: string;
}

interface IClientData {
  document: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  brandcode?: number;
  code?: string;
}

interface IClientAddress {
  brand: IClientAddressBrand;
  city: string;
  code: string;
  complement: string;
  country: string;
  customerCode: string;
  neighborhood: string;
  number: string;
  postalCode: string;
  state: string;
  street: string;
  type: string;
}

interface IClientAddressBrand {
  name: string;
  code: number;
  codename: string;
}

// Feature identification
const featureKey = '@create_order/ADDRESS';

const Address: React.FC<IProps> = ({
  hide,
  selectedClientInfo,
  setSelectedClientInfo,
  closeAllModals,
  setCloseAllModals,
  searchDocumentNumber,
  localStorageClientDocument,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();
  const theme = useTheme();

  // Local refs
  const formZipcodeRef = useRef<FormHandles>(null);
  const formAddressRef = useRef<FormHandles>(null);

  const zipcodeInputRef = useRef<HTMLInputElement>(null);
  const stateInputRef = useRef<ISelectRef>(null);
  const cityInputRef = useRef<HTMLInputElement>(null);
  const neighborhoodInputRef = useRef<HTMLInputElement>(null);
  const streetInputRef = useRef<HTMLInputElement>(null);
  const numberInputRef = useRef<HTMLInputElement>(null);
  const complementInputRef = useRef<HTMLInputElement>(null);
  const receiverInputRef = useRef<HTMLInputElement>(null);

  const [width] = useWindowSize();

  // Local states
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState<undefined | IClientAddress[]>(
    [],
  );
  const [addressData, setAddressData] = useState<undefined | IAddress>(
    undefined,
  );
  const [addressCode, setAddressCode] = useState<undefined | string>();

  useEffect(() => {
    if (closeAllModals === true) {
      setCloseAllModals(false);
      if (typeof hide === 'function') hide();
    }
  }, [closeAllModals, hide, setCloseAllModals]);

  // Handle zipcode submit
  const handleFormZipcodeSubmit: SubmitHandler<IAddress> = useCallback(
    async data => {
      setLoading(true);

      // Setup a schema to be valitated
      const schema = Yup.object()
        .shape({
          zipcode: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_ZIPCODE_TYPE',
                'O tipo do CEP é inválido, deveria ser alfanumérico.',
              ),
            )
            .required(t('REQUIRED_ZIPCODE', 'O CEP é obrigatório.')),
        })
        .noUnknown(true, t('INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        getFormErrors(err, ({ form, errors }) => {
          formZipcodeRef.current?.setErrors(form);
          toast.error(errors[0]);
        });

        setLoading(false);

        return;
      }

      try {
        // API call
        const response = await api.get(`/address/${data.zipcode}`);

        // Check if has returned data
        if (!response.data.zipcode || !response.data.country) {
          const error = t(
            'ZIPCODE_AUTOFILL_MISSING_INFORMATION_FROM_RESPONSE',
            'Não foi possível obter informações precisas sobre este CEP.',
          );
          toast.error(error);
        } else {
          setAddressData(response.data);
        }

        setLoading(false);
      } catch (err: any) {
        const { message } = getResponseError(err, t);
        toast.error(message);

        setLoading(false);
      }
    },
    [t],
  );

  // Handle address submit
  const handleFormAddressSubmit: SubmitHandler<{
    zipcode: string;
    code?: string;
    country?: string;
    state?: string;
    city?: string;
    neighborhood?: string;
    street?: string;
    address_number?: string;
    receiver_name?: string;
    complement?: string;
  }> = useCallback(
    async data => {
      setLoading(true);

      // Setup a schema to be valitated
      const schema = Yup.object()
        .shape({
          state: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_STATE_TYPE',
                'O Estado deve ser composto por caracteres.',
              ),
            )
            .required(t('REQUIRED_STATE_NAME', 'Informe o Estado.')),
          city: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_CITY_TYPE',
                'A cidade deve ser composto por caracteres.',
              ),
            )
            .required(t('REQUIRED_CITY_NAME', 'Informe a cidade.')),
          neighborhood: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_NEIGHBORHOOD_TYPE',
                'O bairro deve ser composto por caracteres.',
              ),
            )
            .required(t('REQUIRED_NEIGHBORHOOD_NAME', 'Informe o bairro.')),
          street: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_STREET_TYPE',
                'O logradouro deve ser composto por caracteres.',
              ),
            )
            .required(t('REQUIRED_STREET_NAME', 'Informe o logradouro.')),
          // Workaround on navigator card auto complete
          address_number: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_NUMBER_TYPE',
                'O número deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(t('REQUIRED_NUMBER_NAME', 'Informe o número.')),
          zipcode: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_ZIPCODE_TYPE',
                'O CEP deve ser composto por caracteres.',
              ),
            )
            .required(t('REQUIRED_ZIPCODE_NAME', 'Informe o CEP.')),
          complement: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_COMPLEMENT_TYPE',
                'O complemento deve ser composto por caracteres alfanuméricos.',
              ),
            ),
          receiver_name: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_RECEIVER_NAME_TYPE',
                'O nome do recebedor deve ser composto por caracteres.',
              ),
            )
            .required(
              t('REQUIRED_RECEIVER_NAME_NAME', 'Informe o nome do recebedor.'),
            ),
        })
        .noUnknown(true, t('INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        getFormErrors(err, ({ form, errors }) => {
          formAddressRef.current?.setErrors(form);
          toast.error(errors[0]);
        });

        setLoading(false);

        return;
      }

      setLoading(false);

      // Set data
      order.setAddress({
        code: addressCode,
        zipcode: data.zipcode,
        city: data.city,
        complement: data.complement,
        country: data.country,
        neighborhood: data.neighborhood,
        number: data.address_number,
        receiver_name: data.receiver_name,
        state: data.state,
        street: data.street,
      });

      // Hide modal
      if (typeof hide === 'function') hide();
    },
    [addressCode, hide, order, t],
  );

  const handleAddressButtonClick = (address: IClientAddress) => {
    setAddressCode(address.code);
    setAddressData({
      zipcode: address.postalCode,
      code: address.code,
      country: address.country,
      state: address.state,
      city: address.city,
      neighborhood: address.neighborhood,
      street: address.street,
      number: address.number,
      complement: address.complement,
    });
    setAddressList(undefined);
  };

  const getClientAddress = useCallback(async () => {
    try {
      // API call
      const response = await api.get('/customer/address', {
        params: {
          customerCode: selectedClientInfo?.code,
          brandCode: selectedClientInfo?.brandcode,
        },
      });

      setAddressList(response.data);
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);

      setLoading(false);
    }
  }, [selectedClientInfo?.brandcode, selectedClientInfo?.code, t]);

  useEffect(() => {
    if (
      selectedClientInfo?.code &&
      selectedClientInfo?.brandcode &&
      searchDocumentNumber?.length === 0 &&
      localStorageClientDocument
    ) {
      getClientAddress();
    }
    if (
      selectedClientInfo?.code &&
      selectedClientInfo?.brandcode &&
      searchDocumentNumber?.length === 11
    ) {
      getClientAddress();
    }
  }, [
    getClientAddress,
    setSelectedClientInfo,
    selectedClientInfo?.brandcode,
    selectedClientInfo?.code,
    searchDocumentNumber?.length,
    localStorageClientDocument,
    selectedClientInfo,
    searchDocumentNumber,
  ]);

  // Initial data
  useEffect(() => {
    if (order.address.zipcode && order.address.state && order.address.city)
      setAddressData(order.address);
  }, [order.address]);

  // When address data changes
  useEffect(() => {
    formAddressRef.current?.setData(
      {
        ...addressData,
        // Woraround on browser card auto complete
        number: undefined,
        address_number: addressData?.number,
      } || {},
    );
  }, [addressData]);

  // When customer data changes
  useEffect(() => {
    if (
      !addressData?.receiver_name &&
      formAddressRef.current &&
      order.customer.name &&
      order.customer.surname
    )
      formAddressRef.current?.setFieldValue(
        'receiver_name',
        `${order.customer.name} ${order.customer.surname}`,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.customer, addressData?.zipcode]);

  // Find address options
  if (addressList?.length)
    return (
      <Container>
        <ul>
          {addressList.slice(0, 3).map((address, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${address.neighborhood}-${index}`}>
              <button
                type="button"
                onClick={() => handleAddressButtonClick(address)}
              >
                <div className="icon">
                  <MdOutlinePlace color={theme.font_low} size={30} />
                </div>
                <div className="info">
                  <span className="postalCode">
                    {address.postalCode.slice(0, 5)}-
                    {address.postalCode.slice(5)}
                  </span>
                  <span className="street">{address.street}</span>
                  <span className="complement">
                    No. {address.number} - {address.complement}
                  </span>
                  <span className="complement">
                    {address.neighborhood} - {address.city}/{address.state}
                  </span>
                </div>
              </button>
            </li>
          ))}
        </ul>
        <Button
          disabled={order.loading}
          onClick={() => {
            setAddressList(undefined);
          }}
        >
          {t('USE_ANOTHER_ADDRESS', 'CADASTRAR NOVO ENDEREÇO')}
        </Button>
      </Container>
    );

  // If zipcode not selected
  if (!addressData?.zipcode) {
    return (
      <Container>
        {order.loading || loading ? (
          <div className="loading">
            <LoadingIndicator color={theme.font_secondary} size={4} />
          </div>
        ) : (
          <Form
            key="formZipcode"
            ref={formZipcodeRef}
            onSubmit={handleFormZipcodeSubmit}
            className="zipcode"
            autoComplete="off"
          >
            <Input
              name="zipcode"
              label={t('ZIPCODE', 'CEP')}
              disabled={order.loading || loading}
              onKeyUp={(e: any) =>
                String(e.target.value).match(/\d+/g)?.join('')?.length === 8 &&
                formZipcodeRef.current?.submitForm()
              }
              onEnterKey={() => formZipcodeRef.current?.submitForm()}
              mask="99.999-999"
              type="tel"
              inputMode="numeric"
              pattern="[0-9]*"
            />
            <Button
              onClick={() => formZipcodeRef.current?.submitForm()}
              disabled={order.loading || loading}
              noMargin
            >
              <MdCheck size={20} color={theme.font_low} />
            </Button>
          </Form>
        )}
      </Container>
    );
  }

  // Selected zipcode
  return (
    <Container>
      <Form
        key="formAddress"
        ref={formAddressRef}
        onSubmit={handleFormAddressSubmit}
        className="address"
        autoComplete="off"
      >
        <section>
          <Input
            ref={zipcodeInputRef}
            name="zipcode"
            label={t('ZIPCODE', 'CEP')}
            disabled={order.loading || loading}
            onEnterKey={() => stateInputRef.current?.show()}
            onKeyUp={(e: any) =>
              String(e.target.value).match(/\d+/g)?.join('')?.length === 8 &&
              stateInputRef.current?.show()
            }
            mask="99.999-999"
            type="tel"
            inputMode="numeric"
            pattern="[0-9]*"
          />
          <Select
            ref={stateInputRef}
            name="state"
            label={t('STATE', 'UF')}
            options={stateOptions}
            onClose={() => cityInputRef.current?.focus()}
            onChange={() => cityInputRef.current?.focus()}
            disabled={order.loading || loading}
          />
        </section>
        <Input
          ref={cityInputRef}
          name="city"
          label={t('CITY', 'Cidade')}
          disabled={order.loading || loading}
          onEnterKey={() => neighborhoodInputRef.current?.focus()}
        />
        <Input
          ref={neighborhoodInputRef}
          name="neighborhood"
          label={t('NEIGHBORHOOD', 'Bairro')}
          disabled={order.loading || loading}
          onEnterKey={() => streetInputRef.current?.focus()}
        />
        <Input
          ref={streetInputRef}
          name="street"
          label={t('STREET', 'Rua')}
          disabled={order.loading || loading}
          onEnterKey={() => numberInputRef.current?.focus()}
        />
        <section>
          <Input
            ref={numberInputRef}
            name="address_number"
            label={t('NUMBER', 'Número')}
            disabled={order.loading || loading}
            onEnterKey={() => complementInputRef.current?.focus()}
          />
          {width > 768 ? (
            <Input
              ref={complementInputRef}
              name="complement"
              label={t('COMPLEMENT', 'Complemento')}
              disabled={order.loading || loading}
              onEnterKey={() => receiverInputRef.current?.focus()}
            />
          ) : (
            <Input
              ref={complementInputRef}
              name="complement"
              label={t('COMPL.', 'Compl.')}
              disabled={order.loading || loading}
              onEnterKey={() => receiverInputRef.current?.focus()}
            />
          )}
        </section>
        <Input
          ref={receiverInputRef}
          name="receiver_name"
          label={t('RECEIVER', 'Recebedor')}
          disabled={order.loading || loading}
          onEnterKey={() => formAddressRef.current?.submitForm()}
        />
      </Form>
      <Button
        onClick={() => {
          formAddressRef.current?.submitForm();
        }}
        disabled={order.loading || loading}
      >
        {t('CONFIRM', 'CONFIRMAR')}
      </Button>
      <Button
        onClick={() => setAddressData(undefined)}
        disabled={order.loading || loading}
        variant="outlined"
      >
        {t('USE_ANOTHER_ZIPCODE', 'USAR OUTRO CEP')}
      </Button>
    </Container>
  );
};

export { Address };
