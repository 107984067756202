import React, { useEffect, useMemo } from 'react';
import {
  Switch,
  Redirect,
  BrowserRouter as Router,
  Route as Navigation,
} from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';

// Page import
import { Statistics } from '@pages/Statistics';
import { Client } from '@pages/Client';
import { Login } from '@pages/Login';
import { Dashboard } from '@pages/Dashboard';
import { SavedBags } from '@pages/SavedBags';
import { CreateOrder } from '@pages/CreateOrder';
import { ListOrders } from '@pages/ListOrders';
import { ViewOrder } from '@pages/ViewOrder';
import { Loading } from '@pages/Loading';

// Action import
import { resumeSessionRequest, signOut } from '@store/modules/auth/actions';

// Route import
import { Route } from './Route';

const Routes: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();

  // Initial login
  useEffect(() => {
    // Try to get token
    const token = localStorage.getItem(
      process.env.REACT_APP_SESSION_PERSIST_KEY,
    );

    // Session
    if (token) dispatch(resumeSessionRequest(token));
    else dispatch(signOut());
  }, [dispatch]);

  // Global states
  const signed = useSelector(state => state.auth.signed);
  const authLoading = useSelector(state => state.auth.loading);

  // Router instance
  const router = useMemo(
    () => (
      <Router>
        <Navigation
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter initial={false}>
              {authLoading || signed === undefined ? (
                <Loading />
              ) : (
                <Switch location={location} key={location.pathname}>
                  <Route path="/" exact component={Login} />
                  <Route
                    path="/dashboard"
                    isPrivate
                    exact
                    component={Dashboard}
                  />
                  <Route path="/carts" isPrivate exact component={SavedBags} />
                  <Route
                    path="/order/new"
                    isPrivate
                    exact
                    component={CreateOrder}
                  />
                  <Route
                    path="/order/:id/:documentWarning?"
                    isPrivate
                    component={ViewOrder}
                  />
                  <Route
                    path="/orders"
                    isPrivate
                    exact
                    component={ListOrders}
                  />
                  <Route path="/client" isPrivate exact component={Client} />
                  <Route
                    path="/statistics"
                    isPrivate
                    exact
                    component={Statistics}
                  />
                  <Redirect to="/" />
                </Switch>
              )}
            </AnimatePresence>
          )}
        />
      </Router>
    ),
    [authLoading, signed],
  );

  return router;
};

export { Routes };
