import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  forwardRef,
} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MaterialUISelect from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useField } from '@unform/core';
import { v4 as uuidv4 } from 'uuid';
import { OutlinedInput } from '@material-ui/core';

// Style import
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Container } from './styles';

// Interfaces
interface ISelectOptions {
  value: any;
  label: any;
}

interface ISelectRef extends HTMLInputElement {
  show: () => any;
  hide: () => any;
}

interface IProps {
  name?: string | null;
  value?: any;
  standalone?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  hide?: boolean;
  placeholder?: string | null;
  error?: string | null;
  className?: string;

  variant?: 'filled' | 'outlined' | 'standard';
  margin?: 'normal' | 'dense' | 'none';
  noFullWidth?: boolean;
  size?: 'small' | 'medium';
  options: ISelectOptions[];

  label?: string | null;
  helpLabel?: string | null;

  onChange?: (value: any) => any;
  onFocus?: (event: React.FocusEvent) => any;
  onOpen?: () => any;
  onClose?: () => any;
}

// Material UI styles
const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: '100%',
    },
  }),
);

const Select = forwardRef<ISelectRef, IProps>(
  (
    {
      name,
      value: originalValue,
      standalone,
      readOnly,
      disabled,
      hide,
      placeholder,
      error,
      className,

      variant,
      margin,
      noFullWidth,
      size,

      options,

      label,
      helpLabel,

      onChange,
      onFocus,
      onOpen,
      onClose,

      ...rest
    },
    ref,
  ) => {
    // Register field name
    const {
      fieldName,
      defaultValue,
      registerField,
      error: formError,
      clearError,
    } = name && !standalone // eslint-disable-next-line react-hooks/rules-of-hooks
      ? useField(name)
      : ({} as ReturnType<typeof useField>);

    // Material UI styles
    const classes = useStyles();

    // Local states
    const [value, setValue] = useState<any>(
      originalValue || defaultValue || '',
    );
    const [open, setOpen] = useState(false);

    // Local refs
    const inputRef = useRef<ISelectRef>(null);

    // Merge external ref with local ref
    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === 'function') ref(inputRef.current);
        else ref.current = inputRef.current;
      }
    }, [inputRef, ref]);

    // UUid to key
    const labelId = uuidv4();

    // Register field on unform
    useEffect(() => {
      if (name && !standalone)
        registerField({
          name: fieldName,
          getValue: () => value,
          setValue: (_: any, newValue: any) => setValue(newValue),
          clearValue: () => setValue(null),
        });
    }, [registerField, name, standalone, fieldName, value]);

    // Set local value when external value changes
    useEffect(() => {
      setValue(originalValue);
    }, [originalValue]);

    // Handle input change
    const handleChange = useCallback(
      (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value);
        if (typeof onChange === 'function') onChange(event.target.value);
      },
      [onChange],
    );

    // Handle input focus
    const handleFocus = useCallback(
      (event: React.FocusEvent) => {
        if (typeof clearError === 'function') clearError();
        if (typeof onFocus === 'function') onFocus(event);
      },
      [clearError, onFocus],
    );

    // Handle dropdown open
    const handleOpen = useCallback(() => {
      setOpen(true);
      if (typeof onOpen === 'function') onOpen();
    }, [onOpen]);

    // Handle dropdown close
    const handleClose = useCallback(() => {
      setOpen(false);
      if (typeof onClose === 'function') onClose();
    }, [onClose]);

    // Assign methods to select ref
    useEffect(() => {
      const selectInstance = inputRef.current;
      if (selectInstance) {
        selectInstance.show = handleOpen;
        selectInstance.hide = handleClose;
      }
    }, [inputRef, handleOpen, handleClose]);

    return (
      <Container
        className={className}
        hide={!!hide}
        fullWidth={!noFullWidth}
        disabled={!!disabled}
        readOnly={!!readOnly}
        error={!!error || !!formError}
      >
        <FormControl
          className={classes.formControl}
          disabled={disabled}
          error={!!error || !!formError}
          variant={variant}
          size={size}
          margin={margin}
        >
          {label && (
            <InputLabel
              shrink={value !== undefined && value !== null}
              id={labelId}
            >
              {label}
            </InputLabel>
          )}
          <MaterialUISelect
            {...rest}
            inputRef={inputRef}
            name={name || undefined}
            labelId={label ? labelId : undefined}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            displayEmpty={!!placeholder}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            inputProps={{
              readOnly,
            }}
            input={
              variant === 'outlined' ? (
                <OutlinedInput
                  notched={value !== undefined && value !== null}
                  label={label}
                />
              ) : undefined
            }
            fullWidth={!noFullWidth}
            variant={variant || undefined}
          >
            {placeholder && (
              <MenuItem value="" disabled>
                {placeholder}
              </MenuItem>
            )}
            {options.map(option => (
              <MenuItem key={uuidv4()} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </MaterialUISelect>
          {(error || formError || helpLabel) && (
            <FormHelperText>{error || formError || helpLabel}</FormHelperText>
          )}
        </FormControl>
      </Container>
    );
  },
);

Select.defaultProps = {
  name: null,
  value: undefined,
  standalone: false,
  readOnly: false,
  disabled: false,
  hide: false,
  placeholder: null,
  error: null,

  variant: 'outlined',
  margin: 'normal',
  noFullWidth: false,
  size: 'small',

  label: null,
  helpLabel: null,

  onChange: undefined,
  onFocus: undefined,
};

export type { ISelectOptions, ISelectRef };
export { Select };
