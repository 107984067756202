import React, { useContext } from 'react';
import { ShepherdTourContext } from 'react-shepherd';

import { OnboardingTourButtonContent } from './styles';

// Interfaces
interface IProps {
  reference: React.RefObject<HTMLButtonElement>;
  completeTourKey: string;
  cancelTourKey?: string;
}

const OnboardingTourButton: React.FC<IProps> = ({
  reference,
  completeTourKey,
  cancelTourKey,
}) => {
  const tour = useContext(ShepherdTourContext);

  tour?.on('complete', () => {
    localStorage.setItem(completeTourKey, 'seen');
  });

  if (cancelTourKey) {
    tour?.on('cancel', () => {
      const currentBagTourCancelNumber = Number(
        localStorage.getItem(cancelTourKey),
      );

      if (currentBagTourCancelNumber) {
        localStorage.setItem(
          cancelTourKey,
          (currentBagTourCancelNumber + 1).toString(),
        );
      } else {
        localStorage.setItem(cancelTourKey, '1');
      }
    });
  }

  return (
    <OnboardingTourButtonContent
      onClick={tour?.start}
      type="button"
      ref={reference}
    >
      Start Bag Tour
    </OnboardingTourButtonContent>
  );
};

export { OnboardingTourButton };
