import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  flex: 1;

  margin-top: 1rem;

  .loading {
    overflow: hidden;
  }

  .camera {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    margin-top: 1rem;

    margin-bottom: 0.25rem;
    border-radius: 0.25rem;
    overflow: hidden;
    height: 250px;
    width: 250px;

    .warn {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.font_primary};
      text-align: center;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    span {
      z-index: 3;
      background: ${({ theme }) => theme.background_danger};
      box-shadow: 0 0 0.5rem ${({ theme }) => theme.background_danger};
      height: 0.1rem;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ${animation} infinite 1s;
    }

    video {
      z-index: 2;
      border-radius: 0.25rem;
    }

    button {
      z-index: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.background_secondary};
      text-align: center;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;

      position: absolute;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
