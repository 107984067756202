import React from 'react';
import { useSelector } from 'react-redux';

// Component import
import { Select } from '@components/Select';

// Hook import
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Feature identification
const featureKey = '@create_order/SELECT_BRAND_SECTION';

const SelectBrand: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const product = useAddProduct();

  // Global states
  const user = useSelector(state => state.auth);

  return (
    <Container>
      <Select
        label={t('BRAND', 'Marca')}
        disabled={
          product.loading ||
          (user.store?.brands.length === 1 &&
            product.selectedBrandCode === user.store.brands[0].brand.code)
        }
        value={product.selectedBrandCode}
        onChange={value => {
          product.setSelectedBrandCode(value);
        }}
        options={
          user.store?.brands
            ?.filter(
              store_brand => store_brand.enabled && store_brand.brand.enabled,
            )
            .map(({ brand }) => ({
              value: brand.code,
              label: brand.name,
            })) || []
        }
      />
    </Container>
  );
};

export { SelectBrand };
