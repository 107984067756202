import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${props => props.color};
  background-color: rgba(0, 0, 0, 0.7);

  .disableSummary {
    position: absolute;
    left: 0;
    height: -webkit-fill-available;
    width: calc(100% - 28rem);
  }

  .summaryArea {
    position: absolute;
    right: 0;
    width: 28rem;
    height: -webkit-fill-available;
    background-color: ${({ theme }) => theme.background_low};
    display: flex;
    flex-direction: column;
    .loading {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: -webkit-fill-available;
    }
    .orderSummaryComponent {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-height: 100%;
      gap: 1.5rem;

      padding: 1rem;
      align-self: stretch;

      overflow: hidden;
      overflow-y: scroll;

      /* width */
      ::-webkit-scrollbar {
        width: 0.25rem;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: none;
      }
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.background_low};
        border-radius: 0.25rem;
      }
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      .orderSummaryHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .backButton {
          button {
            p {
              font-weight: 400;
              text-decoration: underline;
              font-size: 0.75rem;
              color: ${({ theme }) => theme.font_danger};
            }
          }
        }
        .closeButton {
          button {
            color: ${({ theme }) => theme.font_high};
            svg {
              font-size: 1.5rem;
            }
          }
        }
      }
      .titleRow {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;
        /* justify-content: flex-start; */
        /* align-items: center; */
        .title {
          color: ${({ theme }) => theme.font_secondary};
          font-size: 1.25rem;
          font-weight: 600;
        }

        .salespersonRow {
          color: ${({ theme }) => theme.font_secondary};
          font-size: 0.875rem;
          font-weight: 500;
        }

        .info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 1rem;
          border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};

          font-weight: 500;
          font-size: 0.875rem;
        }
      }
      .outOfStockRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 0.063rem ${({ theme }) => theme.font_secondary};
        padding-bottom: 1rem;

        .outOfStockButton {
          width: 45%;
          button {
            p {
              color: ${({ theme }) => theme.font_high};
            }
          }
        }

        .text {
          font-weight: 600;
          font-size: 1rem;
          color: ${({ theme }) => theme.font_danger};
          width: 55%;
        }
      }
      .orderSummaryContent {
        max-height: 100vh;
        .packages {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          height: 100%;
          max-height: inherit;
          width: 100%;

          .package {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding-bottom: 1rem;
            border-bottom: 0.063rem solid ${({ theme }) => theme.font_secondary};
            .packageTitle {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 1.25rem;
              color: ${({ theme }) => theme.font_secondary};
              margin-bottom: 0.5rem;

              p {
                font-weight: 600;
                font-size: 1rem;
                color: ${({ theme }) => theme.font_secondary};
                margin-left: 0.5rem;
              }
            }
            .packageContent {
              p {
                font-weight: 500;
                font-size: 0.875rem;
                color: ${({ theme }) => theme.font_secondary};
              }
            }
            .productCard {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              align-self: stretch;
              background: ${({ theme }) => theme.background_primary};
              border-radius: 0.5rem;
              width: 100%;
              min-height: 8.75rem;
              margin-right: 0.5rem;

              .image {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: stretch;
                align-self: stretch;
                max-width: 6rem;

                img {
                  border-top-left-radius: 0.5rem;
                  border-bottom-left-radius: 0.5rem;
                  object-fit: cover;
                  flex: 1;
                  align-self: stretch;
                  max-width: 6rem;
                }
              }

              .info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                height: 100%;
                width: 100%;
                padding: 2rem 1rem;
                overflow: hidden;
                gap: 0.5rem;

                .outOfStock {
                  p {
                    color: ${({ theme }) => theme.font_primary};
                    background-color: ${({ theme }) => theme.background_danger};
                    border-radius: 1.25rem;
                    font-weight: 400;
                    font-size: 0.75rem;
                    padding: 0.25rem 1rem;
                  }
                }

                .name {
                  font-size: 0.875rem;
                  font-weight: 400;
                  color: ${({ theme }) => theme.font_primary};
                  width: -webkit-fill-available;
                  margin-bottom: 0.25rem;
                  P {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .sizeAndQuantityRow {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  gap: 2rem;
                  .size {
                    p {
                      font-weight: 400;
                      font-size: 0.75rem;
                    }
                  }
                  .quantity {
                    p {
                      font-weight: 400;
                      font-size: 0.75rem;
                    }
                  }
                }

                .price {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 1rem;

                  font-size: 1rem;
                  font-weight: 400;
                  color: ${({ theme }) => theme.font_primary};

                  .original_price {
                    text-decoration: line-through;
                    color: ${({ theme }) => theme.button_secondary};
                  }
                }
              }

              .action {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-right: 1.75rem;
                button {
                  color: ${({ theme }) => theme.font_danger};
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Footer = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  width: 28rem;
  /* min-height: -webkit-fill-available; */
  background-color: ${({ theme }) => theme.background_low};
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding: 1rem;
  gap: 1.5rem;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding-top: 1.5rem;
    border-top: 0.063rem solid ${({ theme }) => theme.font_secondary};

    .infoRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #bdbdbd;
      font-size: 0.875rem;
      font-weight: 400;

      .firstInfo {
        font-weight: 500;
      }

      .coupon {
        text-decoration-line: underline;
      }

      .discountValue {
        color: ${({ theme }) => theme.font_success};
      }
    }
  }

  .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 1.25rem;
      font-weight: 600;
      color: ${({ theme }) => theme.font_primary};
    }
  }
`;
