import React, {
  useRef,
  useState,
  useCallback,
  forwardRef,
  useMemo,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { FormHandles, SubmitHandler } from '@unform/core';

// Constant import
import { supportedCardBrands } from '@constants/supportedCardBrands';

// Component import
import { LoadingIndicator } from '@components/LoadingIndicator';
import { Button } from '@components/Button';
import { Select } from '@components/Select';
import { Input } from '@components/Input';
import { IModalRef, Modal } from '@components/Modal';

// Hook import
import { useTranslation } from '@hooks/useTranslation';
import { useForwardRef } from '@hooks/useForwardRef';
import { useOrder } from '@hooks/useOrder';

// Service import
import { api, getResponseError } from '@services/api';

// Store import
import { IOrderPayment } from '@store/modules/auth/types';

// Util import
import { getFormErrors } from '@util/getFormErrors';

// Style import
import { Container } from './styles';

// Interfaces
interface IManualPhysicalCardModalRef {
  handle: (payment_id: string) => void;
  hide: () => any;
}

// Feature identification
const featureKey = '@order/MANUAL_PHYSICAL_CARD_PAYMENT';

const ManualPhysicalCardPayment = forwardRef<IManualPhysicalCardModalRef>(
  (_, forwardedRef) => {
    // Hooks
    const { t } = useTranslation(featureKey);
    const order = useOrder();

    // Local states
    const [payment, setPayment] = useState<IOrderPayment | undefined>();
    const [addingPayment, setAddingPayment] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cardType, setCardType] = useState('');
    const paymentIndex = useMemo(
      () =>
        order.data?.order.payments.findIndex(
          item => !!payment && item.id === payment?.id,
        ),
      [order, payment],
    );

    // Local refs
    const modalRef = useRef<IModalRef>(null);
    const formRef = useRef<FormHandles>(null);

    // Main actions
    const handle = useCallback(
      (payment_id: string) => {
        if (payment_id && order.data?.order.id) {
          const selected = order.data.order.payments.find(
            item => item.id === payment_id,
          );

          if (selected) {
            setPayment(selected);
            modalRef.current?.show();
          }
        }
      },
      [order],
    );
    const hide = useCallback(() => {
      modalRef.current?.hide();
    }, []);

    // Merge local ref with forward ref
    useForwardRef(forwardedRef, {
      handle,
      hide,
    });

    // Submit handler
    const handleSubmit: SubmitHandler<{
      type: string;
      brand: string;
      installments: number;
      code: string;
      amount: string;
    }> = useCallback(
      async data => {
        // Clear form errors
        if (formRef.current) formRef.current.setErrors({});

        // Required procedure data
        if (!order.data || !payment) return;

        // Setup a schema to be validated
        const schema = Yup.object()
          .shape({
            amount: Yup.mixed().required(
              t(
                'MANUAL_PHYSICAL_CARD_REQUIRED_AMOUNT',
                'O valor do pagamento é obrigatório.',
              ),
            ),
            type: Yup.string()
              .strict()
              .typeError(
                t(
                  'MANUAL_PHYSICAL_CARD_INVALID_CARD_TYPE_TYPE',
                  'O tipo do cartão é inválido, deveria ser alfanumérico.',
                ),
              )
              .oneOf(
                ['credit_card', 'debit_card'],
                t(
                  'MANUAL_PHYSICAL_CARD_INVALID_CARD_TYPE_OPTIONS',
                  'O tipo do cartão é inválido, deveria ser credit_card ou debit_card.',
                ),
              )
              .required(
                t(
                  'MANUAL_PHYSICAL_CARD_REQUIRED_CARD_TYPE',
                  'O tipo do cartão é obrigatório.',
                ),
              ),
            brand: Yup.string()
              .strict()
              .typeError(
                t(
                  'MANUAL_PHYSICAL_CARD_INVALID_CARD_BRAND_TYPE',
                  'O tipo da bandeira do cartão é inválido, deveria ser alfanumérico.',
                ),
              )
              .required(
                t(
                  'MANUAL_PHYSICAL_CARD_REQUIRED_CARD_BRAND',
                  'A bandeira do cartão é obrigatória.',
                ),
              ),
            installments: Yup.mixed(),
            code: Yup.string()
              .strict()
              .typeError(
                t(
                  'MANUAL_PHYSICAL_CARD_INVALID_CARD_CODE_TYPE',
                  'O tipo do código do comprovante do cartão é inválido, deveria ser alfanumérico.',
                ),
              )
              .required(
                t(
                  'MANUAL_PHYSICAL_CARD_REQUIRED_CARD_CODE',
                  'O código do comprovante é obrigatório.',
                ),
              ),
          })
          .noUnknown(true, t('INVALID_REQUEST', 'Requisição inválida.'));

        try {
          await schema.validate(data, {
            abortEarly: false,
            stripUnknown: false,
          });
        } catch (err: any) {
          getFormErrors(err, ({ form, errors }) => {
            formRef.current?.setErrors(form);
            toast.error(errors[0]);
          });
          return;
        }

        setLoading(true);

        try {
          // API call
          await api.post('/providers/manualphysicalcardpayment', {
            amount: Number(String(data.amount).match(/\d+/g)?.join('') || ''),
            order_id: order.data.order.id,
            payment_id: payment.id,
            payload: {
              type: data.type,
              code: data.code,
              brand: data.brand,
              installments: data.installments ? Number(data.installments) : 1,
            },
          });

          // Display message to user
          const message = t(
            'MANUAL_PHYSICAL_CARD_ADD_PAYMENT_SUCCESS',
            'Pagamento adicionado.',
          );
          toast.success(message);

          setLoading(false);

          // Close modal
          if (typeof hide === 'function') hide();
        } catch (err: any) {
          const { message } = getResponseError(err, t);
          toast.error(message);
          setLoading(false);
        }
      },
      [hide, order.data, payment, t],
    );

    // Handle remove
    const handleRemove = useCallback(
      async (charge_id: string) => {
        // Required procedure data
        if (!order.data || !payment) return;

        setLoading(true);

        try {
          // API call
          await api.put('/providers/manualphysicalcardpayment', {
            order_id: order.data.order.id,
            payment_id: payment.id,
            charge_id,
          });

          setLoading(false);

          // Display message to user
          const message = t(
            'MANUAL_PHYSICAL_CARD_REMOVE_PAYMENT_SUCCESS',
            'Pagamento removido.',
          );
          toast.success(message);

          // Close modal
          if (typeof hide === 'function') hide();
        } catch (err: any) {
          const { message } = getResponseError(err, t);
          toast.error(message);
          setLoading(false);
        }
      },
      [hide, order.data, payment, t],
    );

    // Build add payment UI
    const addPayment = useMemo(
      () => (
        <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
          <Select
            name="type"
            disabled={loading}
            options={[
              {
                label: t('DEBIT_CARD', 'Cartão de Crédito'),
                value: 'credit_card',
              },
              {
                label: t('CREDIT_CARD', 'Cartão de Débito'),
                value: 'debit_card',
              },
            ]}
            label={t('METHOD', 'Método')}
            onChange={(value: any) => setCardType(String(value))}
          />
          {!!cardType && (
            <>
              <Select
                name="brand"
                disabled={loading}
                options={supportedCardBrands}
                label={t('FLAG', 'Bandeira')}
              />
              {cardType === 'credit_card' && (
                <Select
                  name="installments"
                  label={t('INSTALLMENTS', 'Parcelas')}
                  options={[
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 },
                    { label: '7', value: 7 },
                    { label: '8', value: 8 },
                    { label: '9', value: 9 },
                    { label: '10', value: 10 },
                    { label: '11', value: 11 },
                    { label: '12', value: 12 },
                  ]}
                />
              )}
              <Input
                name="code"
                disabled={loading}
                label={t('AUTHORIZATION_CODE', 'Código de Autorização')}
              />
              <Input
                name="amount"
                disabled={loading}
                label={t('PAYMENT_AMOUNT', 'Valor do Pagamento')}
                type="tel"
                inputMode="numeric"
                pattern="[0-9]*"
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let { value } = e.target;

                  value = value.replace(/\D+/g, '');
                  value = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(Number(value) / 100);

                  e.target.value = value;
                  return e;
                }}
              />
            </>
          )}
          <div className="actions">
            <Button
              color="success"
              onClick={() => formRef.current?.submitForm()}
            >
              {t('REGISTRY', 'REGISTRAR')}
            </Button>
            <Button
              color="info"
              variant="outlined"
              onClick={() => setAddingPayment(false)}
            >
              {t('RETURN_TO_LIST', 'RETORNAR A LISTAGEM')}
            </Button>
          </div>
        </Form>
      ),
      [cardType, handleSubmit, loading, t],
    );

    // Build charges UI (one by payment method)
    const charges = (
      <ul>
        {payment?.charges.map((charge, index) => (
          <li key={charge.id}>
            <span className="count">#{index + 1}</span>
            <div className="info">
              <span className={`value${charge.canceled_at ? ' canceled' : ''}`}>
                R$ {(charge.amount / 100).toFixed(2)}
              </span>
            </div>
            <div className="actions">
              {!charge.canceled_at && (
                <Button
                  color="danger"
                  variant="outlined"
                  onClick={() => handleRemove(charge.id)}
                >
                  {t('CANCEL', 'CANCELAR')}
                </Button>
              )}
            </div>
          </li>
        ))}
      </ul>
    );

    // Sum charges
    const paymentPaidAmount =
      payment?.charges.reduce(
        (total, current) =>
          current.canceled_at ? total : total + current.amount,
        0,
      ) || 0;

    // Status code
    const paymentCode = payment?.canceled_at
      ? 'canceled'
      : payment?.completed_at || paymentPaidAmount >= (payment?.amount || 0)
      ? 'paid'
      : 'pending';

    // Status message
    const paymentStatus = payment?.canceled_at
      ? t('PAYMENT_CANCELED', 'Cancelado')
      : payment?.completed_at || paymentPaidAmount >= (payment?.amount || 0)
      ? t('PAYMENT_COMPLETED', 'Pago')
      : t('PAYMENT_PEDING', 'Aguardando pagamento');

    return (
      <Modal ref={modalRef} size="small">
        {!payment?.id ||
        !order.data ||
        typeof paymentIndex !== 'number' ||
        paymentIndex < 0 ? (
          <Container>
            <LoadingIndicator />
          </Container>
        ) : (
          <Container>
            <div className="head">
              <div className="left">
                <span className="count">
                  {t('PAYMENT_HEADER', 'Pagamento')} #{paymentIndex + 1}
                </span>
                <span className="type">{payment.payment_type.name}</span>
                <span className={`status ${paymentCode}`}>{paymentStatus}</span>
              </div>
              <div className="right">
                <div className="total">
                  <span className="label">{t('PAYMENT_TOTAL', 'Total')}</span>
                  <span className="value">
                    R$ {(payment.amount / 100).toFixed(2)}
                  </span>
                </div>
                <div className="paid">
                  <span className="label">{t('PAYMENT_PAID', 'Pago')}</span>
                  <span className="value">
                    R$ {(paymentPaidAmount / 100).toFixed(2)}
                  </span>
                </div>
                <div className="left">
                  <span className="label">{t('PAYMENT_LEFT', 'A pagar')}</span>
                  <span className="value">
                    R$ {((payment.amount - paymentPaidAmount) / 100).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
            {addingPayment ? (
              addPayment
            ) : (
              <>
                {charges}
                {!order.data?.status.is_canceled && (
                  <Button
                    color="success"
                    onClick={() => setAddingPayment(true)}
                  >
                    {t('REGISTER_PAYMENT', 'INFORMAR PAGAMENTO')}
                  </Button>
                )}
              </>
            )}
          </Container>
        )}
      </Modal>
    );
  },
);

export type { IManualPhysicalCardModalRef };
export { ManualPhysicalCardPayment };
