import React, {
  useState,
  forwardRef,
  useLayoutEffect,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { MdClose } from 'react-icons/md';

// Portal import
import { Portal } from '../Portal';

// Style import
import { Container } from './styles';

// Interfaces
interface IModalRef {
  show: () => void;
  hide: () => void;
}

interface IModalProps {
  size?:
    | 'smaller'
    | 'small-2'
    | 'small-1'
    | 'small'
    | 'normal'
    | 'large'
    | 'auto';
  title?: string;
  description?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  onShow?: () => void;
  onHide?: () => void;
  blockHide?: boolean;
  autoWidth?: boolean;
}

const Modal = forwardRef<IModalRef, IModalProps>((props, ref) => {
  // Get modal props
  const {
    size,
    title,
    description,
    children,
    footer,
    onShow,
    onHide,
    blockHide,
    autoWidth,
  } = props;

  // Modal ref
  const modalRef = useRef<HTMLDivElement & IModalRef>(null);

  // Local states
  const [open, setOpen] = useState(false);

  // Merge external ref with local ref
  useLayoutEffect(() => {
    if (ref) {
      if (typeof ref === 'function') ref(modalRef.current);
      else ref.current = modalRef.current;
    }
  }, [modalRef, ref]);

  // Modal actions
  const hide = useCallback(() => {
    setOpen(false);
    if (typeof onHide === 'function') onHide();
  }, [onHide]);

  const show = useCallback(() => {
    setOpen(true);
    if (typeof onShow === 'function') onShow();
  }, [onShow]);

  // Close modal on click outside
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (e.target === modalRef.current && !blockHide) {
        hide();
      }
    },
    [hide, blockHide],
  );

  // Assign methods to modal ref
  useEffect(() => {
    const modalInstance = modalRef.current;
    if (modalInstance) {
      modalInstance.addEventListener('click', handleClick);

      modalInstance.show = show;
      modalInstance.hide = hide;
    }

    return () => {
      if (modalInstance)
        modalInstance.removeEventListener('click', handleClick);
    };
  });

  return (
    <Portal>
      <Container ref={modalRef} open={open} size={size} autoWidth={autoWidth}>
        <div className="modal">
          {title && (
            <div className="header">
              <div className="title">
                <h2>{title}</h2>
                <small>{description}</small>
              </div>
              {!blockHide && (
                <button type="button" onClick={() => hide()}>
                  <MdClose size={20} />
                </button>
              )}
            </div>
          )}
          <div className="body">{children}</div>
          {footer && <div className="footer">{footer}</div>}
        </div>
      </Container>
    </Portal>
  );
});

Modal.defaultProps = {
  title: '',
  description: '',
  footer: undefined,
  autoWidth: false,
  onShow: undefined,
  onHide: undefined,
  blockHide: false,
  size: 'normal',
};

export type { IModalRef, IModalProps };
export { Modal };
