import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';

// Layout import
import { Layout } from '@layouts/default';

// Interfaces
interface IChildren extends RouteProps {
  component: any;
  isPrivate?: boolean;
}

const RouteWrapper = ({
  component: Component,
  isPrivate,
  ...rest
}: IChildren) => {
  // Global states
  const signed = useSelector(state => state.auth.signed);
  const user = useSelector(state => state.auth);

  // Private pages with unauthorized user
  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  /* prettier-ignore */
  window.dataLayer.push({
    'event': 'custom-dimension',
    'filial': `${user?.store?.code}`,
    'marca': `${user?.store?.brands?.[0]?.brand?.code}`,
    'perfil': `${user?.type_profile}`,
    'codigo-vendedor': `${user?.sale_codes?.[0]?.code}`,
  });

  // Public page with authorized user
  if (signed && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  // Private route
  if (signed && isPrivate) {
    return (
      <Route
        {...rest}
        render={routeProps => (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        )}
      />
    );
  }

  // Public route
  // !signed && !isPrivate
  return <Route component={Component} {...isPrivate} {...rest} />;
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

export { RouteWrapper as Route };
