import React, { useRef, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import * as Yup from 'yup';

// Constant import
import { forgotPasswordUrl } from '@constants/forgotPasswordUrl';

// Asset impoort
import { ReactComponent as Logo } from '@assets/soma.svg';

// Component import
import { Input } from '@components/Input';
import { Button } from '@components/Button';
import { Checkbox } from '@components/Checkbox';
import { Modal, IModalRef } from '@components/Modal';

// Hook import
import { useTheme } from '@hooks/useTheme';
import { usePagePath } from '@hooks/usePagePath';
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Action import
import { signFailure, signInSuccess } from '@store/modules/auth/actions';

// Util import
import { getFormErrors } from '@util/getFormErrors';

// Style import
import { Container, PasswordVisibleButton, ModalContent } from './styles';

// Feature identification
const featureKey = '@session/LOGIN';

const Login: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const dispatch = useDispatch();
  const theme = useTheme();

  // Local refs
  const formRef = useRef<FormHandles>(null);
  const modalPasswordRef = useRef<IModalRef>(null);
  const inputPasswordRef = useRef<HTMLInputElement>(null);

  // Local states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  // Submit data
  const handleSubmit: SubmitHandler<{
    login: string;
    password: string;
    persist: boolean;
  }> = useCallback(
    async data => {
      // Clear form errors
      if (formRef.current) formRef.current.setErrors({});

      // Setup a schema to be validated
      const schema = Yup.object()
        .shape({
          login: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_LOGIN_TYPE',
                'O login deve ser composto por caracteres alfanuméricos.',
              ),
            )
            .required(t('REQUIRED_LOGIN', 'Informe o login.')),
          password: Yup.string()
            .strict()
            .typeError(
              t(
                'INVALID_PASSWORD_TYPE',
                'A senha deve ser composta por caracteres alfanuméricos.',
              ),
            )
            .required(t('REQUIRED_PASSWORD', 'Informe uma senha.')),
          persist: Yup.boolean()
            .strict()
            .typeError(
              t(
                'INVALID_PERSIST_TYPE',
                'A informação sobre manutenção de sessão é inválida.',
              ),
            ),
        })
        .noUnknown(true, t('INVALID_REQUEST', 'Requisição inválida.'));

      try {
        await schema.validate(data, {
          abortEarly: false,
          stripUnknown: false,
        });
      } catch (err: any) {
        getFormErrors(err, ({ form, errors }) => {
          if (formRef.current) formRef.current.setErrors(form);
          toast.error(errors[0]);
        });

        return;
      }

      // Get data from request
      const { login, password, persist } = data;

      setLoading(true);

      try {
        // Call API
        const response = await api.post('/session', {
          login,
          password,
        });

        // Handle sign success
        dispatch(signInSuccess(response.data, !!persist));

        setLoading(false);
      } catch (err: any) {
        const response = getResponseError(err, t);

        if (response.status === 401 && response.message) {
          toast.error(response.message);
          formRef.current?.setErrors({
            login: response.message,
            password: response.message,
          });
          setError(true);
        } else toast.error(response.message);

        // Handle sign failure
        dispatch(signFailure());

        setLoading(false);
      }
    },
    [t, dispatch],
  );

  return (
    <Container>
      <div className="login">
        <div className="logo">
          <Logo fill={theme.button_high} />
        </div>
        <Form ref={formRef} onSubmit={handleSubmit} autoComplete="off">
          <Input
            disabled={loading}
            type="email"
            autoCorrect="off"
            autoCapitalize="none"
            name="login"
            label={t('LOGIN', 'Login')}
            onEnterKey={() => inputPasswordRef.current?.focus()}
            onChange={() => setError(false)}
          />
          <Input
            ref={inputPasswordRef}
            onEnterKey={() => formRef.current?.submitForm()}
            name="password"
            disabled={loading}
            label={t('PASSWORD', 'Senha')}
            type={passwordVisible ? 'text' : 'password'}
            endAdornment={
              <PasswordVisibleButton
                type="button"
                onClick={() => {
                  if (!loading) setPasswordVisible(!passwordVisible);
                }}
              >
                {passwordVisible ? (
                  <MdVisibilityOff color={theme.font_secondary} size={25} />
                ) : (
                  <MdVisibility color={theme.font_secondary} size={25} />
                )}
              </PasswordVisibleButton>
            }
            onChange={() => setError(false)}
          />
          <Checkbox
            disabled={loading}
            name="persist"
            defaultChecked
            label={t('KEEP_SESSION', 'Mantenha-me conectado')}
          />
        </Form>
        <button
          className="forgot"
          type="button"
          disabled={loading}
          onClick={() => modalPasswordRef.current?.show()}
        >
          {t('UNKNOWN_PASSWORD', 'Não sabe sua senha?')}
        </button>
        <Button
          onClick={() => formRef.current?.submitForm()}
          loading={loading}
          disabled={error}
        >
          {t('SUBMIT', 'ENTRAR')}
        </Button>
      </div>
      <Modal size="small" ref={modalPasswordRef}>
        <ModalContent>
          <p>
            {t(
              'FORGOT_PASSWORD_DESCRIPTION',
              'Suas credenciais para acesso ao Soma Store são as mesmas para o +Vendas.',
            )}
          </p>
          <Button
            disabled={loading}
            href={forgotPasswordUrl}
            noFullWidth
            target="_blank"
          >
            {t('FORGOT_PASSWORD_SUBMIT', 'Recuperar senha do + Vendas')}
          </Button>
          <Button
            disabled={loading}
            onClick={() => modalPasswordRef.current?.hide()}
            noFullWidth
            noMargin
            variant="text"
          >
            {t('FORGOT_PASSWORD_RETURN', 'Voltar')}
          </Button>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export { Login };
