import styled from 'styled-components';

export const Container = styled.ul`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-self: stretch;
  gap: 1rem;

  @media screen and (max-width: 1800px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media screen and (max-width: 768px) {
    li {
      .info {
        .bottom {
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
        }
      }

      .image {
        max-width: 6rem;

        img {
          max-width: 6rem;
        }
      }
    }
  }
`;

export const ProductSectionDesktop = styled.div`
  display: flex;
  flex-direction: column;

  .productSectionTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 30%;

    color: ${({ theme }) => theme.font_secondary};
    font-size: 1.25rem;
    font-weight: 600;

    p {
      margin-left: 0.75rem;
    }
  }

  .productItemRow {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-bottom: 1rem;
  }

  .divider {
    width: 100%;
    border-top: 0.063rem solid ${({ theme }) => theme.font_secondary};
    margin-top: 2.5rem;
    border-radius: 0.125rem;
  }
`;

export const ProductSectionMobile = styled.div`
  display: flex;
  flex-direction: column;

  .productSectionTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 50%;
    margin-bottom: 1.5rem;

    color: ${({ theme }) => theme.font_secondary};
    font-size: 1rem;
    font-weight: 600;

    p {
      margin-left: 0.75rem;
    }
  }

  .productItemRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    gap: 1rem;

    /* width */
    ::-webkit-scrollbar {
      height: 0.5rem;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: none;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.background_secondary};
      border-radius: 0.25rem;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .divider {
    width: 100%;
    border-top: 0.063rem solid ${({ theme }) => theme.font_secondary};
    margin-top: 1rem;
    border-radius: 0.125rem;
  }
`;

export const ProductItemDesktop = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  background: ${({ theme }) => theme.background_primary};
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  min-height: 11.688rem;
  margin-right: 0.5rem;

  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    align-self: stretch;
    max-width: 8.625rem;

    img {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      object-fit: cover;
      flex: 1;
      align-self: stretch;
      max-width: 8.625rem;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    height: 100%;
    width: 100%;
    padding: 1rem;
    overflow: hidden;

    .name {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.font_primary};
      width: -webkit-fill-available;
      margin-bottom: 0.25rem;
      P {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .infoRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
      margin-bottom: 0.25rem;

      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
      font-weight: 400;

      p:first-child {
        margin-right: 1rem;
      }
    }

    .colorAndSizeRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;

      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
      font-weight: 400;

      button {
        display: flex;
        flex-direction: row;

        .changeButtonText {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.font_primary};
          text-decoration: underline;
          margin-right: 0.125rem;
        }
      }
    }

    .price {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      font-size: 1rem;
      color: ${({ theme }) => theme.font_primary};

      .original_price {
        text-decoration: line-through;
        color: ${({ theme }) => theme.button_secondary};
      }
    }
    .unavailablePill {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      p {
        background-color: ${({ theme }) => theme.background_danger};
        padding: 0.5rem 1.5rem;
        border-radius: 1.25rem;
        font-weight: 500;
      }
    }

    .editDelivery {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      button {
        p {
          font-size: 0.75rem;
          font-weight: 400;
          text-decoration: underline;
          color: ${({ theme }) => theme.font_secondary};
        }
      }
    }

    .quantityRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      gap: 1rem;

      p {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.font_secondary};
        margin-right: 1rem;
      }

      .quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.background_high};
        border-radius: 0.25rem;

        button {
          min-width: auto;
          width: 1.2rem;
          height: 1.2rem;
        }

        .number {
          color: ${({ theme }) => theme.font_low};
          padding: 0 0.5rem;
        }
      }
    }
  }
`;

export const ProductItemMobile = styled.li`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-self: stretch;
  background: ${({ theme }) => theme.background_primary};
  border-radius: 0.5rem;
  min-height: 11.688rem;

  .topRow {
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .image {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      align-self: stretch;
      max-width: 7rem;
      max-height: 10rem;

      img {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        object-fit: cover;
        flex: 1;
        align-self: stretch;
        max-width: 7rem;
        max-height: 10rem;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* justify-content: space-between; */
      gap: 0.5rem;

      height: 11.688rem;
      width: 100%;
      padding: 1rem;
      overflow: hidden;

      .name {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.font_primary};
        width: -webkit-fill-available;
        margin-bottom: 0.25rem;
        P {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .infoRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin-bottom: 0.25rem;

        color: ${({ theme }) => theme.font_secondary};
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;

        p:first-child {
          margin-right: 1rem;
        }
      }

      .colorAndSizeRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;

        color: ${({ theme }) => theme.font_secondary};
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
      }

      .price {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;

        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.font_primary};

        .original_price {
          text-decoration: line-through;
          color: ${({ theme }) => theme.button_secondary};
        }
      }

      .editDelivery {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        button {
          display: flex;
          flex-direction: row;

          .changeButtonText {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            color: ${({ theme }) => theme.font_primary};
            text-decoration: underline;
            margin-right: 0.125rem;
          }

          p {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            text-decoration: underline;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }
    }
  }

  .bottomRow {
    padding: 0 0.75rem 1rem 0.75rem;
    .quantityRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      gap: 1rem;

      p {
        font-size: 0.875rem;
        line-height: 1rem;
        color: ${({ theme }) => theme.font_secondary};
        font-weight: 500;
        margin-right: 1rem;
      }

      .quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.background_high};
        border-radius: 0.25rem;

        button {
          min-width: auto;
          width: 1.75rem;
          height: 1.75rem;
        }

        .number {
          color: ${({ theme }) => theme.font_low};
          padding: 0 0.5rem;
        }
      }
    }

    .unavailablePill {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      p {
        background-color: ${({ theme }) => theme.background_danger};
        padding: 0.5rem 1.5rem;
        border-radius: 1.25rem;
        font-weight: 500;
      }
    }
  }
`;

export const Pill = styled.div`
  background-color: ${props => props.color};
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  margin: 0.25rem;
  width: fit-content;
  color: ${({ theme }) => theme.font_low};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    margin-left: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
`;
