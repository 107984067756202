import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { MdOutlineClose, MdWarning } from 'react-icons/md';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api, getResponseError } from '@services/api';

// Util import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  cartId: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMobileBagSummary: React.Dispatch<React.SetStateAction<boolean>>;

  // setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

// Feature identification
const featureKey = '@saved_bags/SAVED_BAGS_MODAL';

const DeleteUniqueBagModal: React.FC<IProps> = ({
  hide,
  cartId,
  setLoading,
  setMobileBagSummary,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  const deleteBag = useCallback(async () => {
    setLoading(true);

    try {
      // API call
      await api.delete(`/v1/cart/${cartId}`);

      setLoading(false);

      if (typeof hide === 'function') hide();
      setMobileBagSummary(false);
    } catch (err: any) {
      const { message } = getResponseError(err, t);
      toast.error(message);
      setLoading(false);
      throw err;
    }
  }, [cartId, hide, setLoading, setMobileBagSummary, t]);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="iconRow">
        <MdWarning />
      </div>
      <div className="textRow">
        <p className="text">
          {t('DELETE_BAG_ASK', 'Deseja excluir a sacola salva?')}
        </p>
        <p className="addtionalTextRow">
          {t('ADDITIONAL_INFO', 'Esta ação não poderá ser desfeita.')}
        </p>
      </div>

      <div className="actionArea">
        <Button
          variant="outlined"
          color="danger"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          {t('NO', 'NÃO')}
        </Button>
        <Button
          onClick={async () => {
            await deleteBag();
          }}
        >
          {t('YES', 'SIM')}
        </Button>
      </div>
    </Container>
  );
};

export { DeleteUniqueBagModal };
