import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { MdOutlineClose } from 'react-icons/md';

// Component import
import { Button } from '@components/Button';
import { Input } from '@components/Input';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { maisVendasApi } from '@services/maisVendasApi';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  showSecondStep?: any;
  setSelectedStep: React.Dispatch<
    React.SetStateAction<
      'salutation' | 'first_step' | 'second_step' | 'third_step' | 'farewell'
    >
  >;
  selectedSentiment:
    | 'very_dissatisfied'
    | 'dissatisfied'
    | 'neutral'
    | 'satisfied'
    | 'very_satisfied'
    | 'none';
  secondStepComment: string;
  thirdStepComment: string;
  setThirdStepComment(comment: string): void;
  setLoading(loading: boolean): void;
}

// Feature identification
const featureKey = '@satisfaction_survey/THIRD_STEP_MODAL';

const ThirdStepModal: React.FC<IProps> = ({
  hide,
  setSelectedStep,
  selectedSentiment,
  secondStepComment,
  thirdStepComment,
  setThirdStepComment,
  setLoading,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  // Local state
  const [response, setResponse] = useState(thirdStepComment);

  const date = new Date();

  const convertSentimentToNumber = (sentiment: string) => {
    const sentimentsList = [
      'very_dissatisfied',
      'dissatisfied',
      'neutral',
      'satisfied',
      'very_satisfied',
    ];
    const sentimentScore = sentimentsList.indexOf(sentiment) + 1;

    return sentimentScore;
  };

  const sendForm = async () => {
    setLoading(true);
    try {
      const sentimentToNumber = convertSentimentToNumber(selectedSentiment);
      await maisVendasApi.post('/soma-store-nps', {
        npsScore: sentimentToNumber,
        experienceReport: secondStepComment,
        improveExperience: response,
      });
      toast.success(t('SURVEY_SENT', 'Resposta enviada com sucesso'));
      localStorage.setItem('NPSAnswerDate', date.toJSON());
      setSelectedStep('farewell');
    } catch {
      toast.error(t('SURVEY_SEND_FAILURE', 'Erro ao enviar resposta'));
    }
    setLoading(false);
  };

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="titleRow">
        <p className="step">3/3</p>

        <p className="titleText">
          {t(
            'HOW_TO_IMPROVE',
            'Como podemos melhorar sua experiência com o Soma Store?',
          )}
        </p>
      </div>
      <div className="menuRow">
        <Input
          id="comment"
          color="primary"
          label="Seu comentário aqui"
          inputProps={{ maxLength: 250 }}
          multiline
          variant="outlined"
          fullWidth
          autoFocus
          style={{
            color: 'white',
            minHeight: '5.875rem',
            borderRadius: '0.5rem',
          }}
          value={response}
          onChange={(e: any) => setResponse(e)}
        />
      </div>
      <div className="actionArea">
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedStep('second_step');
            setThirdStepComment(response);
          }}
        >
          <p className="modalButton">{t('PREVIOUS', 'ANTERIOR')}</p>
        </Button>
        <Button
          onClick={() => {
            sendForm();
          }}
        >
          <p className="modalButton">{t('FINALIZE', 'FINALIZAR')}</p>
        </Button>
      </div>
    </Container>
  );
};

export { ThirdStepModal };
