import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { toast } from 'react-toastify';

// Asset import
import { InvoiceIcon, SwapHorizontalCircle } from '@assets/index';

// Component import
import { Button } from '@components/Button';
import { LoadingIndicator } from '@components/LoadingIndicator';
import { IModalRef } from '@components/Modal';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Service import
import { api } from '@services/api';

// Import types
import { IOrder } from '@store/modules/auth/types';

// Page import
import { ExchangeCoupon } from '../ExchangeCoupon';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  type: string;
  orderId?: string;
  invoiceURL?: string;
  orderData?: IOrder;
  invoiceLoading?: boolean;
  disconnectedPrinterModalRef: React.RefObject<IModalRef>;
}

// Feature identification
const featureKey = '@order/PRINT_FILE_MODAL';

const PrintFileModal: React.FC<IProps> = ({
  hide,
  type,
  orderId,
  invoiceURL,
  orderData,
  invoiceLoading,
  disconnectedPrinterModalRef,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  // Local states
  const [loading, setLoading] = useState(false);

  const renderIcon = () => {
    if (type === 'invoice') return <InvoiceIcon />;
    return <SwapHorizontalCircle />;
  };

  const renderTitle = () => {
    if (type === 'invoice')
      return <div className="iconText">{t('INVOICE', 'Nota Fiscal')}</div>;
    return (
      <div className="iconText">{t('EXCHANGE_COUPON', 'Cupom de Troca')}</div>
    );
  };

  const renderTextBody = () => {
    if (type === 'invoice') {
      return 'A nota fiscal';
    }
    return 'O cupom de troca';
  };

  const printFile = async () => {
    try {
      setLoading(true);
      await api.get(`order/${orderId}/print?type=${type}`);
      setLoading(false);
      toast.success('Impressão enviada');
    } catch {
      toast.error('Erro na tentativa de impressão, tente novamente mais tarde');
      setLoading(false);
      disconnectedPrinterModalRef.current?.show();
      // Hide modal
      if (typeof hide === 'function') hide();
    }
  };

  const renderDownloadButton = () => {
    if (type === 'invoice') {
      const buttonText = invoiceLoading ? (
        <LoadingIndicator size={1.5} />
      ) : (
        <>{t('DOWNLOAD', 'BAIXAR')}</>
      );
      return (
        <a
          className={`downloadButtonLink ${invoiceLoading ? 'disabled' : ''}`}
          href={invoiceURL}
          download={`NotaFiscal_${orderId}.pdf`}
        >
          {buttonText}
        </a>
      );
    }
    if (orderData) {
      return <ExchangeCoupon order={orderData} />;
    }
    return (
      <Button
        variant="outlined"
        onClick={() => toast.error('Erro baixando arquivo')}
      >
        {t('DOWNLOAD', 'BAIXAR')}
      </Button>
    );
  };

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      {loading ? (
        <div className="loadingIndicator">
          <LoadingIndicator size={5} />
        </div>
      ) : (
        <>
          <div className="iconRow">
            <div className="iconImage">
              <p>{renderIcon()}</p>
            </div>
            {renderTitle()}
          </div>
          <div className="textRow">
            <p>{renderTextBody()} já está disponível</p>
          </div>
        </>
      )}
      <div className="actionArea">
        <Button
          onClick={() => {
            printFile();
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
          disabled={loading}
        >
          <p className="modalButton">{t('PRINT', 'IMPRIMIR')}</p>
        </Button>
        {renderDownloadButton()}
      </div>
    </Container>
  );
};

export { PrintFileModal };
