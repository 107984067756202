import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Util import
import { wrap } from '@util/wrap';

// Style import
import { Container } from './styles';

const variants = {
  enter: {
    opacity: 0,
  },
  center: {
    zIndex: 1,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    opacity: 0,
  },
};
// Interfaces
interface IProps {
  images: string[];
}

const ImageCarousel: React.FC<IProps> = ({ images }) => {
  const [page, setPage] = useState(0);

  const imageIndex = wrap(0, images.length, page);

  useEffect(() => {
    const timeout = setTimeout(
      () => setPage(page + 1),
      Math.floor(Math.random() * 30000) + 10000,
    );
    return () => clearTimeout(timeout);
  }, [images, page]);

  return (
    <Container>
      <AnimatePresence initial={false}>
        <motion.img
          key={page}
          src={images[imageIndex]}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: { duration: 0.2 },
          }}
        />
      </AnimatePresence>
    </Container>
  );
};

export { ImageCarousel };
