import React, { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Component import
import { Input } from '@components/Input';
import { Button } from '@components/Button';

// Hook import
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useTranslation } from '@hooks/useTranslation';

// Util import
import {
  formatValueToString,
  getInitialStoreCreditValue,
  hasStoreCreditBeenSelected,
} from '@util/handleOrderValues';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  payment?: any;
}

// Feature identification
const featureKey = '@create_order/STORE_CREDIT_VALUE_MODAL';

const ValueModal: React.FC<IProps> = ({ hide, payment }) => {
  // Hooks
  const { t } = useTranslation(featureKey);
  const order = useOrderCreation();

  const initialInputValue = getInitialStoreCreditValue(
    order.totals,
    order.payments,
    payment,
  );

  const [inputValue, setInputValue] = useState(
    `${(initialInputValue / 100).toFixed(2)}`,
  );

  const [errorMessage, setErrorMessage] = useState(false);

  const handleSubmit = () => {
    const getInputValue = inputValue.replace(',', '.');
    const floatInput = parseFloat(getInputValue);
    const formattedInput = Math.round(floatInput * 100);
    const inputValueExceedsStoreCredit = formattedInput > payment.payload.total;
    const inputValueExceedsOrderTotal = formattedInput > order.totals.total;

    if (inputValueExceedsStoreCredit || inputValueExceedsOrderTotal) {
      setErrorMessage(true);
      return;
    }

    const newPaymentsList = order.payments;
    const hasStoreCreditAlreadyBeenSelected = hasStoreCreditBeenSelected(
      order.payments,
    );

    if (hasStoreCreditAlreadyBeenSelected) {
      newPaymentsList[0].amount = formattedInput;
    } else {
      newPaymentsList.unshift({
        code: payment.code,
        payload: {},
        amount: formattedInput,
        time_limit: payment.time_limit,
      });
    }

    if (newPaymentsList.length > 1) {
      if (formattedInput === order.totals.total) {
        newPaymentsList.pop();
      } else {
        if (newPaymentsList.length > 2) {
          newPaymentsList.pop();
        }
        newPaymentsList[1].amount = order.totals.total - formattedInput;
      }
    }

    order.setPayments(newPaymentsList);
    if (typeof hide === 'function') hide();
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    // Clear whitespaces
    e.currentTarget.value = e.currentTarget.value.trim();
    const editedInput = e.currentTarget.value.replace(/[^,\d]/g, '');
    const inputToNumber = (Number(editedInput) / 100).toFixed(2);

    e.currentTarget.value = editedInput;
    setInputValue(inputToNumber);
    return e;
  };

  const getErrorMessage = () => {
    const getInputValue = inputValue.replace(',', '.');
    const floatInput = parseFloat(getInputValue);
    const formattedInput = Math.round(floatInput * 100);
    if (formattedInput > payment.payload.total) {
      return (
        <p className="errorMessage">
          Valor excede o valor disponível de R${' '}
          {formatValueToString(payment.payload.total)}
        </p>
      );
    }
    return (
      <p className="errorMessage">
        Valor excede o preço do produto de R${' '}
        {formatValueToString(order.totals.total)}
      </p>
    );
  };

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="titleRow">
        {t('STORE_CREDIT_TOTAL', 'Valor de saldo de créditos')}
      </div>
      <div className="textRow">
        {t(
          'CONFIRM_CREDIT_VALUE',
          'Digite o valor que o(a) cliente deseja utilizar de saldo de créditos.',
        )}
      </div>
      <div className="inputRow">
        <Input
          label="Digite o valor"
          value={inputValue}
          error={errorMessage}
          inputMode="numeric"
          pattern="[0-9]*"
          startAdornment="R$"
          onKeyUp={(e: React.FormEvent<HTMLInputElement>) =>
            handleInputChange(e)
          }
        />
        {errorMessage ? (
          <p className="errorMessage">{getErrorMessage()}</p>
        ) : (
          <p className="inputMessage">
            Saldo de créditos disponível: R${' '}
            {formatValueToString(payment.payload.total)}
          </p>
        )}
      </div>
      <Button
        onClick={() => {
          handleSubmit();
        }}
        disabled={!inputValue.length || inputValue === '0.00'}
      >
        {t('CONFIRM', 'CONFIRMAR')}
      </Button>
    </Container>
  );
};

export { ValueModal };
