import React from 'react';
import { useSelector } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';

// Component import
import { Avatar } from '@components/Avatar';
import { Button } from '@components/Button';

// Hook import
import { usePagePath } from '@hooks/usePagePath';
import { useTheme } from '@hooks/useTheme';
import { useOrderCreation } from '@hooks/useOrderCreation';
import { useAddProduct } from '@hooks/useAddProduct';
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container, Header } from '../styles';
import { Content } from './styles';

// Feature identification
const featureKey = '@create_order/SELECT_BRAND_SCREEN';

const SelectBrandScreen: React.FC = () => {
  // Hooks
  usePagePath(featureKey);
  const { t } = useTranslation(featureKey);
  const theme = useTheme();
  const order = useOrderCreation();
  const product = useAddProduct();

  // Global states
  const user = useSelector(state => state.auth);

  // Store without brand
  if (!user.store?.brands?.length)
    return (
      <Container className="error">
        <h2>
          {t(
            'WITHOUT_BRANDS',
            'Esta loja ainda não possui nenhuma marca ativa',
          )}
        </h2>
        <Button onClick={() => order.setAddingProduct(false)}>
          {t('WITHOUT_BRANDS_RETURN', 'VOLTAR')}
        </Button>
      </Container>
    );

  return (
    <Container>
      <Header>
        <button type="button" onClick={() => order.setAddingProduct(false)}>
          <MdArrowBack size={24} color={theme.font_primary} />
        </button>
      </Header>
      <Content>
        <h2>{t('SELECT_FULL_BRAND', 'Selecione uma marca para começar')}</h2>
        <ul>
          {user.store?.brands
            .filter(
              store_brand => store_brand.enabled && store_brand.brand.enabled,
            )
            .map(({ brand }) => (
              <li key={brand.id}>
                <button
                  type="button"
                  onClick={() => product.setSelectedBrandCode(brand.code)}
                >
                  <Avatar
                    key={brand.code}
                    name={brand.name}
                    size={75}
                    noBackground
                    fixedColor={theme.font_primary}
                    src={brand.image_url}
                  />
                  <span>{brand.name}</span>
                </button>
              </li>
            ))}
        </ul>
      </Content>
    </Container>
  );
};

export { SelectBrandScreen };
