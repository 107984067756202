import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: stretch;
  gap: 1rem;
  padding: 1rem;

  color: ${({ theme }) => theme.font_primary};

  h2 {
    font-size: 2rem;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
  }

  .animation {
    width: 31.25rem !important;
    max-width: 80vw !important;
  }
`;
