import styled from 'styled-components';
import MaterialTabs from '@material-ui/core/Tabs';

interface IProps {
  color?: string;
}

export const Container = styled(MaterialTabs)<IProps>`
  .MuiTabs-indicator {
    background: ${({ theme, color }) =>
      color || theme.font_secondary} !important;
  }

  * {
    text-transform: unset !important;
  }

  .Mui-selected {
    color: ${({ theme }) => theme.font_secondary} !important;
  }
`;
