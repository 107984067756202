import styled from 'styled-components';

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
  gap: 1rem;
  align-self: stretch;

  padding: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  gap: 2rem;
  padding-bottom: 15rem;
  flex: 1;
  position: relative;
  justify-content: space-around;

  .metadataSection {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 768px) {
      gap: 2rem;
    }
  }

  .loadingSection {
    justify-content: space-around;
  }

  .cancelButtonMobile {
    width: 100%;
    padding: 0 3rem;
    button {
      .buttonText {
        color: ${({ theme }) => theme.background_high};
      }
    }
  }

  .posPending {
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.font_secondary};
  }

  ul.items {
    /** items */
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 1rem;
    border-radius: 0.5rem;

    > li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      align-self: stretch;
      background: ${({ theme }) => theme.background_primary};
      border-radius: 0.5rem;
      height: 8.625rem;
      width: 100%;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;
        flex: 1;
        gap: 0.5rem;
        padding: 1rem 0rem 1rem 1rem;

        .name {
          font-size: 1rem;
          color: ${({ theme }) => theme.font_primary};
          /* white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; */
          width: -webkit-fill-available;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .brand {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.font_secondary};
        }

        .price {
          display: flex;
          gap: 1rem;

          font-size: 0.75rem;
          color: ${({ theme }) => theme.font_primary};

          .original_price {
            text-decoration: line-through;
          }

          .current_price {
            color: ${({ theme }) => theme.font_success};
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-self: stretch;
          gap: 1rem;

          .quantity {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem;

            button {
              min-width: auto;
              width: 1.2rem;
              height: 1.2rem;
            }

            .number {
              color: ${({ theme }) => theme.font_low};
              padding: 0 0.5rem;
            }
          }
        }
      }

      .right {
        height: 8.625rem;

        img {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          max-width: 8.625rem;
          height: 8.625rem;
        }
      }
    }
  }

  .infinite_aisle {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 2.5rem;

    @media screen and (max-width: 768px) {
      padding: 0 1rem;
    }

    .infinite_aisle_package {
      display: flex;
      flex-direction: column;

      .packageHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 0.5rem;
          justify-content: flex-start;
          align-items: normal;
        }

        div {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .title {
            color: ${({ theme }) => theme.font_secondary};
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
              font-size: 1.25rem;
              font-weight: 600;
              margin-left: 0.5rem;
            }
          }

          .packageCount {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.font_secondary};
            font-size: 1rem;
            font-weight: 600;

            .status {
              &.canceled {
                color: ${({ theme }) => theme.font_danger};
              }

              &.finished {
                color: ${({ theme }) => theme.font_success};
              }

              &.error {
                color: ${({ theme }) => theme.font_error};
              }

              &.pending {
                color: #9e9e9e;
              }
            }
          }

          .deliveryEstimate {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.font_secondary};
            span {
              font-size: 1rem;
            }
          }

          .invoicing {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.font_secondary};
          }
        }
      }
      .productsSection {
        display: flex;
        flex-direction: column;

        .title {
          display: flex;
          flex-direction: row;
          align-items: center;

          color: ${({ theme }) => theme.font_secondary};
          font-size: 1.25rem;
          font-weight: 600;

          p {
            margin-left: 0.75rem;
          }
        }

        .productItemRow {
          width: 100%;

          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          padding-bottom: 1rem;

          @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }

        .divider {
          width: 100%;
          border-top: 0.063rem solid ${({ theme }) => theme.font_secondary};
          margin-top: 2.5rem;
          border-radius: 0.125rem;
        }
      }
    }

    .divider {
      width: 100%;
      border-top: 0.063rem solid ${({ theme }) => theme.font_secondary};
      margin-top: 0.5rem;
      margin-bottom: 2.5rem;
    }
  }

  .own_stock {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 2.5rem;

    @media screen and (max-width: 768px) {
      padding: 0 1rem;
    }

    .infinite_aisle_package {
      display: flex;
      flex-direction: column;

      .packageHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 0.5rem;
          justify-content: flex-start;
          align-items: normal;
        }

        div {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .title {
            color: ${({ theme }) => theme.font_secondary};
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
              font-size: 1.25rem;
              font-weight: 600;
              margin-left: 0.5rem;
            }
          }

          .immediateDelivery {
            color: ${({ theme }) => theme.font_secondary};

            @media screen and (min-width: 768px) {
              font-size: 1rem;
              text-align: right;
            }
          }

          .documents {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            .documentDivider {
              border: solid 1px ${({ theme }) => theme.font_secondary};
              height: 1.25rem;
            }

            button.invoiceDocument {
              display: flex;
              flex-direction: row;
              width: fit-content;
              align-items: center;
              gap: 0.5rem;

              color: ${({ theme }) => theme.font_secondary};

              &.unavailable {
                color: ${({ theme }) => theme.button_hover};

                path {
                  fill: ${({ theme }) => theme.button_hover};
                }

                rect {
                  stroke: ${({ theme }) => theme.button_hover};
                }
              }

              svg {
                height: 1.5rem;
                width: 1.5rem;
              }

              path {
                fill: ${({ theme }) => theme.font_secondary};
              }

              rect {
                stroke: ${({ theme }) => theme.font_secondary};
              }

              p {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5rem;
                letter-spacing: 0.01rem;
              }
            }
          }

          .packageCount {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.font_secondary};

            .status {
              &.finished {
                color: ${({ theme }) => theme.font_success};
              }
            }
          }

          .deliveryEstimate {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.font_secondary};
            span {
              font-size: 1rem;
            }
          }

          .invoicing {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: ${({ theme }) => theme.font_secondary};
            cursor: pointer;
            gap: 3px;

            @media screen and (min-width: 768px) {
              justify-content: flex-end;
            }

            span {
              text-decoration: underline;
            }

            svg {
              height: 20px;

              @media screen and (min-width: 768px) {
                font-size: 16px;
              }
            }
          }

          .disabled {
            pointer-events: none;
          }
        }
      }
      .productsSection {
        display: flex;
        flex-direction: column;

        .title {
          display: flex;
          flex-direction: row;
          align-items: center;

          color: ${({ theme }) => theme.font_secondary};
          font-size: 1.25rem;
          font-weight: 600;

          p {
            margin-left: 0.75rem;
          }
        }

        .productItemRow {
          width: 100%;

          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          padding-bottom: 1rem;

          @media screen and (max-width: 768px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }

        .divider {
          width: 100%;
          border-top: 0.063rem solid ${({ theme }) => theme.font_secondary};
          margin-top: 2.5rem;
          border-radius: 0.125rem;
        }
      }
    }

    .divider {
      width: 100%;
      border-top: 0.063rem solid ${({ theme }) => theme.font_secondary};
      margin-top: 0.5rem;
      margin-bottom: 2.5rem;
    }
  }

  @media screen and (max-width: 1440px) {
    ul.items {
      /** items */
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 1024px) {
    ul.items {
      /** items */
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @media screen and (max-width: 500px) {
    ul.items {
      > li {
        height: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 1rem;

        .left {
          padding: 1rem;
          .name {
            white-space: normal;
            text-overflow: initial;
          }
        }

        .right {
          img {
            border-radius: 0.5rem;
          }
        }
      }
    }
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: stretch;
  gap: 0.5rem;
  color: ${({ theme }) => theme.font_secondary};
  font-weight: 700;
  font-size: 1.25rem;
  padding: 1rem 2rem;

  &.error {
    color: ${({ theme }) => theme.font_error};
  }

  &.canceled {
    color: ${({ theme }) => theme.font_danger};
  }

  &.success {
    color: ${({ theme }) => theme.font_success};
  }

  &.pending {
    color: ${({ theme }) => theme.font_disabled};
  }

  @media screen and (max-width: 768px) {
    padding: 0rem 2rem;
  }
`;

export const Time = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 1rem;
  align-self: stretch;
  font-weight: bold;

  padding: 0.75rem;

  color: ${({ theme }) => theme.font_low};
  background: ${({ theme }) => theme.background_warning};

  &.error {
    color: ${({ theme }) => theme.font_low};
    background: ${({ theme }) => theme.background_error};
  }

  &.pending {
    color: ${({ theme }) => theme.font_low};
    background: ${({ theme }) => theme.background_disabled};
  }

  &.canceled {
    color: ${({ theme }) => theme.font_low};
    background: ${({ theme }) => theme.background_danger};
  }

  &.success {
    color: ${({ theme }) => theme.font_low};
    background: ${({ theme }) => theme.background_success};
  }
`;

export const Payments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  align-self: stretch;
  gap: 1rem;

  ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 1rem;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      align-self: stretch;
      background: ${({ theme }) => theme.background_primary};
      padding: 1rem 2.5rem;

      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
        flex: 1;

        .info {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
          flex-basis: 0;
          gap: 1rem;

          span.count {
            color: ${({ theme }) => theme.font_secondary};
            font-weight: bold;
            font-size: 1.5rem;
          }

          > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            span.type {
              color: ${({ theme }) => theme.font_primary};
              font-size: 1rem;
              font-weight: bold;
            }

            span.status {
              font-size: 1rem;

              .statusRow {
                display: flex;
                flex-direction: row;
                align-items: center;

                .errorSection {
                  position: relative;
                  margin-left: 0.25rem;

                  button {
                    padding: 0;
                    color: ${({ theme }) => theme.button_error};
                    font-size: 1.25rem;
                    div {
                      display: flex;
                    }
                  }

                  .tooltip {
                    position: absolute;
                    left: -4.35rem;
                  }
                }
              }

              &.paid {
                color: ${({ theme }) => theme.font_success};
              }

              &.canceled {
                color: ${({ theme }) => theme.font_danger};
              }

              &.pending {
                color: ${({ theme }) => theme.font_disabled};
                button {
                  color: ${({ theme }) => theme.button_primary};
                  text-decoration: underline;
                }
              }

              &.to_adjust {
                color: ${({ theme }) => theme.font_error};
              }
            }
          }
        }

        .actions {
          button.round {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            padding: 0;
            margin: 0;
            background: ${({ theme }) => theme.background_success};
            padding: 0.5rem;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .total,
        .paid {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          gap: 0.5rem;

          span.label {
            font-size: 1rem;
            color: ${({ theme }) => theme.font_secondary};
            font-weight: bold;
          }

          span.value {
            font-size: 1rem;
            color: ${({ theme }) => theme.font_primary};
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .floating {
      width: 100vw;
    }

    ul {
      li {
        flex-direction: column;
        align-items: baseline;
        justify-content: flex-start;
        padding: 1rem;

        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 1rem;
          flex: 1;

          .info {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex-grow: 1;
            flex-basis: 0;
            gap: 1rem;

            span.count {
              color: ${({ theme }) => theme.font_secondary};
              font-weight: bold;
              font-size: 1.5rem;
            }

            > div {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              span.type {
                color: ${({ theme }) => theme.font_primary};
                font-size: 1rem;
                font-weight: 400;
              }

              span.status {
                font-size: 1rem;

                .statusRow {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  .errorSection {
                    position: relative;
                    margin-left: 0.25rem;

                    button {
                      padding: 0;
                      color: ${({ theme }) => theme.button_error};
                      font-size: 1.25rem;
                      div {
                        display: flex;
                      }
                    }

                    .tooltip {
                      position: absolute;
                      left: -4.35rem;
                    }
                  }
                }

                &.paid {
                  color: ${({ theme }) => theme.font_success};
                }

                &.canceled {
                  color: ${({ theme }) => theme.font_danger};
                }

                &.pending {
                  color: ${({ theme }) => theme.font_disabled};
                }

                &.to_adjust {
                  color: ${({ theme }) => theme.font_error};
                }
              }
            }
          }

          .actions {
            button.round {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              padding: 0;
              margin: 0;
              background: ${({ theme }) => theme.background_success};
              padding: 0.5rem;
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;

          .total {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
            gap: 0.5rem;

            span.label {
              font-size: 1rem;
              color: ${({ theme }) => theme.font_secondary};
              font-weight: bold;
            }

            span.value {
              font-size: 1rem;
              color: ${({ theme }) => theme.font_primary};
            }
          }

          .paid {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: center;
            gap: 0.5rem;
            margin-right: 1rem;

            span.label {
              font-size: 1rem;
              color: ${({ theme }) => theme.font_secondary};
              font-weight: bold;
            }

            span.value {
              font-size: 1rem;
              color: ${({ theme }) => theme.font_primary};
            }
          }
        }
      }
    }
  }
`;

export const Floating = styled.div`
  position: sticky;
  bottom: 0;
  background: ${({ theme }) => theme.background_low};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  margin-right: 2rem;
  margin-left: 2rem;

  align-self: stretch;

  margin-top: 1rem;
  border-top: 0.188rem solid ${({ theme }) => theme.font_secondary};
  border-radius: 0.125rem;
  padding: 1rem 0;

  gap: 1rem;

  width: -webkit-fill-available;
  width: -moz-available;
  .buttonSection {
    width: 18rem;

    button {
      border-radius: 0.5rem;
      .buttonText {
        color: ${({ theme }) => theme.font_primary};
      }
      .remakeText {
        color: ${({ theme }) => theme.background_primary};
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const Metadata = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 2.5rem;

  .customerInfo {
    display: flex;
    flex-direction: column;

    width: 50%;
    gap: 0.25rem;
    div {
      span {
        font-size: 1rem;
      }
    }
  }

  .divider {
    width: 0.125rem;
    height: 90%;
    border-right: solid ${({ theme }) => theme.font_secondary};
    margin-right: 4rem;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    min-width: 100vw;
    border-radius: 0;

    > div {
      border-radius: 0;
    }
  }
`;

export const AdditionalInformation = styled.div`
  padding: 0 40px;
  width: 100%;
  .infoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: solid 0.125rem ${({ theme }) => theme.font_secondary};
    border-bottom: solid 0.125rem ${({ theme }) => theme.font_secondary};
    width: 100%;
    height: 4.5rem;

    div {
      .attendance {
        margin-left: 0.5rem;
      }
      .info {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.font_disabled};
      }
    }

    .divider {
      height: 70%;
      border-left: solid 0.125rem ${({ theme }) => theme.font_secondary};
      margin: 0 3.75rem;
    }
  }
`;

export const AdditionalInformationMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  width: 100%;

  .divider {
    height: 0.125rem;
    width: 100%;
    background-color: ${({ theme }) => theme.background_secondary};
  }

  .rowSection {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-weight: 400;
      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.875rem;
    }

    .info {
      font-weight: 400;
      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
    }
  }
`;

export const ProductItemDesktop = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  background: ${({ theme }) => theme.background_primary};
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  min-height: 11.688rem;
  margin-right: 0.5rem;

  &.unavailable {
    box-shadow: 0 0 0 0.188rem ${({ theme }) => theme.font_danger};
  }

  .image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    align-self: stretch;
    width: 50%;

    img {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      object-fit: cover;
      flex: 1;
      align-self: stretch;
      max-width: 8.625rem;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    height: 100%;
    width: 100%;
    padding: 1rem;
    overflow: hidden;

    .name {
      font-size: 1rem;
      font-weight: 400;
      color: ${({ theme }) => theme.font_primary};
      width: -webkit-fill-available;
      margin-bottom: 0.25rem;
      P {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .infoRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;
      margin-bottom: 0.25rem;

      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
      font-weight: 400;

      p:first-child {
        margin-right: 1rem;
      }
    }

    .colorAndSizeRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 80%;

      color: ${({ theme }) => theme.font_secondary};
      font-size: 0.75rem;
      font-weight: 400;

      .size {
        color: ${({ theme }) => theme.font_primary};
      }

      button {
        display: flex;
        flex-direction: row;

        .changeButtonText {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.font_primary};
          text-decoration: underline;
          margin-right: 0.125rem;
        }
      }
    }

    .price {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;

      font-size: 1rem;
      color: ${({ theme }) => theme.font_primary};

      .original_price {
        text-decoration: line-through;
        color: ${({ theme }) => theme.button_secondary};
      }
    }

    .editDelivery {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      button {
        p {
          font-size: 0.75rem;
          font-weight: 400;
          text-decoration: underline;
          color: ${({ theme }) => theme.font_secondary};
        }
      }
    }

    .quantityRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      gap: 1rem;

      p {
        font-size: 0.75rem;
        color: ${({ theme }) => theme.font_secondary};
        margin-right: 1rem;
      }

      .quantity {
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.background_high};
        border-radius: 0.25rem;

        button {
          min-width: auto;
          width: 1.2rem;
          height: 1.2rem;
        }

        .number {
          color: ${({ theme }) => theme.font_low};
          padding: 0 0.5rem;
        }
      }
    }
  }
`;

export const Totals = styled.div`
  padding: 0 40px;
  width: 100%;

  .panel {
    background-color: ${({ theme }) => theme.background_secondary};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .panelRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      div {
        color: ${({ theme }) => theme.font_low};
        .discountValue {
          color: ${({ theme }) => theme.font_danger};
        }
      }
    }
  }
`;

export const TotalsMobile = styled.div`
  width: 100%;

  .panel {
    background-color: ${({ theme }) => theme.background_secondary};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .panelRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      div {
        color: ${({ theme }) => theme.font_low};
        .discountValue {
          color: ${({ theme }) => theme.font_danger};
        }
      }
    }
  }
`;
