import React from 'react';
import { MdOutlineClose } from 'react-icons/md';

// Asset import
import { SurveyModalIcon } from '@assets/index';

// Component import
import { Button } from '@components/Button';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

// Style import
import { Container } from './styles';

// Interfaces
interface IProps {
  hide?: () => any;
  setSelectedStep: React.Dispatch<
    React.SetStateAction<
      'salutation' | 'first_step' | 'second_step' | 'third_step' | 'farewell'
    >
  >;
}

// Feature identification
const featureKey = '@satisfaction_survey/SATISFACTION_SURVEY_MODAL';

const SatisfactionSurveyModal: React.FC<IProps> = ({
  hide,
  setSelectedStep,
}) => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <Container>
      <div className="closeRow">
        <button
          type="button"
          onClick={() => {
            // Hide modal
            if (typeof hide === 'function') hide();
          }}
        >
          <MdOutlineClose className="closeIcon" />
        </button>
      </div>
      <div className="iconRow">
        <SurveyModalIcon className="surveyIcon" />
        <p>
          {t(
            'TELL_US_YOUR_EXPERIENCE',
            'Quer nos contar sobre sua experiência no Soma Store?',
          )}
        </p>
      </div>
      <div className="textRow">
        <p>
          {t('ANSWER_IN_3_STEPS', 'Responda esta rápida pesquisa em 3 etapas!')}
        </p>
      </div>
      <div className="actionArea">
        <Button
          onClick={() => {
            setSelectedStep('first_step');
          }}
        >
          <p className="modalButton">{t('START', 'COMEÇAR')}</p>
        </Button>
      </div>
    </Container>
  );
};

export { SatisfactionSurveyModal };
