import React from 'react';

// Asset import
import { PrintDisabledIcon } from '@assets/index';

// Hook import
import { useTranslation } from '@hooks/useTranslation';

import { Container } from './styles';

// Feature identification
const featureKey = '@component/PRINTER_CONFIG_EMPTY_LIST';

const EmptyListState: React.FC = () => {
  // Hooks
  const { t } = useTranslation(featureKey);

  return (
    <Container>
      <PrintDisabledIcon width={120} />
      <div className="title">
        {t('NO_CONNECTED_PRINTERS', 'Não há impressoras conectadas')}
      </div>
      <div className="subtitle">
        {t(
          'VERIFY_CONNECTION',
          'Verifique a conexão da internet da loja com a impressora e tente novamente mais tarde.',
        )}
      </div>
    </Container>
  );
};

export { EmptyListState };
