import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;

  margin-top: 1rem;

  h3 {
    font-weight: bold;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.font_primary};
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: flex-start;

    flex: 1;

    min-width: 30%;
  }
`;
