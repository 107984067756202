import { useEffect, EffectCallback, DependencyList, useRef } from 'react';

export const useNonInitialEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const initialRender = useRef(true);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let effectReturns: void | (() => void | any) = () => {};

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    return effectReturns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
